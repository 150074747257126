import React from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button';
import CardButton from 'components/CardButton';
import FeatureCard from 'components/FeatureCard';
import MarketplaceHeroesScene from 'components/MarketplaceHeroesScene';
import NewsletterBox from 'components/NewsletterBox';
import PageTitle from 'components/PageTitle';
import { asset } from 'helpers';
import { scrollTo } from 'sf/helpers/domHelper';
import device from 'models/device';
import BasePage from 'pages/BasePage';
import * as MarketplaceHeroesIndexActions
  from 'pages/MarketplaceHeroesIndex/MarketplaceHeroesIndex.actions';

let cn;

export class MarketplaceHeroesIndexComponent extends BasePage {
  className = 'ts-MarketplaceHeroesIndex';
  title = 'Marketplace Heroes';

  state = {}

  static propTypes = {
    actions: PropTypes.shape({
      downloadMaterials: PropTypes.func,
      getStarted: PropTypes.func,
    }),
  }

  constructor(props) {
    super(props);
    cn = this.cn();
  }

  componentDidMount() {
    this.syncStateWithModel(device, ['xsmUp']);
  }

  handleDownloadButtonClick = () => {
    this.props.actions.downloadMaterials();
  }

  handleSignUpButtonClick = () => {
    this.props.actions.getStarted();
    scrollTo(findDOMNode(this.signUpForm));
  }

  renderAppSection = () => {
    return (
      <section
        className={ cn`__section __section--app` }
      >
        <div className={ cn`ts-container ts-container--narrow` }>
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              color="green"
              size="huge"
              title="Trust Stamp"
              underline={ false }
            />
            <div className={ cn`__app-description` }>
              <p>
                { /* eslint-disable max-len */ }
                Trust Stamp is the most convientent and cost effective online tool that allows you to create a verified profile in 90 seconds. We use over 200 Public Data sources and compare them with Social Media, Driver License and Facial Recognition data to deliver a reliable Trustworthiness Score. You can use it to assess at a glance whether someone is Trustworthy.
                { /* eslint-enable max-len */ }
              </p>
              <p>
                { /* eslint-disable max-len */ }
                This helps both sides of a transaction feel safe. It doesn’t matter whether you are a Twitter Influencer, Facebook Admin in a buy/sell group, blogger or any kind of active member in the internet community you can keep the people in your network safe.
                { /* eslint-enable max-len */ }
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderBenefits = () => {
    /* eslint-disable max-len */
    const benefits = [
      {
        title: 'Donate 25¢ to the charity of your choice',
        text: 'for every Trust Stamp your network uses',
      }, {
        title: 'Enter you into a raffle for a mystery high-tech gift',
        text: 'and give you a chance to win awesome tech gadget from Trust Stamp',
      }, {
        title: 'Recognize you on a press release listing',
        text: 'how many people you promoted safety to and how much you raised for charity',
      },
    ];
    /* eslint-enable max-len */
    return benefits.map((benefit, index) => {
      const number = index + 1;
      return (
        <div
          className="ts-steps__item"
          key={ benefit.title }
        >
          <FeatureCard
            title={ benefit.title }
            text={ benefit.text }
            imageUrl={ asset`img/marketplace-heroes/benefits-${number}.svg` }
            number={ number }
          />
        </div>
      );
    });
  }

  renderBenefitsSection = () => {
    return (
      <section
        className={ cn`__section __section--benefits` }
      >
        <div className={ cn`ts-container` }>
          <PageTitle
            size="big"
            title="What are the benefits of being a Marketplace Hero?"
          />
          <div className={ cn`__steps` }>
            <div className={ cn`__steps-description` }>
              <p>
                { /* eslint-disable max-len */ }
                As a Marketplace Hero you will receive a unique discount code for your network, become eligible for a profit-share opportunity, and at the end of the campaign we’ll:
                { /* eslint-enable max-len */ }
              </p>
            </div>
            <div className="ts-steps">
              { this.renderBenefits() }
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderExplanationSection = () => {
    return (
      <section
        className={ cn`__section __section--explanation` }
      >
        <div className={ cn`ts-container` }>
          <PageTitle
            size="big"
            title="Learn more about our campaign"
          />
          <div className={ cn`__explanation-description` }>
            { /* eslint-disable max-len */ }
            <div>
              You can download materials for your network by using the link below.
            </div>
            <div>
              Paste them into your Social Media to explain your followers how they can increase their safety by using Trust Stamp.
            </div>
            { /* eslint-enable max-len */ }
          </div>
          <div className={ cn`__download-button` }>
            <CardButton
              onClick={ this.handleDownloadButtonClick }
              icon="ios-cloud-download"
              iconSet="io"
              title="Download materials"
            />
          </div>
        </div>
      </section>
    );
  }

  renderIntroSection = () => {
    return (
      <section className={ cn`__section __section--intro` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <div className={ cn`__title` }>
              <PageTitle
                align="left"
                size={ this.state.xsmUp ? 'huge' : 'big' }
                color="green"
                title="Become a Marketplace Hero!"
              />
            </div>
            <div className={ cn`__description` }>
              <p>
                { /* eslint-disable max-len */ }
                Are you an influential person within an online community? You can help increase the safety of online transactions and help reduce stranger danger when meeting someone in person. It will also help save other members from any dangers that come along with any kind of online trading.
                { /* eslint-enable max-len */ }
              </p>
            </div>
            <div className={ this.cn`__sign-up-button` }>
              <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
                Get Started
              </Button>
            </div>
          </div>
        </div>
        <div className={ this.cn`__scene` }>
          <MarketplaceHeroesScene />
        </div>
      </section>
    );
  }

  renderNewsletterSection() {
    return (
      <section className={ cn`__section __section--newsletter` }>
        <div className="ts-container">
          <PageTitle
            title="Newsletter"
            color="white"
          />
          <div className={ cn`__newsletter-title` }>
            Subscribe to our newsletter and stay updated
          </div>
          <div className={ cn`__newsletter` }>
            <NewsletterBox showTitle={ false } />
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderIntroSection() }
        { this.renderBenefitsSection() }
        { this.renderAppSection() }
        { this.renderExplanationSection() }
        { this.renderNewsletterSection() }
      </div>
    );
  }
}

const mapStateToProps = ({ marketplaceHeroesIndex }) => {
  return { ...marketplaceHeroesIndex };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MarketplaceHeroesIndexActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceHeroesIndexComponent);
