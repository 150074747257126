import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogText } from 'components/Dialog';
import InviteContactInputs from 'components/InviteContactInputs';
import Payment from 'components/Payment';
import Radio from 'components/Radio';
import Stamp from 'components/Stamp';
import config from 'config';
import { loadFacebookSdk } from 'helpers';
import analytics from 'models/analytics';
import user from 'models/user';

const SCREEN = {
  MAIN: Symbol('Main screen'),
  EMAIL: Symbol('Email screen'),
  FACEBOOK: Symbol('Facebook screen'),
  PAYMENT: Symbol('Payment screen'),
};

const initialState = {
  facebookShare: null,
  invitationId: 0,
  invitedContacts: [],
  paymentInProgress: false,
  screen: SCREEN.MAIN,
  shouldOwnerPay: undefined, // Must be undefined for unchecked radio behaviour
};

export default class ShareProfileDialog extends BaseComponent {
  className = 'ts-ShareProfileDialog';

  facebookShareUrl = null;
  state = {
    ...initialState,
  };

  static propTypes = {
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    onDismiss: noop,
  };

  componentDidMount() {
    this.syncStateWithModel(user, ['number_of_credits', 'credit_price']);
  }

  show() {
    this.dialogComponent.toggle(true);
    loadFacebookSdk();
  }

  hide() {
    this.dialogComponent.toggle(false);
  }

  handleOnClose = () => {
    this.resetState();
    this.props.onDismiss();
  };

  resetState = () => {
    // WARN: replaceState is not available in ES6
    // https://github.com/substance/archivist-writer/issues/14
    // TODO: move it to BaseComponent if needed anywhere else.
    const replacedState = Object.keys(this.state).reduce((state, stateField) => {
      state[stateField] = initialState[stateField];
      return state;
    }, {});
    // We must preserve synced values (could be in BaseComponent also?)
    ['number_of_credits'].forEach((key) => {
      replacedState[key] = this.state[key];
    });
    // Remember to reset first contact:
    replacedState.invitedContacts[0] = { id: 0, text: '' };
    this.setState(replacedState);
  };

  trimInputs() {
    const trimmedContacts = this.state.invitedContacts.map((contact) => ({
      ...contact,
      text: contact.text.trim(),
    })).filter((contact) => contact.text !== '');

    this.setState({ invitedContacts: trimmedContacts });
  }

  validateInvites(list) {
    const invalidInvites = [];
    const isValid = list.reduce((validAcc, item) => {
      const contact = item.text.trim();
      const isCurrentValid = is.string.isEmail(contact)
        || is.string.isPhone(contact, 'us')
        || is.string.isBlank(contact);
      if (!isCurrentValid) invalidInvites.push(item.id);
      return validAcc && isCurrentValid;
    }, true);

    return {
      isValid,
      invalidInvites,
    };
  }

  // This function shouldn't be called when state.shouldOwnerPay === undefined
  processInvites = () => {
    const { invitedContacts } = this.state;
    const { isValid, invalidInvites } = this.validateInvites(invitedContacts);

    this.setState({
      invalidInvites,
    });

    if (!isValid) {
      this.dialogComponent.showFloatingText({
        text: 'Provided contact channel is not valid',
        isValid: false,
      });
      return;
    }

    if (invitedContacts.length === 1 && !invitedContacts[0].text.match(/\w/)) {
      this.dialogComponent.showFloatingText({
        text: 'Please enter either an email or a phone number',
        isValid: false,
      });
      return;
    }

    this.trimInputs();
    this.proceedWithShare();
  };

  sendAccess = () => {
    if (this.state.facebookShare) {
      user.sendTokenFacebook(this.state.shouldOwnerPay)
        .then(({ url }) => this.openFacebookShareScreen(url));
      return;
    }

    const fail = ({ contacts, errors }) => {
      const firstErrorMessage = errors[0] && errors[0].message ||
        'Something went wrong. Try again later.';

      this.dialogComponent.showFloatingText({
        text: firstErrorMessage,
        isValid: false,
      });
      user.getProfileData().then(() => {
        const filteredContacts = this.state.invitedContacts
          .filter((contact) => contacts.includes(contact.text));
        this.setState({
          paymentInProgress: false,
          invitedContacts: filteredContacts,
          screen: SCREEN.EMAIL,
        });
      });
    };
    const done = () => {
      this.state.invitedContacts.forEach((contact) => {
        const trackEventName = this.state.shouldOwnerPay ?
          'WEBAPP_PREPAID_PROFILE_SHARE_SENT' :
          'WEBAPP_PROFILE_SHARE_SENT';

        analytics.trackEvent(trackEventName, {
          email: contact.text,
        });
      });
      this.dialogComponent.showFloatingText({
        text: 'Token successfully sent',
        autoHide: true,
      }, this.handleOnClose);
      user.getProfileData();
    };
    const tokens = this.state.invitedContacts.map((invite) => invite.text);

    this.setState({ paymentInProgress: true });
    user.sendTokensBatch(tokens, this.state.shouldOwnerPay).then(done, fail);
  };

  updateInvitationList = (invitedContacts) => {
    this.setState({
      invitedContacts,
    });
  };

  setPaymentInProgress = () => {
    this.setState({ paymentInProgress: true });
  };

  handlePaymentFailure = (errorMsg) => {
    this.setState({ paymentInProgress: false });
    this.dialogComponent.showFloatingText({
      text: errorMsg || 'Payment failed',
      isValid: false,
    });
  };

  openFacebookShareScreen = (url) => {
    this.facebookShareUrl = url;
    this.openScreen(SCREEN.FACEBOOK, () => FB && FB.XFBML.parse(this.facebookShareWrapperNode));
  };

  openPaymentScreen = () => {
    this.openScreen(SCREEN.PAYMENT);
  };

  openContactInputScreen = () => {
    this.openScreen(SCREEN.EMAIL);
  };

  openScreen = (screen, callback = noop) => {
    this.setState({ screen }, callback);
  };

  proceedWithFacebookShare = () => {
    this.setState({
      facebookShare: true,
    }, this.proceedWithShare);
  };

  proceedWithShare = () => {
    if (this.state.shouldOwnerPay) {
      this.openPaymentScreen();
    } else {
      this.sendAccess();
    }
  };

  renderMainScreen = () => {
    // when app is free skip payment screens and go directly to email inputs
    if (this.state.credit_price === -1) {
      this.openContactInputScreen();
      return null;
    }
    let buttonText;
    if (this.state.shouldOwnerPay === undefined) {
      buttonText = 'Select an option';
    } else if (this.state.shouldOwnerPay === true) {
      buttonText = 'Go to sharing';
    } else if (this.state.shouldOwnerPay === false) {
      buttonText = 'Send your profile link';
    }

    return (
      <DialogText>
        <div className={ this.cn`__payment-method` }>
          <div className={ this.cn`__payment-item` }>
            <Radio
              className={ this.cn`__radio` }
              stateLink={ [this, 'shouldOwnerPay'] }
              value={ true }
              style="radio"
            >
              I will pay for access
            </Radio>
            <div className={ this.cn`__payment-info` }>
              <Stamp>Use</Stamp>, PayPal or Credit Card to prepay the profile view
            </div>
          </div>
          <div className="ts-vertical-or" />
          <div className="ts-or" />
          <div className={ this.cn`__payment-item` }>
            <Radio
              className={ this.cn`__radio` }
              stateLink={ [this, 'shouldOwnerPay'] }
              value={ false }
              style="radio"
            >
              Visitor will pay for access
            </Radio>
            <div className={ this.cn`__payment-info` }>
              The recipient of the link will have to pay <Stamp>1</Stamp> for viewing your profile
            </div>
          </div>
        </div>
        <Button
          onClick={ this.openContactInputScreen }
          theme="success"
          disabled={ this.state.shouldOwnerPay === undefined }
        >
          { buttonText }
        </Button>
      </DialogText>
    );
  };

  renderContactInputScreen = () => {
    return (
      <DialogText>
        <div>Enter email address or cell phone number below</div>
        <InviteContactInputs
          invalidInvites={ this.state.invalidInvites }
          onUpdate={ this.updateInvitationList }
        />
        <Button
          disabled={ this.state.invitedContacts.length === 0 }
          theme="success"
          onClick={ this.processInvites }
        >
          { this.state.shouldOwnerPay ? 'Proceed to payment' : 'Share' }
        </Button>
        <Button
          className={ this.cn`__share-with-facebook-button` }
          disabled={ this.state.invitedContacts.length === 0 }
          theme="blue"
          onClick={ this.proceedWithFacebookShare }
        >
          Share with Facebook
        </Button>
      </DialogText>
    );
  };

  renderFacebookShareScreen = () => {
    const url = this.facebookShareUrl;
    return (
      <DialogText rel={ this.createRef('facebookShareWrapperNode') }>
        <div
          className="fb-share-button"
          data-href={ url }
          data-layout="button"
          data-size="large"
          data-mobile-iframe="true"
        >
          <a
            className="fb-xfbml-parse-ignore"
            target="_blank"
            rel="noopener noreferrer"
            href={ `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}%2F&amp;src=sdkpreparse` }
          >
            Share
          </a>
        </div>
      </DialogText>
    );
  };

  renderPaymentScreen = () => {
    const credits = parseInt(this.state.number_of_credits, 10);
    const invitationsCount = this.state.invitedContacts.length;
    const invitationsAvailable = Math.floor(credits / config.invitationCost);
    const insufficientCredits = invitationsCount * config.invitationCost > credits;
    return (
      <DialogText>
        <div className={ this.cn`__payment-method` }>
          <div className={ this.cn`__payment-item` }>
            <div className={ this.cn`__payment-item-header` }>
              You currently have <Stamp>{ credits }</Stamp>
            </div>
            <div className={ this.cn`__payment-item-text` }>
              You can send your link to { invitationsAvailable > 1
                ? `${invitationsAvailable} people` : 'one person' }
            </div>
            <Button
              disabled={ insufficientCredits || this.state.paymentInProgress }
              theme="success"
              onClick={ this.sendAccess }
            >
              Use your credits
            </Button>
          </div>
          <div className="ts-vertical-or" />
          <div className="ts-or" />
          <div className={ this.cn`__payment-item` }>
            <div className={ this.cn`__payment-item-header` }>
              Pay with PayPal or Credit Card
            </div>
            <div className={ this.cn`__payment-item-text` }>
              Send link without using your balance
            </div>
            <Payment
              amount={ invitationsCount * config.invitationCost }
              disabled={ this.state.paymentInProgress }
              options={ [{
                text: `Pay $${invitationsCount * config.invitationCost * config.creditPrice}`,
                value: invitationsCount * config.invitationCost * config.creditPrice,
              }] }
              onError={ this.handlePaymentFailure }
              onSubmit={ this.setPaymentInProgress }
              onSuccess={ this.sendAccess }
            />
          </div>
        </div>
      </DialogText>
    );
  };

  getScreenRenderMethod = {
    [SCREEN.MAIN]: this.renderMainScreen,
    [SCREEN.PAYMENT]: this.renderPaymentScreen,
    [SCREEN.EMAIL]: this.renderContactInputScreen,
    [SCREEN.FACEBOOK]: this.renderFacebookShareScreen,
  };

  dialogTitles = {
    [SCREEN.MAIN]: () => 'Share your profile',
    [SCREEN.PAYMENT]: () => {
      const invitations = this.state.invitedContacts.length;
      const personPhrase = invitations > 1 ? `${invitations} people` : 'one person';
      return `Share your profile with ${personPhrase}`;
    },
    [SCREEN.EMAIL]: () => 'Share your profile link',
    [SCREEN.FACEBOOK]: () => 'Share your profile link with Facebook',
  }

  render() {
    return (
      <Dialog
        className={ this.rootcn() }
        ref={ (component) => this.dialogComponent = component }
        title={ this.dialogTitles[this.state.screen]() }
        onDismiss={ this.handleOnClose }
      >
        { this.getScreenRenderMethod[this.state.screen]() }
      </Dialog>
    );
  }
}
