import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'helpers';

// TODO: Add beautiful parallax

export default class MarketplaceHeroesScene extends BaseComponent {
  className = 'ts-MarketplaceHeroesScene';

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const layers = [
      {
        name: 'city',
        src: asset`/img/marketplace-heroes/city.svg`,
      }, {
        name: 'clouds',
        src: asset`/img/marketplace-heroes/clouds.svg`,
      }, {
        name: 'heroes',
        src: asset`/img/marketplace-heroes/heroes.svg`,
      },
    ];
    return (
      <div className={ this.rootcn`` }>
        { layers.map(({ name, src }) => (
          <img
            alt=""
            className={ this.cn`__layer __layer--${name}` }
            key={ name }
            src={ src }
          />
        )) }
      </div>
    );
  }
}
