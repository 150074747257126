import React from 'react';
import is from 'next-is';
import BaseComponent from 'components/BaseComponent';
import help from 'models/help';
import user from 'models/user';
import { DATA, STEPS } from './ResetPassword.constants';
import ResetPasswordEmail from './ResetPasswordEmail';
import ResetPasswordLinkReceived from './ResetPasswordLinkReceived';
import ResetPasswordLinkSent from './ResetPasswordLinkSent';
import ResetPasswordNewPassword from './ResetPasswordNewPassword';
import ResetPasswordSelfie from './ResetPasswordSelfie';

export default class ResetPasswordComponent extends BaseComponent {
  state = {
    resetPasswordStep: STEPS.SELFIE,
  };

  componentDidMount() {
    this.syncStateWithModel(user, [
      'reset_password_selfie',
      'reset_password_code',
    ]);
  }

  handleStepSubmit = (data) => {
    switch (this.props.step) {
      case STEPS.SELFIE: {
        // take a selfie and go to email capture
        user.saveSelfieForPasswordResetAndProceed(
          data,
          DATA[this.props.step].nextUrl
        );
        break;
      }
      case STEPS.EMAIL: {
        // capture an email get stored selfie and request for a email with password reset link
        user.sendEmailAndSelfieForPasswordReset(
          DATA[this.props.step].nextUrl
        );
        break;
      }
      case STEPS.LINK_RECEIVED: {
        // get link from email, capture and store selfie without redirect;

        user.saveSelfieForPasswordReset(data);
        // perform login and redirect on success to capture new password
        user.loginWithSelfie(
          this.props.code,
          DATA[this.props.step].nextUrl
        );
        break;
      }
      case STEPS.NEW_PASSWORD: {
        // get captured selfie, code and new passwords and send them to set a new password
        user.setNewPassword(
          DATA[this.props.step].nextUrl
        );
        break;
      }
      default:
        break;
    }
  };

  validateStepProgress = (props) => {
    // step and requirements validations
    if (props.step === STEPS.EMAIL) {
      // cannot proceed without selfie stored in a store
      if (!user.get('reset_password_selfie')) {
        help.addNotification({
          title: 'Error',
          type: 'error',
          value: 'You need a selfie to proceed. Take one.',
        });
        this.navigate('/business/ResetPassword/Selfie.html', 'replace');
      }
    }
    if (props.step === STEPS.LINK_RECEIVED) {
      // cannot proceed without a code from email
      if (!this.state.reset_password_code) {
        /* eslint-disable max-len */
        help.addNotification({
          title: 'Error',
          type: 'error',
          value: 'Your reset code from url is missing! Click received link once again or try to reset a password from the beginning.',
        });
        /* eslint-enable max-len */
        this.navigate('/business/ResetPassword/Selfie.html', 'replace');
      }
    }
    if (props.step === STEPS.NEW_PASSWORD) {
      // cannot proceed without a code from email and selfie stored in a store
      if (!this.state.reset_password_selfie || !this.state.reset_password_code) {
        this.navigate(
          `/business/ResetPassword/LinkReceived.html?code=${this.props.code}`,
          'replace'
        );
      }
    }
  }

  componentDidUpdate(nextProps) {
    if (is.browser()) {
      this.validateStepProgress(nextProps);
    }
  }

  getContentComponent = (step) => {
    switch (step) {
      case STEPS.EMAIL:
        return ResetPasswordEmail;
      case STEPS.LINK_SENT:
        return ResetPasswordLinkSent;
      case STEPS.LINK_RECEIVED:
        return ResetPasswordLinkReceived;
      case STEPS.NEW_PASSWORD:
        return ResetPasswordNewPassword;
      case STEPS.SELFIE:
      default:
        return ResetPasswordSelfie;
    }
  };

  render() {
    const Component = this.getContentComponent(this.props.step);
    return (<div>
      <Component
        onSubmit={ this.handleStepSubmit }
      />
    </div>);
  }
}
