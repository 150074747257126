import React from 'react';
import _first from 'lodash/first';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import Select from 'components/Select';
import states from 'models/state';
import Img from 'sf/components/Img';
import PhotoCaptureButton from 'sf/components/PhotoCaptureButton';

import {
  DOCUMENT_TYPES,
} from 'constants';

import {
  asset,
} from 'helpers';

const CONTEXT_HELP_NOTIFICATIONS = {
  FRONT: {
    OVERALL: [
      'YOUR_ID_PICTURE',
    ],
  },
  BACK: {
    OVERALL: [
      'BACK_OF_THE_ID',
    ],
  },
};

const STATE_ERROR = 'Please select a state!';
const DOCUMENT_TYPE_ERROR = 'Please select a document type!';

export default class DocumentUploader extends BaseComponent {
  className = 'ts-DocumentUploader';

  static propTypes = {
    captureSide: PropTypes.oneOf(['front', 'back']),
    showDocumentTypeSelect: PropTypes.bool,
    showStateSelect: PropTypes.bool,
    onPhotoTaken: PropTypes.func,
  };

  static defaultProps = {
    captureSide: 'front',
    showDocumentTypeSelect: true,
    showStateSelect: true,
  };

  state = {
    state: states.get('states') && _first(states.get('states')).value,
    document_type: _first(DOCUMENT_TYPES).value,
  }

  getDocumentValidity = () => {
    const hasDocumentTypeError = this.props.showDocumentTypeSelect &&
      !this.state.document_type;
    if (hasDocumentTypeError) {
      this.refs.document_type.setValid(false, DOCUMENT_TYPE_ERROR);
    }
    return !hasDocumentTypeError;
  };

  getStateValidity = () => {
    const hasStateError = this.props.showStateSelect &&
      !this.state.state;
    if (hasStateError) {
      this.refs.state.setValid(false, STATE_ERROR);
    }
    return !hasStateError;
  };

  handlePhotoTaken = (dataURI) => {
    // Validation is done like this, because we need
    // a model-agnostic way to do it.
    const isDocumentInvalid = !this.getDocumentValidity();
    const isStateInvalid = !this.getStateValidity();
    if (isDocumentInvalid || isStateInvalid) return;
    this.props.onPhotoTaken({
      imageDataURI: dataURI,
      state: this.state.state,
      document_type: this.state.document_type,
    });
  };

  setDocumentSelectValid = () => this.refs.state.setValid(true);

  setStateSelectValid = () => this.refs.state.setValid(true);

  render() {
    return (
      <div className={ this.rootcn`` }>
        { this.props.captureSide === 'front' && (
          <div>
            <HelpButton
              className={ this.cn`__help` }
              notifications={ CONTEXT_HELP_NOTIFICATIONS.FRONT.OVERALL }
            />
            <PageTitle
              className={ this.cn`__title` }
              title={ <span>Take or upload a picture <br /> of the photo page of your ID. </span> }
              hr={ true }
            />
            { this.props.showStateSelect &&
              <div>
                <p className={ this.cn`__p` }>
                  First choose your state and document type
                </p>
                <Select
                  clearable={ false }
                  displayName="State"
                  options={ states.get('states') }
                  placeholder=""
                  ref="state"
                  stateLink={ [this, 'state'] }
                  onChange={ this.setStateSelectValid }
                />
              </div> }
            { this.props.showDocumentTypeSelect &&
              <Select
                clearable={ false }
                displayName="Document type"
                options={ DOCUMENT_TYPES }
                placeholder=""
                stateLink={ [this, 'document_type'] }
                ref="document_type"
                onChange={ this.setDocumentSelectValid }
              />
            }
            <div className={ this.cn`__intro-box` }>
              <Img src={ asset`img/illu_driving_licence_single.svg` } />
            </div>
            <p className={ this.cn`__p` }>
              In case of a Driving License or another card-like ID, it is the front.
            </p>
            <div className={ this.cn`__button-wrapper` }>
              <PhotoCaptureButton
                allowUploadAlternative={ true }
                alternativeText="Take a photo"
                alternativeUploadText="Upload a file"
                onPhotoTaken={ this.handlePhotoTaken }
                overlay="id-front"
                webcamParams={ { minPixels: 640 * 480 } }
              />
            </div>
          </div>
        ) }
        { this.props.captureSide === 'back' && (
          <div>
            <HelpButton
              className={ this.cn`__help` }
              notifications={ CONTEXT_HELP_NOTIFICATIONS.BACK.OVERALL }
            />

            <PageTitle
              className={ this.cn`__title` }
              hr={ true }
              title="Flip your ID to capture the back or upload it."
            />
            <div className={ this.cn`__intro-box` }>
              <img src={ asset`img/illu_driving_licence.svg` } alt="" />
            </div>

            <div className={ this.cn`__button-wrapper` }>
              <PhotoCaptureButton
                allowUploadAlternative={ true }
                overlay="id-back"
                onClick={ this.handleClick }
                onPhotoTaken={ this.handlePhotoTaken }
              />
            </div>
          </div>
        ) }
      </div>
    );
  }
}
