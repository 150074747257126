import React from 'react';
import Waypoint from 'react-waypoint';

import BasePage from 'pages/BasePage';
import Button from 'components/Button';
import MainTextBox from 'components/content-pages/MainTextBox';
import { asset } from 'helpers';
import device from 'models/device';
import { Row, Col } from 'sf/components/Grid';
import Icon from 'sf/components/Icon';
import { onFullRender, scrollTo } from 'sf/helpers/domHelper';

export default class RealtorIndex extends BasePage {
  className = 'ts-RealtorIndex';

  state = {
    mainSectionStatus: false,
  };

  componentDidMount() {
    this.syncStateWithModel(device, ['smDown']);
    onFullRender().then(() => this.setState({ mainSectionStatus: true }));
  }

  handleWaypointEnter = () => {
    this.publish('mainLayout:hideFixedMenu');
  };

  handleWaypointLeave = ({ currentPosition }) => {
    if (currentPosition !== 'below') {
      this.publish('mainLayout:showFixedMenu');
    }
  };

  renderMainSection() {
    const { mainSectionStatus } = this.state;
    const mainTitleClassNames = {
      '__main-title': true,
      '__main-title--visible': mainSectionStatus,
    };
    const mainSubtitleClassNames = {
      '__main-subtitle': true,
      '__main-subtitle--visible': mainSectionStatus,
    };
    const mainBtnClassNames = {
      '__main-btn': true,
      '__main-btn--visible': mainSectionStatus,
    };
    const mainImgClassNames = {
      '__main-img-face': true,
      '__main-img-face--visible': mainSectionStatus,
    };
    const mainImgMeshClassNames = {
      '__main-img-face-mesh': true,
      '__main-img-face-mesh--visible': mainSectionStatus,
    };

    return (
      <section className={ this.cn`__section --black` }>
        <div className="ts-container">
          <div className={ this.cn`__main` }>
            <div className={ this.cn`__main-absolute` }>
              <div className={ this.cn`__main-section-cta` }>
                <div className={ this.cn`__main-section-cta-title` }>
                  What do consumers think?
                </div>
                <a
                  className={ this.cn`__main-section-cta-icon` }
                  onClick={ () => scrollTo(this.ourServices, 500, 60) }
                  role="presentation"
                >
                  <Icon
                    set="ts"
                    size={ 21 }
                    type="down-arrow"
                  />
                </a>
              </div>
            </div>

            <div className={ this.cn`__main-relative` }>
              <Row className={ this.cn`__main-relative-row` }>
                <Col
                  className={ this.cn`__main-relative-left` }
                  md={ 5 }
                >
                  <div className={ this.cn(mainTitleClassNames) }>
                    Protect Yourself.
                    <br />
                    Protect Your Business.
                  </div>
                  <div className={ this.cn(mainSubtitleClassNames) }>
                    Safeguarding real estate with the power of artificial intelligence.
                  </div>
                  <div className={ this.cn(mainBtnClassNames) }>
                    <Button
                      onClick={ () => scrollTo(this.ourCoreSolution, 500, 60) }
                      theme="primary"
                    >
                      <div className={ this.cn`__main-btn-title` }>
                        Learn more
                      </div>
                      <div className={ this.cn`__main-btn-icon` }>
                        <Icon
                          set="ts"
                          size={ 16 }
                          type="chevron-down"
                        />
                      </div>
                    </Button>
                  </div>
                </Col>
                <Col md={ 7 }>
                  <div className={ this.cn`__main-img-box` }>
                    <img
                      alt="Main"
                      className={ this.cn(mainImgClassNames) }
                      src={ asset`corporate/no-mesh.jpg` }
                    />
                    <img
                      alt="Main mesh"
                      className={ this.cn(mainImgMeshClassNames) }
                      ref={ (mainImgMesh) => this.mainImgMesh = mainImgMesh }
                      src={ asset`corporate/face-mesh.jpg` }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderSafetyToolSection() {
    return (
      <section
        className={ this.cn`__section` }
        ref={ (ourCoreSolution) => this.ourCoreSolution = ourCoreSolution }
      >
        <div className="ts-container">
          <div className={ this.cn`__section-content` }>
            <div className={ this.cn`__section-box-absolute` }>
              <img
                className={ this.cn`__section-box-absolute-image` }
                src={ asset`img/consumer_surveys.png` }
                alt=""
              />
              <p
                className={ this.cn`__section-box-absolute-paragraph` }
              >
                { /* eslint-disable max-len */ }
                In a Google Consumer Survey, over 80% of consumers believe that Realtors® have an obligation to verify a stranger&apos;s identity.
                { /* eslint-enable */ }
              </p>
            </div>

            { /* eslint-disable max-len */ }
            <div className={ this.cn`__section-box-relative` }>
              <Row>
                <Col
                  md={ 11 }
                  offset={ { md: 1 } }
                >
                  <Row>
                    <Col
                      md={ 7 }
                      lg={ 8 }
                      offset={ { md: 5, lg: 4 } }
                    >
                      <MainTextBox
                        subtitles={ ['It’s time to make your safety a priority. Our safety tool empowers you to verify the identity and trustworthiness of potential clients for free before taking that first face to face meeting. It is free, secure, and easy to implement in your business.'] }
                        title="Trust Stamp’s Realtor® Safety Tool"
                      />
                    </Col>
                    <Col
                      md={ 7 }
                      lg={ 8 }
                      offset={ { md: 5, lg: 4 } }
                    >
                      <img
                        className={ this.cn`__safety-tool-img` }
                        src={ asset`img/coffee.jpeg` }
                        alt="Pair of people chatting over coffee"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            { /* eslint-enable max-len */ }
          </div>
        </div>
      </section>
    );
  }

  renderHowItWorksSection() {
    /* eslint-disable max-len */
    const infoBlockData = [
      {
        icon: 'screens',
        subtitle: 'After you register, use our web tool to send a text or email inviting your potential client to create their profile.',
        title: 'Invite your potential client',
      },
      {
        icon: 'mail',
        subtitle: 'Your potential client will receive a link to create their profile. All they need to do is take a quick selfie, submit a photo of their Government issued ID, and link one social media account. Now your client can share their profile with you.',
        title: 'Client creates their profile',
      },
      {
        icon: 'accept-circle',
        subtitle: "Once shared, you can see the client's name, selfie, and a green, amber, or red safety indicator which communicated their potential trustworthiness. All data is treated with bank-level security. After you have reviewed the client's profile, the client's data is deleted from our system.",
        title: 'Review Client Profile',
      },
    ];
    /* eslint-enable max-len */

    const renderInfoBlock = (data) => data.map((block, index) => (
      <Col
        key={ index } // eslint-disable-line react/no-array-index-key
        md={ 4 }
      >
        <Icon
          set="ts"
          size={ 69 }
          type={ block.icon }
        />
        <h2 className={ this.cn`__how-it-works-title` }>
          { block.title }
        </h2>
        <p className={ this.cn`__how-it-works-subtitle` }>
          { block.subtitle }
        </p>
      </Col>
    ));

    return (
      <section
        className={ this.cn`__section __how-it-works-section` }
        ref={ (ourServices) => this.ourServices = ourServices }
      >
        <div className="ts-container">
          <div className={ this.cn`__section-content __how-it-works` }>
            { /* eslint-disable max-len */ }
            <Row>
              <Col md={ 4 }>
                <MainTextBox
                  title={ <span>Peace of Mind<br />In Under 2 Minutes</span> }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 12 }>
                <div className={ this.cn`__how-it-works-info-block-row` }>
                  <Row>
                    { renderInfoBlock(infoBlockData) }
                  </Row>
                </div>
              </Col>
            </Row>
            { /* eslint-enable max-len */ }
          </div>
        </div>
      </section>
    );
  }

  renderForBusinessSection() {
    return (
      <section
        className={ this.cn({
          '__for-business': true,
          '--white': this.state.smDown,
        }) }
      >
        <img
          alt="Meeting od people"
          className={ this.cn`__for-business-image` }
          src={ asset`img/meeting.jpg` }
        />
        <MainTextBox
          className={ this.cn({
            '__for-business-text': true,
            'ts-container': this.state.smDown,
          }) }
          /* eslint-disable */
          subtitles={ ["The Trust Stamp App for Realtors® is a quick and simple tool to establish the identity and trustworthiness of a stranger before meeting them and/or providing access to your client’s home or property. This App is available only to members of the National Association of Realtors® and is accessed using your NRDS number."] }
          /* eslint-enable */
          title="Using Trust Stamp for your business"
        />
      </section>
    );
  }

  renderTrustedMailSection() {
    return (
      <section className={ this.cn`__section __trusted-mail-section` }>
        <div className="ts-container">
          <div className={ this.cn`__trusted-mail` }>
            { /* Changed 04 to 03 on ux request */ }
            <Row>
              <Col
                md="6"
              >
                <img
                  alt="Trusted Mail mailbox view"
                  className={ this.cn`__trusted-mail-image` }
                  src={ asset`img/mailbox.png` }
                />
              </Col>
              <Col
                md="6"
              >
                <MainTextBox
                  subtitles={ [
                    /* eslint-disable */
                    "Trusted Mail requires a facial-biometric sign-in with proprietary proof of life to create and send a secure e-mail.  The authorized recipient likewise uses their facial-biometric sign-in to open and decrypt the e-mail.",
                    "Mail can be designated as one-to-one or one-to-many potentially permitting multiple authorized recipients to decrypt the email."
                    /* eslint-enable */
                  ] }
                  title="Secure your email, and secure your future"
                />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    );
  }

  render() {
    // TODO: Waiting for layout
    return (
      <div className={ this.rootcn() }>
        { this.renderMainSection() }
        <Waypoint
          onEnter={ this.handleWaypointEnter }
          onLeave={ this.handleWaypointLeave }
          topOffset="150px"
        />
        { this.renderSafetyToolSection() }
        { this.renderHowItWorksSection() }
        { this.renderForBusinessSection() }
        { this.renderTrustedMailSection() }
      </div>
    );
  }
}
