import React from 'react';
import pick from 'lodash/pick';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import reactTimeout from 'react-timeout';
import BaseComponent from 'components/BaseComponent';
import payment from 'models/payment';
import Button from 'sf/components/Button';
import CardIcon from 'sf/components/CardIcon';
import { CURRENCIES, CURRENCY_SYMBOLS } from 'sf/constants';

class PaymentCard extends BaseComponent {
  className = 'ts-PaymentCard';

  static propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.oneOf(Object.values(CURRENCIES)),
    paymentMethod: PropTypes.func.isRequired,
    onError: PropTypes.func,
    onReady: PropTypes.func,
  };

  static defaultProps = {
    amount: 1,
    currency: CURRENCIES.GBP,
    onError: noop,
    onSuccess: noop,
  };

  state = {
    cardType: null,
    isFormValid: false,
    isFormBeingSubmitted: false,
  };

  handleCardTypeChange = (event) => {
    if (event.cards.length === 1) {
      this.setState({
        cardType: event.cards[0].type,
      });
    }
  }

  handleValidityChange = (hostedFieldsInstance) => {
    const state = hostedFieldsInstance.getState();
    const isFormValid = Object.keys(state.fields).every((key) => {
      return state.fields[key].isValid;
    });
    this.setState({
      isFormValid,
    });
  }

  handleFromSubmit = (event, hostedFieldsInstance) => {
    const { amount, paymentMethod } = this.props;
    event.preventDefault();
    this.setState({
      isFormBeingSubmitted: true,
    });
    hostedFieldsInstance.tokenize((tokenizeErr, data) => {
      if (tokenizeErr) return;

      paymentMethod({
        ...pick(data, ['nonce', 'type']),
        amount,
      })
        .then(this.props.onSuccess)
        .then(() => {
          this.props.setTimeout(() => {
            this.setState({
              isFormBeingSubmitted: false,
            });
          }, 2000);
        })
        .catch(this.props.onError);
    });
  }

  setup = () => {
    const placeholderColor = '#bbbbbe';
    payment.getClientToken().then((clientToken) => {
      global.braintree.client.create({
        authorization: clientToken,
      }, (clientErr, clientInstance) => {
        if (clientErr) return;
        global.braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            'input': {
              'font-size': '13px',
            },
            'input.invalid': {
              'color': '#c10000',
            },
            'input.valid': {
              'color': '#373a3c',
            },
            '::-webkit-input-placeholder': {
              'color': placeholderColor,
            },
            ':-moz-placeholder': {
              'color': placeholderColor,
            },
            '::-moz-placeholder': {
              'color': placeholderColor,
            },
            ':-ms-input-placeholder': {
              'color': placeholderColor,
            },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: '4111 1111 1111 1111',
            },
            cvv: {
              selector: '#cvv',
              placeholder: '123',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: `10 / ${((new Date()).getUTCFullYear() + 3).toString().substr(-2)}`,
            },
          },
        }, (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) return;
          const form = ReactDOM.findDOMNode(this.refs.paymentForm);
          form.addEventListener('submit', (event) => {
            return this.handleFromSubmit(event, hostedFieldsInstance);
          }, false);
          hostedFieldsInstance.on('cardTypeChange', this.handleCardTypeChange);
          hostedFieldsInstance.on('validityChange', () => {
            return this.handleValidityChange(hostedFieldsInstance);
          });
        });
      });
    });
  }

  componentDidMount() {
    payment.getBraintreeScripts(this.setup);
  }

  render() {
    return (
      <form
        className={ this.rootcn() }
        ref="paymentForm"
        method="post"
      >
        <div className={ this.cn`__container-card-number` }>
          <label
            className={ this.cn`__field-label` }
            htmlFor="card-number"
          >
            Card number
          </label>
          <div
            className={ this.cn`__input-wrap` }
            id="card-number"
          >
            <div className={ this.cn`__card-icon` }>
              <CardIcon type={ this.state.cardType } />
            </div>
          </div>
        </div>
        <div className={ this.cn`__container-clear` }>
          <div className={ this.cn`__container-expiration` }>
            <label
              className={ this.cn`__field-label` }
              htmlFor="expiration-date"
            >
              Expiration date
            </label>
            <div
              className={ this.cn`__input-wrap __input-wrap--expiration` }
              id="expiration-date"
            >
            </div>
          </div>
          <div className={ this.cn`__container-cvv` }>
            <label
              className={ this.cn`__field-label` }
              htmlFor="cvv"
            >
              CVV
            </label>
            <div
              className={ this.cn`__input-wrap __input-wrap--cvv` }
              id="cvv"
            >
            </div>
          </div>
        </div>
        <div className={ this.cn`__pay-button` }>
          <Button
            className={ this.cn`__button` }
            disabled={
              !this.state.isFormValid ||
              this.state.isFormBeingSubmitted ||
              !this.props.amount
            }
            mainAction={ true }
            visuallyDisabled={ !this.state.isFormValid || this.state.isFormBeingSubmitted }
          >
            Pay&nbsp;
            { CURRENCY_SYMBOLS[this.props.currency] }
            { (this.props.amount * this.props.creditPrice).toFixed(2) }
          </Button>
        </div>
      </form>
    );
  }
}

export default reactTimeout(PaymentCard);
