import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { getDate } from 'sf/helpers';

export default class PaymentListItem extends BaseComponent {
  className = 'ts-PaymentListItem';

  static propTypes = {
    amount: PropTypes.number.isRequired,
    payment_date: PropTypes.string.isRequired,
    receiver: PropTypes.string, // not implemented
    type: PropTypes.oneOf(['PAYMENT', 'RECHARGE']).isRequired, // not implemented
    is_success: PropTypes.bool,
  };

  static defaultProps = {
    receiver: 'Your Trust Stamp account',
    type: 'RECHARGE',
  };

  renderStatus() {
    const status = this.props.is_success ? 'SUCCESS' : 'REJECTED';
    const statusMap = {
      'SUCCESS': 'success',
      'REJECTED': 'failed',
    };
    return (
      <span className={ this.cn(`__status __status--${status.toLowerCase()}`) }>
        { statusMap[status] }
      </span>
    );
  }

  renderTransactionType() {
    const transactionTypeMap = {
      'PAYMENT': 'Preapproved payment',
      'RECHARGE': 'Recharge',
    };
    return (
      <span className={ this.cn`__type` }>
        { transactionTypeMap[this.props.type] }
      </span>
    );
  }

  render() {
    const { amount, payment_date, receiver, type } = this.props;
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__details` }>
          <div className={ this.cn`__receiver` }>
            { receiver }
          </div>
          <div className={ this.cn`__info` }>
            { this.renderTransactionType() }
            { this.renderStatus() }
          </div>
        </div>
        <div className={ this.cn`__details` }>
          <div className={ this.cn`__amount` }>
            { type === 'PAYMENT' ? '-' : null } ${ amount }
          </div>
          <div className={ this.cn`__date` }>
            { getDate(payment_date) }
          </div>
        </div>
      </div>
    );
  }
}
