import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Icon from 'sf/components/Icon';
import InfoBox from 'components/InfoBox';
import Pagination from 'components/Pagination';
import Render from 'components/Render';
import { getDate } from 'sf/helpers';
import model from 'models/wallet';
import userModel from 'models/user';

export default class SendProfileList extends BaseComponent {
  className = 'ts-SendProfileList';

  state = {
    resentInvitations: [],
  };

  static propTypes = {
    title: PropTypes.string,
  };

  static defaultProps = {
    title: null,
  };

  componentDidMount() {
    this.syncStateWithModel(model, [
      'shareHistoryList',
      'shareHistoryPage',
      'shareHistoryPagesCount',
    ]);
  }

  componentDidMount() {
    model.set({ shareHistoryPage: 1 }).then(() => model.getShareHistoryList());
  }

  handleSendAgainButtonClick = (resendUrl, modified) => () => {
    userModel.resendToken(resendUrl).then(() => {
      const resentInvitations = this.state.resentInvitations.concat([modified]);
      this.setState({
        resentInvitations,
      });
    });
  }

  handlePaginationChange = (shareHistoryPage) => {
    model.set({
      shareHistoryPage,
    });
  }

  render() {
    const { title } = this.props;
    const { shareHistoryList: list, shareHistoryPage, shareHistoryPagesCount } = this.state;
    return (
      <div className={ this.rootcn() }>
        <Render when={ title }>
          <h1 className={ this.cn`__title` }>{ title }</h1>
        </Render>
        <Render when={ !list.length }>
          <InfoBox icon="paper-airplane">You haven’t shared your profile.</InfoBox>
        </Render>
        <Render when={ list.length }>
          <div className={ this.cn`__row __row--head` }>
            <div className={ this.cn`__contact __row-item` }>User</div>
            <div className={ this.cn`__modified __row-item` }>Date</div>
            <div className={ this.cn`__viewed __row-item` }>Status</div>
            <div className={ this.cn`__actions __row-item` } />
          </div>
          { list.map(
            (
              { contact, modified, viewed, resend_url, viewer_paid_view }
            ) => {
              const isInvitationResent = this.state.resentInvitations.indexOf(modified) > -1;
              const statusClassName = {
                '__viewed': true,
                '__row-item': true,
                '__viewed--success': viewed,
              };
              const getViewStatus = () => {
                switch (true) {
                  case viewer_paid_view:
                    return 'Viewer pays for view - system does not track the status';
                  case viewed:
                    return 'Viewed your profile';
                  default:
                    return 'Hasn`t viewed your profile';
                }
              };
              return (
                <div className={ this.cn`__row` } key={ `${resend_url}` }>
                  <div className={ this.cn`__contact __row-item` } data-title="User">
                    <Icon
                      className={ this.cn`__icon` }
                      set="fa"
                      size={ 16 }
                      type="user"
                    />
                    { contact }
                  </div>
                  <div className={ this.cn`__modified __row-item` } data-title="Date">
                    { getDate(modified) }
                  </div>
                  <div className={ this.cn(statusClassName) } data-title="Status">
                    <Icon
                      className={ this.cn`__icon` }
                      set="io"
                      size={ 16 }
                      type={ viewed ? 'checkmark' : 'close' }
                    />
                    { getViewStatus() }
                  </div>
                  <div className={ this.cn`__actions __row-item` }>
                    <Render when={ !viewed && !isInvitationResent }>
                      <Button onClick={ this.handleSendAgainButtonClick(resend_url, modified) }>
                        Send again
                      </Button>
                    </Render>
                    <Render when={ isInvitationResent }>
                      <div className={ this.cn`__resent-message` }>
                        <Icon
                          className={ this.cn`__icon` }
                          set="io"
                          size={ 18 }
                          type="paper-airplane"
                        />
                        Invitation resent
                      </div>
                    </Render>
                  </div>
                </div>
              );
            }
)
          }
          <div className={ this.cn`__pagination` }>
            <Pagination
              activePage={ shareHistoryPage }
              onPageSelect={ this.handlePaginationChange }
              pagesCount={ shareHistoryPagesCount }
            />
          </div>
        </Render>
      </div>
    );
  }
}
