import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Spinner from 'sf/components/Spinner';

const defaultOptions = {
  exposedFields: [],
  LoadingComponent: Spinner,
  ErrorComponent: null,
  promisedPropName: 'promised',
};

/**
 * Higher-Order Component (HOC) that renders component when promise is resolved/rejected.
 * Wrapped component gets "promised"
 *
 * Example1: Resolution
 *   TestComponentWrapped = promisedComponent(TestComponent, Promise.resolve('yes'));
 *   // renders: <TestComponent promised={ { status: true, value: 'yes' } } />
 *
 * Example2: Rejection
 *   TestComponentWrapped = promisedComponent(TestComponent, Promise.reject('no'));
 *   // renders: null
 *
 *   TestComponentWrapped = promisedComponent(TestComponent, Promise.reject('no'), {
 *     ErrorComponent: TestComponent
 *   });
 *   // renders: <TestComponent promised={ { status: false, value: 'no' } } />
 *
 * @param  {React Component} ComposedComponent [description]
 * @param  {Promise} promise   Promise
 * @param  {Object} _options   Options
 * @param  {Array} _options.exposedFields          Fields that will be accessible by ref.
 * @param  {Array} _options.LoadingComponent       Component to render when promise is not resolved
 * @param  {Array} _options.ErrorComponent         Component to render when promise is rejected.
 * @param  {Array} _options.promisedPropName       Prop name that will pass promise value
 * @return {Object}            HOC
 */
export default function withPromise(ComposedComponent, promise, _options = {}) {
  const options = {
    ...defaultOptions,
    ..._options,
  };

  // make sure that console never gets unhalded promise error.
  promise.catch((err) => err);

  return class PromisedComponent extends React.PureComponent {
    constructor(props) {
      super(props);
      options.exposedFields.forEach((fieldName) => {
        Object.defineProperty(this, fieldName, {
          get() { return this.composedRef.current && this.composedRef.current[fieldName]; }
        });
      });
      promise
        .then((value) => this.setState({
          value: { status: true, value },
          ComponentToRender: ComposedComponent,
        }))
        .catch((value) => this.setState({
          value: { status: false, value },
          ComponentToRender: options.ErrorComponent,
        }));
    }

    state = {
      ComponentToRender: options.LoadingComponent,
    };

    composedRef = React.createRef();

    static renderTo = BaseComponent.renderTo; // used for widgets

    render() {
      const Component = this.state.ComponentToRender;

      if (!Component) return null;

      const props = {
        ...this.props,
        ref: this.composedRef,
      };

      if (options.promisedPropName) {
        props[options.promisedPropName] = this.state.value;
      }

      return (
        <Component
          { ...props }
        />
      );
    }
  };
}
