import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
// import analytics from 'models/analytics';
import deviceModel from 'sf/models/device';
import BaseComponent from 'components/BaseComponent';
import Button from 'sf/components/Button';
import Dialog from 'sf/components/Dialog';
import PaymentCard from 'sf/components/PaymentCard';
import PaymentPayPal from 'sf/components/PaymentPayPal';
import { CURRENCIES } from 'sf/constants';

export default class Payment extends BaseComponent {
  className = 'ts-Payment';

  state = {};

  static propTypes = {
    amount: PropTypes.number,
    cardPaymentExpanded: PropTypes.bool,
    creditPrice: PropTypes.number,
    currency: PropTypes.oneOf(Object.values(CURRENCIES)),
    disabled: PropTypes.bool,
    paymentMethod: PropTypes.func.isRequired,
    theme: PropTypes.oneOf(['horizontal', 'vertical']),
    onError: PropTypes.func, // error in validation or payment
    onReady: PropTypes.func, // payment widget ready
    onSubmit: PropTypes.func, // option button clicked (braintree requires submit button in <form>)
    onSuccess: PropTypes.func, // payment successfully finished
  };

  static defaultProps = {
    amount: 1,
    cardPaymentExpanded: true,
    disabled: false,
    theme: 'vertical',
    onError: noop,
    onReady: noop,
    onSubmit: noop,
    onSuccess: noop,
  };

  handleCreditCardButtonClick = () => {
    this.refs.cardPaymentDialog.toggle(true);
  }

  componentDidMount() {
    this.syncStateWithModel(deviceModel, ['xxsmUp']);
  }

  handlePaypalPaymentSuccess = () => {
    this.props.onSuccess();
  }

  handleCardPaymentSuccess = () => {
    this.props.onSuccess();
    if (!this.props.cardPaymentExpanded) {
      this.refs.cardPaymentDialog.toggle(false);
    }
  }

  renderCardPaymentSection = () => {
    const cardPayment = (
      <PaymentCard
        amount={ this.props.amount }
        creditPrice={ this.props.creditPrice }
        currency={ this.props.currency }
        paymentMethod={ this.props.paymentMethod }
        onSuccess={ this.handleCardPaymentSuccess }
      />
    );
    return this.props.cardPaymentExpanded ? cardPayment : (
      <Dialog
        align={ { points: ['tc', 'tc'], offset: [0, 140] } }
        className={ this.cn`__card-payment-dialog` }
        ref="cardPaymentDialog"
        size="small"
        title="Payment information"
      >
        { cardPayment }
      </Dialog>
    );
  }

  render() {
    const cn = this.cn();
    return (
      <div className={ this.rootcn(`--theme-${this.props.theme}`) }>
        <PaymentPayPal
          amount={ this.props.amount }
          creditPrice={ this.props.creditPrice }
          currency={ this.props.currency }
          disabled={ this.props.disabled }
          paymentMethod={ this.props.paymentMethod }
          onSuccess={ this.handlePaypalPaymentSuccess }
          onError={ this.props.onError }
        />
        <div className={ cn`__or ts-or` } />
        { this.props.cardPaymentExpanded
          ? null
          : (
            <Button
              className={ this.cn`__use-credit-card-button` }
              onClick={ this.handleCreditCardButtonClick }
              disabled={ this.props.disabled }
              mainAction={ true }
            >
              Use your credit card
            </Button>
          )
        }
        { this.renderCardPaymentSection() }
      </div>
    );
  }
}
