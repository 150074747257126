import React from 'react';
import omit from 'lodash/omit';
import template from 'lodash/template';
import webcam from 'mighty-webcamjs';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import BaseComponent from 'components/BaseComponent';
import AdvancedPhotoCapture from 'sf/components/AdvancedPhotoCapture';
import Button from 'sf/components/Button';
import { mediator, getWebcamParams } from 'sf/helpers';
import { isEqualShallow } from 'sf/helpers/react';
import { toggleFullScreen } from 'sf/helpers/domHelper';
import onFullRender from 'sf/hoc/OnFullRender';
import { HELP_MESSAGE_CAMERA_NOT_WORKING, HELP_MESSAGE_CAMERA_IN_USE } from 'sf/messages';
import FlashLightButton from 'mighty-webcamjs/components/FlashLightButton';
import SwitchCameraButton from 'mighty-webcamjs/components/SwitchCameraButton';
import {
  UPLOAD_PHOTO_BUTTON,
  TAKE_PHOTO_BUTTON,
  ACTION_CLICK,
  ACTION_TAP,
  POSSIBLE_CAMERA_PROBLEM_MESSAGE,
  POSSIBLE_CAMERA_PROBLEM_MESSAGE_IOS,
  POSSIBLE_CAMERA_PROBLEM_MESSAGE_ANDROID,
} from 'sf/l10n';

const UPLOAD_ALTERNATIVE_FILE_ONLY = 'captureOnly';

/**
 * Overlays that triggers landscape orientation.
 */
const LANDSCAPE_OVERLAYS = ['qrcode', 'barcode', 'id', 'id-back', 'id-front', 'id-front-auto'];

/**
 * PhotoCaptureButton works similar to PhotoCapture but instead of showing preview right away,
 * displays only button. When button is clicked, full-screen photo capture app is showed.
 */
class PhotoCaptureButton extends BaseComponent {
  className = 'ts-PhotoCaptureButton';

  // communicationChannel is important in case of having multiple webcam components attached
  // when using with iOS.
  static communicationChannel = 0;

  state = {
    // eslint-disable-next-line
    communicationChannel: ++PhotoCaptureButton.communicationChannel,

    ...this.syncStateWithModelInitial(webcam.params, [
      'load',
      'camera',
      'capture_mode',
    ]),
    isPrivacyPolicyPopupOpen: false,
  };

  static UPLOAD_ALTERNATIVE_FILE_ONLY = UPLOAD_ALTERNATIVE_FILE_ONLY; // public
  /* eslint-disable max-len */
  static propTypes = {
    allowCrop: PropTypes.bool,
    allowRetake: PropTypes.bool,
    /**
     * allowUploadAlternative enables file upload next to webRTC photo capture.
     * This option does nothing on iOS, as it uses file fallback already.
     *
     * This option allows to take picture on desktops. To disable it on
     * desktops, set it to 'captureOnly'.
     */
    allowUploadAlternative: PropTypes.oneOf([true, false, UPLOAD_ALTERNATIVE_FILE_ONLY]),
    alternativeText: PropTypes.string,
    alternativeUploadText: PropTypes.string,
    cropAspectRatio: PropTypes.number,
    /**
     * delayedStart: access webcam AFTER camera dialog is opened.
     * This might be useful when using more than one PhotoCaptureButton on the page.
     */
    delayedStart: PropTypes.bool,
    onClick: PropTypes.func,
    onError: PropTypes.func,
    onPhotoTaken: PropTypes.func,
    /*
     * onToggle event fires when fullscreen toggles
     */
    onToggle: PropTypes.func,
    outlined: PropTypes.bool,
    /**
     * one of:
     * 'barcode', 'face', 'id', 'id-back', 'id-front', 'id-front-auto', ''
     */
    overlay: PropTypes.oneOf(['qrcode', 'barcode', 'face', 'id', 'id-back', 'id-front', 'id-front-auto', '']),
    /**
     * Button "pill" type
     */
    pill: PropTypes.bool,
    theme: PropTypes.string,
    /**
     * Params passed to a webcam library directly.
     * Available webcamParams (+ default values):
     *
     *  * read more below:
     *  * **cssPrefix: 'webcamjs',** // prefix for all css classes
     *  * **dest_height: 1600,** // these default to width/height
     *  * **dest_width: 1600,** // size of captured image.
     *  * **enable_file_fallback: true,**
     *  * **flip_horiz: false,** // flip image horiz (mirror mode)
     *  * **flip_horiz_back: undefined,** // flip image horizontally on both: front and back camera
     *  * **force_file: false,** // force file upload mode
     *  * **force_fresh_photo: false,** // When true, it will disallow to Select Photo from Library on iOS
     *  * **image_format: 'jpeg',** // image format (may be jpeg or png)
     *  * **jpeg_quality: 90,** // jpeg image quality from 0 (worst) to 100 (best)
     *  * **output_format: 'canvas',** // 'base64', 'canvas', 'blob'
     *  * **use_ImageCapture: true,** // use experimental ImageCapture API if available
     *  * **use_ImageCapture_flashlight: false,** // use flashlight, part of ImageCapture API if available
     *  * **use_ImageCapture_grabFrame: false,** // use grabFrame, part of ImageCapture API (fallback to takePhoto)
     *  * **use_ImageCapture_takePhoto: true,** // use takePhoto, part of ImageCapture API if available
     *  * **verbose: false,** // Set to false to hide logs.
     *  * **webcam_path: './',** // URI to CSS file (defaults to the js location)
     *  * **switch_camera_node: 'Switch camera',** // Contents of "Switch Camera" button. React Node required
     *  * **flash_light_node: 'Enable Flash Light',** // React Node required
     *  * **no_interface_found_text: 'No supported webcam interface found.',**
     */
    webcamParams: PropTypes.object,
    /**
     * forceFallbackUpload forces to use <input type="file" capture /> instead of webrtc.
     *
     * It's set automatically to true when there are multiple back cameras on Samsung devices.
     */
    forceFallbackUpload: PropTypes.bool,
    /**
     * displayPrivacyPolicyPopup is used to display Privacy policy dialog in the beginning of verification flow
     */
    displayPrivacyPolicyPopup: PropTypes.bool,
    /**
     * privacyPolicyDialogComponent is the dialog component itself that accepts 2 callback functions: handleAcceptConsent and handleCancelConsent
     */
    privacyPolicyDialogComponent: PropTypes.func,
  };
  /* eslint-enable max-len */

  static defaultProps = {
    allowCrop: false,
    allowRetake: false,
    allowUploadAlternative: false,
    alternativeText: null,
    alternativeUploadText: null,
    delayedStart: false,
    onClick: noop,
    onError: noop,
    onToggle: noop,
    outlined: true,
    pill: true,
    theme: 'button',
    webcamParams: {},
    displayPrivacyPolicyPopup: false,
    privacyPolicyDialogComponent: noop,
  };

  toggle = (status) => {
    if (this.refs.AdvancedPhotoCapture) {
      this.refs.AdvancedPhotoCapture.toggle(status);
    }
  };

  click = () => {
    this.handleClick();
  };

  handleInitialClick = () => {
    if (this.props.displayPrivacyPolicyPopup) {
      this.setState({ isPrivacyPolicyPopupOpen: true });
    } else {
      this.handleClick();
    }
  }

  handleClick = () => {
    if (!this.props.delayedStart && !webcam.checkIfCanCapture()) {
      return mediator.publish('showHelp', HELP_MESSAGE_CAMERA_NOT_WORKING());
    }
    this.props.onClick();
    mediator.publish('photoTaken');

    // Android: Timeout when previous element could trigger keyboard.
    // This way we give a time to hide software keyboard
    const isKeyboardShowed = is.isKeyboardTrigger(document.activeElement);

    // iOS
    if (document.activeElement && document.activeElement.blur) document.activeElement.blur();

    const timeout = isKeyboardShowed ? 1000 : 0;
    this.setTimeout(() => {
      this.toggle(true);
    }, timeout);
  };

  handleToggle = async (isShowed) => {
    /* eslint-disable no-restricted-globals */
    this.props.onToggle(isShowed);
    if (is.mobile() || is.tablet()) {
      try {
        await toggleFullScreen(isShowed).catch(noop);

        if (
          isShowed
          && screen.orientation
          && screen.orientation.lock
          && LANDSCAPE_OVERLAYS.includes(this.props.overlay)
        ) {
          try {
            return isShowed
              ? await screen.orientation.lock('landscape')
              : screen.orientation.unlock();
          } catch (e) { /* do nothing */ }
        }
      } catch (e) { /* do nothing */ }
    }
    /* eslint-enable no-restricted-globals */
  };

  handlePhotoTaken = (photoURI, extraInfo) => {
    if (!this.props.allowCrop && !this.props.allowRetake) {
      this.toggle(false);
    }

    this.props.onPhotoTaken(photoURI, extraInfo);
    this.setState({ cameraBackButtonClicked: false });
  };

  handleFallbackUploadEvent = (value) => {
    return this.handlePhotoTaken(value);
  };

  renderFallbackUploadNode({ className, capture = true }) {
    // renderFallbackUploadNode is taking file input from webcamJS and covers react-buttons with it.
    // We can't create own `<input type=file` here because we'd loose a lot of webcamjs
    // functionality like:
    // - auto-rotation for photos from iOS
    // - auto-downscaling for really big photos
    // - detecting if photo was just taken or taken from library
    //   (iOS + allowUploadAlternative:false)
    // - blur detection
    // - capture="environment" or capture="user" would be wiped, as react does not support it.
    webcam.set({ capture_mode: webcam.constants.CAPTURE_MODE_PHOTO });
    const fallbackNode = webcam.getUploadFallbackNode(`${this.state.communicationChannel}`, {
      className,
      capture,
      onChange: (e) => {
        if (!e.target.files || !e.target.files.length) return;
        this.props.onClick(); // simulate onClick on iOS
        mediator.publish('photoTaken');
      },
      disabled: this.props.disabled,
    });

    if (this.props.delayedStart) {
      this.subscribe(
        webcam.params,
        `imageSelected:${this.state.communicationChannel}`,
        this.handleFallbackUploadEvent,
      );
    }

    return fallbackNode;
  }

  shouldForceFallbackUpload() {
    return this.props.forceFallbackUpload || !is.userMediaSupported();
  }

  renderFallbackUploadButton() {
    if (this.shouldForceFallbackUpload()) {
      const fallbackNodeProps = {
        className: this.cn({
          '__fallback-file-upload-wrapper': true,
          '__fallback-file-upload-wrapper--hidden': this.props.displayPrivacyPolicyPopup,
        }),
      };

      if (this.props.allowUploadAlternative) {
        fallbackNodeProps.capture = false;
      }

      // input covers button
      return this.renderFallbackUploadNode(fallbackNodeProps);
    }

    return <div />;
  }

  renderAlternativeUploadButton() {
    const { allowUploadAlternative, alternativeUploadText } = this.props;

    const buttonText = alternativeUploadText || template(UPLOAD_PHOTO_BUTTON)({
      actionName: (is.mobile() || is.tablet()) ? ACTION_TAP : ACTION_CLICK,
    });
    if (
      !allowUploadAlternative
      || is.iOS() && !is.userMediaSupported()
      || is.desktop() && allowUploadAlternative === UPLOAD_ALTERNATIVE_FILE_ONLY
    ) {
      return null;
    }
    return (
      <div
        className={ this.cn`__alternative-file-upload` }
        key={ `alternativeUpload-${this.state.capture_mode}` }
      >
        <Button
          { ...this.getPropsForButton() }
          theme="action"
          className={ this.cn`__alternative-file-upload __button` }
          onClick={ () => this.publish('photoTaken') }
        >
          { buttonText }
        </Button>
        { this.renderFallbackUploadNode({
          className: this.cn`__alternative-file-upload-wrapper`,
          capture: false,
        }) }
      </div>
    );
  }

  getPropsForButton() {
    return omit(this.props, [
      'onPhotoTaken', 'overlay', 'webcamParams', 'allowCrop', 'allowRetake',
      'allowUploadAlternative', 'alternativeText', 'delayedStart', 'viewFinderText',
      'forceFallbackUpload', 'privacyPolicyDialogComponent', 'displayPrivacyPolicyPopup'
    ]);
  }

  handleError = (err) => {
    console.error(err); // eslint-disable-line no-console
    // NOTE: reference to `this` is passed so that.toggle(false) can be performed easly
    //       and avoiding creation of redundant ref.

    this.errorHandler(err);
    return this.props.onError(err, this);
  };

  errorHandler = (err) => {
    // GENERIC handler. when "onError" returns false, this one won't be called.
    if (!err) return;

    switch (err.name) {
      // case 'OverconstrainedError':
      //   this.toggle(false);
      //   this.publish(
      //     'showHelp',
      //     HELP_MESSAGE_CAMERA_NOT_WORKING(),
      //   );

      //   break;

      case 'NotAllowedError':
        // err: camera is disabled
        // message: "Permission denied"
        // name: "NotAllowedError"
        this.toggle(false);
        this.publish(
          'showHelp',
          HELP_MESSAGE_CAMERA_NOT_WORKING(),
        );

        break;
      case 'NotReadableError':
      case 'SourceUnavailableError':
      case 'PermissionDeniedError': // Not sure if this ever fire
        this.toggle(false);
        this.publish(
          'showHelp',
          HELP_MESSAGE_CAMERA_IN_USE(),
        );

        break;
      default: break;
    }
  };

  componentDidMount() {
    this.subscribe('cameraBackButtonClick', this.handleCameraBackButtonClick);
  }

  handleCameraBackButtonClick = () => {
    // in case of using "back button", display message
    this.setState({ cameraBackButtonClicked: true });
  };

  renderCameraWarning = () => {
    let msg = POSSIBLE_CAMERA_PROBLEM_MESSAGE;
    if (is.iOS()) {
      if (!is.safari()) {
        msg = POSSIBLE_CAMERA_PROBLEM_MESSAGE_IOS;
      }
    } else if (!is.chrome()) {
      msg = POSSIBLE_CAMERA_PROBLEM_MESSAGE_ANDROID;
    }

    if (is.desktop()) {
      msg = POSSIBLE_CAMERA_PROBLEM_MESSAGE;
    }

    return (
      <div className={ this.cn`__warning-message` }>
        { msg }
      </div>
    );
  };

  renderButtons() {
    return (
      <React.Fragment>
        <SwitchCameraButton webcam={ webcam } model={ webcam.params } />
        <FlashLightButton webcam={ webcam } model={ webcam.params } />
      </React.Fragment>
    );
  }

  getWebcamParams = () => {
    const { props, state } = this;

    const webcamParams = getWebcamParams({
      errorHandler: this.errorHandler,
      ...props.webcamParams,
      force_fresh_photo: !props.allowUploadAlternative,
      communicationChannel: `${state.communicationChannel}`,
      delayedStart: props.delayedStart,
      userMedia: !this.shouldForceFallbackUpload(),
      controls: false,
    });

    if (this.shouldForceFallbackUpload()) {
      webcamParams.captureTriggers = [];
    }

    if (!isEqualShallow(webcamParams, this._webcamParams)) {
      this._webcamParams = webcamParams;
    }

    return this._webcamParams;
  }

  handleCancelConsent = () => {
    this.setState({ isPrivacyPolicyPopupOpen: false })
  }

  handleFallbackButtonInitClick = () => {
    // Select and trigger click event on fallback input element
    const container = findDOMNode(this);
    if (container) {
      const input = container.querySelector('input[type=file]');
      if (input) input.click();
    }
  }

  handleAcceptConsent = () => {
    if (this.shouldForceFallbackUpload()) {
      return this.handleFallbackButtonInitClick
    }
    return this.handleClick
  }

  renderPrivacyPolicyDialog = () => {
    if (!this.state.isPrivacyPolicyPopupOpen) return null;

    return this.props.privacyPolicyDialogComponent({
      handleAcceptConsent: this.handleAcceptConsent(),
      handleCancelConsent: this.handleCancelConsent,
    })
  }

  render(props, state) {
    const buttonText = template(
      props.alternativeText || TAKE_PHOTO_BUTTON
    );

    const buttonClassNames = {
      '__button': true,
      '__button--single': !props.allowUploadAlternative,
    };
    return (
      <div
        className={ this.rootcn`` }
      >
        { state.cameraBackButtonClicked && this.renderCameraWarning() }

        <Button
          { ...this.getPropsForButton() }
          className={ this.cn(buttonClassNames) }
          theme="action"
          onClick={ this.handleInitialClick }
          disabled={ (this.shouldForceFallbackUpload() || props.disabled)
            ? props.disabled
            : is.userMediaSupported() && !props.delayedStart && !state.load
          }
        >
          { buttonText({ actionName: (is.mobile() || is.tablet()) ? ACTION_TAP : ACTION_CLICK }) }
        </Button>

        { this.renderPrivacyPolicyDialog() }
        { this.renderAlternativeUploadButton() }
        { this.renderFallbackUploadButton() }
        <AdvancedPhotoCapture
          data-test={ props['data-test'] ? `${props['data-test']}-photo-capture` : undefined }
          ref="AdvancedPhotoCapture"
          allowCrop={ props.allowCrop }
          allowRetake={ props.allowRetake }
          cropAspectRatio={ props.cropAspectRatio }
          cropSlideable={ props.cropSlideable }
          cropperCaption={ props.cropperCaption }
          delayedStart={ props.delayedStart }
          fullScreen={ true }
          onToggle={ this.handleToggle }
          onError={ this.handleError }
          onPhotoTaken={ this.handlePhotoTaken }
          webcamParams={ this.getWebcamParams() }
          overlay={ props.overlay }
          viewFinderText={ props.viewFinderText }
        >
          { this.props.children || this.renderButtons() }
        </AdvancedPhotoCapture>
      </div>
    );
  }
}

export default onFullRender(PhotoCaptureButton, ['click', 'webcam']);
