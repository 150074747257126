import React from 'react';
import PropTypes from 'prop-types';
import noop from 'no-op';

import BaseComponent from 'components/BaseComponent';
import WebcamPropTypes from 'sf/components/Webcam/WebcamPropTypes';

import {
  BARCODE_CAMERA_READER_TEXT,
  BARCODE_CAMERA_READER_TIMEOUT_TEXT,
  QRCODE_CAMERA_READER_TEXT,
  QRCODE_CAMERA_READER_TIMEOUT_TEXT,
} from 'sf/l10n';
import { webcam } from 'sf/components/Webcam';
import Spinner from 'sf/components/Spinner';

// https://en.wikipedia.org/wiki/ISO/IEC_7810
const ID1_HEIGHT = 2.125;
const ID1_WIDTH = 3.370;


export default class ViewFinder extends BaseComponent {
  // ts-Webcam is used instead of ts-ViewFinder to limit migration issues
  className = 'ts-Webcam';

  static propTypes = {
    overlay: WebcamPropTypes.overlay,
    // videoWidth and videoHeight: real stream dimensions
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    // videoClientWidth and videoClientHeight: video element size
    videoClientHeight: PropTypes.number,
    videoClientWidth: PropTypes.number,

    /**
     * Triggers when viewfinder's size or position changes.
     * Use it when you need to rely on viefinder's dimensions.
     *
     * Callback contains following properties:
     * - x (Number, can be negative)
     * - y (Number, can be negative)
     * - width (Number)
     * - height (Number)
     * - scaled (Object. The same values as above but scaled according to screen size)
     *
     * When viewfinder is not present, all values are set to 0 or undefined.
     */
    onSizeChange: PropTypes.func,
    /*
     * Text to display.
     * Provide 2-element array to display text on the top and the bottom.
     */
    text: PropTypes.node,
    theme: PropTypes.oneOf(['', 'default', 'success', 'warning']),

    messageTimeout: PropTypes.number,
  };

  static defaultProps = {
    overlay: '',
    onSizeChange: noop,
    text: '',
    theme: 'default',
    messageTimeout: 15 * 1000,
  };

  sizeParams = {};

  getAspectRatio = () => this.props.videoWidth / this.props.videoHeight;
  getCSSOrientationName = () => this.getAspectRatio() >= 1 ? 'horizontal' : 'vertical';

  state = this.syncStateWithModelInitial(webcam.params, [
    'cameraVideoElement',
    'isFullyLoaded',
    'live',
  ]);

  componentDidMount() {
    this.setTimeout(
      () => {
        this.setState({ isMessageTimedOut: true });
        this.publish('OVERLAY_MESSAGE_TIMEOUT');
      },
      this.props.messageTimeout,
    );
    this.triggerSizeChange();
  }

  componentDidUpdate() {
    this.triggerSizeChange();
  }

  componentWillUnmount() {
    this.sizeParams = {};
    this.triggerSizeChange();
    super.componentWillUnmount();
  }

  renderOverlayText() {
    const { text } = this.props;
    const overlayText = Array.isArray(text)
      ? text
      : [text];

    return (
      <React.Fragment>
        <div className={ this.cn`ts-ViewFinder__text` }>
          { overlayText[0] }
        </div>
        <div
          className={ this.cn`ts-ViewFinder__text ts-ViewFinder__text--bottom` }
        >
          { overlayText[1] }
        </div>
      </React.Fragment>
    );
  }

  triggerSizeChange() {
    this.props.onSizeChange(this.sizeParams);
  }

  renderOverlay = () => {
    const aspectRatio = this.getAspectRatio();
    if (!aspectRatio) {
      return null;
    }
    const { theme, overlay } = this.props;
    const overlayClassName = {
      'ts-ViewFinder': true,
      [`ts-ViewFinder--theme-${theme}`]: theme,
      [`__cam--overlay-${overlay}`]: overlay,
      [`__cam--orientation-${this.getCSSOrientationName()}`]: true,
    };

    return (
      <div
        { ...this.pickProps() }
        className={ this.cn(overlayClassName) }
        // aspectRatio:
        // any value from "0.1" to "2.9"
        // - 2.0 for devices with 18:9 aspect ratio
        // - 1.8 for 1280x720 (desktop)
        // - 1.5 for 2560x1728 (surface pro)
        // - 1.3 for 640x480 (desktop)
        // - 0.6 for 720x1280 (mobile)
        // - 0.8 for 480x640 (mobile)
        data-aspect-ratio={ aspectRatio.toFixed(1) }
      >
        { this.renderOverlayText() }
      </div>
    );
  };

  renderCustomOverlay(name) {
    const aspectRatio = this.getAspectRatio();

    if (!aspectRatio) { // aspectRatio not provided == cam not started
      return null;
    }
    const customOverlayData = getCustomOverlay(name);
    const shouldDisplayTimeoutMessage = this.state.isMessageTimedOut
      && customOverlayData.timeoutMessage;
    const { videoClientHeight, videoClientWidth, videoWidth, videoHeight } = this.props;

    const isHorizontal = aspectRatio >= 1;

    const viewFinderSizeModifier = getCustomViewFinderSize(
      name,
      videoHeight * videoWidth,
      isHorizontal,
    );

    const offsetX = (videoClientHeight - videoClientHeight * viewFinderSizeModifier) / 2;
    const viewFinderWidth = videoClientWidth - 2 * offsetX;
    const viewFinderHeight = Math.floor(viewFinderWidth / customOverlayData.ratio);

    if (!viewFinderHeight) {
      return null;
    }

    const scale = videoWidth / videoClientWidth;

    this.sizeParams = {
      x: offsetX * scale,
      y: ((videoClientHeight - viewFinderHeight) / 2) * scale,
      width: viewFinderWidth * scale,
      height: viewFinderHeight * scale,

      scaled: {
        x: offsetX,
        y: (videoClientHeight - viewFinderHeight) / 2,
        width: viewFinderWidth,
        height: viewFinderHeight,
      }
    };

    const overlayClassName = {
      'ts-ViewFinder': true,
      '__cam--overlay-custom': true,
      [`__cam--overlay-${name}`]: name,
      [`__cam--orientation-${this.getCSSOrientationName()}`]: true,
    };

    return (
      <div
        { ...this.pickProps() }
        style={ {
          width: viewFinderWidth,
          height: viewFinderHeight,
        } }
        className={ this.cn(overlayClassName) }
        data-aspect-ratio={ this.getAspectRatio().toFixed(1) }
      >
        <span
          className={ this.cn({
            '__cam-overlay-message': true,
            '__cam-overlay-message--visible': !shouldDisplayTimeoutMessage,
          }) }
        >
          { customOverlayData.message }
        </span>
        <span
          className={ this.cn({
            '__cam-overlay-message': true,
            '__cam-overlay-message--visible': shouldDisplayTimeoutMessage,
          }) }
        >
          { customOverlayData.timeoutMessage }
        </span>
      </div>
    );
  }

  render({ overlay }, { live, cameraVideoElement, isFullyLoaded }) {
    if (!live || !cameraVideoElement || !isFullyLoaded) {
      return <Spinner className="ts-ViewFinder ts-Webcam__loader" />;
    }

    return getCustomOverlay(overlay)
      ? this.renderCustomOverlay(overlay)
      : this.renderOverlay();
  }
}

function getCustomOverlay(name) {
  // const name = ['id-front', 'id-back'].includes(inputName)
  //   ? 'id'
  //   : inputName;

  return ({
    'qrcode': {
      sizes: {
        verticalLock: true,
        default: 0.3,
        xsm: 0.35,
        sm: 0.2,
        md: 0.15,
        lg: 0.1,
      },
      ratio: 27 / 17,
      timeoutMessage: QRCODE_CAMERA_READER_TIMEOUT_TEXT,
      message: QRCODE_CAMERA_READER_TEXT,
    },
    'barcode': {
      sizes: {
        verticalLock: true,
        default: 0.85,
        xsm: 0.8,
        sm: 0.775,
        md: 0.75,
        lg: 0.725,
      },
      ratio: 28 / 10,
      timeoutMessage: BARCODE_CAMERA_READER_TIMEOUT_TEXT,
      message: BARCODE_CAMERA_READER_TEXT,
    },
    'id-front-auto': {
      sizes: {
        verticalLock: true,
        default: 0.5,
        xsm: 0.45,
        sm: 0.4,
        md: 0.35,
        lg: 0.3,
      },
      ratio: ID1_WIDTH / ID1_HEIGHT,
      timeoutMessage: 'Please capture the ID manually using the camera button.',
      // eslint-disable-next-line max-len
      message: 'Hold your ID still in front of the camera. Our software will capture it automatically.',
    },
  })[name];
}

/**
 * Get barcode ViewFinder size in percentage of video size.
 * It's easier for the user to keep DL a bit further from camera.
 * Higher resolution we have, smaller box we can use. But if user has camera
 * resolution less 720x480, then we need to use most of the available space.
 *
 * @param  {Number} resolutionInPixels Resolutions in pixels, like `640 * 480`
 * @return {Number}                    Percentage ratio
 */
function getCustomViewFinderSize(name, resolutionInPixels, isHorizontal) {
  const viewFinderSizes = getCustomOverlay(name).sizes;
  switch (true) {
    case !isHorizontal && viewFinderSizes.verticalLock: return Math.max(
      ...Object.values(viewFinderSizes)
    );
    case resolutionInPixels > 1920 * 1920: return viewFinderSizes.lg;
    case resolutionInPixels >= 1680 * 1280: return viewFinderSizes.md;
    case resolutionInPixels >= 1280 * 720: return viewFinderSizes.sm;
    case resolutionInPixels >= 720 * 480: return viewFinderSizes.xsm;
    default: return viewFinderSizes.default;
  }
}
