import _get from 'lodash/get';
import is from 'next-is';
import { AGE_OF_ELIGIBILITY, OLDEST_PERSON_BIRTHDATE } from 'constants';
import { chain } from 'sf/helpers';
import date from 'sf/models/date';

/* eslint-disable max-len */
export const hasDigits = (value) => /\d/.test(value) ? undefined : 'Must have at least one digit';
export const hasLowerCaseLetter = (value) => (!!value && /[a-z]/.test(value)) ? undefined : 'Must have at least one lowercase letter';
export const hasUpperCaseLetter = (value) => /[A-Z]/.test(value) ? undefined : 'Must have at least one uppercase letter';
export const hasSpecialCharacter = (value) => /[!@#$%^&*)(+=._-]/.test(value) ? undefined : 'Must have at least one special character';
export const hasSpace = (value) => /\s/g.test(value ? value.trim() : null) ? undefined : 'Must be at least two words';
export const isDigits = (value) => /^[0-9]+$/.test(value) ? undefined : 'Must be digits only';
export const isEmail = (value) => is.string.isEmail(value) ? undefined : 'Must be valid e-mail';
export const isLetters = (value) => (value && /^[a-zA-Z ]+$/.test(value)) ? undefined : 'Must be letters only';
// negative number for dev and testing purposes
// "Must be digits only" (w/o special chars enumerated) is a valid error message
// see more: TRUS2-283
export const isRealtorNumberChars = (value) => /^-?[0-9]+$/.test(value) ? undefined : 'Must be digits only';
export const isRequired = (value) => value ? undefined : 'Required';
export const isTooOld = (value) => is.date.isSameOrBefore(OLDEST_PERSON_BIRTHDATE, value) ? undefined : 'Provided date is not valid';
export const isTooYoung = (value) => is.date.isMinimumAge(value, AGE_OF_ELIGIBILITY) ? undefined : 'Sorry, you’re too young';
export const isUsTooOld = (value) => chain(value)
  .pipe(date.getDate)
  .pipe(date.toISODate)
  .pipe(isTooOld)
  .value;
export const isUsTooYoung = (value) => chain(value)
  .pipe(date.getDate)
  .pipe(date.toISODate)
  .pipe(isTooYoung)
  .value;
export const isPubliclyAccessibleUrl = (value) => new RegExp(/^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i).test(value) ? undefined : 'Must be valid url';
export const isNameCharacters = (value) => (value && /^[a-zA-ZÀ-ʯ ().'`-~–—-]+$/.test(value)) ? undefined : 'Invalid characters';
export const maxLength = (max) => (value) => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = (min) => (value) => !value || value.length < min ? `Must be ${min} characters or more` : undefined;
export const isEligibleBirthDate = [isTooOld, isTooYoung];
export const isEligibleUsBirthDate = [isUsTooOld, isUsTooYoung];
export const isFullName = [hasSpace, isNameCharacters, maxLength(40), minLength(2)];
export const isLength = (length) => [maxLength(length), minLength(length)];
export const isNrds = [isRealtorNumberChars, maxLength(9), minLength(9)];
export const isPassword = [hasDigits, hasLowerCaseLetter, hasUpperCaseLetter, minLength(8)];

export const handleFormValidation = (context) => (err) => {
  // validation example:
  // { legal_name: "Use only latin letters" }
  const validation = _get(err, 'response.body.data.validation');
  if (Object.keys(validation || {}).length) {
    context.formValidation(Promise.reject(validation));
  }
};
export const isTheSameAs = (fieldName, errorMessage = 'It doesn\'t match') => (value, allValues) =>
  value === allValues[fieldName] ? undefined : errorMessage;
/* eslint-enable */
