import { get, postMultipart, requestEnd } from 'sf/helpers/request';
import help from 'models/help';
import user from 'models/user';
import * as TYPES from 'pages/UserSettings/UserSettings.types';
import { dataURItoImage } from 'sf/helpers/canvas';
import { scrollTo } from 'sf/helpers/domHelper';

const selfieUpdateSuccess = () => ({
  type: TYPES.SELFIE_UPDATE_SUCCESS,
});

const selfieUpdateFailure = () => ({
  type: TYPES.SELFIE_UPDATE_FAILURE,
});

export const hideRetakeSelfieDialog = () => ({
  type: TYPES.HIDE_RETAKE_SELFIE_DIALOG,
});

export function regenerateKey() {
  return function (dispatch) {
    const resolve = () => {
      dispatch({
        type: TYPES.API_KEY_REGENERATE_SUCCESS,
      });

      // TODO: remove when we get rid of models and redux store will be only source of data
      user.getProfileData();

      help.addNotification({
        title: 'Success',
        type: 'success',
        value: 'Your API key has been regenerated',
      });
    };
    const reject = () => {
      dispatch({
        type: TYPES.API_KEY_REGENERATE_FAILURE,
      });
      help.addNotification({
        title: 'Error',
        type: 'error',
        value: 'Something went wrong. Your API key has not been regenerated.',
      });
    };
    help.clearNotifications('');
    get('backend/profile/generate_api_key/', 'UNIVERSAL')
      .end(requestEnd(resolve, reject));
  };
}

export function initializeSelfieUpdate() {
  // Dispatch initialize action
  return {
    type: TYPES.INITIALIZE_SELFIE_UPDATE,
  };
}

export async function updateSelfie(photoDataURI) {
  const { data, extension } = await dataURItoImage(photoDataURI);
  return function (dispatch) {
    const resolve = () => {
      // Scroll to top to see helpcenter :-| TODO: Remove when we'll introduce floating header
      scrollTo(0);
      // Dispatch success action
      dispatch(selfieUpdateSuccess());
      // Add notification that everything went fine
      help.addNotification({
        title: 'Success',
        type: 'success',
        value: 'Your selfie has been successfully changed',
      });
      // Refresh the profile data
      // TODO: remove when we get rid of models and redux store will be only source of data ;-)
      user.getProfileData();
    };
    const reject = () => {
      // Scroll to top to see helpcenter :-| TODO: Remove when we'll introduce floating header
      scrollTo(0);
      // Dispatch failure action
      dispatch(selfieUpdateFailure());
      // Notification that something went wrong is handled by requestEnd
    };
    // Clear old notifications
    help.clearNotifications('');
    // Send new selfie down the wire
    postMultipart('backend/profile/change_image/', true)
      .field('image_type', 'selfie')
      .attach('image', data, `photo.${extension}`)
      .end(requestEnd(resolve, reject));
  };
}
