import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Dialog, { DialogText } from 'components/Dialog';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';

export default class RetakeSelfieDialog extends BaseComponent {
  static propTypes = {
    onDismiss: PropTypes.func,
    onPhotoTaken: PropTypes.func,
  }

  static defaultProps = {
    onDismiss: noop,
    onPhotoTaken: noop,
  }

  toggle(isVisible) {
    this.dialogInstance.toggle(isVisible);
  }

  hideDialog = () => {
    this.dialogInstance.hide();
  }

  showDialog = () => {
    this.dialogInstance.show();
  }

  render() {
    return (
      <Dialog
        className={ this.cn`__capture-dialog` }
        onDismiss={ this.props.onDismiss }
        ref={ (dialog) => { this.dialogInstance = dialog; } }
        title="Retake your selfie"
      >
        <DialogText>
          {
            is.iOS() && !is.userMediaSupported() ?
              <p>Switch to front camera and take a good selfie</p> :
              <p>Center your face in the on-screen oval</p>
          }
        </DialogText>
        <LocalAdvancedPhotoCapture
          allowCrop={ false }
          overlay="face"
          onEnterCaptureMode={ this.hideDialog }
          onExitCaptureMode={ this.showDialog }
          onPhotoTaken={ this.props.onPhotoTaken }
        />
      </Dialog>
    );
  }
}
