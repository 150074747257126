import React from 'react';
import Button from 'components/Button';
import FeatureCard from 'components/FeatureCard';
import NewsletterBox from 'components/NewsletterBox';
import PageTitle from 'components/PageTitle';
import { creditPrice } from 'config';
import { asset } from 'helpers';
import device from 'models/device';
import BasePage from 'pages/BasePage';

let cn;

export default class P2PIndex extends BasePage {
  className = 'ts-P2PIndex';

  constructor(props) {
    super(props);
    cn = this.cn();
  }

  state = {}

  componentDidMount() {
    this.syncStateWithModel(device, ['xxsmUp']);
  }

  handleSignUpButtonClick = () => {
    this.navigate('/SignUp.html');
  }

  handleMarketplaceHeroesButtonClick = () => {
    this.navigate('/mph');
  }

  renderIntroSection() {
    return (
      <section className={ cn`__section __section--intro` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              color="white"
              title={
                <span>
                  The Trust&#160;Stamp App for P2P
                </span>
              }
            />
            <div className={ cn`__description` }>
              { /* eslint-disable max-len */ }
              <p>
                <span className={ cn`__dimmed` }>The Trust&#160;Stamp App for </span>
                <span className={ cn`__p2p-highlight` }>P2P </span>
                <span className={ cn`__dimmed` }>
                  is a fast, convenient and affordable tool for strangers to verify each other's identity and establish trustworthiness before engaging in transactions on P2P marketplaces such as Craigslist, Facebook Marketplace, Uber, Airbnb, etc.
                </span>
              </p>
              <p className={ cn`__dimmed` }>
                Using sophisticated algorithms and artificial intelligence, Trust&#160;Stamp analyzes one’s government issued form of I.D. with their selfie, and compares the result against over 200 data sources. Social media data is then used to further verify one's identity along with public records (including criminal and sex offender databases) to generate a 0-800 trustworthiness score.
              </p>
              <p className={ cn`__dimmed` }>
                All in seconds and for less than the cost of a cup of coffee. There are no registration or on-going fees to create a Trust Stamp profile. Sign up today for free and verify whom you are meeting before your next transaction!
              </p>
              { /* eslint-enable max-len */ }
            </div>
            <div className={ this.cn`__sign-up-button` }>
              <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
                Get Started
              </Button>
            </div>
          </div>
        </div>

        <img
          alt=""
          src="img/img-laptop-screen.png"
          className={ cn`__intro-img-laptop-screen` }
        />
      </section>
    );
  }

  renderMarketplaceHeroesBanner() {
    return (
      <div className={ cn`ts-container` }>
        <div className={ cn`__marketplace-heroes-banner` }>
          <div className={ cn`__marketplace-heroes-title` }>
            Become a Marketplace Hero!
          </div>
          <p className={ cn`__marketplace-heroes-paragraph` }>
            { /* eslint-disable max-len */ }
            Are you an influential person within an online community? Become a Marketplace Hero and help increase safety in the online community by reducing the stranger danger that exsist when internet buyers and sellers meet in person.
            { /* eslint-enable max-len */ }
          </p>
          <Button
            onClick={ this.handleMarketplaceHeroesButtonClick }
            size="big"
          >
            { this.state.xxsmUp ? 'Go to Marketplace Heroes' : 'Become a Hero' }
          </Button>
        </div>
      </div>
    );
  }

  renderFeaturesSection() {
    return (
      <section className={ cn`__section __section--features` }>
        <div className={ cn`ts-container` }>
          <PageTitle
            size="big"
            title="​The 2-Minute Trust&#160;Stamp Process"
          />
          <div className={ cn`__steps` }>
            <div className="ts-steps">
              <div className="ts-steps__item">
                <FeatureCard
                  title="Create your profile"
                  text={ <span>Using our web site, iPhone or Android app you can
                    make a Trust&#160;Stamp profile absolutely free. Just
                    follow the guided registration process and in less than
                    2 minutes you can have your trust stamp ready to share!
                  </span> }
                  imageUrl={ asset`img/img-process-fb-01.svg` }
                  number={ 1 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title={
                    <span>Request a buyer/seller <br /> to share their Trust&#160;Stamp</span>
                  }
                  text="On average, it takes less than 10 seconds to send an SMS or email inviting
                    someone to create or share their Trust&#160;Stamp profile. Once they have shared
                    their profile they can view their confirmed name, photo, and Trust Score."
                  imageUrl={ asset`img/img-process-fb-02.svg` }
                  number={ 2 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title="Verify his identity once you meet"
                  text="Upon meeting, you will have a verified photograph, which you can use to
                    recognize the person you are buying from or selling to!"
                  imageUrl={ asset`img/img-process-fb-03.svg` }
                  number={ 3 }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderExplanationSection() {
    return (
      <section className={ cn`__section __section--explanation` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              title="Identity Empowerment"
            />
            <div className={ cn`__description` }>
              <p className={ cn`__dimmed` }>
                Trust&#160;Stamp is an alternative to expensive background checks and
                in some cases can alleviate stranger danger for both parties. In addition
                to the verified name, photograph and Trust Score, the Trust&#160;Stamp
                is color-coded , enabling you to quickly make an informed judgment
                as to the level of trust and safeguards that are appropriate.
              </p>
              <p className={ cn`__dimmed` }>
                At ${ creditPrice } per transaction, you can protect yourself while saving time that
                would be wasted manually researching potential sellers/buyers.
                To maintain your privacy and data security.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderAppPresentationSection() {
    return (
      <section className={ cn`__section __section--app-presentation` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              title="Security and Privacy"
            />
            <div className={ cn`__description` }>
              <p className={ cn`__dimmed` }>
                Trust&#160;Stamp was designed to protect people’s privacy as well as securing
                their data. When a profile is made it is viewable only by the creator,
                no information is sent until the creator decides to share it with a third
                party. Once shared, only the name, selfie and Trust Score is viewable.
                After 12 hours, that profile is deleted from the third party’s profile.
              </p>

              <p className={ cn`__dimmed` }>
                Trust&#160;Stamp stores no confidential information and stores only essential
                data such as login credentials. All other data is completely purged.
                Trust&#160;Stamp is committed to empowering users to secure and control
                their own data and does not distribute it to third party companies.
              </p>

              <p className={ cn`__dimmed` }>
                Trust&#160;Stamp is only ID verification, and the Trust Score strictly complies
                with clause 3.15 of Facebook’s platform policy, which specifically
                prohibits the use of data obtained from Facebook to make decisions
                about eligibility, (e.g. to approve or reject an application or how much
                interest to charge on a loan).
              </p>
            </div>
          </div>
        </div>
        <img
          alt=""
          src="img/img-appscreen-invite.png"
          className={ cn`__app-presentation-phone-screen` }
        />
      </section>
    );
  }

  renderSignUpSection() {
    return (
      <section className={ cn`__section __section--sign-up` }>
        <div className="ts-container">
          <p className={ cn`__sign-up-text` }>
            Sign up today for free. As a founder member you will never pay to maintain your account.
          </p>
          <div className={ cn`__sign-up-button--bottom` }>
            <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
              Get Started
            </Button>
          </div>
        </div>
      </section>
    );
  }

  renderNewsletterSection() {
    return (
      <section className={ cn`__section __section--newsletter` }>
        <div className="ts-container">
          <PageTitle
            title="Newsletter"
            color="white"
          />
          <div className={ cn`__newsletter-title` }>
            Subscribe to our newsletter and stay updated
          </div>
          <div className={ cn`__newsletter` }>
            <NewsletterBox showTitle={ false } />
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderIntroSection() }
        { this.renderMarketplaceHeroesBanner() }
        { this.renderFeaturesSection() }
        { this.renderExplanationSection() }
        { this.renderAppPresentationSection() }
        { this.renderSignUpSection() }
        { this.renderNewsletterSection() }
      </div>
    );
  }
}
