import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import { Row, Col } from 'sf/components/Grid';
import PaymentCreditCardButton from 'components/PaymentCreditCardButton';
import PaymentPayPalButton from 'components/PaymentPayPalButton';
import Render from 'components/Render';
import Stamp from 'components/Stamp';

const STAMPS_TO_UNLOCK_PROFILE = 1;

export default class ProfileViewPaymentDialog extends BaseComponent {
  className = 'ts-ProfileViewPaymentDialog';

  static propTypes = {
    isViewerLoggedIn: PropTypes.bool,
    isVisible: PropTypes.bool,
    price: PropTypes.number,
    viewerStampsCount: PropTypes.number,
    onDismiss: PropTypes.func,
    onStampsPayment: PropTypes.func,
    onSuccessfulPayment: PropTypes.func,
  };

  static defaultProps = {
    isViewerLoggedIn: false,
    isVisible: true,
    price: 0,
    viewerStampsCount: 0,
    onDismiss: noop,
    onStampsPayment: noop,
    onSuccessfulPayment: noop,
  };

  componentDidMount() {
    this.toggleDialog(this.props.isVisible);
  }

  componentWillReceiveProps({ isVisible }) {
    this.toggleDialog(isVisible);
  }

  handleLogInButtonClick = () => {
    this.navigate(`/LogIn.html?next=${window.location.href}`);
  };

  toggleDialog = (isVisible) => {
    this.dialogInstance.toggle(isVisible);
  };

  payWithStamps = () => {
    this.props.onStampsPayment();
  }

  render() {
    const { isViewerLoggedIn, onDismiss, viewerStampsCount, price } = this.props;
    return (
      <Dialog
        className={ this.rootcn`` }
        onDismiss={ onDismiss }
        ref={ (instance) => { this.dialogInstance = instance; } }
        title="Unlock the profile"
      >
        <div className={ this.cn`__body` }>
          <Row>
            <Col md={ 6 }>
              <div className={ this.cn`__cell` }>
                <div className={ this.cn`__title` }>
                  Use <Stamp>1</Stamp> to view the profile
                </div>
                <Render when={ isViewerLoggedIn }>
                  <div className={ this.cn`__subtitle` }>
                    You currently have <Stamp>{ viewerStampsCount }</Stamp>
                  </div>
                  <Render when={ viewerStampsCount }>
                    <Button onClick={ this.payWithStamps }>
                      Use your Stamps
                    </Button>
                  </Render>
                  <Render when={ !viewerStampsCount }>
                    <Button disabled={ true }>
                      You don’t have any Stamps
                    </Button>
                  </Render>
                </Render>
                <Render when={ !isViewerLoggedIn }>
                  <div className={ this.cn`__subtitle` }>
                    You have to log in to use Stamps
                  </div>
                  <Button onClick={ this.handleLogInButtonClick }>
                    Log in to use Stamps
                  </Button>
                </Render>
              </div>
            </Col>
            <Col md={ 6 }>
              <div className={ this.cn`__cell` }>
                <div className={ this.cn`__title` }>
                  Unlock for ${ price }
                </div>
                <div className={ this.cn`__subtitle` }>
                  Using PayPal or Credit Card
                </div>
                <PaymentPayPalButton
                  amount={ STAMPS_TO_UNLOCK_PROFILE }
                  onError={ this.props.onUnsuccessfulPayment }
                  onSuccess={ this.props.onSuccessfulPayment }
                />
                <div className={ this.cn`__or` }>
                  or
                </div>
                <PaymentCreditCardButton
                  amount={ STAMPS_TO_UNLOCK_PROFILE }
                  onError={ this.props.onUnsuccessfulPayment }
                  onSuccess={ this.props.onSuccessfulPayment }
                />
                <div className={ this.cn`__powered` }>
                  Powered by <span className={ this.cn`__braintree` }>Braintree</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Dialog>
    );
  }
}
