import atom from 'atom-js';
import is from 'next-is';
import { get, post, errorHandler } from 'sf/helpers/request';

const model = atom.setup({
  validation: {
    email: {
      'Please provide your email address': is.string,
      'Your email address is not valid': is.string.isEmail,
    },
    phone: {
      'Please provide phone number': is.string,
      'Please provide valid phone number': (input) => {
        return is.string.isPhone(input, 'us');
      },
    },
  },
  methods: {
    sendEmailChange: (resolve, reject) => {
      post('backend/profile/change_email/', 'UNIVERSAL')
        .type('form')
        .send({
          email: model.get('email'),
          url: `${location.origin}/EmailChangeConfirmation.html`,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve();
          }
        });
    },
    getEmailCodeVerification: (resolve, reject, code) => {
      get('backend/profile/confirm_changed_email/', 'UNIVERSAL')
        .query({
          code,
        })
        .end((err) => {
          if (err) {
            model.set({
              isEmailConfirmed: false,
            });
            reject();
          } else {
            model.set({
              isEmailConfirmed: true,
            });
            resolve();
          }
        });
    },

    sendPhoneChange: (resolve, reject) => {
      post('backend/profile/change_phone/', 'UNIVERSAL')
        .type('form')
        .send({
          phone_number: model.get('phone'),
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve();
          }
        });
    },
    getPhoneCodeVerification: (resolve, reject) => {
      get('backend/profile/change_phone/', 'UNIVERSAL')
        .query({
          code: model.get('phoneVerificationCode'),
        })
        .end((err, data) => {
          if (err) {
            reject(data.body.data.message);
          } else {
            resolve();
          }
        });
    },
  },
})({
  email: null,
  phone: null,
  isEmailConfirmed: null,
});

export default model;
