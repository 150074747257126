/* global BRAND_NAME */
import atom from 'atom-js';
import pick from 'lodash/pick';
import webcam from 'mighty-webcamjs';
import is from 'next-is';
import { post, errorHandler, requestEnd } from 'sf/helpers/request';
import { HUBSPOT } from 'constants';

const model = atom.setup({
  validation: {
    first_name: {
      'Please provide your name': is.string,
      'Please type at least 2 characters': (input) =>
        is.string.minLen(input, 2, true),
    },
    last_name: {
      'Please provide your surname': is.string,
      'Please type at least 2 characters': (input) =>
        is.string.minLen(input, 2, true),
    },
    name: {
      'Please provide your name': is.string,
      'Please type at least 2 characters': (input) =>
        is.string.minLen(input, 2, true),
    },
    email: {
      'Please provide your email address': is.string,
      'This is not a valid email address': is.string.isEmail,
    },
    phone: {
      'Please provide valid phone number': (input) => {
        return input.length > 0
          ? is.string(input) && is.string.isPhone(input)
          : true;
      },
    },
    message: {
      'Please provide message': is.isString,
    },
  },
  methods: {
    register(resolve, reject) {
      const data = pick(model.get(), [
        'first_name',
        'last_name',
        'email',
        'phone',
        'company',
      ]);

      post('backend/contactus/trial/')
        .type('form')
        .send(data)
        .end(requestEnd(resolve, reject, false));
    },

    /**
     * sendExtendedMessage method works the same as sendMessage but takes several extra params:
      'company_name',
      'company_size',
      'email',
      'first_name',
      'last_name',
      'message',
      'website_url',
      'source',

      instead of

      'name' and 'email'
     *
     * @return {[type]} [description]
     */
    sendExtendedMessage(resolve, reject, recipient, callOptions = {}) {
      const {
        website_url,
        first_name,
        last_name,
        company_name,
        company_size,
        message,
        consent,
        email,
      } = model.get();

      const extendedCallOptions = {
        ...callOptions,
        name: `${first_name} ${last_name}`,
        message: `source: ${callOptions.source} |
First Name: ${first_name} |
Last Name: ${last_name} |
Company: ${company_name || 'Not provided'} (${company_size ||
          'Company size not provided'}) |
Website URL: ${website_url || 'Not provided'} |
Consent for future emails: ${consent || 'Not provided'} |
Reply to: ${email} |

Message:
${message}`,
      };
      return model
        .sendMessage(recipient, extendedCallOptions)
        .then(resolve)
        .catch(reject);
    },

    sendMessage(resolve, reject, recipient, callOptions = {}) {
      const options = {
        includeDebugInfo: true,
        ...callOptions,
      };
      let detailedMessage = options.message || model.get('message');

      if (options.includeDebugInfo) {
        detailedMessage += `
--------------

navigator.platform: ${navigator.platform}
navigator.userAgent: ${navigator.userAgent}
navigator.vendor: ${navigator.vendor}
camera info: ${JSON.stringify(webcam.cameraInfs)}`;
      }

      post('backend/contactus/', 'UNIVERSAL')
        .type('form')
        .send({
          email: options.email || model.get('email'),
          message: detailedMessage,
          name: options.name || model.get('name'),
          app: BRAND_NAME,
          captchaResponse: options.captchaResponse,
          recipient,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            resolve();
          }
        });
    },

    sendMoneyDemoRequest: (resolve, reject) => {
      const { name, email } = model.get();

      post('backend/contactus/money2020/', 'UNIVERSAL')
        .type('form')
        .send({ email, name })
        .end(requestEnd(resolve, reject));
    },

    sendHubspotMessage: (resolve, reject) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const subject = urlParams.get('subject');

      const {
        first_name,
        last_name,
        title,
        company_name,
        email,
        country,
        phone,
        reason,
        message,
      } = model.get();

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT.PORTAL_ID}/${HUBSPOT.FORM_ID}`;

      const data = {
        // add time stamp to the end of the message
        submittedAt: new Date().toISOString(),
        fields: [
          {
            name: 'firstname',
            value: first_name,
          },
          {
            name: 'lastname',
            value: last_name,
          },
          {
            name: 'title',
            value: title || 'Not provided',
          },
          {
            name: 'company',
            value: company_name,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'country',
            value: country,
          },
          {
            name: 'phone',
            value: phone || 'Not provided',
          },
          {
            name: 'reason',
            value: reason,
          },
          {
            name: 'message',
            value: message || 'Not provided',
          },
        ],
        context: {
          pageUri: 'https://truststamp.ai/ContactUs.html',
          pageName: subject || 'Contact Us | Trust Stamp',
        },
      };

      const final_data = JSON.stringify(data);

      post(url, 'UNIVERSAL')
        .type('json')
        .send(final_data)
        .end(requestEnd(resolve, reject));
    },

    sendUnsubscribeMessage: (resolve, reject) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const subject = urlParams.get('subject');

      const { email, message } = model.get();

      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT.PORTAL_ID}/${HUBSPOT.UNSUBSCRIBE_FORM_ID}`;

      const data = {
        // add time stamp to the end of the message
        submittedAt: new Date().toISOString(),
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'message',
            value: message || 'Not provided',
          },
        ],
        context: {
          pageUri: 'https://truststamp.ai/Unsubscribe.html',
          pageName: subject || 'Unsubscribe | Trust Stamp',
        },
      };

      const final_data = JSON.stringify(data);

      post(url, 'UNIVERSAL')
        .type('json')
        .send(final_data)
        .end(requestEnd(resolve, reject));
    },
  },
})();

export default model;
