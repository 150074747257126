import React from 'react';
import omit from 'lodash/omit';
import webcam from 'mighty-webcamjs';
import { RECORDING_STARTED } from 'mighty-webcamjs/video-recorder';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import ReactTooltip from 'react-tooltip';

import BaseComponent from 'components/BaseComponent';
import Button from 'sf/components/Button';
// import CameraCountDown from 'sf/components/CameraCountDown';
import CameraCountDown from 'sf/components/CameraCountDown/CameraCountDownOpenCV';
import Icon from 'sf/components/Icon';
import { prefetch } from 'sf/components/Icon/model';
import IconButton from 'sf/components/IconButton';
import ProgressBar from 'sf/components/ProgressBar';
import Rec from 'sf/components/VideoCapture/Rec';
import Webcam from 'sf/components/Webcam';
import {
  blockPageInTransition,
  takeControlOverWindow,
} from 'sf/helpers/domHelper';
import {
  INSTRUCTION_MOVE_SIDE_TO_SIDE,
  RECORDING,
  PERFECT,
  RECORDING_STARTS_IN,
  WEBCAM_BACK,
  TAKE_VIDEO,
} from 'sf/l10n';
import { HELP_MESSAGE_CAMERA_NOT_WORKING } from 'sf/messages';
import device from 'sf/models/device';
import withTooltip from 'sf/hoc/Tooltip';

const IconWithTooltip = withTooltip(IconButton);

const WEBCAM_CONTAINER_MAX_WIDTH = 1152;
const WEBCAM_CONTAINER_MAX_HEIGHT = 864;
const ICON_HAPPY_FACE = { set: 'io', type: 'android-happy' };
const ICON_BACK = { set: 'fa', type: 'chevron-left' };

export default class VideoCapture extends BaseComponent {
  className = 'ts-VideoCapture';

  state = {
    hidden: this.props.fullScreen,
    webcamContainerWidth: 'auto',
    webcamContainerHeight: 'auto',
    webcamContainerLeftOffset: 0,
    webcamContainerTopOffset: 0,
    enableFullScreenFallbackMode: false,

    ...this.syncStateWithModelInitial(
      webcam.params,
      ['videoRecording', 'live'],
      (key, value) => {
        switch (key) {
          case 'videoRecording':
            return this.updateProgressIndicator(value);
          default: /* noop */
        }
      }
    ),
  };

  static propTypes = {
    allowRetake: PropTypes.bool,
    closeOnEnd: PropTypes.bool,
    fullScreen: PropTypes.bool,
    maxLength: PropTypes.number, // internal use only for video length control
    onBackButtonClick: PropTypes.func,
    onToggle: PropTypes.func,
    onVideoTaken: PropTypes.func.isRequired,
    overlay: PropTypes.string,
    webcamParams: PropTypes.object,
    countDownMode: PropTypes.oneOf([
      'simple',
      'face',
      'disabled',
    ]),
  };

  static defaultProps = {
    children: '',
    closeOnEnd: true,
    fullScreen: true,
    maxLength: 5 * 60 * 1000, // 5 minutes
    onBackButtonClick: noop,
    onToggle: noop,
    webcamParams: {},
    countDownMode: 'simple'
  };

  videoInstance = React.createRef();

  updateProgressIndicator = (recordingStatus) => {
    const updateTime = () => {
      if (!this.startRecordingTime) return;

      const recordingTimeLeft = this.startRecordingTime - Date.now() + this.props.maxLength;
      this.setState({ recordingTimeLeft });
    };

    if (recordingStatus === RECORDING_STARTED) {
      this.startRecordingTime = Date.now();
      updateTime();
      this.recordingInterval = setInterval(updateTime, 250);
    } else {
      clearInterval(this.recordingInterval);
      this.startRecordingTime = null;
    }
  };

  componentDidMount() {
    CameraCountDown.prefetch();

    this.subscribe(webcam.params, 'videoTaken', (data) => {
      const extra = {};
      if (data.video_preparing_time) {
        extra.video_preparing_time = data.video_preparing_time;
      }
      this.handleVideoTaken(data.blob, extra);
    });

    this.subscribe(device, ['windowHeight', 'windowWidth'], () => {
      this.updateCameraContainerDimensions();
    });

    this.subscribe(device, 'orientation', () => {
      if (webcam.params.get('videoRecording')) {
        // HACK: when camera is recording, then reattach never happens.
        // We need to inform other components that size needs to be adjusted.
        const video = webcam.getVideo();
        const dimensions = {
          width: video.videoWidth,
          height: video.videoHeight,
        };
        webcam.params.set('loadedVideoDimensions', dimensions);
      }
    });

    this.subscribe(webcam.params, 'loadedVideoDimensions', (dimensions) => {
      // Some browsers (Firefox) can result 0x0 before granting access.
      // Some browsers (Android) can result 2x2 before granting access.
      const pixels = dimensions.width * dimensions.height
      this.areDimensionsLoaded = Number.isFinite(pixels)
        && dimensions.width * dimensions.height > 4;
      this.updateCameraContainerDimensions();
    });

    this.updateCameraContainerDimensions();

    prefetch(
      ICON_BACK,
      ICON_HAPPY_FACE
    );
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.toggleFullScreenFix(false);
    this.removeWindowControl();
    this.videoInstance = null;
  }

  updateCameraContainerDimensions = () => {
    const containerWidth = this.props.fullScreen
      ? device.windowWidth()
      : this.rootNode && this.rootNode.clientWidth;
    const containerHeight = this.props.fullScreen
      ? device.windowHeight()
      : this.rootNode && this.rootNode.clientHeight;

    const webcamContainerWidth = Math.min(containerWidth, WEBCAM_CONTAINER_MAX_WIDTH);
    const webcamContainerHeight = Math.min(containerHeight, WEBCAM_CONTAINER_MAX_HEIGHT);

    this.setState({
      webcamContainerWidth,
      // leave some space for mobile browsers navigation bar at the bottom which can overlay
      // document and can block bottom buttons and ui elements
      webcamContainerHeight,
      webcamContainerLeftOffset: (containerWidth - webcamContainerWidth) / 2,
      webcamContainerTopOffset: (containerHeight - webcamContainerHeight) / 2,
    });
  };

  handleVideoTaken = (videoDataBlob, extra = {}) => {
    this.props.onVideoTaken(videoDataBlob, extra);
    if (this.props.closeOnEnd) {
      this.toggle(false);
    }
  };

  removeWindowControl = () => {
    // eslint-disable-next-line no-unused-expressions
    this.windowControl && this.windowControl.remove();
    delete this.windowControl;
  };

  toggleFullScreenFix(isDisplayed) {
    // transform breaks position fixed:
    // https://stackoverflow.com/questions/28157125/why-does-transform-break-position-fixed
    //
    // This function can be also found in AdvancedPhotoCapture
    //
    // Fix for OL-674
    if (this.props.fullScreen) {
      const fixClassName = this.cn`__position-fixed-glitch-fix`;
      if (isDisplayed) {
        let parent = findDOMNode(this);
        if (!parent) return;

        // eslint-disable-next-line no-cond-assign
        while (parent = parent.parentElement) {
          parent.classList.add(fixClassName);
        }
      } else {
        [...document.querySelectorAll(`.${fixClassName}`)]
          .forEach((domNode) => {
            domNode.classList.remove(fixClassName);
          });
      }
    }
  }

  async toggle(show) {
    blockPageInTransition();

    this.toggleFullScreenFix(show);

    if (this.props.fullScreen) {
      this.removeWindowControl();
      if (show) {
        this.windowControl = takeControlOverWindow(this.rootNode);
      }
    }

    if (show && !webcam.helpers.videoRecorder.checkIfCanRecord()) {
      this.publish('showHelp', HELP_MESSAGE_CAMERA_NOT_WORKING());
      return;
    }

    const promise = this.props.onToggle(show);

    if (show && promise && promise.then) {
      // wait for toggleFullScreen
      await promise.catch(noop);
    }

    this.setState({ hidden: !show }, async () => {
      if (show) {
        if (!this.areDimensionsLoaded) {
          this.videoInstance.current.reinitialize();
        }
        // prevent video element "jump" effect
        this.rootNode.style.visibility = 'hidden';
      }
      document.body.style.overflow = show ? 'hidden' : '';

      this.updateCameraContainerDimensions();
      this.setTimeout(() => {
        if (show) {
          this.rootNode.style.visibility = '';
        }

        if (this.props.fullScreen) {
          this.publish('fullScreen--toggle', show);
        }
        this.updateCameraContainerDimensions();
      }, 250);
    });

    if (this.state.videoRecording) {
      this.stopRecordingVideo();
    }
  }

  startRecordingVideo = () => {
    if (this.state.hidden) return;

    this.handleViewFinderText(
      [
        <div>
          &nbsp; &nbsp;{ RECORDING }…
        </div>,
        INSTRUCTION_MOVE_SIDE_TO_SIDE,
      ],
      'success',
    );

    webcam.helpers.videoRecorder.startRecording();
  };

  stopRecordingVideo = (dispatch = false) => {
    try {
      webcam.helpers.videoRecorder.stopRecording(dispatch);
    } catch (e) { /* noop */ }
  };

  handleBackButtonClick = () => {
    this.publish('cameraBackButtonClick');
    this.stopRecordingVideo();
    this.props.onBackButtonClick();
  };

  handleCameraError = (...args) => {
    this.setState({ enableFullScreenFallbackMode: is.mobile() });

    if (this.props.webcamParams.onError) {
      this.props.webcamParams.onError(...args);
    }
  }

  renderWebcamComponent() {
    if (is.userMediaSupported() && !global.MediaRecorder) {
      // Disable camera prompt in case of MediaRecorder not supported.
      // File-upload is applied instead.
      return null;
    }

    const webCamContainerStyle = {
      width: this.state.webcamContainerWidth || 'auto',
      height: this.state.webcamContainerHeight || 'auto',
      top: this.state.webcamContainerTopOffset || 0,
      left: this.state.webcamContainerLeftOffset || 0,
    };

    return (
      <div
        className={ this.rootcn`__webcam-container` }
        style={ webCamContainerStyle }
      >
        <Webcam
          ref={ this.videoInstance }
          { ...this.props.webcamParams }
          onError={ this.handleCameraError }
          overlay={ this.props.overlay }
          capture_mode={ webcam.constants.CAPTURE_MODE_VIDEO }
          video_length={ this.props.maxLength }
          viewFinderText={ this.state.viewFinderText || this.props.webcamParams.viewFinderText }
          viewFinderTheme={ this.state.viewFinderTheme }
          enableFullScreenFallbackMode={ this.state.enableFullScreenFallbackMode }
        />

        { this.renderRecordingIndicator() }

        { this.renderCaptureButton() }

        { this.props.children }

        <IconWithTooltip
          className={ this.cn`__back-button` }
          { ...ICON_BACK }
          iconSize={ 24 }
          onClick={ this.handleBackButtonClick }
          aria-label={ WEBCAM_BACK }
          tooltipText={ WEBCAM_BACK }
        />

        { this.props.countDownMode === 'disabled' ?
          null :
          (this.state.hidden
          || <CameraCountDown
            onDone={ this.startRecordingVideo }
            onViewFinderText={ this.handleViewFinderText }
            onCountDown={ this.handleCountDown }
            mode={ this.props.countDownMode }
          />)
        }
      </div>
    );
  }

  handleViewFinderText = (viewFinderText, viewFinderTheme) => {
    this.setState({ viewFinderText, viewFinderTheme });
  };

  handleCountDown = (step) => {
    const viewFinderText = (
      <div>
        { this.props.countDownMode === 'face' && (
          <React.Fragment>
            &nbsp; &nbsp;<Icon { ...ICON_HAPPY_FACE } size={ 24 } />{ PERFECT }&nbsp;
          </React.Fragment>
          )
        }
        { RECORDING_STARTS_IN } { step }…
      </div>
    );

    this.handleViewFinderText(viewFinderText, 'success');
  };

  renderRecordingIndicator() {
    const { recordingTimeLeft, videoRecording } = this.state;
    const { maxLength, countDownMode } = this.props;

    if (countDownMode !== 'disabled' || videoRecording) {
      return (
        <ProgressBar
          className={ this.cn`__capture-simple-indicator` }
          max={ maxLength }
          size={ 48 }
          color="white"
          value={ recordingTimeLeft == null ? maxLength : recordingTimeLeft }
        />
      );
    }

    return null;
  }

  renderCaptureButton() {
    const { videoRecording } = this.state;
    const { countDownMode } = this.props;

    if (countDownMode !== 'disabled' || videoRecording) {
      return null;
    }

    return (
      <Button
        key="start-record"
        theme="action"
        onClick={ this.startRecordingVideo }
        className={ this.cn`__capture-button` }
        aria-label={ TAKE_VIDEO }
        data-tip={ TAKE_VIDEO }
      />
    );
  }

  render(props, state) {
    if (!is.browser()) return;

    const className = {
      '--full-screen': props.fullScreen,
      '--hidden': state.hidden,
      '--is-recording': state.videoRecording,
    };

    return (
      <div
        { ...omit(props, Object.keys(VideoCapture.propTypes)) }
        className={ this.rootcn(className) }
        ref={ this.createRef('rootNode') }
        role="dialog"
        aria-modal="true"
      >
        { props.countDownMode === 'disabled' && <ReactTooltip /> }

        { state.videoRecording && <Rec className={ this.cn`__rec` } /> }

        { this.renderWebcamComponent() }
      </div>
    );
  }
}
