import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import InfoBox from 'components/InfoBox';
import Pagination from 'components/Pagination';
import PaymentListItem from 'components/PaymentListItem';
import Render from 'components/Render';
import device from 'models/device';
import wallet from 'models/wallet';

export default class PaymentList extends BaseComponent {
  className = 'ts-PaymentList';

  state = {};

  static propTypes = {
    title: PropTypes.string,
  };

  componentDidMount() {
    this.syncStateWithModel(device, [
      'xsmUp',
    ]);
    this.syncStateWithModel(wallet, [
      'paymentsHistoryPage', 'paymentsHistoryPagesCount', 'paymentsHistoryList',
    ]);
  }

  handlePaginationChange = (page) => {
    this.navigate(`/Wallet-${page}.html`);
  }

  render() {
    const isListNotEmpty = this.state.paymentsHistoryList &&
      this.state.paymentsHistoryList.length > 0;
    return (
      <div className={ this.rootcn() }>
        <Render when={ this.props.title }>
          <h1 className={ this.cn`__title` }>
            { this.props.title }
          </h1>
        </Render>
        <Render when={ !isListNotEmpty }>
          <InfoBox icon="ios-pricetags-outline">
            { this.state.xsmUp ?
              'You haven’t made any payments yet.' :
              'No payments yet.'
            }
          </InfoBox>
        </Render>
        { isListNotEmpty &&
          this.state.paymentsHistoryList.map((item) => (
            <div
              key={ `${item.transaction_id}-${item.payment_date}-${item.amount}` }
              className={ this.cn`__row` }
            >
              <PaymentListItem { ...item } />
            </div>
          )) }
        <div className={ this.cn`__pagination` }>
          <Pagination
            activePage={ this.state.paymentsHistoryPage }
            onPageSelect={ this.handlePaginationChange }
            pagesCount={ this.state.paymentsHistoryPagesCount }
          />
        </div>
      </div>
    );
  }
}
