import React from 'react';
import BaseComponent from 'components/BaseComponent';
import user from 'models/user';
import ErrorPage from 'pages/ErrorPage';

export default function requireUserRole(ComposedComponent, requiredUserRole) {
  return class UserRole extends BaseComponent {
    render() {
      return user.hasRole(requiredUserRole) ?
        <ComposedComponent { ...this.props } /> :
        <ErrorPage />;
    }
  };
}
