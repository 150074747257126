import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ActionBar from 'components/ActionBar';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import EmailConfirmationBar from 'components/EmailConfirmationBar';
import Render from 'components/Render';

export default class ProfileHeader extends BaseComponent {
  className = 'ts-ProfileHeader';

  static propTypes = {
    published: PropTypes.bool,
    emailConfirmed: PropTypes.bool,
    onPublish: PropTypes.func,
  };

  static defaultProps = {
    emailConfirmed: true,
    published: null,
    onPublish: noop,
  }

  openProfilePreview = () => {
    this.navigate('/ProfilePreview.html');
  };

  publishProfile = () => {
    this.props.onPublish();
  };

  openShareProfileDialog = () => {
    this.navigate('/Profile.html?action=share-profile');
  };

  render() {
    if (this.props.emailConfirmed === false) {
      return <EmailConfirmationBar />;
    }

    return (
      <ActionBar title="Your Profile">
        <Button
          onClick={ this.openProfilePreview }
          theme="ternary"
        >
          Preview your profile
        </Button>
        <Render when={ typeof this.props.published === 'boolean' }>
          <Button
            onClick={ this.publishProfile }
            theme={ this.props.published ? 'ternary' : 'secondary' }
          >
            { this.props.published ? 'Unpublish profile' : 'Publish profile' }
          </Button>
        </Render>
        <Render when={ this.props.published }>
          <Button
            onClick={ this.openShareProfileDialog }
            theme="secondary"
          >
            Share your profile
          </Button>
        </Render>
      </ActionBar>
    );
  }
}
