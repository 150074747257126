import React from 'react';
import PageTitle from 'components/PageTitle';
import BasePage from 'pages/BasePage';

export default class TermsAndConditions extends BasePage {
  className = 'ts-TermsAndConditions';
  title = 'Terms & Conditions and Privacy Policy';
  subtitle = 'THIS IS A LEGALLY BINDING AGREEMENT';

  renderContents() {
    /* eslint-disable max-len */
    return (
      <div className={ this.cn`__page-content` }>
        <p><strong>Trust Stamp is intended for only for use by adults resident and situate within the United States of America. Registration, viewing or other use by any person under the age of 14 years is strictly prohibited.</strong></p>

        <p>Both the creator / subject of a Trust Stamp and every viewer of a Trust Stamp agree to be bound by these Terms and Conditions:</p>

        <p>Trust Stamp is designed to be used only or personal economic and social transactions and enables users to create a digital identity that they can choose to share with 3rd parties.</p>

        <p>We do not carry out “background checks” or provide credit or any other references or reports.</p>

        <p>We report information that we obtain from a limited range of data sources and the information available may not be 100% accurate, complete, or up to date.</p>

        <p>The trust score that we create is based upon our analysis of objective data that we have found but cannot be treated as conclusive of any past, existing or future circumstance.</p>

        <p>We use biometric data in that we store your photograph as a means of identifying you and we also compare your photograph to your driver’s license as a means of confirming your identity. We may also use your photograph as a means of identifying you in your social media accounts or elsewhere online. We may also create and store a check-sum or other means of later confirming the validity of your stored photograph or its match to other photographs.</p>

        <p>In all cases the subject of the Trust Stamp is its creator and chooses to send or display the Trust Stamp to 3rd parties and should ensure that the contents and all data provided to generate the Trust Stamp are complete accurate. To this end, the subject / creator of each Trust Stamp warrants that all information supplied is complete and accurate and agrees not to use the Trust Stamp if it is inaccurate in its representation of the creator / subject.</p>

        <p>Trust Stamp is not responsible for the accuracy of the data provided by the Trust Stamp creator or the contents of the Trust Stamp that is derived therefrom.</p>

        <p>The creator / subject of a Trust Stamp is responsible for their own actions and the use of the Trust Stamp and agree to indemnify Trust Stamp and its owners, officers, employees, servants, agents and affiliates against any liability, cost or expense that results from the use of a Trust Stamp by its creator / subject or any party to whom the Trust Stamp is disclosed.</p>

        <p><strong>If you are the recipient of a Trust Stamp, do not use it as a substitute for your own due diligence.</strong></p>

        <p>Trust Stamp does not make any representation or warranty about the accuracy of the information obtained or about the safety, character or integrity of the holder of a Trust Stamp.</p>

        <p>Viewers should not accept a Trust Stamp unless it is accessed or sent directly from the Trust Stamp server (https://truststamp.net) and a Trust Stamp is only indicative of data accessed on the date of issue and should not be replicated or reused at a future time. Trust Stamps are generated for the use only of the person that they profile and are provided to 3rd parties only at the request of their creator.</p>

        <p>Any suspicions as to inaccuracy, falsification or misuse of a Trust Stamp should immediately be notified to: <a href="mailto:security@truststamp.net">security@truststamp.net</a>.</p>

        <p>Trust Stamp’s maximum liability in either contract or tort is limited to $1 (One Dollar) and each of the creator of the Trust Stamp and the viewer agree to that limitation and release Trust Stamp and its owners, officers, employees, servants, agents and affiliates from any other liability</p>

        <p>This agreement, and the relationship between the parties shall be subject to the laws of the State of Georgia in the United States of America and the Superior Court of Fulton County, Georgia shall have sole jurisdiction subject only to the arbitration clause that follows</p>

        <p><strong>Any controversy or claim arising out of or relating to this Agreement or the breach thereof, shall be settled by binding arbitration administered by Henning Mediation & Arbitration Service, Inc. (HMA) of Atlanta, Georgia, USA in accordance with its rules, and judgment upon the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The arbitration hearing and all proceedings in connection therewith shall take place in Atlanta, Georgia. The arbitration shall be conducted by one or more arbitrators selected by the parties from HMA panel of arbitrators, or, if they are unable to agree on the selection, by one or more arbitrators appointed by HMA. The arbitration hearing shall be commenced within 90 days of the filing of a Demand for Arbitration by either party, and the award shall be rendered within 30 days of the conclusion of such hearing.</strong></p>

      </div>
    );
    /* eslint-enable */
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` }>
        <div className={ this.cn`__page-title` }>
          <PageTitle
            title={ this.title }
            subtitle={ this.subtitle }
          />
        </div>
        { this.renderContents() }
      </div>
    );
  }
}
