import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import BaseComponent from 'components/BaseComponent';
import PillButton from 'components/PillButton';
import Spinner from 'components/Spinner';

export default class PolSection extends BaseComponent {
  className = 'ts-PolSection';

  static propTypes = {
    videoUrl: PropTypes.string,
    isPrivateViewMode: PropTypes.bool,
    isPreviewMode: PropTypes.bool,
    isVideoProcessing: PropTypes.bool,
    onRetakeButtonClick: PropTypes.func,
  };

  static defaultProps = {
    videoUrl: null,
    isVideoProcessing: false,
    isPrivateViewMode: false,
    isPreviewMode: false,
    onRetakeButtonClick: noop,
  };

  handleRetakeClick = () => {
    this.props.onRetakeButtonClick();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__header` }>
          Proof of Liveness
          { this.props.isPrivateViewMode &&
            !this.props.isVideoProcessing &&
            !this.props.isPreviewMode &&
            (
              <div className={ this.cn`__manage` }>
                <PillButton
                  isInked={ false }
                  onClick={ this.handleRetakeClick }
                  shell={ true }
                >
                  Retake video
                </PillButton>
              </div>
            )
          }
        </div>
        <div className={ this.cn`__wrap` }>
          <div className={ this.cn`__video-container` }>
            { this.props.isVideoProcessing ? (
              <div className={ this.cn`__video-spinner` }>
                <Spinner
                  className={ this.cn`__spinner` }
                  size="small"
                />
              </div>
            ) : (
              <ReactPlayer
                url={ this.props.videoUrl }
                controls={ true }
                width="100%"
                height="100%"
              />
            ) }
          </div>
        </div>
      </div>
    );
  }
}
