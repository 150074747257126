import is from 'next-is';
import { generateToken } from 'sf/helpers';
import {
  HELP_CENTER_NOTIFICATIONS,
  HELP_CENTER_ADAPTED_NOTIFICATIONS_KEYS,
} from 'components/HelpCenter/HelpCenter.constants';

const NOTIFICATION_GROUPID_LENGTH = 4;

export const generateGroupId = () => {
  return generateToken(NOTIFICATION_GROUPID_LENGTH);
};

export const getHelpCenterNotification = (notificationName) => {
  const notification = HELP_CENTER_NOTIFICATIONS[notificationName];
  if (!notification) {
    /* eslint-disable no-console */
    console.error(`Wrong notification name: '${notificationName}' provided.`);
    /* eslint-enable no-console */
    return;
  }

  return is.isObject(notification) ? notification : { value: notification };
};

export const getHelpCenterNotifications = (notificationNames = []) => {
  return notificationNames.map((notificationName, index) =>
    Object.assign(
      {},
      {
        order: index + 1,
      },
      getHelpCenterNotification(notificationName)
    ));
};

export function adaptResponseMessage(message) {
  return Object
    .entries(HELP_CENTER_ADAPTED_NOTIFICATIONS_KEYS)
    .filter(([key]) => message.includes(key))
    .map(([, value]) => HELP_CENTER_NOTIFICATIONS[value])
    .join() || message;
}

