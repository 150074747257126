import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class PageTitle extends BaseComponent {
  className = 'ts-PageTitle';

  static propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    color: PropTypes.oneOf(['green', 'black', 'white']),
    context: PropTypes.node,
    size: PropTypes.oneOf(['huge', 'big', 'medium', 'small']),
    subtitle: PropTypes.node,
    theme: PropTypes.oneOf(['regular', 'light']),
    title: PropTypes.node.isRequired,
    underline: PropTypes.bool,
  };

  static defaultProps = {
    align: 'center',
    color: 'black',
    context: null,
    size: 'medium',
    subtitle: null,
    theme: 'regular',
    underline: true,
  };

  render() {
    const { align, color, context, size, subtitle, theme, title, underline } = this.props;
    const classNames = {
      [`--${size}`]: size,
      [`--${align}`]: align,
      [`--${color}`]: color,
      [`--${theme}`]: theme,
      '--underline': underline,
    };
    return (
      <div className={ this.rootcn(classNames) }>
        <h1 className={ this.cn`__title` }>
          { title }
          { context && <span className={ this.cn`__context` }>({ context })</span> }
        </h1>
        { typeof subtitle === 'string' &&
          <h2
            className={ this.cn`__subtitle` }
            dangerouslySetInnerHTML={ { __html: subtitle } }
          />
        || typeof subtitle === 'object' &&
          <h2
            className={ this.cn`__subtitle` }
          >
            { subtitle }
          </h2>
        }
      </div>
    );
  }
}
