import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';
import user from 'models/user';
import { getQuery } from 'sf/helpers';

export default class ResetPasswordLinkReceived extends BaseComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructorBrowser() {
    const { code } = getQuery();
    if (code) {
      user.set({
        reset_password_code: code,
      });
    }
  }

  handlePhotoTaken = (data) => {
    this.props.onSubmit(data);
  }

  render() {
    return (
      <LocalAdvancedPhotoCapture
        allowRetake={ false }
        allowCrop={ false }
        onPhotoTaken={ this.handlePhotoTaken }
        overlay="face"
      />
    );
  }
}
