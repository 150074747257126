import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Icon from 'sf/components/Icon';

export default class Radio extends BaseComponent {
  className = 'ts-Radio';

  static propTypes = {
    children: PropTypes.node,
    stateLink: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.oneOf(['check', 'radio']),
  };

  static defaultProps = {
    children: '',
    onChange: noop,
    style: 'check',
  };

  handleChange = () => {
    const [stateOwner, key] = this.props.stateLink;

    stateOwner.setState({
      [key]: this.props.value,
    });
  };

  setValid() {
    throw new Error('setValid of Radio is not implemented');
  }

  render() {
    const [stateOwner, key] = this.props.stateLink;
    const modelValue = stateOwner.state[key];
    const internalValue = modelValue === this.props.value && modelValue != null; // eslint-disable-line
    return (
      <div
        className={ this.rootcn({
          '__disabled': this.props.disabled,
        }) }
        role="button"
        tabIndex={ 0 }
        onClick={ this.props.disabled ? noop : this.handleChange }
      >
        <div className={ this.cn({
          [`__${this.props.style}`]: true,
          [`__${this.props.style}--selected`]: internalValue,
        }) }
        >
          {
            // modelValue can be undef/null if so, we don't render ico (undetermined support)
            internalValue ?
              <Icon
                className={ this.cn(`__${this.props.style}-icon`) }
                type={ this.props.style === 'radio' ? 'circle' : 'check' }
                size={ this.props.style === 'check' ? 16 : 7 }
              /> :
              null
          }
        </div>
        <span>{ this.props.children }</span>
      </div>
    );
  }
}
