import React from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import IconButton from 'components/IconButton';
import Render from 'components/Render';
import ValidationInput from 'sf/components/ValidationInput';

const MAX_EMAIL_INVITES = 5;

export default class InviteContactInputs extends BaseComponent {
  className = 'ts-InviteContactInputs';

  static propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    maxInvites: PropTypes.number,
    invalidInvites: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    onChange: noop,
    maxInvites: MAX_EMAIL_INVITES,
    invalidInvites: [],
  };

  state = {
    invitedContacts: [{
      id: 0,
      text: '',
    }],
    lastInvitationId: 0,
  };

  componentDidMount() {
    this.props.onUpdate(this.state.invitedContacts);
  }

  componentDidUpdate() {
    // Reset all valid states and set invalid correspondingly
    this.state.invitedContacts.forEach((invite) =>
      this.refs[`input-${invite.id}`] && this.refs[`input-${invite.id}`].setValid(true));
    this.props.invalidInvites.forEach((inviteId) =>
      this.refs[`input-${inviteId}`] && this.refs[`input-${inviteId}`].setValid(false));
  }

  addContact = (lastAddedId) => {
    const contacts = this.state.invitedContacts;
    // Check if this contact already exists…
    const lastAddedContact = contacts.find(({ id }) => id === lastAddedId);
    const isContactValueDuplicted = contacts.filter(
      ({ text }) => text === lastAddedContact.text
    ).length > 1;
    if (isContactValueDuplicted) {
      this.refs[`input-${lastAddedId}`].setValid(false);
      return;
    }
    // …and add new contact if not.
    const clonedInvitations = contacts.slice(0);
    clonedInvitations.push({
      id: ++this.state.lastInvitationId,
      text: '',
    });
    this.setState({ invitedContacts: clonedInvitations });
    this.props.onUpdate(clonedInvitations);
  };

  removeContact = (id) => {
    const index = this.state.invitedContacts.findIndex((el) => el.id === id);
    const clonedInvitations = this.state.invitedContacts.slice(0);
    clonedInvitations.splice(index, 1);
    this.setState({ invitedContacts: clonedInvitations });
    this.props.onUpdate(clonedInvitations);
  };

  getStateSetterForIndex(id) {
    const that = this;
    return {
      get state() {
        // We must return some object wit "state" prop.
        // React calls component::render() ?somehow?, when it should be
        // destroyed, but at this moment we don't have such id in invitedContacts
        // See more: https://discuss.reactjs.org/t/how-does-react-decide-whether-to-reuse-an-existing-component/571
        return that.state.invitedContacts.find((el) => el.id === id) || { state: null };
      },
      setState: (stateObj, callback) => {
        const stateValue = typeof stateObj === 'function' ? stateObj() : stateObj;
        const index = that.state.invitedContacts.findIndex((el) => el.id === id);
        const clonedInvitations = this.state.invitedContacts.slice(0);
        const newState = {
          ...clonedInvitations[index],
          ...stateValue,
        };
        clonedInvitations[index] = omit(newState, [stateValue[newState.errorField] ? 'error' : null]);
        this.setState(
          () => ({ invitedContacts: clonedInvitations }),
          () => {
            this.props.onUpdate(this.state.invitedContacts);
            callback();
          }
        );
      },
    };
  }

  render() {
    const invitesCount = this.state.invitedContacts.length;
    return (
      <ul className={ `${this.cn('__email-list')}
        ${this.rootcn(this.props.maxInvites === 1 ? '--single' : '')}` }
      >
        { this.state.invitedContacts.map((item, index) => (
          <li className={ this.cn`__email-entry` } key={ item.id }>
            <ValidationInput
              ref={ `input-${item.id}` }
              stateLink={ [this.getStateSetterForIndex(item.id), 'text'] }
              className={ this.cn`__send-email-input` }
              placeholder="Add e-mail or phone number"
              type="text"
              autoCorrect="off"
              autoCapitalize="off"
              onChange={ this.props.onChange }
            />

            <Render when={ invitesCount > 1 && item.text }>
              <div className={ this.cn`__remove-email` }>
                <IconButton
                  onClick={ () => this.removeContact(item.id) }
                  transparent={ true }
                  type="ios-close"
                  iconSize={ 20 }
                />
              </div>
            </Render>

            <Render when={ invitesCount < this.props.maxInvites }>
              <div className={ this.cn`__add-email` }>
                <IconButton
                  onClick={ () => this.addContact(item.id) }
                  transparent={ true }
                  disabled={ index + 1 === invitesCount && !item.text }
                  type="person-add"
                />
              </div>
            </Render>
          </li>
        )) }
      </ul>
    );
  }
}
