import { sentryLog, sleep, asset } from 'sf/helpers';
import { unlockPageUnload } from 'sf/helpers/domHelper';
import { UNEXPECTED_ERROR_TITLE, UNEXPECTED_ERROR_CONTENT } from 'sf/l10n';
import { getCrossOriginWorker } from 'cross-origin-worker';

export const getBlobFromURL = (blobURL) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', blobURL, true);
    xhr.responseType = 'blob';
    xhr.onerror = reject;
    xhr.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      }
    };
    xhr.send();
  });
};

/**
 * async-friendly version of the canvas.toBlob
 *
 * @param  {Canvas} canvas
 * @return {Promise<Blob>}
 */
export const canvasToBlob = (canvas, format = 'image/png', quality = 1) => (
  new Promise((resolve, reject) => {
    if (canvas.toBlob) {
      // every browser but Edge
      canvas.toBlob((blob) => {
        resolve(blob);
      }, format, quality);
    } else {
      reject();
    }
  })
);

/**
 * async-friendly version of the canvas.toBlob
 *
 * @param  {Canvas} canvas
 * @return {Promise<string>}
 */
export const canvasToBlobURL = (...args) => (
  canvasToBlob(...args).then((blob) => {
    return URL.createObjectURL(blob);
  })
);

/**
 * Takes encoded image, and returns format acceptable by superagent.attach
 *
 * NOTE: this function takes care of the cleanup. Input canvas or object url is removed
 *
 * @param  {string or Canvas} dataURI, Canvas or blob URL
 * @return {Promise(Object)}          sample: { data: [object Blob], extension: 'jpeg' }
 */
export const photoToRequestAttachment = async (dataURI, retry = 0) => {
  let blob;
  try {
    if (typeof dataURI === 'string') {
      // getBlobFromURL handles blob: and data:image inputs
      blob = await getBlobFromURL(dataURI);
      URL.revokeObjectURL(dataURI);
    } else if (dataURI instanceof HTMLCanvasElement) {
      blob = await canvasToBlob(dataURI);
      dataURI.remove();
    } else {
      throw new Error('Unknown param passed to photoToRequestAttachment');
    }
  } catch (e) {
    if (retry < 1) {
      await sleep(1000); // one retry attempt
      return photoToRequestAttachment(dataURI, retry + 1);
    }

    /**
     * This error might happen in case of a full memory. Page reload should fix the problem,
     * at least temporarily.
     */
    sentryLog(new Error('Can\'t reach BLOB in photoToRequestAttachment'), e);

    // eslint-disable-next-line no-alert
    alert(`${UNEXPECTED_ERROR_TITLE}. ${UNEXPECTED_ERROR_CONTENT}.`);

    unlockPageUnload();
    // window.location.reload();
    return {};
  }

  const ext = /^image\/(.*)/.exec(blob.type);
  if (!ext) {
    sentryLog(`"${blob.type}" is not valid type for photoToRequestAttachment`);
  }

  return {
    data: blob,
    extension: ext && ext[1],
  };
};

/**
 * drawOnCanvas takes Image, canvas or video and draws it on the new canvas instance.
 * When second parameter is provided there are two options:
 * - Canvas: reuse canvas. Size of the input canvas will be adjusted to the input image
 * - Options: When options are provided image is resized.
 *     options.width: number,
 *     options.height: number,
 *     options.scale: number, (instead of width and height. range from 0 to 1)
 *     options.canvas: Canvas (optional)
 *
 * When width/height are provided then image is not stretched but is drawn in the center.
 *
 * @param  {HTMLVideoElement/HTMLImageElement/HTMLCanvasElement/ImageBitmap} input
 * @param  {Canvas/Object} canvasOrOptions (optional)
 * @return {Canvas}
 */
export const drawOnCanvas = (input, canvasOrOptions = {}) => {
  // videoWidth = HTMLVideoElement
  // naturalWidth = HTMLImageElement / Image
  // width = HTMLCanvasElement or ImageBitmap

  const canvas = canvasOrOptions instanceof HTMLCanvasElement
    ? canvasOrOptions
    : (canvasOrOptions.canvas || document.createElement('canvas'));
  const ctx = canvas.getContext('2d');

  if (ctx === null) {
    // https://stackoverflow.com/questions/40482586/getcontext2d-returns-null-in-safari-10
    // possible memleak
    const msg = 'ctx in storeFrame is null.';
    console.error(msg); // eslint-disable-line no-console
    return canvas;
  }

  const srcWidth = input.videoWidth || input.naturalWidth || input.width;
  const srcHeight = input.videoHeight || input.naturalHeight || input.height;

  if (
    canvasOrOptions instanceof HTMLCanvasElement
    || canvasOrOptions.scale === 1
    || !canvasOrOptions.width
  ) {
    // no scaling
    canvas.width = srcWidth;
    canvas.height = srcHeight;

    ctx.drawImage(input, 0, 0, canvas.width, canvas.height);
  } else {
    // downsizing
    canvas.width = canvasOrOptions.scale * srcWidth || canvasOrOptions.width;
    canvas.height = canvasOrOptions.scale * srcHeight || canvasOrOptions.height;

    // stackoverflow.com/questions/23104582/scaling-an-image-to-fit-on-canvas#answer-23105310
    const hRatio = canvas.width / srcWidth;
    const vRatio = canvas.height / srcHeight;
    const ratio = Math.min(hRatio, vRatio);
    const centerShiftX = (canvas.width - srcWidth * ratio) / 2;
    const centerShiftY = (canvas.height - srcHeight * ratio) / 2;

    ctx.imageSmoothingEnabled = true;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // eslint-disable-next-line max-len
    ctx.drawImage(input, 0, 0, srcWidth, srcHeight, centerShiftX, centerShiftY, srcWidth * ratio, srcHeight * ratio);
  }

  return canvas;
};

/**
 * This function creates an image from a URL, canvas or dataURI.
 * It's also the easiest way to put image on canvas, when
 * image is loaded.
 *
 * @param  {string, Blob, File or Canvas} input
 * @param  {bool} allowBlob (optional) blobType. When false,
 *                          dataURL is used instead
 * @return {Promise}
 */
export const getImage = (input, allowBlob = true, imageType = 'image/png') => {
  return new Promise((resolve, reject) => {
    if (!input) {
      reject();
    }

    const img = new Image();
    let cleanup = () => {};

    img.onload = () => {
      resolve(img);
      cleanup();
    };
    img.onerror = reject;

    if (input instanceof File) {
      /*
       Handle image file input
       Example of use:
        <input
          type="file"
          accept="image/*"
          capture={ true }
          onChange={ (e) => {
            getImage(target.files[0]).then((image) => {
              // ...
            });
          } }
        />
       */

      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        img.src = readerEvent.target.result;
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(input);
    } else if (input instanceof Blob) {
      img.src = URL.createObjectURL(input);
      cleanup = () => URL.revokeObjectURL(img.src);
    } else if (typeof input === 'string') {
      // Data URI or URL
      img.src = input;
    } else if (input instanceof HTMLCanvasElement) {
      if (allowBlob && input.toBlob) {
        // every browser but Edge
        return input.toBlob((blob) => {
          cleanup = () => URL.revokeObjectURL(img.src);
          img.src = URL.createObjectURL(blob);
        }, imageType);
      }

      img.src = input.toDataURL(imageType);
    } else if (input instanceof Image) {
      img.src = input.src;
    } else {
      throw new Error('Input passed to getImage is not string or canvas.');
    }
  });
};

/**
 * Crop rectangle from the center of the provided canvas
 *
 * @param {Canvas} canvas
 * @param {Number} width
 * @param {Number} height
 * @returns {imageData}
 */
export const cropCanvas = (canvas, width, height) => {
  const imageWidth = canvas.width;
  const imageHeight = canvas.height;

  const cropWidth = Math.min(imageWidth, width);
  const cropHeight = Math.min(imageHeight, height);
  const x = (imageWidth - cropWidth) / 2;
  const y = (imageHeight - cropHeight) / 2;

  return canvas.getContext('2d').getImageData(x, y, cropWidth, cropHeight);
};


/**
 * getCanvas converts input to canvas.
 *
 * @param  {Canvas, Image, Video or base64 image or blob image} input
 * @return {Promise}
 */
export const getCanvas = (input) => {
  if (input instanceof HTMLCanvasElement) {
    return Promise.resolve(input);
  }

  const easlyTransferrableTypes = [
    Image,
    HTMLImageElement,
    HTMLVideoElement,
    ImageBitmap,
  ];

  const isEaslyTransferrableType = easlyTransferrableTypes
    .some((Type) => input instanceof Type);

  return new Promise((resolve, reject) => {
    if (isEaslyTransferrableType) {
      return resolve(drawOnCanvas(input));
    }

    getImage(input)
      .then((img) => {
        resolve(drawOnCanvas(img));
        img.remove();
      })
      .catch(reject);
  });
};

/**
 * logImage logs image to console.
 * This function was created because 'console-image' npm module prints image twice.
 *
 * As it consumes memory, that we need on low-end devices, this function is enabled
 * only on LOCAL and DEV.
 *
 * examples:
 *    logImage(img); // img = new Image();
 *    logImage('data:image/gif;base64,R0lGODlhyAAiALM...DfD0QAADs');
 *    logImage(canvas); // canvas = document.createElement('canvas');
 *    logImage([img1, img2, ...]);
 *
 * @param  {...mixed} images Canvas, Image, blob URL or URI
 */
export const logImage = (...images) => {
  if (ENV === 'local') {
    if (Array.isArray(images[0])) {
      // eslint-disable-next-line no-console
      // return console.log('Image array', images);
      return images[0].forEach((img) => {
        logImage(img);
      })
    }
    images.forEach((imgSrc) => {
      if (imgSrc instanceof Promise) return; // result of ImageCapture.takePhoto

      if (imgSrc instanceof ImageData) {
        const canvas = document.createElement('canvas');
        canvas.width = imgSrc.width;
        canvas.height = imgSrc.height;
        canvas.getContext('2d').putImageData(imgSrc, 0, 0);

        return logImage(canvas);
      }

      // NOTE: It's impossible to log blob in a console. Convert blob to canvas first.
      if (
        typeof imgSrc === 'string' && imgSrc.substr(0, 5) === 'blob:'
        || imgSrc instanceof Image && imgSrc.src.substr(0, 5) === 'blob:'
      ) {
        return getCanvas(imgSrc).then(logImage);
      }

      getImage(imgSrc, false)
        .then((img) => {
          const { width, height, src } = img;
          // eslint-disable-next-line no-console
          console.log(
            '%c+',
            `font-size: 0;
padding: ${Math.ceil(height / 2)}px ${Math.ceil(width / 2)}px;
line-height: 0;
background: url(${src.replace(/\(/g, '%28').replace(/\)/g, '%29')});
background-size: ${width}px ${height}px;
color: transparent;
background-repeat: no-repeat;`
          );
        });
    });
  }
};
let measureBlurWorker;

let measureBlur = async (canvasData) => {
  const lib = require('inspector-bokeh/async');
  if (!measureBlurWorker) {
    measureBlurWorker = getCrossOriginWorker(asset`lib/measure_blur/measure_blur_worker.js`);
  }
  const worker = await measureBlurWorker;
  lib.setup({
    worker,
  });

  // After the first call, we replace this loader function with an
  // actual library.
  measureBlur = lib;

  return lib(canvasData);
};

/**
 * @param {string[]} photoURIArray - An array of blob URLs.
 * @param {number} desiredWidth - The desired width for the cropped images.
 * @param {number} desiredHeight - The desired height for the cropped images.
 * @returns {Promise<{avg_edge_width_perc: number,
 *                    avg_edge_width: number, originalCanvas: Canvas}[]>}
 */
export const cropAndCheckBlur = async (
  canvasArray,
  desiredWidth,
  desiredHeight
) => {
  const croppedImagePromises = canvasArray.map(async (canvas) => {
    const croppedImageData = cropCanvas(
      canvas,
      desiredWidth,
      desiredHeight,
    );
    try { // Worker might not be loaded correctly because of CSP
      const { avg_edge_width_perc, avg_edge_width } = await measureBlur(croppedImageData);
      return { avg_edge_width_perc, avg_edge_width, originalCanvas: canvas };
    } catch (e) {
      // eslint-disable-next-line
      console.log('blur detection could not be performed due CSP policy restriction');
      return { originalCanvas: canvas };
    }
  });

  const results = await Promise.all(croppedImagePromises);

  // sort by avg_edge_width_perc (higher means more blurry)
  results.sort((a, b) => a.avg_edge_width_perc - b.avg_edge_width_perc);

  return results;
};

export const getLeastBlurryCanvas = async (canvasArray, cropSize = 250, removeBadImages = true) => {
  if (!Array.isArray(canvasArray)) {
    return canvasArray;
  }
  if (canvasArray.length === 1) {
    return canvasArray[0];
  }

  const checkedImages = await cropAndCheckBlur(canvasArray, cropSize, cropSize);

  if (removeBadImages) {
    canvasArray.forEach((canvas) => {
      if (canvas !== checkedImages[0].originalCanvas) {
        canvas.remove();
      }
    })
  }

  return checkedImages[0].originalCanvas;
};

