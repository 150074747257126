import atom from 'atom-js';
import is from 'next-is';
import _get from 'lodash/get';
import omit from 'lodash/omit';

import { symbols } from 'sf/helpers';
import { get, errorHandler, requestEnd } from 'sf/helpers/request';
import router from './router';
import user from './user';

function getProfileID() {
  return router.get('params').shortProfileID;
}

const PUBLIC_ACCESS_MODES = symbols('FULL', 'LIMITED', 'FORBIDDEN');

const model = atom.setup({
  modelName: 'publicUserModel',
  validation: {
    PUBLIC_ACCESS_MODES: {
      options: (value) => !!PUBLIC_ACCESS_MODES[value],
    },
    inSync: {
      bool: is.isBoolean,
    },
  },
  methods: {
    setProfileID(resolve, reject, shortProfileID) {
      if (model.get('shortProfileID') !== shortProfileID) {
        model.clear().then(() => {
          model.set('shortProfileID', shortProfileID);
          resolve();
        }, reject);
      }
    },
    getFullProfileData(resolve, reject, shortProfileID = getProfileID()) {
      model.setProfileID(shortProfileID);

      const isSignedIn = !!user.get('isSignedIn');
      get(`backend/view/${shortProfileID}/full/`, isSignedIn)
        .end(fullProfileDataCallback(resolve, reject));
    },
    getProfileData(resolve, reject, forceAccess = false, accessToken = null) {
      const cb = resolve;
      const getLimited = () => {
        model
          .getLimitedProfileData()
          .then(cb, resolve);
      };
      const getFullThenLimited = () => {
        model.getFullProfileData().then(cb, getLimited);
      };

      if (accessToken) {
        // handle access from prepaid view
        model
          .getPrepaidProfileData(accessToken)
          .then(cb, resolve);
        return;
      }

      if (!forceAccess && user.get('isSignedIn')) {
        return model
          .checkAccessForSignedUser()
          .then(getFullThenLimited, getLimited);
      }

      getFullThenLimited();
    },
    getLimitedProfileData(resolve, reject, shortProfileID = getProfileID()) {
      model.setProfileID(shortProfileID);

      get(`backend/view/${shortProfileID}/limited/`)
        .end((err, res) => {
          const data = res.body.data || {};
          if (err) {
            if (data.errorCode === 403) {
              model.set('accessMode', PUBLIC_ACCESS_MODES.FORBIDDEN);
              reject(err);
              return;
            }
            errorHandler(err, res);
          } else {
            model.set({
              ...omit(data, ['absolute_photo_url']),
              accessMode: PUBLIC_ACCESS_MODES.LIMITED,
              photo: data.absolute_photo_url,
              services: data.services,
            });
            resolve(res);
          }
        });
    },
    getPrepaidProfileData(resolve, reject, accessToken, shortProfileID = getProfileID()) {
      model.setProfileID(shortProfileID);

      if (accessToken.includes('/')) {
        // backend URL passed in accessToken value.
        // example URL: `/backend/view/requested/d999d94a-9e99-4e77-b127-8a1030c131cc/`
        return get(accessToken, 'UNIVERSAL')
          .end(fullProfileDataCallback(resolve, reject));
      }

      get(`backend/view/prepaid/${accessToken}/`, user.get('isSignedIn'))
        .end(fullProfileDataCallback(resolve, reject));
    },
    checkAccessForSignedUser(resolve, reject, shortProfileID = getProfileID()) {
      get(`backend/view/has_access/${shortProfileID}/`, 'UNIVERSAL')
        .end(requestEnd(resolve, reject));
    },
  },
})();

function fullProfileDataCallback(resolve, reject) {
  return (err, res) => {
    const data = res.body.data || {};
    if (err) {
      // this error shouldn't be shown, it's confusing to the user
      if (_get(res, 'body.data.message') !== 'Payment required') {
        errorHandler(err, res);
      }
      if (data.errorCode === 403) {
        // Profile not published. Let's try getting limited data instead
        return model.getLimitedProfileData(model.get('shortProfileID')).then(resolve, reject);
      }
      reject(err);
    } else {
      model.set({
        ...omit(data, [
          'absolute_photo_url',
        ]),
        accessMode: PUBLIC_ACCESS_MODES.FULL,
        photo: data.absolute_photo_url,
        services: data.services,
      });
      resolve(res);
    }
  };
}

Object.assign(model, {
  PUBLIC_ACCESS_MODES,
  getAge() {
    return user.getAge(model.get('date_birth'));
  },
});

export default model;
