import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Script from 'sf/components/Script';

export default class ExternalRedirect extends BaseComponent {
  className = 'ts-ExternalRedirect';

  static propTypes = {
    to: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { to } = this.props;
    if (to) window.location.replace(to);
  }

  render() {
    return (
      <Script>
        { `window.location.replace('${this.props.to}')` }
      </Script>
    );
  }
}
