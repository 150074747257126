import React from 'react';
import noop from 'no-op';

import PageTitle from 'components/PageTitle';
import { BRANDINGS } from 'constants';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import {
  mediator,
} from 'sf/helpers';

export default class VerificationComplete extends BasePage {
  className = 'ts-VerificationComplete';
  title = 'Your data is currently being processed.';

  state = {
    verificationRequests: 0,
    verificationPassed: false,
  };

  componentDidMount() {
    this.checkVerificationStatus(noop, noop, BRANDINGS.ORGANIZATION_SUPERVISOR === BRAND_NAME);
  }

  checkVerificationStatus = () => {
    registration.getVerificationStatus().then((res) => {
      if (registration.get('verification_in_progress')) {
        const verificationRequests = this.state.verificationRequests + 1;
        this.setState({
          verificationRequests,
        });
        this.publish('GlobalLoader--toggle');
        if (verificationRequests > 5) {
          this.publish(
            'GlobalLoader--setCustomMessage',
            'Still processing...'
          );
        }
        setTimeout(this.checkVerificationStatus, 2000);
      } else if (!registration.get('verification_complete')
        && !registration.get('verification_in_progress')) {
        // error - go and retake POL video and pass error message to SignUpVideoReUpload
        registration.set('verification_error_message', res.trimmedMessage);
        mediator.publish('setRegistrationStep', '/SignUpRetakeVideo.html');
        this.navigate('/SignUpRetakeVideo.html');
      } else if (registration.get('verification_complete')) {
        registration.sendPINCode()
          .then(registration.get('isRealtor') && registration.setRealtorNumber())
          .then(registration.signUpComplete)
          .then(() => {
            registration.clear();
            this.clearCustomMessage();
            this.setState(() => ({ verificationPassed: true }));
            mediator.publish('setRegistrationStep', registration.REGISTRATION_DONE_STEP);
            this.navigate('/Profile.html');
          });
      }
    }, () => this.clearCustomMessage());
  }

  clearCustomMessage = () => this.publish('GlobalLoader--setCustomMessage', '');

  render() {
    const contentClassNames = {
      '__card-content': true,
      '__card-content--visible': true,
    };

    return (
      <div className={ this.rootcn`` }>
        <section className={ this.cn`ts-container ts-container--narrow __section` }>
          <div className={ this.cn(contentClassNames) }>
            <PageTitle
              className={ this.cn`__title` }
              hr={ true }
              title={ this.title }
            />
          </div>
        </section>
      </div>
    );
  }
}
