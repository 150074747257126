import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
// import DriversLicense from 'components/DriversLicense';
import CustomerDocument from 'components/CustomerDocument';
import PillButton from 'components/PillButton';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class DocumentSection extends BaseComponent {
  className = 'ts-DocumentSection';

  static propTypes = {
    document: PropTypes.object,
    isDocumentVisible: PropTypes.bool,
    isHiddenDuringSharing: PropTypes.bool,
    isPrivateViewMode: PropTypes.bool,
    isSelfieMismatched: PropTypes.bool,
    portrait: PropTypes.string,
    onRetakeButtonClick: PropTypes.func,
    onBackRetakeButtonClick: PropTypes.func,
    onToggleVisibilityClick: PropTypes.func,
  };

  state = {
    isDocumentInEditMode: false,
    documentSide: 'front',
  };

  handleDocumentFlip = (documentSide) => this.setState({ documentSide });

  handleDocumentManageClick = () => {
    this.setState({
      isDocumentInEditMode: !this.state.isDocumentInEditMode,
    });
  }

  renderDocumentActions() {
    const isDocumentHidden = this.props.isHiddenDuringSharing;
    const eyeIconType = isDocumentHidden ? 'eye' : 'eye-slash';

    return (
      <div className={ this.cn`__actions` }>
        <div className={ this.cn`__toggle-visibility` }>
          <Button
            className={ this.cn`__action-button` }
            theme="no-theme"
            onClick={ this.props.onToggleVisibilityClick }
          >
            <div className={ this.cn`__actions-icon` }>
              <Icon type={ eyeIconType } />
            </div>
            { isDocumentHidden ? 'Show' : 'Hide' }
          </Button>
        </div>

        <div className={ this.cn`__retake` }>
          <Button
            className={ this.cn`__action-button` }
            theme="no-theme"
            onClick={ this.state.documentSide === 'front' ?
              this.props.onRetakeButtonClick :
              this.props.onBackRetakeButtonClick }
          >
            <div className={ this.cn`__actions-icon` }>
              <Icon set="io" type="camera" />
            </div>
            Retake
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const {
      document,
      isDocumentVisible,
      isHiddenDuringSharing,
      isPrivateViewMode,
      isSelfieMismatched,
    } = this.props;

    const classNames = {
      '--active': this.state.isDocumentInEditMode,
    };

    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.cn`__header` }>
          Document
          <Render when={ isPrivateViewMode }>
            <div className={ this.cn`__manage` }>
              <PillButton
                isInked={ false }
                onClick={ this.handleDocumentManageClick }
                shell={ true }
                type={ this.state.isDocumentInEditMode ? 'success' : 'default' }
              >
                { this.state.isDocumentInEditMode ? 'Stop editing' : 'Manage' }
              </PillButton>
            </div>
          </Render>
        </div>

        <Render when={ isDocumentVisible }>
          <div className={ this.cn`__wrap` }>
            <CustomerDocument
              back={ _get(document, ['back_photo', 'url']) }
              front={ _get(document, ['front_photo', 'url']) }
              isSelfieMismatched={ isSelfieMismatched }
              mode={ isHiddenDuringSharing ? 'simple' : 'full' }
              onFlip={ this.handleDocumentFlip }
            >
              { this.renderDocumentActions() }
            </CustomerDocument>
          </div>
        </Render>

        <Render when={ !isDocumentVisible }>
          <CustomerDocument
            isSelfieMismatched={ isSelfieMismatched }
          />
        </Render>
      </div>
    );
  }
}
