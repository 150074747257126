import React from 'react';
import PropTypes from 'prop-types';
import noop from 'no-op';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import { asset } from 'helpers';
import device from 'models/device';

export default class MetaBox extends BaseComponent {
  className = 'ts-MetaBox';

  static propTypes = {
    isSelfieMismatched: PropTypes.bool,
    isPolFailed: PropTypes.bool,
    onRetakeSelfieButtonClick: PropTypes.func,
    onRetakeDriversLicenseButtonClick: PropTypes.func,
  };

  static defaultTypes = {
    isSelfieMismatched: false,
    isPolFailed: false,
    onRetakeSelfieButtonClick: noop,
    onRetakeDriversLicenseButtonClick: noop,
  };

  state = {}

  componentDidMount() {
    this.syncStateWithModel(device, ['xxsmUp']);
  }

  renderHint = ({ content, name, title, icon }, index) => {
    const className = {
      '__hint': true,
      [`__hint--${name}`]: true,
    };
    return (
      <div className={ this.cn(className) } key={ `hint-${index}` }>
        <img alt="" className={ this.cn`__hint-image` } src={ icon } />
        <div className={ this.cn`__hint-body` }>
          <h2 className={ this.cn`__hint-title` }>
            { title }
          </h2>
          { content }
        </div>
      </div>
    );
  }

  render() {
    const hints = [{
      name: 'photo',
      icon: asset`img/img-retake-selfie.svg`,
      propName: 'isSelfieMismatched',
      title: 'Photo mismatch',
      content: (
        <div>
          <p className={ this.cn`__hint-text` }>
            We couldn’t match your photos.
          </p>
          <p className={ this.cn`__hint-text` }>
            { /* eslint-disable max-len */ }
            Please upload better quality photos to increase your Trustworthiness. Retake video or document photo and follow the instructions.
            { /* eslint-enable */ }
          </p>
          <Button
            onClick={ this.props.onRetakeSelfieButtonClick }
            theme="primary"
            disableInk={ true }
          >
            Retake video
          </Button>
          <Button
            onClick={ this.props.onRetakeDriversLicenseButtonClick }
            theme="primary"
            disableInk={ true }
          >
            { this.state.xxsmUp ? 'Retake document photo' : 'Retake document' }
          </Button>
        </div>
      ),
    }, {
      name: 'pol',
      icon: asset`img/img-retake-selfie.svg`,
      title: 'Proof of Liveness failed',
      propName: 'isPolFailed',
      content: (
        <div>
          <p className={ this.cn`__hint-text` }>
            We couldn’t recognise your face on the video.
          </p>
          <p className={ this.cn`__hint-text` }>
            { /* eslint-disable max-len */ }
            Please retake video and follow the instructions. Proving that you are a real person will increase your Trustworthiness.
            { /* eslint-enable */ }
          </p>
          <Button
            onClick={ this.props.onRetakeSelfieButtonClick }
            theme="primary"
            disableInk={ true }
          >
            Retake video
          </Button>
        </div>
      ),
    }, {
      name: 'social',
      icon: asset`img/img-add-network.svg`,
      title: 'Add another Social Media Network',
      content: (
        <div>
          <p className={ this.cn`__hint-text` }>
            By adding more Social Media Profiles, you really improve your overall Trust Score.
          </p>
          <p className={ this.cn`__hint-text` }>
            We use sophisticated algorithms to assess the value of each Social Media Profile.
            The older it is and the more you use it, the better overall Trustworthiness
            of particular network is.
          </p>
        </div>
      ),
    }];
    return (
      <div className={ this.rootcn() }>
        <h1 className={ this.cn`__title` }>
          Improve your Trust Score
        </h1>
        <div className={ this.cn`__hints` }>
          { hints
            .filter(({ propName }) => !propName || this.props[propName])
            .map(this.renderHint)
          }
        </div>
      </div>
    );
  }
}
