import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Pagination extends BaseComponent {
  className = 'ts-Pagination';

  static propTypes = {
    activePage: PropTypes.number,
    onPageSelect: PropTypes.func,
    pagesCount: PropTypes.number,
  };

  static defaultProps = {
    activePage: 1,
    pagesCount: 1,
    onPageSelect: noop,
  };

  handlePageSelect = (event, selectedPage) => {
    event.preventDefault();
    this.props.onPageSelect(selectedPage);
  }

  render() {
    if (this.props.pagesCount < 2) return null;

    const pagesMarkup = Array.from({ length: this.props.pagesCount }, (item, index) => {
      const classNames = {
        '__item': true,
        '__item--active': index + 1 === this.props.activePage,
      };
      return (
        <a
          className={ this.cn(classNames) }
          href="#"
          key={ `page-${index}` }
          onClick={ (event) => this.handlePageSelect(event, index + 1) }
        >
          { index + 1 }
        </a>
      );
    });
    return (
      <div className={ this.rootcn() }>
        { pagesMarkup }
      </div>
    );
  }
}
