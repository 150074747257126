import React from 'react';
import PropTypes from 'prop-types';
import noop from 'no-op';

import BaseComponent from 'components/BaseComponent';
import Input from '../Input';

class InputAdder extends BaseComponent {
  className = 'ts-InputAdder';

  static propTypes = {
    inputProps: PropTypes.object,
    onAdd: PropTypes.func,
  };

  static defaultValues = {
    onAdd: noop,
  }

  clear = () => {
    this.inputField.clear();
  }

  handleEnterKeypress = () => {
    this.props.onAdd(this.inputField.value);
  }

  handleAddButtonClick = () => {
    this.props.onAdd(this.inputField.value);
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__input-container` }>
          <Input
            ref={ (el) => { this.inputField = el; } }
            inputProps={ this.props.inputProps }
            onEnterKeypress={ this.handleEnterKeypress }
          />
        </div>
        <div className={ this.cn`__add-button-container` }>
          <button
            className={ this.cn`__add-button` }
            onClick={ this.handleAddButtonClick }
          >
            +
          </button>
        </div>
      </div>
    );
  }
}

export default InputAdder;
