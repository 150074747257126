import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';

import PaymentCard from 'sf/components/PaymentCard';
import PaymentPayPal from 'sf/components/PaymentPayPal';
import paymentModel from 'models/payment';
import BaseComponent from 'components/BaseComponent';
import Dialog from 'components/Dialog';
import { CURRENCIES, CURRENCY_SYMBOLS } from 'sf/constants';
import Input from '../Input';
import Button from '../Button';

class CreditsAdder extends BaseComponent {
  className = 'ts-CreditsAdder';

  static propTypes = {
    onAddSuccess: PropTypes.func,
  };

  static defaultProps = {
    currencySymbol: CURRENCY_SYMBOLS[CURRENCY],
    onAddSuccess: noop,
  };

  state = {
    creditsInputAmount: 1,
  }

  componentDidMount = () => {
    this.setState({
      creditPrice: Number(paymentModel.get('credit_price')),
    });
  }

  handleCreditsInputChange = () => {
    this.setState({
      creditsInputAmount: parseInt(this.creditsInput.value, 10) || 0,
    });
  }

  handlePayPalButtonClick = () => {
    this.props.onAddSuccess();
  }

  handlePaymentSuccess = () => {
    this.setState({
      creditsInputAmount: 1,
    });
    this.props.onAddSuccess();
  }

  handlePaypalPaymentSuccess = () => {
    this.handlePaymentSuccess();
  }

  handleCardPaymentSuccess = () => {
    this.cardPaymentDialog.toggle(false);
    this.handlePaymentSuccess();
  }

  handleCreditCardButtonClick = () => {
    this.cardPaymentDialog.toggle(true);
  }

  renderCardPaymentSection = () => {
    const cardPayment = (
      <PaymentCard
        amount={ this.state.creditsInputAmount }
        onSuccess={ this.handleCardPaymentSuccess }
      />
    );
    return (
      <Dialog
        align={ { points: ['tc', 'tc'], offset: [0, 140] } }
        className={ this.cn`__card-payment-dialog` }
        ref={ (el) => { this.cardPaymentDialog = el; } }
        size="small"
        title="Payment information"
      >
        { cardPayment }
      </Dialog>
    );
  }

  render() {
    const totalPrice = Number(this.state.creditPrice * this.state.creditsInputAmount).toFixed(2);
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__add-credits-container` }>
          <div className={ this.cn`__add-credits-header` }>
            Add more credits
          </div>
          <div className={ this.cn`__add-credits-field` }>
            <Input
              ref={ (el) => { this.creditsInput = el; } }
              onChange={ this.handleCreditsInputChange }
              initialValue={ this.state.creditsInputAmount }
              className={ this.cn`__add-credits-field-input` }
            />
          </div>
          <div className={ this.cn`__add-price-info` }>
            1C = { this.props.currencySymbol }{ this.state.creditPrice }
          </div>
        </div>
        <div className={ this.cn`__payment-container` }>
          <div className={ this.cn`__total-price-container` }>
            <div className={ this.cn`__total-price-container-label` }>
              Total price
            </div>
            <div className={ this.cn`__total-price-container-price` }>
              { this.props.currencySymbol }{ totalPrice }
            </div>
          </div>
          <div className={ this.cn`__payment-buttons-container` }>
            <PaymentPayPal
              amount={ this.state.creditsInputAmount }
              onSuccess={ this.handlePaypalPaymentSuccess }
              onError={ this.props.onError }
              currency={ CURRENCIES.USD }
              renderActionButton={ ({ onClick, disabled }) => {
                return (
                  <Button
                    className={ this.cn`__payment-paypal-button` }
                    theme={ {
                      color: 'blue',
                    } }
                    disabled={ disabled }
                    onClick={ onClick }
                  >
                    PayPal
                  </Button>
                );
              } }
            />
            <Button
              onClick={ this.handleCreditCardButtonClick }
              className={ this.cn`__payment-creditcard-button` }
              theme={ {
                color: 'gray',
              } }
            >
              Credit Card
            </Button>
            { this.renderCardPaymentSection() }
          </div>
        </div>
      </div>
    );
  }
}

export default CreditsAdder;
