import React from 'react';
import ActionBar from 'components/ActionBar';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import PredefinedMessage from 'components/PredefinedMessage';
import user from 'models/user';

export default class EmailConfirmationBar extends BaseComponent {
  className = 'ts-EmailConfirmationBar';

  state = {
    isVerificationResent: false,
  };

  resendVerificationEmail = () => {
    user.resendVerificationEmail()
      .then(() => {
        this.setState({ isVerificationResent: true });
      });
  };

  getTitle = () => {
    return (
      <PredefinedMessage
        className={ this.cn`__unconfirmed-email` }
        id="EMAIL_NOT_CONFIRMED"
      />
    );
  };

  render() {
    return (
      <ActionBar
        important={ true }
        title={ this.getTitle() }
      >
        <Button
          disabled={ this.state.isVerificationResent }
          theme="ternary"
          onClick={ this.resendVerificationEmail }
        >
          { this.state.isVerificationResent ?
            'Verification e-mail sent' :
            'Resend verification e-mail'
          }
        </Button>
      </ActionBar>
    );
  }
}
