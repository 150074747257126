import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class AdminAlert extends BaseComponent {
  className = 'ts-AdminAlert';

  static propTypes = {
    message: PropTypes.any,
    type: PropTypes.oneOf([
      'default',
      'error',
      'info',
      'success',
      'warning',
    ]),
  };

  static defaultProps = {
    type: 'default',
  }

  render() {
    return this.props.children ? (
      <div className={ this.rootcn`--${this.props.type}` }>
        { this.props.children }
      </div>
    ) : null;
  }
}

export default AdminAlert;
