import React from 'react';
import { refreshCost } from 'config';

/* eslint-disable max-len */
const FAQ_DATA = [
  {
    title: 'Marketplace Heroes Campaign',
    content: [{
      title: 'What is Trust Stamp P2P and how can it help promote safety in the online marketplace?',
      content: (
        <div>
          <p>Trust Stamp P2P is an online service developed by Trust Stamp and customized by Team P2P for the online peer-to-peer (P2P) marketplace. You can use Trust Stamp P2P to mitigate risks by:</p>
          <ul>
            <li>Verifying the identity of buyers / sellers to ensure they are who they say they are</li>
            <li>Performing a background check on buyers / sellers before meeting in person</li>
            <li>Determining buyers / sellers trustworthiness through their presence on social media</li>
          </ul>
          <p>These three uses can all be achieved in less than two minutes and costs less than a cup of coffee.</p>
        </div>
      ),
    }, {
      title: 'Who is Team P2P?',
      content: (
        <div>
          <p>Team P2P is a free-moving, innovative group within the Trust Stamp organization whom have been tasked with initiating and executing campaigns to promote online safety and deliver safety tools to the online marketplace.</p>
          <p>Team P2P has identified a great need due to the stranger danger that exists in many online reseller communities, especially in Facebook’s buy / sell groups. To deliver the solution for this need, Team P2P is kicking off the Marketplace Heroes Campaign, which will assist Facebook Admins and Influencers in promoting awareness and delivering safety tools to buyers and sellers within their network who are engaging in internet-mediated transactions.</p>
        </div>
      ),
    }, {
      title: 'What is the Marketplace Heroes Campaign?',
      content: (
        <div>
          <p>The Marketplace Heroes Campaign is a movement started by Team P2P within the Trust Stamp organization. The purpose of this movement is two parts:</p>
          <ol>
            <li>To promote online trust and safety between buyers and sellers in P2P marketplaces such as Craigslist, Facebook’s Marketplace and buy / sell groups, OfferUp, Letgo, etc.</li>
            <li>Deliver safety tools, including the Trust Stamp P2P service, which group administrators can provide to their members</li>
          </ol>
          <p>The Marketplace Heroes Campaign will run for three months, during which time Team P2P will be supporting the Marketplace Heroes with materials and tools to help make their marketplaces safer to buy and sell goods. The customized Trust Stamp P2P web service is the primary tool they will use to do this.</p>
          <p>As mentioned before, this service will allow buyers and sellers in less than two minutes to verify that the stranger is who they say they are, to check their public criminal records for red flags, and to determine their trustworthiness based on their presence on social media.</p>
          <p>At the end of the campaign, Team P2P will comprise a list of all the Marketplace Heroes and how many people they promoted safety to. Then for every Trust Stamp that was used by the Marketplace Heroes network, Team P2P will donate ${ refreshCost } to the local charity of each their choice.</p>
        </div>
      ),
    }, {
      title: 'What is a Marketplace Hero?',
      content: (
        <div>
          <p>Team P2P is recruiting influential people who have active roles in P2P marketplaces to partner with them and help spread awareness for the “stranger dangers” present when transacting in online marketplaces, as well as help deliver solutions to curtail those dangers.</p>
          <p>Influential persons include Twitter Influencers, Facebook Buy / Sell Group Administrators, as well as other influencers in the multitude of online communities.</p>
        </div>
      ),
    }, {
      title: 'Becoming a Marketplace Hero?',
      content: (
        <div>
          <p>Becoming a Marketplace Heroes has many benefits! Not only will you be recognized for promoting online safety and keeping your network safe, you will be eligible for profit sharing opportunity with Trust Stamp, and at the end of each campaign the following will take place:</p>
          <ol>
            <li>
              <p>A <strong>press release</strong> will be published announcing the following:</p>
              <ol>
                <li>A list of all the Marketplace Heroes who participated in the campaign</li>
                <li>How many people each Marketplace Heroes promoted online safety to</li>
                <li>How much was raised for charity by each Marketplace Hero</li>
              </ol>
            </li>
            <li><strong>${ refreshCost } for every Trust Stamp</strong> used by the Marketplace Heroes network will be donated to the local charity of their choice</li>
            <li>One Marketplace Hero will be chosen at random for a <strong>high-tech mystery gift</strong> sponsored by Trust Stamp</li>
          </ol>
          <p>The Marketplace Heroes  will run approximately four times per year for three months each. After the end of each campaign, Marketplace Heroes will be inducted into the Marketplace Heroes Hall of Fame on Facebook, receiving full recognition on the Marketplace Heroes Facebook page.</p>
        </div>
      ),
    }, {
      title: 'Why should I use Trust Stamp?',
      content: (
        <div>
          <p>Trust Stamp P2P’s mission is to provide you the recommended level of caution to take before meeting an absolute stranger through the internet and to verify that they are who they say they are.</p>
          <p>By verifying data of the person you are about to meet, you greatly decrease the chances of fraud, assault, theft, or worse. You can also avoid being one of the many whom have been victims to internet-mediated assaults, robberies, and murders.</p>
        </div>
      ),
    }, {
      title: 'How do I use Trust Stamp?',
      content: (
        <div>
          <p>Directly from your Trust Stamp profile, you can invite a stranger to create a Trust Stamp profile via SMSs or email before meeting to transact. The stranger will see who are you are in the notification. To register, they will enter basic information, confirm their email and cell phone, link 1 social media account, take a selfie, and take a photo of the front of their driver’s license.</p>
          <p>That’s it!</p>
        </div>
      ),
    }, {
      title: 'When do I send out a Trust Stamp request?',
      content: (
        <div>
          <p>Sending a Trust Stamp is a 2-minute process to help confirm an individual’s identity and trustworthiness. It utilizes things such as biometric data, social media and public data records. It provides a proactive tool to protect yourself and others and replaces inadequate methods of identity verification such as google searches.</p>
        </div>
      ),
    }, {
      title: 'Is my data safe with Trust Stamp?',
      content: (
        <div>
          <p>Trust Stamp’s technical team is built up of experts from companies like Google and Credit Suisse. Trust Stamp follows bank-level data security protocols and only stores necessary information, such as log in credentials. We use encrypted channels to transmit all data to ensure security of all confidential information.</p>
        </div>
      ),
    }],
  }, {
    title: 'Strangers',
    content: [{
      title: 'What kind of stranger info will I get?',
      content: (
        <div>
          <p>By sending out a Trust Stamp, you will get the picture and name of the stranger. You will also get their Trust Score (from 0-800) comprised of data from 200+ public data sources.</p>
          <p>Additionally, you will be able to identify the level of required caution at a glance, using a circular Token. There are three possible colors (levels of caution) of the Token:</p>
          <ul>
            <li>Red - We found public record conflicts that the viewer should be aware of</li>
            <li>Yellow - We found some inconsistencies of data surveyed, but nothing to elicit exceptional caution</li>
            <li>Green - We found no inconsistencies of data surveyed, nor anything to elicit exceptional caution</li>
          </ul>
        </div>
      ),
    }, {
      title: 'How is the Trust score calculated?',
      content: (
        <div>
          <p>Trust Stamp uses a sophisticated and proprietary algorithm that compares and correlates data such as:</p>
          <ul>
            <li>Facial recognition of selfie, driver’s license and social media pictures</li>
            <li>Correlation of data between social media accounts and data provided by the stranger</li>
            <li>Correlation of data between driver’s license and other data sources</li>
            <li>Criminal background and sex offender records</li>
            <li>Consistent and correlated data across all data sources</li>
          </ul>
        </div>
      ),
    }, {
      title: 'Stranger got a low score, what does it mean?',
      content: (
        <div>
          <p>A low score indicates that we either don’t have enough data to compare in order to get a good score or the data provided doesn’t have strong matching signals. It can be easily improved by doing things such as:</p>
          <ul>
            <li>Adding more social media to profile</li>
            <li>Adding social media accounts that the person uses more regularly and have more activity</li>
            <li>Better resolution and quality of driver’s license photograph and selfie</li>
          </ul>
        </div>
      ),
    }, {
      title: 'Why didn’t I receive the stranger’s Trust Stamp?',
      content: (
        <div>
          <p>The stranger has to choose whether they want to grant you access to their profile, and send your their Trust Stamp. They might decide that they don’t want to do it.</p>
          <p>It’s possible also that they have gotten the invite but they just haven’t gone through the registration process. You can use the “Ask again” feature to renew your invitation or contact them personally.</p>
          <p>Why can’t the stranger send me their Trust Stamp even though they registered?</p>
          <p>There are two possible reasons:</p>
          <ul>
            <li>The stranger might not have verified their email yet</li>
            <li>The stranger also has to not only register, but must publish their profile in order to share it</li>
          </ul>
        </div>
      ),
    }],
  }, {
    title: 'General FAQ',
    content: [{
      title: 'How do I send Trust Stamp to strangers?',
      content: (
        <div>
          <p>When you are logged into the Trust Stamp P2P web application, there will be a button with the option to “Invite people to Trust Stamp”.</p>
          <p>When you click this button, you will be asked to provide either the stranger’s email address or phone number. Depending on what is entered, they will receive an invite to register either through email or SMS text.</p>
        </div>
      ),
    }, {
      title: 'What will the stranger receive when I invite them to Trust Stamp?',
      content: (
        <div>
          <p>They will receive a personalized invitation from you through mail or a text message inviting them to register with Trust Stamp and share their profile to you.</p>
        </div>
      ),
    }, {
      title: 'How do I add Stamps?',
      content: (
        <div>
          <p>You can always buy Stamps to add to your account. Once bought they do not expire. In order to do so, click on the “Recharge your account” link. This will take you to your wallet and allow you to purchase more Trust Stamps using either PayPal or credit cards.</p>
        </div>
      ),
    }, {
      title: 'How do I use the discount code?',
      content: (
        <div>
          <p>First click on the “Recharge your account” link. Once in the wallet, scroll down and select the link “I have a discount code”. Once selected, you will have the opportunity to redeem the code and apply the discount.</p>
        </div>
      ),
    }, {
      title: 'What is Trust Stamp?',
      content: (
        <div>
          <p>Trust Stamp is a solution to guard against identity theft, friendly-fraud and “stranger-danger”. It provides a secure digital token and objective trustworthiness score. It achieves that by verifying the user’s photograph, identity and trustworthiness using patented algorithms and artificial intelligence resources from Microsoft and IBM Watson to review profile, photographic & drivers license data together with over 200 public records, e-commerce and social media sites.</p>
        </div>
      ),
    }, {
      title: 'Where does the data come from?',
      content: (
        <div>
          <p>Data comes from the social media and other information provided by the user. This is then compared against public data record information services. We do not store this data. Instead we pull fresh information with every request to view of a Trust Stamp that was shared or profile refresh. The Trust Score is always up-to-date when someone sends it out, to ensure that both parties remain safe at any point.</p>
        </div>
      ),
    }, {
      title: 'How do I or other people register?',
      content: (
        <div>
          <p>The registration process is a set of simple steps:</p>
          <ul>
            <li>Add a social media account</li>
            <li>Take a picture of a driver’s license</li>
            <li>Take a selfie</li>
            <li>Provide basic address/email/phone number information</li>
            <li>Confirm email/phone number</li>
            <li>Create a Pin number</li>
          </ul>
          <p>The process itself takes about 90 seconds and ends officially once your profile is published.</p>
        </div>
      ),
    }],
  },
];
/* eslint-enable max-len */

export default FAQ_DATA;
