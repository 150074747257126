import React from 'react';
import isPlainObject from 'lodash/isPlainObject';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import BaseComponent from 'components/BaseComponent';
import PaperInput from 'components/PaperInput';
import PillButton from 'components/PillButton';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class BasicProfileInfoRow extends BaseComponent {
  className = 'ts-BasicProfileInfoRow';

  static propTypes = {
    isConfirmed: PropTypes.bool,
    isHidden: PropTypes.bool,
    isLocked: PropTypes.bool,
    isPublic: PropTypes.bool,
    name: PropTypes.string,
    onConfirmationButtonClick: PropTypes.func,
    onVisibilityChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    visualDependencies: PropTypes.shape({
      false: PropTypes.object,
      true: PropTypes.object,
    }),
  };

  static defaultProps = {
    onConfirmationButtonClick: noop,
    onVisibilityChange: noop,
  };

  state = {}

  setValid = (status, message) => {
    this.setState({
      isValid: status,
      validationMessage: message,
    });
  }

  handlePillButtonClick = () => {
    this.props.onVisibilityChange(
      this.props.hiddenKey,
      this.props.visualDependencies
    );
  }

  handleConfirmButtonClick = () => {
    this.props.onConfirmationButtonClick(this.props.name);
  }

  render() {
    const { isConfirmed, isHidden, isLocked, isPublic, label, name, value } = this.props;
    const isPaperInput = isPlainObject(value);
    const isInformationHidden = isHidden || !value || (isPaperInput && !value.value);
    const isInformationLocked = isLocked && this.props.hiddenKey;
    const descriptionClassNames = {
      '__description': true,
      '__description--hidden': isInformationHidden || isInformationLocked,
    };
    const confirmationClassNames = {
      '__confirmation': true,
      '__confirmation--negative': !isConfirmed,
    };
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__label` }>
          { label }:
        </div>
        <div className={ this.cn(descriptionClassNames) }>
          <Render when={ isInformationLocked }>
            Information locked
          </Render>
          <Render when={ !isInformationLocked }>
            <Render when={ isInformationHidden && isPublic }>
              Information hidden
            </Render>
            <Render when={ !isInformationHidden || !isPublic }>
              <Render when={ isPaperInput }>
                <PaperInput { ...value } { ...this.state } />
              </Render>
              <Render when={ !isPaperInput }>
                <div>{ value }</div>
              </Render>
            </Render>
          </Render>
        </div>
        <Render when={ typeof isHidden === 'boolean' }>
          <div className={ this.cn`__visibility-buttons` }>
            <PillButton
              onClick={ this.handlePillButtonClick }
              type={ isHidden ? 'success' : 'default' }
            >
              { isHidden ? 'Show' : 'Hide' }
            </PillButton>
          </div>
        </Render>
        <Render when={ typeof isConfirmed === 'boolean' }>
          <div className={ this.cn(confirmationClassNames) }>
            <Render when={ isConfirmed && isPublic }>
              <div className={ this.cn`__checkmark` }>
                <Icon set="io" type="checkmark" title="Confirmed" />
              </div>
            </Render>
            <Render when={ !isConfirmed && !isPublic }>
              <div className={ this.cn`__confirmation-button` }>
                <PillButton
                  onClick={ this.handleConfirmButtonClick }
                  type="warning"
                >
                  Please confirm
                </PillButton>
              </div>
            </Render>
          </div>
        </Render>
        <Render when={ !isPublic && isHidden }>
          <div
            className={ this.cn`__hidden-icon` }
            data-for={ `tooltip-${name}` }
            data-tip="Information hidden"
          >
            <Icon set="io" size={ 20 } type="eye-disabled" />
            <ReactTooltip id={ `tooltip-${name}` } effect="solid" />
          </div>
        </Render>
      </div>
    );
  }
}
