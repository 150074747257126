/* eslint-disable */
        module.exports = {
  "action-element-base": "#008750",
  "action-element-active": "#00ad68",
  "brand-x-light": "#66cba3",
  "brand-light": "#71e3b6",
  "brand-base": "#00ad68",
  "brand-mid-dark": "#009359",
  "brand-dark": "#008750",
  "brand-x-dark": "#114e35",
  "fonts-slab": "museo-slab, Georgia, \"Times New Roman\", serif",
  "fonts-sans": "museo-sans, Helvetica, Arial, sans-serif",
  "fonts-mono": "Monaco, Consolas, \"Andale Mono\", monospace",
  "brandName": "realtor",
  "aboutUrl": null,
  "appName": "TrustStamp for Realty",
  "companyName": "TrustStamp",
  "policyUrl": null,
  "trustStampUrl": "https://dev.truststamp.net",
  "realtorUrl": "https://dev.truststamp.re"
}
      /* eslint-enable */
      