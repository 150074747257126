import React from 'react';
import is from 'next-is';

import Card from 'components/Card';
import VideoCaptureTips from 'components/VideoCaptureTips';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import VideoCaptureButton from 'sf/components/VideoCaptureButton';
import {
  mediator,
} from 'sf/helpers';

export default class SignUpVideoUpload extends BasePage {
  className = 'ts-SignUpVideoUpload';

  state = {
    mobileVersion: is.mobile() || is.tablet(),
  }

  handleVideoTaken = (dataURI) => {
    registration
      .sendUserPolVideo(dataURI)
      .then(
        () => {
          registration.set('polVideoPassed', true);
          mediator.publish('setRegistrationStep', '/SignUpPhoto.html');
        }
      )
      .catch(() => {
        registration.set('polVideoPassed', false);
        this.notify('error', 'Uploading user POL video failed. Please try again.');
      });
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` } >
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          <VideoCaptureTips
            actionButton={
              <VideoCaptureButton
                mainAction={ true }
                maxLength={ 5 * 1000 } // 5 seconds
                mediaRecorderFallback={ MEDIA_STREAM_DEFAULT_FALLBACK_TYPE }
                outlined={ false }
                overlay="face"
                onVideoTaken={ this.handleVideoTaken }
              />
            }
          />
        </Card>
      </div>
    );
  }
}
