import React from 'react';

import Payment from 'components/Payment';
import { ROUTES } from 'constants';
import payment from 'models/payment';
import registrationModel from 'models/registration';
import BasePage from 'pages/BasePage';
import { mediator } from 'sf/helpers';

export default class SignUpPayment extends BasePage {
  className = 'ts-SignUpPayment';

  handlePaymentSuccess = () => {
    if (registrationModel.get('organization')) {
      mediator.publish('setRegistrationStep', registrationModel.REGISTRATION_DONE_STEP);
      registrationModel.signUpComplete().then(() => {
        this.navigate('/Profile.html');
      });
    } else {
      mediator.publish('setRegistrationStep', ROUTES.APPLY_FORM.COMPLETE);
      this.navigate(ROUTES.APPLY_FORM.COMPLETE);
    }
  };

  renderPayment() {
    return (
      <div>
        <div className={ this.cn`__payment-item-header` }>
          To finish registration pay with PayPal or Credit Card
        </div>
        <div className={ this.cn`__payment-item-price` }>
          Total price: { }
          <span>
            ${ payment.getPrice() }
          </span>
        </div>
        <Payment
          disableAnalytics={ true }
          onSuccess={ this.handlePaymentSuccess }
        />
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` }>
        { this.renderPayment() }
      </div>
    );
  }
}
