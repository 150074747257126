import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Wrapper extends BaseComponent {
  className = 'ts-Wrapper';

  static propTypes = {
    children: PropTypes.node
  };

  static defaultProps = {
    children: ''
  };

  render() {
    return (
      <div
        { ...this.props }
        className={ this.rootcn`` }
      >
        { this.props.children }
      </div>
    );
  }
}
