import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import reactTimeout from 'react-timeout';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import CardIcon from 'components/CardIcon';
import payment from 'models/payment';

class PaymentCreditCardDialog extends BaseComponent {
  className = 'ts-PaymentCreditCardDialog';

  static PropTypes = {
    amount: PropTypes.number,
    onError: PropTypes.func,
    onReady: PropTypes.func,
  };

  static defaultProps = {
    amount: 1,
    onError: noop,
    onSuccess: noop,
  };

  state = {
    cardType: null,
    isFormValid: false,
    isFormBeingSubmitted: false,
  };

  componentDidMount() {
    this.syncStateWithModel(payment, ['credit_price']);
    payment.set('amount', this.props.amount);
  }

  componentWillReceiveProps(nextProps) {
    payment.set('amount', nextProps.amount);
  }

  handleCardTypeChange = (event) => {
    if (event.cards.length === 1) {
      this.setState({
        cardType: event.cards[0].type,
      });
    }
  }

  handleValidityChange = (hostedFieldsInstance) => {
    const state = hostedFieldsInstance.getState();
    const isFormValid = Object.keys(state.fields).every((key) => {
      return state.fields[key].isValid;
    });
    this.setState({
      isFormValid,
    });
  }

  handleFromSubmit = (event, hostedFieldsInstance) => {
    event.preventDefault();
    this.setState({
      isFormBeingSubmitted: true,
    });
    hostedFieldsInstance.tokenize((tokenizeErr, data) => {
      if (tokenizeErr) return;
      payment.set({
        nonce: data.nonce,
        type: data.type,
        details: data.details,
      }).then(() => {
        payment.pay().then(this.props.onSuccess, this.props.onError).then(() => {
          this.props.setTimeout(() => {
            this.setState({
              isFormBeingSubmitted: false,
            });
          }, 2000);
        });
      });
    });
  }

  setup = () => {
    payment.getClientToken().then((clientToken) => {
      global.braintree.client.create({
        authorization: clientToken,
      }, (clientErr, clientInstance) => {
        if (clientErr) return;
        global.braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            'input': {
              'font-size': '13px',
            },
            'input.invalid': {
              'color': '#c10000',
            },
            'input.valid': {
              'color': '#373a3c',
            },
          },
          fields: {
            number: {
              selector: '#card-number',
              placeholder: '4111 1111 1111 1111',
            },
            cvv: {
              selector: '#cvv',
              placeholder: '123',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: `10 / ${((new Date()).getUTCFullYear() + 3).toString().substr(-2)}`,
            },
          },
        }, (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) return;
          const form = ReactDOM.findDOMNode(this.refs.paymentForm);
          form.addEventListener('submit', (event) => {
            return this.handleFromSubmit(event, hostedFieldsInstance);
          }, false);
          hostedFieldsInstance.on('cardTypeChange', this.handleCardTypeChange);
          hostedFieldsInstance.on('validityChange', () => {
            return this.handleValidityChange(hostedFieldsInstance);
          });
        });
      });
    });
  }

  componentDidMount() {
    payment.getBraintreeScripts(this.setup);
  }

  render() {
    return (
      <form
        className={ this.rootcn() }
        ref="paymentForm"
        method="post"
      >
        <div
          className={ this.cn`__input-wrap` }
          id="card-number"
        >
          <div className={ this.cn`__card-icon` }>
            <CardIcon type={ this.state.cardType } />
          </div>
        </div>
        <div
          className={ this.cn`__input-wrap __input-wrap--cvv` }
          id="cvv"
        />
        <div
          className={ this.cn`__input-wrap __input-wrap--expiration` }
          id="expiration-date"
        />
        <div className={ this.cn`__button` }>
          <Button
            disabled={ !this.state.isFormValid || this.state.isFormBeingSubmitted }
            visuallyDisabled={ !this.state.isFormValid || this.state.isFormBeingSubmitted }
          >
            Pay ${ payment.getPrice(this.props.amount) }
          </Button>
        </div>
      </form>
    );
  }
}

export default reactTimeout(PaymentCreditCardDialog);
