import React from 'react';
import PropTypes from 'prop-types';
import is from 'next-is';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogText } from 'components/Dialog';
import InviteContactInputs from 'components/InviteContactInputs';
import Payment from 'components/Payment';
import Stamp from 'components/Stamp';
import config from 'config';
import device from 'models/device';
import invitationModel from 'models/invitation';
import user from 'models/user';

const defaultState = {
  invitations: [],
  isPaymentEnabled: false,
  sending: false,
};

export default class InvitationDialog extends BaseComponent {
  className = 'ts-InvitationDialog';

  static propTypes = {
    onInvitationSuccess: PropTypes.func.isRequired,
  }

  state = Object.assign({}, defaultState);

  componentDidMount() {
    this.syncStateWithModel(device, ['xxsmUp']);
    this.syncStateWithModel(user, ['credit_price', 'number_of_credits', 'email', 'phone']);
  }

  show() {
    this.refs.dialog.toggle(true);
  }

  validateContactInput(input) {
    if (!input) return false;

    let error = null;
    if (input === this.state.email || input === this.state.phone) {
      error = 'You are not alone! Don\'t need to invite yourself!';
    }
    if (!is.string.isEmail(input) && !is.string.isPhone(input, 'us')) {
      error = 'This is neither a valid e-mail address nor a phone number!';
    }

    return error ? error : true;
  }

  sendRequest(contact) {
    if (!contact) return;

    return invitationModel.sendInvitation(contact);
  }

  validateInvitations(invitations) {
    const invalidInvites = [];
    const validationErrors = invitations
      .filter((invitation) => invitation.text !== '')
      .map((invitation) => {
        const isCurrentValid = this.validateContactInput(invitation.text);
        if (isCurrentValid !== true) invalidInvites.push(invitation.id);
        return isCurrentValid;
      })
      .filter((value) => value !== true);

    return {
      invalidInvites,
      validationErrors,
    };
  }

  // TODO: Extract validation to helper module for reusability
  // Similar funcitonality is also available in ShareProfileDialog.js
  // -> handleEmailList() and sendAccess()
  handleInviteSubmit = () => {
    const fail = (errorMessage) => {
      this.displayError(errorMessage);
      this.setState({ sending: false });
    };
    const done = () => {
      this.displaySuccess(
        'Verification request successfully sent',
        () => {
          this.props.onInvitationSuccess();
          this.setState({ sending: false });
        }
      );
    };

    const { invalidInvites, validationErrors } = this.validateInvitations(this.state.invitations);

    this.setState({
      invalidInvites,
    });

    if (validationErrors.length === 0) {
      Promise.all(this.state.invitations.map((invitation) => this.sendRequest(invitation.text)))
        .then(done)
        .catch(fail);
    } else {
      this.displayError(validationErrors.pop());
    }
  };

  displayError(message) {
    this.refs.dialog.showFloatingText({
      text: message,
      isValid: false,
    });
    this.refs.dialog.scrollToTop();
  }

  displaySuccess(message, callback) {
    this.refs.dialog.showFloatingText({
      text: message,
      isValid: true,
      autoHide: true,
    }, callback);
    this.refs.dialog.scrollToTop();
  }

  updateInvitationList = (invitations) => {
    this.setState(
      { invitations },
      this.updateValidation,
    );
  };

  updateValidation = () => {
    const { invalidInvites } = this.validateInvitations(this.state.invitations);
    this.setState({
      isPaymentEnabled: invalidInvites.length === 0 &&
        this.state.invitations.length > 0 &&
        this.state.invitations[0].text !== '',
    });
  };

  resetState = () => {
    this.setState({ ...defaultState });
  };

  renderPayment() {
    const credits = parseInt(this.state.number_of_credits, 10);
    const insufficientCredits = this.state.invitations > credits;
    const { isPaymentEnabled, credit_price } = this.state;

    return (
      <div className={ this.cn`__payment-method` }>
        { credit_price === -1 ? ( // free (and no payments) when -1
          <div className={ this.cn`__payment-item` }>
            <Button
              onClick={ this.handleInviteSubmit }
              theme={ isPaymentEnabled && !insufficientCredits ? 'success' : 'disabled' }
              disabled={ !isPaymentEnabled }
            >
              Ask to verify
            </Button>
          </div>
        ) : [
          <div className={ this.cn`__payment-item` }>
            <div className={ this.cn`__payment-item-header` }>
              You currently have <Stamp>{ credits }</Stamp>
            </div>
            <div className={ this.cn`__payment-item-text` }>
              You can ask { credits > 1 ? `${credits} people` : 'one person' } to verify
            </div>
            <Button
              onClick={ this.handleInviteSubmit }
              theme={ isPaymentEnabled && !insufficientCredits ? 'success' : 'disabled' }
              disabled={ !isPaymentEnabled }
            >
              Use your credits
            </Button>
          </div>,
          <div className="ts-vertical-or" />,
          <div className="ts-or" />,
          <div className={ this.cn`__payment-item` }>
            <div className={ this.cn`__payment-item-header` }>
              Pay with PayPal or Credit Card
            </div>
            <div className={ this.cn`__payment-item-text` }>
              Verify someone without using your balance
            </div>
            <Payment
              disabled={ !isPaymentEnabled }
              onSubmit={ () => {
                this.setState({ paymentInProgress: true });
              } }
              onError={ (error) => {
                this.setState({ paymentInProgress: false });
                this.displayError(error.message || 'Payment failed');
              } }
              onSuccess={ () => {
                this.setState({ paymentInProgress: false });
                this.handleInviteSubmit();
              } }
            />
          </div>,
        ] }
      </div>
    );
  }

  render() {
    return (
      <Dialog
        className={ this.rootcn() }
        ref="dialog"
        title="Ask someone to verify"
        onDismiss={ this.resetState }
        size="large"
      >
        <DialogText>
          <p>Write their e-mail addresses or cell phone numbers below</p>
          { this.state.credit_price !== -1 && ( // free (and no payments) when -1
            <p>
              Verification request costs <Stamp>{ config.verificationCost }</Stamp>
            </p>
          ) }
          <InviteContactInputs
            invalidInvites={ this.state.invalidInvites }
            onUpdate={ this.updateInvitationList }
            onChange={ this.updateValidation }
            maxInvites={ 1 }
          />
          { this.renderPayment() }
        </DialogText>
      </Dialog>
    );
  }
}
