import React from 'react';
// import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export class PersonTable extends BaseComponent {
  className = 'ts-PersonTable';

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.children }
      </div>
    );
  }
}

export class PersonTableRow extends BaseComponent {
  className = 'ts-PersonTableRow';

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.children }
      </div>
    );
  }
}

export class PersonTableHeader extends BaseComponent {
  className = 'ts-PersonTableHeader';

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.children }
      </div>
    );
  }
}

export class PersonTableLabel extends BaseComponent {
  className = 'ts-PersonTableLabel';

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.children }
      </div>
    );
  }
}

export class PersonTableCell extends BaseComponent {
  className = 'ts-PersonTableCell';

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.children }
      </div>
    );
  }
}

export default PersonTable;
