import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Icon from 'sf/components/Icon';

export default class HiddenLabel extends BaseComponent {
  className = 'ts-HiddenLabel';

  render() {
    return (
      <div className={ this.rootcn() }>
        <span className={ this.cn`__icon` }>
          <Icon set="fa" type="eye-slash" size={ 14 } />
        </span>
        Hidden
      </div>
    );
  }
}
