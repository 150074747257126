import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import Button from 'components/Button';

function InfographicTableOfContents({ entries, activeEntry, onClick }) {
  const renderEntry = (entry) => {
    const className = {
      '__entry': true,
      '__entry--active': entry.id === activeEntry.id,
    };

    return (
      <li
        className={ this.cn(className) }
        key={ entry.id }
      >
        <Button
          theme="link-unstyled"
          onClick={ () => onClick(entry) }
        >
          { entry.title }
        </Button>
      </li>
    );
  };

  return (
    <nav>
      <ul className={ this.rootcn`` }>
        { entries.map(renderEntry) }
      </ul>
    </nav>
  );
}

const entryType = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
});

InfographicTableOfContents.propTypes = {
  activeEntry: entryType,
  entries: PropTypes.arrayOf(entryType).isRequired,
  onClick: PropTypes.func,
};

InfographicTableOfContents.defaultProps = {
  activeEntry: null,
  entries: [],
  onClick: noop,
};

export default baseFunctionalComponent(InfographicTableOfContents, 'InfographicTableOfContents');
