// Time to do some sketchy s**t... doo da doo da,
// Hope I get away with it oh doo da day

const type = 'application/javascript';

const getCrossOriginWorkerURL = (originalWorkerUrl, _options = {}) => {
  const options = {
    // skipSameOrigin disabled on the local ENV to make
    // polyfills/required Worker check work
    skipSameOrigin: ENV === 'local' ? false : true,
    useBlob: true,

    ..._options,
  };

  if (
    options.skipSameOrigin && (
      !originalWorkerUrl.includes('://')
      || originalWorkerUrl.includes(window.location.origin)
    )) {

    // The same origin - Worker will run fine
    return Promise.resolve(originalWorkerUrl);
  }

  return new Promise((resolve, reject) =>
    fetch(originalWorkerUrl)
      .then((res) => res.text())
      .then((codeString) => {
        const workerPathURL = originalWorkerUrl.includes('://')
          ? originalWorkerUrl
          : `${window.location.origin}${originalWorkerUrl}`

        let workerPath = new URL(workerPathURL).href.split('/');
        workerPath.pop();

        const importScriptsFix = `const _importScripts = importScripts;
const _fixImports = (url) => new URL(url, '${workerPath.join('/') + '/'}').href;
importScripts = (...urls) => _importScripts(...urls.map(_fixImports));`;

        let finalURL = `data:${type},${encodeURIComponent(importScriptsFix + codeString)}`;

        if (options.useBlob) {
          finalURL = URL.createObjectURL(
            new Blob([`importScripts("${finalURL}")`], { type })
          )
        }
        resolve(finalURL);
      })
      .catch(reject)
  );
};

async function getCrossOriginWorker(originalWorkerUrl) {
  let workerURL = await getCrossOriginWorkerURL(originalWorkerUrl);
  try {
    return new Worker(workerURL);
  } catch(e) {
    // CSP blocked blob:
    // Let's try data: URL
    try {
      workerURL = await getCrossOriginWorkerURL(originalWorkerUrl, { useBlob: false });
      return new Worker(workerURL);
    } catch(e) {
      // CSP blocked data: URL
      // final try: original URL
      return new Worker(originalWorkerUrl);
    }
  }
}

module.exports = {
  getCrossOriginWorkerURL,
  getCrossOriginWorker
};
