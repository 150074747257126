import React from 'react';
import BaseComponent from 'components/BaseComponent';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';
import { STEPS } from 'components/ResetPIN/ResetPIN.constants';
import user from 'models/user';
import login from 'models/login';

export default class ResetPINSelfie extends BaseComponent {
  static defaultProps = {
    onSubmit: (dataURI) => {
      return login.sendSelfie(dataURI);
    },
  }

  handleSubmit = (dataURI) => {
    this.props.onSubmit(dataURI)
      .then(() => {
        user.setPINResetStep(STEPS.PIN);
      });
  }

  render() {
    return (
      <LocalAdvancedPhotoCapture
        allowCrop={ false }
        allowRetake={ false }
        fullScreen={ false }
        onPhotoTaken={ this.handleSubmit }
        overlay="face"
      />
    );
  }
}
