import React from 'react';
import { connect } from 'react-redux';
import { brandName } from 'brandingVariables';
import Button from 'components/Button';
import BusinessSignUp from 'components/BusinessSignUp';
import PageTitle from 'components/PageTitle';
import { BRANDINGS } from 'constants';
import { getAllChildRoutesFromProps } from 'helpers';
import { pascalCase, getQuery } from 'sf/helpers';
import businessSignUp, { DATA, STEPS } from 'models/businessSignUp';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import Breadcrumbs from 'sf/components/Breadcrumbs';

class BusinessSignUpPage extends BasePage {
  className = 'ts-BusinessSignUpPage';

  state = {};

  componentDidMount() {
    this.queryParams = getQuery();
    businessSignUp.setSignUpStep(this.props.route.stepName);
    businessSignUp.setOrganization(this.queryParams.organization ||
      registration.get('organization'));
    this.syncStateWithModel(businessSignUp, [
      'activeStep',
      'doneSteps',
      'isActiveStepDone',
      'nextStep',
      'organization',
    ]);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.route.stepName !== nextProps.route.stepName) {
      businessSignUp.setSignUpStep(nextProps.route.stepName);
    }
  }

  getRouteForStep(step) {
    const routePart = pascalCase(step);
    return `/business/SignUp${routePart}.html`;
  }

  handleStepSubmit = (data) => {
    if (this.state.isActiveStepDone) {
      this.navigate(this.getRouteForStep(this.state.nextStep));
      return;
    }
    const { postMethod } = DATA[this.state.activeStep];
    const isOrganization = BRANDINGS.ORGANIZATION_SUPERVISOR === brandName;
    const { admin_invitation_id } = getQuery();
    if (this.state.activeStep === STEPS.BASIC_INFO) {
      data.organization = this.state.organization;
      data.discount_code = this.props.mph_discount_code;
      data.admin_invitation_id = admin_invitation_id;
    }
    if (postMethod) {
      businessSignUp[postMethod](
        data,
        this.getRouteForStep(this.state.nextStep),
        isOrganization
      );
    }
  }

  handleResetButtonClick = () => {
    businessSignUp.resetRegistration();
    this.navigate(this.getRouteForStep(STEPS.BASIC_INFO));
  }

  render() {
    if (this.props.route.stepName !== this.state.activeStep) return null;
    const { title, subtitle } = DATA[this.state.activeStep];
    const isOrganization = BRANDINGS.ORGANIZATION_SUPERVISOR === brandName;
    return (
      <div className={ this.rootcn`` }>
        <div className={ this.cn`ts-container` }>
          <h1 className={ this.cn('__header') }>
            { BRANDINGS.ORGANIZATION_SUPERVISOR === brandName ?
              'Organization Account Sign  Up' :
              'NRDS Account Sign Up'
            }
          </h1>
          <div className={ this.cn`__scratch-button` }>
            <Button
              onClick={ this.handleResetButtonClick }
              theme="ternary"
            >
              Start from scratch
            </Button>
          </div>
        </div>
        <div className={ this.cn('__breadcrumbs') }>
          <Breadcrumbs
            allRoutes={ getAllChildRoutesFromProps(this.props) }
            clickable={ false }
            routeParent="/business/SignUp.html"
          />
        </div>
        <div className={ this.cn`ts-container` }>
          { (subtitle || title) &&
            <div className={ this.cn`__page-title` }>
              <PageTitle
                subtitle={ subtitle }
                title={ isOrganization ? 'Create an Organization Account' : title }
              />
            </div>
          }
          <BusinessSignUp
            isDone={ this.state.doneSteps.includes(this.state.activeStep) }
            onSubmit={ this.handleStepSubmit }
            step={ this.state.activeStep }
          />
          { this.props.children }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => {
  return {
    mph_discount_code: profile.mph_discount_code,
  };
};

export default connect(mapStateToProps, null)(BusinessSignUpPage);
