import atom from 'atom-js';
import is from 'next-is';
import { post, postMultipart, requestEnd } from 'sf/helpers/request';
import { getStringValidation } from 'helpers/validation';
import { dataURItoImage } from 'sf/helpers/canvas';

const model = atom.setup({
  validation: {
    login: {
      ...getStringValidation('email address'),
      'Your email address is not valid': is.string.isEmail,
    },
    password: {
      ...getStringValidation('password'),
    },
  },
  methods: {
    async sendPhoto(
      resolve,
      reject,
      {
        photoDataURI,
        brandingPrefix = 'business',
      } = {},
    ) {
      const image = await dataURItoImage(photoDataURI);

      postMultipart(`backend/login/${brandingPrefix}/photo/`, 'LOGIN')
        .attach('photo', image.data, `photo.${image.extension}`)
        .end(requestEnd(resolve, reject));
    },
    sendCredentials(
      resolve,
      reject,
      {
        credentials,
        discount_code,
        brandingPrefix = 'business',
      } = {},
    ) {
      post(`backend/login/${brandingPrefix}/login/`, 'LOGIN')
        .type('form')
        .send({
          ...credentials,
          discount_code,
        })
        .end(requestEnd(resolve, reject));
    },
  },
})();

export default model;
