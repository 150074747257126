import React, { Fragment } from 'react';
import _get from 'lodash/get';
import noop from 'no-op';
import walletInsight from 'models/walletInsight';
import BasePage from 'pages/BasePage';
import { DESCRIPTIONS } from 'pages/WalletInsightProfile/WalletInsightProfile.constants';
import Alert from 'sf/components/Alert';
import IconButton from 'sf/components/IconButton';
import LinearGradientBar from 'sf/components/LinearGradientBar';
import { asset } from 'helpers';
import { getHumanReadableTimeDiff, getQuery } from 'sf/helpers';
import { dateRenderer, roundMoneyRenderer } from 'sf/helpers/renderers';
import withTooltip from 'sf/hoc/Tooltip';
import { getStateByShortName } from 'sf/models/world';

const IconButtonWithTooltip = withTooltip(IconButton);

const AVATAR_PLACEHOLDER = asset`img/avatar-placeholder.jpeg`;

const getLinearGradientBarRenderer = ({ min, max }) => (value) => (
  <LinearGradientBar
    max={ max }
    min={ min }
    value={ value }
  />
);

const dateOrDashRenderer = (v) => v ? dateRenderer(v) : '-';

const valueOrDashRenderer = (v) => v || '-';

const stateOrDashRenderer = (v) => (getStateByShortName(v) || { label: '-' }).label;

const timeTillNowOrDashRenderer = (v) => v ? getHumanReadableTimeDiff(v) : '-';

const percentRenderer = (v) => `${Math.round((v || 0) * 100)}%`;

const dashOrMoneyRenderer = (v) => v ? roundMoneyRenderer(v) : '-';

const HOUSEHOLD_MEASURES = {
  'Estimated Household Income': ['measures.income360', dashOrMoneyRenderer],
  'Discretionary Spending': ['measures.ds_dollar', dashOrMoneyRenderer],
  'Economic Segment': ['measures.economic_cohorts', valueOrDashRenderer],
  'Financial Risk': ['measures.frscv5', getLinearGradientBarRenderer({ min: 0, max: 814 })],
  'Mortgage Financial Risk': ['measures.fmsv8', getLinearGradientBarRenderer({ min: 0, max: 850 })],
  'Credit Capacity': ['measures.fcci', getLinearGradientBarRenderer({ min: 0, max: 9 })],
};

const PROPERTY_MEASURES = {
  'Estimated Home Value': ['measures.home_value', dashOrMoneyRenderer],
  'Estimated Combined Loan Balance': ['measures.home_loan', dashOrMoneyRenderer],
  'Time in Home': ['measures.origination_date', timeTillNowOrDashRenderer],
  'Current CLTV': ['measures.cltv', percentRenderer],
  'Estimated Property Equity': ['measures.property_equity', dashOrMoneyRenderer],
};

const PROFILE_INFO = {
  'Email': ['profile.email', valueOrDashRenderer],
  'Birthdate': ['profile.date_birth', dateOrDashRenderer],
  'Phone': ['profile.phone', valueOrDashRenderer],
  'Member since': ['profile.member_since', dateOrDashRenderer],
};

const PROPERTY_INFO = {
  'Address': ['equifax.address', valueOrDashRenderer],
  'City': ['equifax.city', valueOrDashRenderer],
  'State': ['equifax.state', stateOrDashRenderer],
  'ZIP code': ['equifax.zip_code', valueOrDashRenderer],
};

export default class WalletInsightProfile extends BasePage {
  className = 'ts-WalletInsightProfile';

  state = {};

  componentDidMount() {
    this.syncStateWithModel(walletInsight, ['profile']);
    const { id } = getQuery();
    if (!id) this.navigate('/AskSomeoneToVerify.html');
    walletInsight.getProfileInfo(id);
  }

  List = ({ values, className }) => (
    <div
      className={ this.cn({
        '__list': true,
        [className]: className,
      }) }
    >
      { Object.entries(values).map(
        ([name, [valueName, renderer = (s) => s]]) => (
          <div
            className={ this.cn`__list-row` }
            key={ `name-${name}` }
          >
            <div className={ this.cn`__list-name` }>
              <span className={ this.cn`__list-name-wrapper` }>
                { name }:
                { this.renderHelpButton(DESCRIPTIONS[name]) }
              </span>
            </div>
            <div className={ this.cn`__list-value` }>
              { renderer(_get(this.state, `profile.${valueName}`)) }
            </div>
          </div>
        )
      ) }
    </div>
  );

  Section = ({ children, className = '', title }) => (
    <div className={ this.cn`__section ${className}` }>
      <span className={ this.cn`__section-title` }>
        { title }
      </span>
      <div className={ this.cn`__section-content` }>
        { children }
      </div>
    </div>
  );

  renderHelpButton = (tooltipText, className = this.cn`__list-description-button`) => {
    if (!tooltipText) return null;

    return (
      <IconButtonWithTooltip
        className={ className }
        set="io"
        tooltipText={ tooltipText }
        type="ios-help-outline"
        onClick={ noop }
      />
    );
  }

  render() {
    const photo_url = _get(this.state, 'profile.photo_url', AVATAR_PLACEHOLDER);
    return this.state.profile ? (
      <Fragment>
        {
          !_get(this.state, 'profile.equifax.found_in_eqf') &&
          <Alert
            className={ this.cn`__alert` }
            theme="warning"
          >
            <div className="ts-container">
              Address not found - no property data available
            </div>
          </Alert>
        }
        <div className={ this.rootcn`ts-container` }>
          <div className={ this.cn`__row` }>
            <this.Section
              className={ this.cn`__section--medium` }
              title="Basic Information"
            >
              <div className={ this.cn`__name` }>
                { `${
                  _get(this.state, 'profile.equifax.first_name')
                } ${
                  _get(this.state, 'profile.equifax.last_name')
                }` }
              </div>
              <div className={ this.cn`__profile-additional-data` }>
                <img
                  alt="Profile avatar"
                  className={ this.cn`__profile-photo` }
                  src={ photo_url }
                />
                <this.List
                  className={ this.cn`__profile-list` }
                  values={ PROFILE_INFO }
                />
              </div>
            </this.Section>
            <div className={ this.cn`__column __section--narrow` }>
              <this.Section
                title="Home Purchase Propensity"
              >
                <span className={ this.cn`__hpp-name` }>Lead scored</span>

                { this.renderHelpButton(
                  `The Home Purchase Propensity score identifies the likelihood that a lead
will transact within the next 2-6 months.`,
                  this.cn`__inline-icon-button`
)
                }

                <LinearGradientBar
                  isBig={ true }
                  max={ 1000 }
                  min={ 0 }
                  value={ _get(this.state, 'profile.measures.purchase') }
                />
              </this.Section>
              <this.Section
                title="Property Information"
              >
                <div className={ this.cn`__measures-wrapper` }>
                  <this.List
                    className={ this.cn`__profile-list __profile-list-property` }
                    values={ PROPERTY_INFO }
                  />
                </div>
              </this.Section>
            </div>
          </div>
          <this.Section
            title="Estimated Financial Profile"
          >
            <div className={ this.cn`__measures-wrapper` }>
              <this.List
                className={ this.cn`__measures-left` }
                values={ HOUSEHOLD_MEASURES }
              />
              <div className={ this.cn`__measures-separator` } />
              <this.List
                className={ this.cn`__measures-right` }
                values={ PROPERTY_MEASURES }
              />
            </div>
          </this.Section>
        </div>
      </Fragment>
    ) : null;
  }
}
