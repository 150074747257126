import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import BaseComponent from 'components/BaseComponent';
import deviceModel from 'models/device';
import user from 'models/user';

export default class ScoreToken extends BaseComponent {
  className = 'ts-ScoreToken';

  state = {};

  static contextTypes = {
    userModel: PropTypes.object,
    viewMode: PropTypes.string,
  };

  static propTypes = {
    isSelfieMismatched: PropTypes.bool,
    onRetakeSelfieButtonClick: PropTypes.func,
  };

  static defaultTypes = {
    isSelfieMismatched: false,
    onRetakeSelfieButtonClick: noop,
  };

  constructor() {
    super();
    this.subscribe('reloadOneAll', () => {
      user.getProfileData();
    });
  }

  componentDidMount() {
    this.syncStateWithModel(this.context.userModel, ['photo', 'ts_token']);
    this.syncStateWithModel(deviceModel, ['xxsmUp']);
    this.hackTokenForSafari();
  }

  componentDidUpdate() {
    this.hackTokenForSafari();
  }

  hackTokenForSafari() {
    // HACK for Safari OSX and iOS
    // https://jira.10clouds.com/browse/TS-850
    if (
      (is.safari() || global.REALTOR_PLATFORM === 'ios') &&
      this.state.ts_token &&
      this.refs.token
    ) {
      const attrName = 'data-hack-for-safari-applied';
      const node = findDOMNode(this.refs.token).querySelector('.ts-public-token__score-caption');
      if (node.getAttribute(attrName) !== attrName) {
        node.setAttribute('y', node.getAttribute('y') - 13);
        node.setAttribute(attrName, attrName);
      }
    }
  }

  renderSVGScore() {
    const classNames = {
      '--selfie-mismatched': this.props.isSelfieMismatched,
    };
    return (
      <div
        className={ this.rootcn(classNames) }
      >
        { /*
          // TODO: disabled only a button for now;
          remove whole retake selfie feature when the dust settles
        */ }
        { /* <Render when={ this.context.viewMode === 'PRIVATE' }>
          <div className={ this.cn`__edit` }>
            <PillButton
              isInked={ false }
              onClick={ this.props.onRetakeSelfieButtonClick }
              shell={ true }
            >
                Retake
            </PillButton>
          </div>
        </Render> */ }
        <div
          className={ this.cn`__token` }
          ref="token"
          dangerouslySetInnerHTML={ { __html: this.state.ts_token } }
        />
      </div>
    );
  }

  renderUnknownScore() {
    return null;
  }

  render() {
    return this.state.ts_token ?
      this.renderSVGScore() :
      this.renderUnknownScore();
  }
}
