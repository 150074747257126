import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import { Col, Row } from 'sf/components/Grid';

export default class LandingInfographic extends BaseComponent {
  className = 'ts-LandingInfographic';

  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    emphasisInTitle: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    id: '',
    children: null,
    emphasisInTitle: '',
    image: '',
  };

  renderEmphasizedTitle = () => {
    const { title, emphasisInTitle } = this.props;
    if (!emphasisInTitle) return title;
    const className = this.cn`__title--emphasize`;
    const re = new RegExp(emphasisInTitle, 'g');
    return (
      <h1
        className={ this.cn`__title` }
        dangerouslySetInnerHTML={ {
          __html: title.replace(re, `<span class=${className}>${emphasisInTitle}</span>`),
        } }
      />
    );
  }

  render() {
    const classNames = {
      [`--${this.props.id}`]: this.props.id,
    };
    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.cn`__image-wrap` }>
          <Row center={ ['xxsm'] }>
            <Col lg={ 7 }>
              <div className={ this.cn`__image-background` }>
                <Render when={ this.props.image }>
                  <img
                    alt=""
                    className={ this.cn`__image` }
                    src={ this.props.image }
                  />
                </Render>
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={ 6 }>
            { this.renderEmphasizedTitle() }
          </Col>
        </Row>
        <Render when={ this.props.children }>
          <Row>
            <Col lg={ 6 }>
              <div className={ this.cn`__content` }>
                { this.props.children }
              </div>
            </Col>
          </Row>
        </Render>
      </div>
    );
  }
}
