import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class FeatureCard extends BaseComponent {
  className = 'ts-FeatureCard';

  static propTypes = {
    imageUrl: PropTypes.string,
    number: PropTypes.number,
    text: PropTypes.node,
    title: PropTypes.node,
  };

  static defaultProps = {
    imageUrl: null,
    number: null,
    text: null,
    title: null,
  };

  render() {
    const { imageUrl, number, text, title } = this.props;
    return (
      <div className={ this.rootcn(`--card-${number}`) }>
        { number && <small className={ this.cn`__number` }>{ number }</small> }
        { imageUrl &&
          <div className={ this.cn`__image-container` }>
            <img className={ this.cn`__image` } src={ imageUrl } alt="" />
          </div>
        }
        { title && <div className={ this.cn`__title` }>{ title }</div> }
        { text && <p className={ this.cn`__text` }>{ text }</p> }
      </div>
    );
  }
}
