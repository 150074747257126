import React from 'react';
import _some from 'lodash/some';

import { brandName } from 'brandingVariables';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import SocialServicesCards, { MODES } from 'components/SocialServicesCards';
import { BRANDINGS, BUSINESS_SERVICE, ORGANIZATION_SERVICE, SERVICES } from 'constants';
import device from 'models/device';
import BasePage from 'pages/BasePage';
import Card from 'sf/components/Card';

const CONTEXT_HELP_NOTIFICATIONS = [
  'SOCIAL_MEDIA_TRUST_SCORE',
  'SOCIAL_MEDIA_LOG_IN',
  'SOCIAL_MEDIA_REQUIRED',
];

export default class SignUpSelectSocialnetwork extends BasePage {
  className = 'ts-SignUpSelectSocialnetwork';

  state = {};

  componentDidMount() {
    this.syncStateWithModel(device, ['xxsmUp']);
  }

  getText() {
    if (brandName === BRANDINGS.ORGANIZATION_SUPERVISOR) {
      return {
        title: 'Connect a social media account to Trust Stamp',
      };
    }

    switch (true) {
      case device.xxsmUp():
        return {
          title: 'Choose organization supervisor account’s type',
          subtitle: 'It will also act as your login method',
        };
      default:
        return {
          title: 'Sign up with a social media account',
          subtitle: 'This account will be the first step to building your Trust score',
        };
    }
  }

  render() {
    const classNames = this.state.xxsmUp ? 'ts-container ts-container--narrow' : null;
    const services =
      [
        ...brandName === BRANDINGS.REALTOR ? [BUSINESS_SERVICE] : [],
        ...brandName === BRANDINGS.ORGANIZATION_SUPERVISOR ? [ORGANIZATION_SERVICE] : [],
        ...SERVICES.filter(({ isUsedDuringAuth }) => isUsedDuringAuth),
      ];
    const isBussinessService = _some(
      [BRANDINGS.REALTOR, BRANDINGS.ORGANIZATION_SUPERVISOR],
      (brand) => (brand === brandName)
    );

    return (
      <div className={ this.rootcn(classNames) }>
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          <div className={ this.cn`__page-title` }>
            <PageTitle
              className={ this.cn`__title` }
              title={ this.getText().title }
              subtitle={ this.getText().subtitle }
            />
            <HelpButton
              className={ this.cn`__title-help-button` }
              notifications={ CONTEXT_HELP_NOTIFICATIONS }
            />
          </div>
          <div className={ this.cn`__content` }>
            <SocialServicesCards
              includeBusinessOnlyService={ isBussinessService }
              mode={ MODES.SIGN_UP }
              services={ services }
            />
          </div>
        </Card>
      </div>
    );
  }
}
