import React from 'react';
import { compose } from 'recompose';
import { handleFormValidation } from 'helpers/validations';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import {
  TERMS_OF_USE,
} from 'pages/WalletInsightRegistration/WalletInsightRegistration.constants';
import Button from 'sf/components/Button';
import Card from 'sf/components/Card';
import Checkbox from 'sf/components/Checkbox';
import Input from 'sf/components/Input';
import PageTitle from 'sf/components/PageTitle';
import withLabel, { POSITIONS } from 'sf/hoc/Label';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLink = compose(
  withValidation,
  withStateLink,
)(Input);

const ValidationCheckboxWithStateLinkAndLabel = compose(
  withValidation,
  withStateLink,
  withLabel,
)(Checkbox);

export default class WalletInsightRegistration extends BasePage {
  className = 'ts-WalletInsightRegistration';

  state = {
    firm_name: undefined,
    individuals_name: user.get('legal_name'),
    individuals_email: user.get('email'),
    promocode: '',
    checkbox: false,
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    let isFormValid = true;

    await this.formValidation(
      user.set(this.state)
    )
      .catch(() => {
        isFormValid = false;
      });

    if (!isFormValid) return;

    await user
      .acceptWalletInsightTerms(this.state)
      .then(() => this.navigate('/AskSomeoneToVerify.html'))
      .catch(handleFormValidation(this));
  };

  isSubmitButtonDisabled = () => !Object.values(this.state)
    .every(Boolean);

  render() {
    return (
      <Card
        className={ this.rootcn`` }
      >
        <form
          className={ this.cn`__form` }
          onSubmit={ this.handleSubmit }
        >
          <div className={ this.cn`__form-left` }>
            <PageTitle
              className={ this.cn`__page-title` }
              hr={ true }
              subtitle="Please verify fields that have been auto-filled"
              title="Register your account"
            />
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="Firm name"
              title="Firm name"
              ref="firm_name"
              stateLink={ [this, 'firm_name'] }
            />
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="Legal name"
              title="Legal name"
              ref="individuals_name"
              stateLink={ [this, 'individuals_name'] }
            />
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="Email"
              title="Email"
              ref="individuals_email"
              stateLink={ [this, 'individuals_email'] }
            />
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="Promocode"
              title="Promocode"
              ref="promocode"
              stateLink={ [this, 'promocode'] }
            />
          </div>
          <div className={ this.cn`__form-right` }>
            <p className={ this.cn`__terms-title` }>
              Acknowledgement and Agreement Required
            </p>
            <span className={ this.cn`__terms` }>
              { TERMS_OF_USE }
            </span>
            <ValidationCheckboxWithStateLinkAndLabel
              className={ this.cn`__checkbox` }
              labelClassName={ this.cn`__checkbox-label` }
              labelPosition={ POSITIONS.RIGHT }
              labelText="I acknowledge and agree"
              ref="checkbox"
              stateLink={ [this, 'checkbox'] }
            />
            <Button
              className={ this.cn`__button` }
              disabled={ this.isSubmitButtonDisabled() }
            >
              Submit
            </Button>
          </div>
        </form>
      </Card>
    );
  }
}
