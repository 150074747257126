import React from 'react';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import { ROUTES } from 'constants';
import Icon from 'sf/components/Icon';

class ResetPasswordLinkSent extends BaseComponent {
  className = 'ts-ResetPasswordLinkSent';

  render() {
    return (
      /* eslint-disable max-len */
      <div className={ this.rootcn() }>
        <div className={ this.cn`__icon-container` }>
          <Icon
            className={ this.cn`__email-sent-icon` }
            set="io"
            type="checkmark"
          />
          <span className={ this.cn`__email-sent-text` }>
            Email sent!
          </span>
          <span className={ this.cn` __no-reset-email` }>
            Didn&apos;t receive an email?&nbsp;
            <Link
              className={ this.cn`__no-reset-mail-link` }
              to={ ROUTES.CONTACT_US }
            >
              Contact us
            </Link>
          </span>
        </div>
      </div>
      /* eslint-enable max-len */
    );
  }
}

export default ResetPasswordLinkSent;
