import React from 'react';
import BaseComponent from 'components/BaseComponent';

export default class Render extends BaseComponent {
  className = 'ts-VideoCaptureRec';

  render() {
    return (
      <div className={ this.rootcn`` }>
        <div className={ this.cn`__circle` }></div>
        <span className={ this.cn`__text` }>&nbsp;REC</span>
      </div>
    );
  }
}
