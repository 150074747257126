import React from 'react';
import PropTypes from 'prop-types';


import BaseComponent from 'components/BaseComponent';
import userModel from 'models/user';
import messengerModel from 'models/messenger';
import Heading from '../../elements/Heading';
import CreditsAdder from '../../elements/CreditsAdder';
import Messenger from '../../elements/Messenger';

class AdminPanelBalanceView extends BaseComponent {
  className = 'ts-AdminPanelBalanceView';

  state = {
    number_of_credits: 0,
    messages: [],
  }

  static propTypes = {
    children: PropTypes.node,
  };

  componentDidMount = () => {
    this.syncStateWithModel(userModel, [
      'number_of_credits',
    ]);
    messengerModel.clearMessages();
    userModel.getProfileData();
  }

  handleAddCredits = () => {
    messengerModel.clearMessages();
    messengerModel.addMessage({
      msg: 'Credits added to your account',
      type: 'success',
    });
    userModel.getProfileData();
  }

  render() {
    return (
      <div className={ this.rootcn() } >
        <div className={ this.cn`__heading-container` }>
          <Heading
            subtitle="Pick wisely"
          >
            Your credit balance
          </Heading>
        </div>
        <Messenger />
        <div className={ this.cn`__summary-adder-container` }>
          <div className={ this.cn`ts-Section __credits-summary-container` }>
            <div className={ this.cn`__credits-summary` }>
              <div className={ this.cn`__credits-number` }>
                { this.state.number_of_credits }
              </div>
              <div className={ this.cn`__credits-symbol` }>
                C
              </div>
              <div className={ this.cn`__credits-info` }>
                You can use credits to invite contractors
              </div>
            </div>
          </div>
          <div className={ this.cn`ts-Section __credits-adder-container` }>
            <CreditsAdder
              onAddSuccess={ this.handleAddCredits }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPanelBalanceView;
