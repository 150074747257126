/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { CONTENT } from './constant';

export default class Cookies extends BasePage {
  className = 'ts-Cookies';
  title = 'Cookies';
  // eslint-disable-next-line max-len
  description = 'We research and leverage artificial intelligence and machine learning to deliver the most comprehensive facial biometric identity authentication solution.';


  render() {
    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <h1>Use of cookies by Trust Stamp</h1>
          <p>Cookies are small text files that are placed on your computer by websites that you visit. Cookies are used to make websites either work outright or work more efficiently. They can also provide information to the owners of the site. The table below explains the cookies we use and why.</p>
          <div className={ this.cn`__table-container` }>
            <table>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Name</th>
                  <th>Purpose</th>
                  <th>Duration</th>
                  <th>Requirement</th>
                </tr>
              </thead>
              <tbody>
                { CONTENT.map(({ id, cookie, name, purpose, duration, requirement }) => {
                  return (
                    <tr key={ id }>
                      <td>{ cookie }</td>
                      <td>{ name }</td>
                      <td>
                        { purpose }
                      </td>
                      <td>{ duration }</td>
                      <td>{ requirement }</td>
                    </tr>
                  );
                }) }
              </tbody>
            </table>
          </div>
          <h1>How do I change my cookie settings?</h1>
          <p>You can change your cookie preferences at any time by clicking on the ‘C’ icon at the bottom left hand corner. You can then adjust the available slider to ‘On’ or ‘Off’, then clicking ‘Save and close’. You may need to refresh your page for your settings to take effect.</p>
        </div>
      </div>
    );
  }
}
