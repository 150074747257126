import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import settingsModel from 'models/settings';
import ValidationInput from 'sf/components/ValidationInput';

export default class BasicProfileInfoPhoneDialog extends BaseComponent {
  className = 'ts-BasicProfileInfo';

  static propTypes = {
    confirmationPhone: PropTypes.string,
    onPhoneVerified: PropTypes.func,
  };

  static defaultProps = {
    confirmationPhone: '',
    onPhoneVerified: noop,
  };

  state = {};

  open() {
    this.refs.dialog.toggle(true);
    this.setState({
      phoneVerificationCode: null,
    });
  }

  handlePhoneVerifyButtonClick = () => {
    this.formValidation(
      settingsModel.set({
        phoneVerificationCode: this.state.phoneVerificationCode,
      })
    ).then(() => {
      const done = () => {
        this.refs.dialog.toggle(false);
        this.props.onPhoneVerified();
      };
      const fail = (message) => {
        this.refs.dialog.showFloatingText({
          text: message,
          isValid: false,
        });
      };
      settingsModel.getPhoneCodeVerification().then(done, fail);
    });
  }

  render() {
    return (
      <Dialog
        className={ this.cn`__change-dialog` }
        ref="dialog"
        size="small"
        title="Confirm your phone number"
      >
        <div className={ this.cn`__change-dialog-body` }>
          Please enter the code sent to
          <div className={ this.cn`__change-dialog-important` }>
            { this.props.confirmationPhone }
          </div>
          <div className={ this.cn`__change-dialog-input` }>
            <ValidationInput
              ref="phoneVerificationCode"
              type="text"
              placeholder="Verification code…"
              stateLink={ [this, 'phoneVerificationCode'] }
              inputMode="numeric"
            />
          </div>
          <div className={ this.cn`__button` }>
            <Button
              onClick={ this.handlePhoneVerifyButtonClick }
              size="big"
              type="submit"
            >
              That’s the code
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}
