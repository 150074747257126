import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import reactTimeout from 'react-timeout';
import BaseComponent from 'components/BaseComponent';
import Img from 'sf/components/Img';

class CompareImages extends BaseComponent {
  className = 'ts-CompareImages';
  loadedImages = 0;

  static defaultProps = {
    onLoad: noop,
    onError: noop,
    separatorPosition: 150,
  };

  static propTypes = {
    images: PropTypes.array.isRequired,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    initialSeparatorPosition: PropTypes.number,
    separatorPosition: PropTypes.number,
  };

  state = {
    separatorPosition: this.props.initialSeparatorPosition ?
      this.props.initialSeparatorPosition :
      this.props.separatorPosition,
  };

  componentDidMount() {
    this.props.setTimeout(() => {
      this.interval = this.props.setInterval(this.setSeparatorPositionTick, 7);
    }, 2000);
  }

  setSeparatorPositionTick = () => {
    if (this.state.separatorPosition < this.props.separatorPosition) {
      this.setState({
        separatorPosition: this.state.separatorPosition + 2,
      });
    } else {
      this.props.clearInterval(this.interval);
    }
  }

  handleDrag = (event, data) => {
    this.setState({
      separatorPosition: data.x,
    });
  }

  handleDragStart = () => {
    this.setState({
      isDragged: true,
    });
  }

  handleDragStop = () => {
    this.setState({
      isDragged: false,
    });
  }

  handleImageError = () => {
    this.props.onError();
  }

  handleImageLoaded = () => {
    this.loadedImages += 1;

    if (this.loadedImages === 2) {
      this.props.onLoad();
    }
  }

  render() {
    return (
      <div className={ this.rootcn({ '--dragged': this.state.isDragged }) } ref="container">
        <Img
          className={ this.cn`__layer` }
          onLoad={ this.handleImageLoaded }
          onError={ this.handleImageError }
          src={ this.props.images[0] }
        />
        <div
          className={ this.cn`__layer __layer--active` }
          style={ {
            width: `${this.state.separatorPosition}px`,
          } }
        >
          <Img
            className={ this.cn`__layer` }
            onLoad={ this.handleImageLoaded }
            onError={ this.handleImageError }
            src={ this.props.images[1] }
          />
        </div>
        <Draggable
          axis="x"
          bounds="parent"
          position={ {
            x: this.state.separatorPosition,
            y: 0,
          } }
          onDrag={ this.handleDrag }
          onStart={ this.handleDragStart }
          onStop={ this.handleDragStop }
        >
          <div
            className={ this.cn`__ball` }
          />
        </Draggable>
      </div>
    );
  }
}

export default reactTimeout(CompareImages);
