import React from 'react';
import capitalize from 'lodash/capitalize';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import Button from 'components/Button';
import Icon from 'sf/components/Icon';

function SocialNetworkLogInButton(props) {
  const { serviceName, onClick } = props;

  return (
    <div>
      Please log in again to continue using { capitalize(serviceName) }.
      Expired connections do not count towards your Trust Score.
      <Button
        { ...omit(props, ['serviceName', 'onClick']) }
        className={ this.cn`__button` }
        onClick={ onClick }
        theme="ternary"
      >
        <Icon
          className={ this.cn`__icon` }
          type={ serviceName }
          set="ts"
          size={ 24 }
        />
        <span className={ this.cn`__label` } >
          Connect with { capitalize(serviceName) }
        </span>
      </Button>
    </div>
  );
}

SocialNetworkLogInButton.propTypes = {
  serviceName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

SocialNetworkLogInButton.defaultProps = {
  onClick: noop,
};

export default baseFunctionalComponent(SocialNetworkLogInButton, 'SocialNetworkLogInButton');
