import React, { Fragment } from 'react';
import noop from 'no-op';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import { handleFormValidation } from 'helpers/validations';
import payment from 'models/payment';
import walletInsight from 'models/walletInsight';
import Button from 'sf/components/Button';
import Card from 'sf/components/Card';
import Dialog from 'components/Dialog';
import Input from 'sf/components/Input';
import PageTitle from 'sf/components/PageTitle';
import Payment from 'sf/components/Payment';
import Select from 'sf/components/Select';
import { getQuery } from 'sf/helpers';
import { digitParser } from 'sf/helpers/parsers';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';
import world from 'sf/models/world';
import publicUser from 'models/publicUser';
import user from 'models/user';

const states = world.get('states');

const ValidationInputWithStateLink = compose(
  withValidation,
  withStateLink,
)(Input);

export default class WalletInsightForm extends BaseComponent {
  className = 'ts-WalletInsightForm';
  title = 'Lead Accelerator™';
  subtitle = 'Search for insights on potential clients through their full name and address.';

  state = {
    address: undefined,
    city: undefined,
    first_name: undefined,
    invitation_key: getQuery().id,
    access_token: getQuery().accessToken, // access token to get profile's data
    last_name: undefined,
    state: undefined,
    zip_code: undefined,
  };

  handlePaymentSuccess = () => {
    this.navigate('/AskSomeoneToVerify.html');
  }

  handleSubmit = async (e) => {
    let isFormValid = true;

    e.preventDefault();

    await this.formValidation(
      walletInsight.set(this.state)
    )
      .catch(() => {
        isFormValid = false;
      });
    if (!isFormValid) return;

    const invitation_key = await walletInsight
      .triggerSearch(this.state)
      .catch(handleFormValidation(this));

    if (invitation_key) {
      if (user.get('promocode_requests_left')) {
        // bypass payment dialog
        this.paymentConfirmationCallback({ nonce: Math.random(), invitation_key });
      } else {
        this.setState(
          { invitation_key },
          () => this.paymentDialogInstance.toggle(true),
        );
      }
    }
  };

  paymentConfirmationCallback = async ({ nonce, invitation_key }) => {
    await payment.payForWalletInsight({ nonce }, invitation_key || this.state.invitation_key);

    this.notify('success', 'Evaluation complete');
  }

  componentDidMount() {
    const accessToken = this.state.access_token;
    this.syncStateWithModel(user, ['promocode_requests_left']);
    if (accessToken) {
      publicUser
        .getProfileData(false, accessToken)
        .then((res) => {
          const userData = res.body.data;
          const legalNameArr = (userData.legal_name || '').split(' ');
          const last_name = legalNameArr.pop();
          const first_name = legalNameArr.join(' ');

          this.setState({
            first_name,
            last_name,
            address: userData.address,
            city: userData.city,
            state: userData.state,
            zip_code: userData.zip_code,
          });
        }).catch(console.error); // eslint-disable-line no-console
    }
  }

  renderFreeRequestsInfo() {
    const { promocode_requests_left } = this.state;
    if (!promocode_requests_left) return null;

    const requestsWord = promocode_requests_left === 1
      ? 'request'
      : 'requests';

    return (
      <p className={ this.cn`__promocode-text` }>
        You have <strong>{ this.state.promocode_requests_left }</strong> free { requestsWord } left.
      </p>
    );
  }

  render() {
    const title = this.state.invitation_key
      ? 'Evaluate Lead Accelerator™'
      : 'Look up potential clients';

    return (
      <Fragment>
        <Card
          className={ this.rootcn`` }
        >
          <PageTitle
            className={ this.cn`__page-title` }
            hr={ true }
            subtitle={ this.subtitle }
            title={ title }
          />
          <form
            className={ this.cn`__form` }
            onSubmit={ this.handleSubmit }
          >
            <div className={ this.cn`__input-row __input-row--with-hr` }>
              <ValidationInputWithStateLink
                className={ this.cn`__input` }
                placeholder="First name"
                ref="first_name"
                stateLink={ [this, 'first_name'] }
              />
              <ValidationInputWithStateLink
                className={ this.cn`__input` }
                placeholder="Last name"
                ref="last_name"
                stateLink={ [this, 'last_name'] }
              />
            </div>
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="Street"
              ref="address"
              stateLink={ [this, 'address'] }
            />
            <ValidationInputWithStateLink
              className={ this.cn`__input` }
              placeholder="City"
              ref="city"
              stateLink={ [this, 'city'] }
            />
            <div className={ this.cn`__input-row` }>
              <Select
                className={ this.cn`__input` }
                options={ states }
                placeholder="State"
                placeholderInitiallyVisible={ true }
                ref="state"
                stateLink={ [this, 'state'] }
              />
              <ValidationInputWithStateLink
                className={ this.cn`__input` }
                maxLength={ 5 }
                parser={ digitParser }
                placeholder="Zip Code"
                ref="zip_code"
                stateLink={ [this, 'zip_code'] }
              />
            </div>

            { this.renderFreeRequestsInfo() }

            <Button
              className={ this.cn`__button` }
              disabled={ this.areFields }
            >
              Enter
            </Button>
          </form>
        </Card>
        <Dialog
          className={ this.cn`__payment-dialog` }
          ref={ (dialog) => { this.paymentDialogInstance = dialog; } }
          onDismiss={ noop }
        >
          <PageTitle
            className={ this.cn`__page-title` }
            hr={ true }
            title={ `Get access to the Lead Accelerator™ for $${WALLET_INSIGHT_PRICE.toFixed(2)}` }
          />
          <Payment
            creditPrice={ WALLET_INSIGHT_PRICE }
            currency="USD"
            paymentMethod={ this.paymentConfirmationCallback }
            onError={ noop }
            onSuccess={ this.handlePaymentSuccess }
          />
        </Dialog>
      </Fragment>
    );
  }
}
