import React from 'react';
import accounting from 'accounting-js';
import _get from 'lodash/get';
import { CURRENCY_SYMBOLS } from 'sf/constants';
import date from 'sf/models/date';
import world from 'sf/models/world';

export const dateRenderer = (dateString, useOrdinalIndicator) => dateString ?
  date.toFormattedDate(new Date(dateString), useOrdinalIndicator) :
  null;

export const shortDateRenderer = (dateString) => dateString ?
  date.toProfileFormattedDate(new Date(dateString)) :
  null;

export const countryRenderer = (code) => {
  if (!code) return 'None';
  const countryName = _get(world.getCountryByCode(code), 'label');
  if (!countryName) {
    throw new Error('Invalid country code provided.');
  }
  return countryName;
};

export const urlRenderer = (url, emptyText = 'None', className = '') => url && url !== 'None' ? (
  // eslint-disable-next-line
  <a className={ className } target="_blank" href={ url }>View →</a>
) : emptyText;

export const imageRenderer = (src, className) => src ? (
  <div
    className={ className }
    style={ {
      backgroundImage: `url(${src})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center left',
      backgroundRepeat: 'no-repeat',
    } }
  />
) : null;

export const moneyRenderer = accounting.formatMoney;

const MONEY_FORMAT = '%s%v';

export const decimalMoneyRenderer = (amount, options = {}) => moneyRenderer(
  amount,
  {
    symbol: options.symbol || CURRENCY_SYMBOLS[CURRENCY],
    thousand: options.thousand || ',',
    format: MONEY_FORMAT,
    precision: 2,
  },
);

export const roundMoneyRenderer = (amount, options = {}) => moneyRenderer(
  amount,
  {
    symbol: options.symbol || CURRENCY_SYMBOLS[CURRENCY],
    thousand: options.thousand || ',',
    decimal: options.decimal || '.',
    format: MONEY_FORMAT,
    precision: 0,
  },
);
