import React from 'react';
import PageTitle from 'components/PageTitle';
import { ROUTES } from 'constants';
import { get } from 'sf/helpers/request';
import BasePage from 'pages/BasePage';

export default class AgentConfirmation extends BasePage {
  className = 'ts-AgentConfirmation';
  title = 'Page:AgentConfirmation';

  state = {
    isRequestComplete: false,
    isAgentConfirmed: false,
  };

  constructorBrowser() {
    get(`backend/organization/confirm_agent/${location.search}`)
      .end((err, res) => {
        if (err) {
          this.notify('error', res.body.detail || res.body.data.message);
        } else {
          this.setState({
            isAgentConfirmed: true,
          });
        }
        this.setState({
          isRequestComplete: true,
        });
      });
  }

  getTitleText = () => {
    if (this.state.isAgentConfirmed) {
      return {
        title: 'Well done!',
        subtitle: 'You have been confirmed as an Agent of your Organization',
      };
    }
    return {
      title: 'Oops! Something went wrong!',
      subtitle: 'Please click the button in the e-mail again or contact us',
    };
  }

  handleBackToProfileButtonClick = () => {
    this.navigate('/Profile.html');
  }

  handleContactUsButtonClick = () => {
    this.navigate(ROUTES.CONTACT_US);
  }

  render() {
    const classNames = {
      '': true,
      '--hidden': !this.state.isRequestComplete,
    };
    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.rootcn`__content` }>
          <div className={ this.cn`__title` }>
            <PageTitle
              { ...this.getTitleText() }
              underline={ false }
            />
          </div>
        </div>
      </div>
    );
  }
}
