import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

import './ProgressBar.scss';

export default class ProgressBar extends BaseComponent {
  className = 'ts-ProgressBar';

  static propTypes = {
    circleRadius: PropTypes.number,
    color: PropTypes.string,
    max: PropTypes.number,
    size: PropTypes.number,
    step: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number,
    roundedEdges: PropTypes.bool,
    cx: PropTypes.number,
    cy: PropTypes.number,
    svgWidth: PropTypes.number,
    svgHeight: PropTypes.number,
  };

  static defaultProps = {
    circleRadius: 10,
    color: 'red',
    max: 100,
    size: 36,
    step: 5,
    type: 'ring',
    strokeWidth: 12,
    roundedEdges: false,
    cx: 17,
    cy: 18,
    svgWidth: 36,
    svgHeight: 36,
  };

  renderVisuals() {
    const {
      type,
      color,
      value,
      max,
      circleRadius,
      strokeWidth,
      roundedEdges,
      cx,
      cy,
      svgWidth,
      svgHeight } = this.props;
    const circumference = circleRadius * 2 * Math.PI;

    switch (type) {
      case 'ring':
        // SOURCE: https://codepen.io/anon/pen/QYPGgZ
        return (
          <svg
            className={ this.cn`__progress-ring` }
            width={ svgWidth }
            height={ svgHeight }
            key={ type }
          >
            <circle
              className={ this.cn`__progress-ring__circle` }
              stroke={ color }
              strokeWidth={ strokeWidth }
              fill="transparent"
              r={ circleRadius }
              cx={ cx }
              cy={ cy }
              style={ {
                strokeDasharray: `${circumference} ${circumference}`,
                strokeDashoffset: circumference - value / max * circumference,
                strokeLinecap: `${roundedEdges ? 'round' : ''}`,
              } }
            />
          </svg>
        );
      default:
        return (
          <progress
            key={ type }
            value={ value }
            max={ max }
            className={ this.cn`__progress-default` }
          />
        );
    }
  }

  render({ size, value, max }) {
    return (
      <div
        className={ this.rootcn() }
        style={ { transform: `scale(${size / 36})` } }
        data-value={ value }
        data-max={ max }
      >
        { this.renderVisuals() }
      </div>
    );
  }
}
