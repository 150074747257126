import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import HiddenLabel from 'components/HiddenLabel';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';
import { asset } from 'helpers';
import { getHumanReadableTimeDiff } from 'sf/helpers';

const TEXT = {
  USERNAME_MISMATCH: {
    error: 'Your name doesn’t match your Trust Stamp name',
    hint: 'Change your name to your Trust Stamp one to increase your Trust Score.',
  },
  PICTURE_MISMATCH: {
    error: 'Your profile picture doesn’t match your selfie',
    hint: 'Match your profile photo to your selfie to increase your Trust Score. ',
  },
};

const getErrorText = (errors = [], type = 'error') => {
  const firstError = errors[0];
  return firstError ? TEXT[firstError][type] : null;
};

export default class SocialTile extends BaseComponent {
  className = 'ts-SocialTile';

  static propTypes = {
    edited: PropTypes.bool,
    service: PropTypes.shape({
      created_date: PropTypes.string,
      profile_name: PropTypes.string,
      profile_pic: PropTypes.string,
      profile_url: PropTypes.string,
      published: PropTypes.bool,
      service_name: PropTypes.string,
      used_during_signup: PropTypes.bool,
    }),
    viewMode: PropTypes.string.isRequired,
    onConnect: PropTypes.func,
    onDisconnect: PropTypes.func,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
  };

  static defaultProps = {
    edited: false,
    service: null,
    onConnect: noop,
    onDisconnect: noop,
    onHide: noop,
    onShow: noop,
  };

  handleDisconnectButtonClick = () => {
    this.props.onDisconnect(this.props.service);
  };

  handleAddConnectionButtonClick = () => {
    this.props.onConnect(this.props.service);
  };

  handleHideButtonClick = () => {
    this.props.onHide(this.props.service);
  };

  handleShowButtonClick = () => {
    this.props.onShow(this.props.service);
  };

  get profilePicture() {
    const { connected, profile_pic } = this.props.service;

    if (connected) {
      return profile_pic ? profile_pic : asset`img/user-image-placeholder.png`;
    }
    return asset`img/pix.png`;
  }

  render() {
    const {
      connected,
      connections,
      created_date,
      errors,
      profile_name,
      published,
      service_name,
      used_during_signup,
    } = this.props.service;
    const className = {
      '--active': this.props.edited,
      '--primary': used_during_signup,
      '--hidden': !published,
      '--can-connect': !connected,
      '--with-hint': getErrorText(errors, 'hint'),
    };
    const connectionsClassName = {
      '__connections': true,
      '__connections--suspicious': connections === 0,
    };
    const privateViewMode = this.props.viewMode !== 'PUBLIC';

    return (
      <div className={ this.rootcn(className) }>
        { /* eslint-disable jsx-a11y/anchor-has-content */ }
        <a
          className={ this.cn(`__icon __icon-service __icon--${service_name}`) }
          href={ this.props.service.profile_url }
          rel="noopener noreferrer"
          target="_blank"
        />
        { /* eslint-enable jsx-a11y/anchor-has-content */ }
        <Render when={ !published && connected && privateViewMode }>
          <Icon
            className={ this.cn`__icon __icon--hidden` }
            size={ 22 }
            type="eye-slash"
          >
            Hidden
          </Icon>
        </Render>
        <div
          className={ this.cn`__portrait` }
          style={ { backgroundImage: `url(${this.profilePicture})` } }
        />
        <div className={ this.cn`__name` }>
          { profile_name || 'Profile locked' }
        </div>
        <Render when={ privateViewMode || published }>
          <Render when={ privateViewMode && getErrorText(errors) }>
            <div className={ this.cn`__message` }>
              { getErrorText(errors) }
            </div>
          </Render>
          { created_date &&
            <div className={ this.cn`__age` }>
              <Icon
                className={ this.cn`__age-icon` }
                set="io"
                type="ios-timer-outline"
              >
                Account age
              </Icon>
              { getHumanReadableTimeDiff(created_date) }
            </div>
          }
          { typeof connections === 'number' &&
            <div className={ this.cn(connectionsClassName) }>
              <Icon
                className={ this.cn`__connections-icon` }
                set="io"
                type="android-people"
              >
                Number of connections
              </Icon>
              { connections }
            </div>
          }
        </Render>
        <div className={ this.cn`__overlay` }>
          <Render when={
            privateViewMode &&
            connected &&
            getErrorText(errors, 'hint')
          }
          >
            <div className={ this.cn`__hint` }>
              <p>{ getErrorText(errors, 'hint') }</p>
              <p>We will update your score the next time you share profile.</p>
            </div>
          </Render>
          <div className={ this.cn`__actions` }>
            <Render when={ connected }>
              { published &&
                <Button
                  className={ this.cn`__actions-item __actions-item--hide` }
                  theme="no-theme"
                  disabled={ !this.props.service.published }
                  onClick={ this.handleHideButtonClick }
                >
                  <span className={ this.cn`__actions-item-icon` }>
                    <Icon type="eye-slash" size={ 16 } />
                  </span>
                  Hide
                </Button>
              }
              { !published &&
                <Button
                  className={ this.cn`__actions-item __actions-item--show` }
                  theme="no-theme"
                  disabled={ this.props.service.published }
                  onClick={ this.handleShowButtonClick }
                >
                  <span className={ this.cn`__actions-item-icon` }>
                    <Icon type="eye" size={ 16 } />
                  </span>
                  Show
                </Button>
              }
              <Button
                className={ this.cn`__actions-item __actions-item--disconnect` }
                theme="no-theme"
                disabled={ this.props.service.used_during_signup }
                onClick={ this.handleDisconnectButtonClick }
              >
                <span className={ this.cn`__actions-item-icon` }>
                  <Icon type="chain-broken" size={ 16 } />
                </span>
                Disconnect
              </Button>
            </Render>
            <Render when={ !connected }>
              <Button
                className={ this.cn`__actions-item __actions-item--connect` }
                theme="no-theme"
                onClick={ this.handleAddConnectionButtonClick }
              >
                <span className={ this.cn`__actions-item-icon` }>
                  <Icon type="plus" set="io" size={ 16 } />
                </span>
                Add connection
              </Button>
            </Render>
          </div>
        </div>
        <Render when={ !published && connected && this.props.viewMode === 'PUBLIC' }>
          <HiddenLabel />
        </Render>
      </div>
    );
  }
}
