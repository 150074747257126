import React from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import ReactSelect from 'react-select';
import BaseComponent from 'components/BaseComponent';

export default class Select extends BaseComponent {
  className = 'ts-Select';

  static propTypes = {
    async: PropTypes.bool,
    modifierClassName: PropTypes.string,
    displayName: PropTypes.string,
    isValid: PropTypes.oneOf([true, false, null]),
    loadingPlaceholder: PropTypes.string,
    stateLink: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    displayName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isValid: props.isValid,
    };
  }

  get value() {
    const { stateLink } = this.props;
    return this.props.value || stateLink[0].state[stateLink[1]];
  }

  setValid = (status, invalidMessage = '') => { // public
    this.setState({
      isValid: status,
      invalidMessage: status ? false : invalidMessage,
    });
  };

  handleChange = (newValue) => {
    const { stateLink } = this.props;
    if (stateLink) {
      stateLink[0].setState({
        [stateLink[1]]: newValue ? newValue.value : null,
      });
    }

    this.setValid(null);

    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  componentDidMount() {
    this.setParentDanger();
  }

  componentDidUpdate() {
    this.setParentDanger();
  }

  setParentDanger() {
    // bootstrap-specific:
    // Bootstrap requires input parent to have has-danger class.
    const { classList } = findDOMNode(this.refs.input).parentNode;
    const method = this.state.isValid === false ? 'add' : 'remove';
    classList[method]('has-danger');
    classList[this.state.isValid ? 'add' : 'remove']('has-success');
  }

  renderLabel() {
    if (!this.props.displayName) return null;

    return (
      <label className={ this.cn`__label` }>
        { this.props.displayName }
      </label>
    );
  }

  focus() { // public
    this.refs.input.focus();
  }

  render() {
    const Component = this.props.async ? ReactSelect.Async : ReactSelect;
    const selectClassNames = {
      '__field': true,
      '__field--success': this.state.isValid,
      '__field--danger': this.state.isValid === false,
    };
    const rootClassName = this.props.modifierClassName ? `--${this.props.modifierClassName}` : '';
    const { value } = this;
    const propsToOmit = [
      'async', 'isValid', 'stateLink', 'modifierClassName', 'onChange', 'value', 'wrapperOnClick',
    ];
    return (
      <div
        className={ this.rootcn(rootClassName) }
        data-toggle={ this.state.invalidMessage ? 'tooltip' : '' }
        title={ this.state.invalidMessage || this.props.title }
        onClick={ this.props.wrapperOnClick || noop }
        tabIndex={ this.props.wrapperOnClick ? 1 : -1 }
        role="button"
      >
        { this.renderLabel() }
        <Component
          cache={ false }
          onChange={ this.handleChange }
          ref="input"
          searchPromptText="boo"
          value={ value }
          { ...omit(this.props, propsToOmit) }
          className={ this.cn(selectClassNames) }
          tabIndex={ this.props.disabled ? '-1' : this.props.tabIndex }
        />
      </div>
    );
  }
}
