import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import PaymentCreditCardDialog from 'components/PaymentCreditCardDialog';
import PaymentPayPalButton from 'components/PaymentPayPalButton';
import analytics from 'models/analytics';
import deviceModel from 'models/device';

export default class Payment extends BaseComponent {
  className = 'ts-Payment';

  state = {};

  static propTypes = {
    onSuccess: PropTypes.func, // payment successfully finished
    onError: PropTypes.func, // error in validation or payment
    onReady: PropTypes.func, // payment widget ready
    onSubmit: PropTypes.func, // option button clicked (braintree requires submit button in <form>)
    disableAnalytics: PropTypes.bool,
    disabled: PropTypes.bool,
    amount: PropTypes.number,
    theme: PropTypes.oneOf(['horizontal', 'vertical']),
  };

  static defaultProps = {
    onSuccess: noop,
    onError: noop,
    onReady: noop,
    onSubmit: noop,
    disableAnalytics: false,
    disabled: false,
    theme: 'vertical',
  };

  handleCreditCardButtonClick = () => {
    this.cardPaymentDialog.toggle(true);
  }

  componentDidMount() {
    this.syncStateWithModel(deviceModel, ['xxsmUp']);
  }

  handlePaymentPayPalButtonSuccess = () => {
    this.props.onSuccess();

    if (!this.props.disableAnalytics) {
      analytics.trackEvent('WEBAPP_CREDITS_ADDED', {
        amount: this.props.amount,
        type: 'paypal',
      });
    }
  };

  render() {
    const cn = this.cn();
    const { disableAnalytics } = this.props;

    return (
      <div className={ this.rootcn(`--theme-${this.props.theme}`) }>
        <PaymentPayPalButton
          amount={ this.props.amount }
          disabled={ this.props.disabled }
          onSuccess={ this.handlePaymentPayPalButtonSuccess }
          onError={ this.props.onError }
        />
        <div className={ cn`__or` }>or</div>
        <Button
          onClick={ this.handleCreditCardButtonClick }
          disabled={ this.props.disabled }
        >
          Use your credit card
        </Button>
        <Dialog
          align={ { points: ['tc', 'tc'], offset: [0, 140] } }
          className={ cn`__card-payment-dialog` }
          ref={ (cardPaymentDialog) => this.cardPaymentDialog = cardPaymentDialog }
          size="small"
          title="Payment information"
        >
          <PaymentCreditCardDialog
            amount={ this.props.amount }
            onSuccess={ () => {
              this.cardPaymentDialog.toggle(false);
              this.props.onSuccess();

              if (!disableAnalytics) {
                analytics.trackEvent('WEBAPP_CREDITS_ADDED', {
                  amount: this.props.amount,
                  type: 'card',
                });
              }
            } }
          />
        </Dialog>
      </div>
    );
  }
}
