import React from 'react';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import payment from 'models/payment';
import user from 'models/user';
import wallet from 'models/wallet';
import ValidationInput from 'sf/components/ValidationInput';

export default class DiscountCodesForm extends BaseComponent {
  className = 'ts-DiscountCodesForm';

  state = {
    discountCode: '',
  }

  handleSubmitCode = (e) => {
    e.preventDefault();
    const success = (responseText) => {
      this.hideError();
      this.publish('showFloatingText', {
        text: responseText || 'Discount code enabled',
        autoHide: true,
      });
      user.getProfileData();
      wallet.getAccountBalance();
    };
    const fail = ({ statusCode, message }) => {
      switch (statusCode) {
        case 404:
          this.setState({
            // eslint-disable-next-line
            error: 'The discount code you entered is invalid or expired. Please \
check it again or enter a new one',
          });
          break;
        case 400:
          this.setState({
            error: message || 'Please enter a discount code',
          });
          break;

        default:
          this.setState({
            error: 'Unknown error',
          });
          break;
      }
    };

    payment.useDiscountCode(this.state.discountCode).then(success, fail);
  }

  hideError = () => {
    this.setState({
      error: false,
    });
  }

  render() {
    return (
      <div
        { ...this.props }
        className={ this.rootcn() }
      >
        <form onSubmit={ this.handleSubmitCode }>
          <p className={ this.cn`__muted-text` }>
            Enter your discount code (case sensitive)
          </p>

          <ValidationInput
            className={ this.cn`__code-input` }
            placeholder="Discount code"
            stateLink={ [this, 'discountCode'] }
            autoFocus={ true }
            onChange={ this.hideError }
          />

          { this.state.error ?
            <p className={ this.cn`__error-text` }>
              { this.state.error }
            </p> : null
          }

          <Button type="submit">
            Save
          </Button>
        </form>
      </div>
    );
  }
}
