import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledFooter,
} from 'components/StyledForm';
import user from 'models/user';
import Input from 'sf/components/Input';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLink = compose(
  withStateLink,
  withValidation,
)(Input);

export default class ResetPasswordEmail extends BaseComponent {
  className = 'ts-ResetPasswordEmail';

  state = {
    email: undefined,
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = await this.formValidation(
      user.set({
        reset_password_email: this.state.email,
      })
    )
      .catch(() => false);

    if (!isFormValid) return;

    await this.props.onSubmit();
  }

  render() {
    return (
      <StyledForm
        centered={ true }
        width="narrow"
      >
        <form
          onSubmit={ this.handleSubmit }
        >
          <div className={ this.cn`__email` }>
            <StyledLabel>Your email used in registration</StyledLabel>
            <StyledInput>
              <ValidationInputWithStateLink
                autocomplete="off"
                placeholder="Your email"
                ref="email"
                stateLink={ [this, 'email'] }
              />
            </StyledInput>
            <StyledFooter>
              <Button
                disabled={ !this.state.email }
                type="submit"
              >
                Reset your password
              </Button>
            </StyledFooter>
          </div>
        </form>
      </StyledForm>
    );
  }
}
