import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default function withValidation(ComposedComponent) {
  return class Validation extends BaseComponent {
    className = 'ts-Validation';

    state = {
      isValid: true,
    };

    static propTypes = {
      className: PropTypes.any,
      onChange: PropTypes.func,
    };

    static defaultProps = {
      className: null,
      onChange: noop,
    };

    handleChange = (value) => {
      this.setValid(true);
      this.props.onChange(value);
    };

    setValid = (status, invalidMessage) => { // public
      this.setState({
        isValid: status,
        invalidMessage: status ? false : invalidMessage,
      });
    };

    render() {
      const classNames = {
        [this.props.className]: this.props.className,
        '--with-error': !this.state.isValid,
      };
      return [
        <ComposedComponent
          { ...this.props }
          className={ this.rootcn(classNames) }
          key="input"
          onChange={ this.handleChange }
        />,
        !this.state.isValid &&
          <div
            className={ this.cn`__hint __hint--error` }
            key="hint"
          >
            { this.state.invalidMessage }
          </div>,
      ];
    }
  };
}
