import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import InfoBox from 'components/InfoBox';
import Render from 'components/Render';
import { ROLES, ROUTES } from 'constants';
import { trackEvent } from 'models/analytics';
import user from 'models/user';
import { getDate } from 'sf/helpers';
import Icon from 'sf/components/Icon';

const STATUS_NAME = {
  'registered': 'Registered',
  'not_registered': 'Didn’t register',
  'rejected': 'Rejected',
  'no_access': 'User registered but did not publish profile',
  'not_invited': 'Lead Accelerator™ data only',
};

export default class InvitationList extends BaseComponent {
  className = 'ts-InvitationList';

  static propTypes = {
    invitations: PropTypes.array,
  };

  static defaultProps = {
    invitations: [],
  };

  state = {
    resentVerificationRequests: [],
  }

  componentDidMount() {
    this.syncStateWithModel(user, ['wallet_insight_terms_accepted']);
  }

  handleAskAgainButtonClick = (url) => () => {
    user.postConsecutiveVerificationRequest(url).then(() => {
      const resentVerificationRequests = this.state.resentVerificationRequests.concat([url]);
      this.setState({
        resentVerificationRequests,
      });
    });
  }

  handleViewEquifaxProfile = (id) => () => window.open(
    `${ROUTES.WALLET_INSIGHT_PROFILE}?id=${id}`,
    '_blank',
  );

  handleEvaluteEquifaxProfile = (id, { relative_short_url, view_profile_url } = {}) => {
    const accessTokenURLComponent = relative_short_url || view_profile_url
      ? `&accessToken=${this.getProfileAccessToken(relative_short_url, view_profile_url)}`
      : '';

    return () => this.navigate(`${ROUTES.WALLET_INSIGHT_FORM}?id=${id}${accessTokenURLComponent}`);
  };

  getProfileAccessToken = (relative_short_url, view_profile_url) => {
    if (user.get('isSignedIn') && user.hasRole(ROLES.BUSINESS_USER)) {
      // HACK: this should be provided by backend, but after struggle with deadlines,
      // it's managed by frontend :(
      /* eslint-disable max-len */
      return `/backend/view/${relative_short_url}/full/`;
      /* eslint-enable */
    }

    return view_profile_url;
  }

  handleViewProfile = (relative_short_url, view_profile_url) => () => {
    if (global.REALTOR) {
      trackEvent('REALTOR_ACCESS_PROFILE');
    }
    const accessToken = this.getProfileAccessToken(relative_short_url, view_profile_url);
    this.navigate(`/${relative_short_url}-profile.html?accessToken=${accessToken}`);
  }

  renderTableHead() {
    return (
      <div className={ this.cn`__item __item--head` }>
        <div className={ this.cn`__user-data` }>
          <div className={ this.cn`__details` }>
            User
          </div>
          <div className={ this.cn`__type` }>
            Current result
          </div>
          <div className={ this.cn`__date` }>
            Request date
          </div>
        </div>
        <div className={ this.cn`__actions` } />
      </div>
    );
  }

  renderRow = ({
    has_wallet_insight,
    invited_info,
    invitation_key,
    modified,
    relative_short_url,
    resend_url,
    status,
    view_profile_url,
  }) => {
    const { resentVerificationRequests } = this.state;
    const verificationRequestsResent = resentVerificationRequests.includes(resend_url);
    return (
      <div className={ this.cn`__item` } key={ view_profile_url }>
        <div className={ this.cn`__user-data` }>
          <div className={ this.cn`__details` } data-title="User">
            <div className={ this.cn`__name` }>
              <div className={ this.cn`__icon-user` }>
                <Icon
                  set="io"
                  type="person"
                />
              </div>
              { invited_info.legal_name || invited_info.contact }
            </div>
            <Render when={ invited_info.legal_name }>
              <div className={ this.cn`__email` }>{ invited_info.contact }</div>
            </Render>
            <Render when={ status === 'registered' }>
              <Button
                className={ this.cn`__view-profile` }
                theme="secondary"
                onClick={ this.handleViewProfile(relative_short_url, view_profile_url) }
              >
                View profile
              </Button>
            </Render>
            <Render
              when={ status === 'not_registered' }
            >
              { !verificationRequestsResent &&
                <Button
                  className={ this.cn`__ask-again` }
                  theme="secondary"
                  onClick={ this.handleAskAgainButtonClick(resend_url) }
                >
                  Ask again
                </Button>
              }
              { verificationRequestsResent &&
                <div className={ this.cn`__request-resent-info` }>
                  Request resent
                </div>
              }
            </Render>
          </div>
          <div
            className={ this.cn(`__type __type--${status.toLowerCase()}`) }
            data-title="Current result"
          >
            <Icon
              className={ this.cn`__icon` }
              set="io"
              size={ 16 }
              type={ ['registered', 'not_invited'].includes(status) ? 'checkmark' : 'close' }
            />
            { STATUS_NAME[status] }
          </div>
          <div className={ this.cn`__date` } data-title="Request date">
            { getDate(modified) }
          </div>
        </div>
        <div className={ this.cn`__actions` }>
          { this.state.wallet_insight_terms_accepted &&
            this.renderWalletInsightButton(
              has_wallet_insight,
              invitation_key,
              { relative_short_url, view_profile_url }
)
          }
        </div>
      </div>
    );
  }

  renderWalletInsightButton = (has_wallet_insight, invitation_key, profile_url) => {
    return has_wallet_insight
      ? <Button
          className={ this.cn`__view-profile` }
          theme="secondary"
          onClick={ this.handleViewEquifaxProfile(invitation_key) }
      >
        View Lead Accelerator™
      </Button>
      : <Button
          className={ this.cn`__view-profile` }
          theme="secondary"
          onClick={ this.handleEvaluteEquifaxProfile(invitation_key, profile_url) }
      >
        Evaluate Lead Accelerator™
      </Button>;
  }

  render() {
    const { invitations } = this.props;

    if (!invitations) return null;
    return (
      <div className={ this.rootcn() }>
        <Render when={ invitations.length > 0 }>
          { this.renderTableHead() }
          { invitations.map(this.renderRow) }
        </Render>
        <Render when={ invitations.length === 0 }>
          <InfoBox icon="paper-airplane">
            Send your first invitation
          </InfoBox>
        </Render>
      </div>
    );
  }
}
