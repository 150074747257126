import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import ValidationTextarea from 'components/ValidationTextarea';
import { ROUTES } from 'constants';
import contact from 'models/contact';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';

/* eslint-disable max-len */
const SUBTITLES = {
  social: 'Please contact us with any questions regarding the campaign or to recover your lost discount code.',
  default: 'We will respond within 24 hours.',
};
/* eslint-enable max-len */

export default class ContactUs extends BasePage {
  className = 'ts-ContactUs';
  title = 'Get in touch';

  static propTypes = {
    // Contact form is sent to [sales@truststamp.net, support@truststamp.net, social@truststamp.net]
    recipient: PropTypes.oneOf(['sales', 'support', 'social']),
  };

  static defaultProps = {
    recipient: 'support',
  };

  state = {
    email: user.get('email'),
    name: user.get('legal_name'),
    isMessageSent: false,
  };

  constructor(props) {
    super(props);
    this.setSubtitle(_get(props, 'route.recipient') || props.recipient);
  }

  componentDidMount() {
    this.subscribe('router:navigate', (route) => {
      if (route === ROUTES.CONTACT_US) {
        this.setState({
          isMessageSent: false,
        });
      }
    });
    contact.set({
      email: user.get('email'),
      name: user.get('legal_name'),
    });
    this.syncStateWithModel(contact, ['name', 'email', 'message']);
  }

  componentWillUpdate(nextProps) {
    this.recipient = _get(nextProps, 'route.recipient') || nextProps.recipient;
    this.setSubtitle(this.recipient);
  }

  setSubtitle = (recipient) => {
    this.subtitle = SUBTITLES[recipient] || SUBTITLES.default;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.refs.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.refs.submitButton, false);
    };

    this.formValidation(
      contact.set(this.state)
    ).then(() => {
      contact.sendMessage(this.recipient)
        .then(() => {
          this.setState({
            isMessageSent: true,
          });
          contact.set('message', '');
          hideProcessing();
        })
        .catch(() => {
          this.publish('showFloatingText', {
            text: 'Error sending message. Please try again.',
            isValid: false,
          });
          hideProcessing();
        });
    }, hideProcessing);
  }

  renderContent() {
    if (this.state.isMessageSent) {
      return (
        <p className={ this.cn`__info` }>
          Your message has been successfully sent. We will reply as soon as possible.
        </p>
      );
    }
    const { email, message, name } = this.state;
    const isFormValid = email && message && name;
    return (
      <form
        onSubmit={ this.handleSubmit }
        className={ this.cn`__form` }
      >
        <div className="row">
          <div className="col-md-6">
            <ValidationInput
              ref="name"
              type="text"
              placeholder="Name"
              stateLink={ [this, 'name'] }
            />
          </div>
          <div className="col-md-6">
            <ValidationInput
              ref="email"
              type="text"
              placeholder="E-mail"
              stateLink={ [this, 'email'] }
            />
          </div>
        </div>
        <ValidationTextarea
          ref="message"
          rows={ 8 }
          placeholder="Message"
          stateLink={ [this, 'message'] }
        />
        <div className={ this.cn`__button` }>
          <Button
            disabled={ !isFormValid }
            type="submit"
            mainAction={ true }
            ref="submitButton"
          >
            Send message
          </Button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <PageTitle
          className={ this.cn`__page-title` }
          title={ this.title }
          subtitle={ this.subtitle }
        />
        { this.renderContent() }
      </div>
    );
  }
}
