import atom from 'atom-js';
import debounce from 'lodash/debounce';
import { FEEDBACKS } from 'sf/components/Webcam/constants';

const DEBOUNCE_TIME = 500;

const modelName = 'webcamModel';

const debounceMap = {};
const feedbackFunctionMap = {};
const modelFeedbackFunctionMap = {};

const model = atom.setup({
  modelName: modelName,
  methods: {
    addRects(resolve, reject, rects) {
      const rectNames = rects.map(({ name }) => name);
      if (!rects || !rects.every((rect) => rect.name && rect.coords && rect.color)) {
        throw new Error('Invalid rect config');
      }
      model.set({
        rects: [
          ...model.get('rects').filter(({ name }) => !rectNames.includes(name)),
          ...rects,
        ],
      });
      rectNames.forEach((setName) => {
        clearTimeout(debounceMap[setName]);
        debounceMap[setName] = setTimeout(() => model.set({
          rects: [
            ...model.get('rects').filter(({ name }) => name !== setName),
          ],
        }), DEBOUNCE_TIME);
      });
    },
  },
})({
  rects: [],
  overlaySize: {},
});

Object.keys(FEEDBACKS).forEach((feedback) => {
  const toDebounceMethodName = `${feedback}ToDebounce`;
  const debouncedMethodName = `${feedback}Debounced`;

  feedbackFunctionMap[toDebounceMethodName] = () => model.set({ [feedback]: false });
  feedbackFunctionMap[debouncedMethodName] =
    debounce(feedbackFunctionMap[toDebounceMethodName], DEBOUNCE_TIME);

  modelFeedbackFunctionMap[`${feedback}Feedback`] = () => {
    model.set({ [feedback]: true });
    feedbackFunctionMap[debouncedMethodName]();
  };
});

export default {
  ...model,
  ...modelFeedbackFunctionMap,
};
