import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import device from 'models/device';

export default class Card extends BaseComponent {
  className = 'ts-Card';

  state = {};

  static propTypes = {
    fullWidth: PropTypes.bool,
    size: PropTypes.oneOf(['big', 'small']),
  };

  static defaultProps = {
    fullWidth: false,
    size: 'big',
  };

  componentDidMount() {
    this.syncStateWithModel(device, ['xsmUp']);
  }

  render() {
    const { size } = this.props;
    const fullWidth = this.props.fullWidth && !this.state.xsmUp;
    const classNames = {
      [`--${size}`]: size,
      '--full-width': fullWidth,
    };
    return (
      <div
        className={ this.rootcn(classNames) }
      >
        { this.props.children }
      </div>
    );
  }
}
