import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class InfoBox extends BaseComponent {
  className = 'ts-InfoBox';

  static propTypes = {
    children: PropTypes.node,
    icon: PropTypes.string,
  };

  static defaultProps = {
    children: '',
    icon: null,
  };

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__info` }>
          <Render when={ this.props.icon }>
            <div className={ this.cn`__icon` }>
              <Icon set="io" type={ this.props.icon } size={ 18 } />
            </div>
          </Render>
          <div className={ this.cn`__text` }>
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }
}
