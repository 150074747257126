import React from 'react';
import BaseComponent from 'components/BaseComponent';
import webcam from 'sf/models/webcam';

export default class WebcamRects extends BaseComponent {
  className = 'ts-WebcamRects';

  state = {
    rects: [],
  };

  componentDidMount() {
    this.syncStateWithModel(webcam, ['rects']);
  }

  render() {
    return (
      <div
        { ...this.pickProps() }
        className={ this.rootcn() }
      >
        <div className={ this.cn`__wrapper` }>
          { (this.state.rects)
            .map(({ color, coords, name }) => {
              const [left, top, width, height] = coords;
              return (
                <div
                  key={ name }
                  style={ {
                    ...color ? { border: `2px solid ${color}` } : {},
                    position: 'absolute',
                    top: `${top}%`,
                    left: `${left}%`,
                    height: `${height}%`,
                    width: `${width}%`,
                  } }
                />
              );
            }) }
        </div>
      </div>
    );
  }
}
