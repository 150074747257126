import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class AdminPanelVerificationView extends BaseComponent {
  className = 'ts-AdminPanelVerificationView';

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className={ this.rootcn() } >
        AdminPanelVerificationView
      </div>
    );
  }
}

export default AdminPanelVerificationView;
