import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export const THEMES = {
  danger: 'danger',
  default: 'default',
};

const withTheme = (ComposedComponent) => {
  return class Theme extends BaseComponent {
    className = 'ts-Theme';

    static propTypes = {
      className: PropTypes.any,
      theme: PropTypes.oneOf(Object.values(THEMES)),
    };

    static defaultProps = {
      className: null,
      theme: THEMES.default,
    };

    render() {
      const { className, theme } = this.props;
      const classNames = {
        [className]: className,
        [`--${theme}`]: theme,
      };
      return (
        <ComposedComponent
          { ...this.props }
          className={ this.rootcn(classNames) }
        />
      );
    }
  };
};

export default withTheme;
