/* eslint-disable max-len */
import React from 'react';
import omit from 'lodash/omit';
import webcam from 'mighty-webcamjs';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import BaseComponent from 'components/BaseComponent';
import Button from 'sf/components/Button';
import VideoCapture from 'sf/components/VideoCapture';
import { COOLDOWN_TIMEOUT } from 'sf/components/Webcam';
import { waitFor } from 'sf/helpers';
import { toggleFullScreen } from 'sf/helpers/domHelper';
import { renderTemplate } from 'sf/helpers/react';
import onFullRender from 'sf/hoc/OnFullRender';
import {
  ACTION_CLICK,
  ACTION_TAP,
  TAKE_VIDEO_BUTTON,
} from 'sf/l10n';
import { HELP_MESSAGE_CAMERA_NOT_WORKING } from 'sf/messages';
import SwitchCameraButton from 'mighty-webcamjs/components/SwitchCameraButton';
import FlashLightButton from 'mighty-webcamjs/components/FlashLightButton';


// eslint-disable-next-line no-console
const logErr = (...args) => console.error('VideoCapture error', ...args);

/**
 * VideoCaptureButton works similar to VideoCapture but instead of showing preview right away,
 * displays only button. When button is clicked, full-screen video capture app is showed.
 */
class VideoCaptureButton extends BaseComponent {
  className = 'ts-VideoCaptureButton';

  // communicationChannel is important in case of having multiple webcam components attached
  // when using with iOS.
  static communicationChannel = 0;

  state = {
    communicationChannel: ++VideoCaptureButton.communicationChannel,
    hasCameraError: false,
    isPrivacyPolicyPopupOpen: false,
  };

  static propTypes = {
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    onClick: PropTypes.func,
    onVideoTaken: PropTypes.func,
    outlined: PropTypes.bool,
    /**
     * one of:
     * 'barcode', 'face', 'id', 'id-back', 'id-front', 'id-front-auto', ''
     */
    overlay: PropTypes.string,
    /**
     * Button "pill" type
     */
    pill: PropTypes.bool,
    /**
     * Params passed to a webcam library directly.
     * Available webcamParams (+ default values):
     *
     *  * read more below:
     *  * **cssPrefix: 'webcamjs',** // prefix for all css classes
     *  * **dest_height: 1600,** // these default to width/height
     *  * **dest_width: 1600,** // size of captured image.
     *  * **enable_file_fallback: true,**
     *  * **flip_horiz: false,** // flip image horiz (mirror mode)
     *  * **flip_horiz_back: undefined,** // flip image horizontally on both: front and back camera
     *  * **force_file: false,** // force file upload mode
     *  * **force_fresh_photo: false,** // When true, it will disallow to Select Photo from Library on iOS
     *  * **image_format: 'jpeg',** // image format (may be jpeg or png)
     *  * **jpeg_quality: 90,** // jpeg image quality from 0 (worst) to 100 (best)
     *  * **output_format: 'canvas',** // 'base64', 'canvas', 'blob'
     *  * **use_ImageCapture: true,** // use experimental ImageCapture API if available
     *  * **use_ImageCapture_flashlight: false,** // use flashlight, part of ImageCapture API if available
     *  * **use_ImageCapture_grabFrame: false,** // use grabFrame, part of ImageCapture API (fallback to takePhoto)
     *  * **use_ImageCapture_takePhoto: true,** // use takePhoto, part of ImageCapture API if available
     *  * **verbose: false,** // Set to false to hide logs.
     *  * **webcam_path: './',** // URI to CSS file (defaults to the js location)
     *  * **switch_camera_node: 'Switch camera',** // Contents of "Switch Camera" button. React Node required
     *  * **flash_light_node: 'Enable Flash Light',** // React Node required
     *  * **no_interface_found_text: 'No supported webcam interface found.',**
     */
    webcamParams: PropTypes.object,
    viewFinderText: PropTypes.node,
    forceFallbackUpload: PropTypes.bool,
    /**
     * - simple: 4, 3, 2, 1... Recording
     * - face: Recording starts when face is visible in the overlay.
     * - disabled: User have to take a video manually.
     */
    countDownMode: PropTypes.oneOf([
      'simple',
      'face',
      'disabled',
    ]),
    /**
     * displayPrivacyPolicyPopup is used to display Privacy policy dialog in the beginning of verification flow
     */
    displayPrivacyPolicyPopup: PropTypes.bool,
    privacyPolicyDialogComponent: PropTypes.func,
  };

  static defaultProps = {
    countDownMode: 'simple',
    disabled: false,
    forceFallbackUpload: false,
    maxLength: 0, // milliseconds
    onClick: noop,
    onVideoTaken: noop,
    outlined: true,
    overlay: '',
    pill: true,
    viewFinderText: '',
    webcamParams: {},
    displayPrivacyPolicyPopup: false,
    privacyPolicyDialogComponent: noop,
  };

  componentDidMount() {
    this.syncStateWithModel(webcam.params, ['load', 'live']);
  }

  handleClick = () => {
    this.props.onClick();
    if (this.shouldForceFallback()) {
      const container = findDOMNode(this);
      if (container) {
        const input = container.querySelector('input[type=file]');
        if (input) input.click();
      }
    } else {
      this.refs.videoCapture.toggle(true);
    }
  };

  click = () => {
    // OL-951
    waitFor(() => !this.shouldBeDisabled(), {
      timeout: this.state.hasCameraError ? 0 : COOLDOWN_TIMEOUT,
      interval: 250,
    })
      .then(() => { this.handleClick(); })
      .catch(() => {
        this.publish('showHelp', HELP_MESSAGE_CAMERA_NOT_WORKING());
      });
  };

  shouldForceFallback() {
    return this.props.forceFallbackUpload
      || (is.mobile() || is.tablet()) && (!global.MediaRecorder || this.state.hasCameraError);
  }

  renderUploadFallback() {
    // - iOS uses file fallback because of lack of WebRTC or flash support
    // - iOS 11 supports WebRTC but MediaRecorder is not supported
    // - Some Android browsers ("Browser" and "Samsung Browser") does not support
    //   MediaRecorder API, so camera is limited to photo capture only.
    //   In this case we use file fallback as in iOS.

    if (this.shouldForceFallback()) {
      // input covers button
      webcam.set({
        capture_mode: webcam.constants.CAPTURE_MODE_VIDEO,
        camera: webcam.constants[this.props.overlay === 'face' ? 'CAM_FRONT' : 'CAM_BACK'],
      });

      return webcam.getUploadFallbackNode(`vid-${this.state.communicationChannel}`);
    }

    return null;
  }

  handleVideoCaptureBackButtonClick = () => {
    this.refs.videoCapture.toggle(false);
  };

  handleCameraError = (err) => {
    this.setState({ hasCameraError: true });

    // eslint-disable-next-line no-console
    console.log('Camera Error', err);

    return false; // error handled, prevent default error handler
  };

  handleToggle = async (isShowed) => {
    if (is.mobile() || is.tablet()) {
      return toggleFullScreen(isShowed);
    }
  };

  renderVideoCapture() {
    return (
      <VideoCapture
        ref="videoCapture"
        allowRetake={ false }
        fullScreen={ true }
        maxLength={ this.props.maxLength }
        onBackButtonClick={ this.handleVideoCaptureBackButtonClick }
        onError={ logErr }
        onToggle={ this.handleToggle }
        onVideoTaken={ this.props.onVideoTaken }
        overlay={ this.props.overlay }
        countDownMode={ this.props.countDownMode }
        webcamParams={ {
          ...this.props.webcamParams,
          viewFinderText: this.props.viewFinderText,
          communicationChannel: `vid-${this.state.communicationChannel}`,
          onError: this.handleCameraError,
          userMedia: !this.shouldForceFallback(),
          controls: false,
        } }
      >
        <SwitchCameraButton webcam={ webcam } model={ webcam.params } />
        <FlashLightButton webcam={ webcam } model={ webcam.params } />
      </VideoCapture>
    );
  }

  shouldBeDisabled() {
    if (this.props.disabled) return true;

    return this.shouldForceFallback()
      ? false
      : global.MediaRecorder && (!this.state.load || !this.state.live);
  }

  handleCancelConsent = () => {
    this.setState({ isPrivacyPolicyPopupOpen: false })
  }

  renderPrivacyPolicyDialog = () => {
    if (!this.state.isPrivacyPolicyPopupOpen) return null

    return (
      this.props.privacyPolicyDialogComponent({
        handleAcceptConsent: this.handleClick,
        handleCancelConsent: this.handleCancelConsent
      })
    )
  }

  handleInitialClick = () => {
    if (this.props.displayPrivacyPolicyPopup) {
      this.setState({ isPrivacyPolicyPopupOpen: true })
    } else {
      this.handleClick();
    }
  }

  render() {
    /* eslint-disable object-curly-newline */
    const actionText = this.props.children || renderTemplate(TAKE_VIDEO_BUTTON)({
      actionName: (is.mobile() || is.tablet()) ? ACTION_TAP : ACTION_CLICK,
    });
    /* eslint-enable object-curly-newline */

    return (
      <div
        className={ this.rootcn`` }
      >
        <Button
          theme="action"
          { ...omit(
            this.props,
            Object.keys(VideoCaptureButton.defaultProps)
          ) }
          className={ this.cn`__button` }
          outlined={ this.props.outlined }
          pill={ this.props.pill }
          onClick={ this.handleInitialClick }
          disabled={ this.shouldBeDisabled() }
        >
          { actionText }
        </Button>
        { this.renderPrivacyPolicyDialog() }
        { this.renderVideoCapture() }
        { this.renderUploadFallback() }
      </div>
    );
  }
}

export default onFullRender(VideoCaptureButton, ['click']);
