import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import NativeSelect from './NativeSelect';
import { REACT_SELECT_THEME } from './constants';

let ReactSelect = () => <div />; // SSR
if (typeof window !== 'undefined') {
  ReactSelect = require('react-select').default;
}

/**
 * React-select 3.2.0:
 * https://github.com/JedWatson/react-select/blob/45f6f292b44e434fe6286e6017e43245528b72fb/packages/react-select/src/Select.js
 */

export default class Select extends BaseComponent {
  className = 'ts-Select';

  static propTypes = {
    async: PropTypes.bool,
    convertBooleans: PropTypes.bool,
    description: PropTypes.string,
    disabled: PropTypes.bool,
    displayName: PropTypes.string,
    error: PropTypes.string,
    hint: PropTypes.node,
    isClearable: PropTypes.bool,
    isValid: PropTypes.oneOf([true, false, null]),
    isValidatedByProp: PropTypes.bool,
    loadingPlaceholder: PropTypes.string,
    /**
     * loadOptionsOnce is similar to `loadOptions`` available in `async={ true }`
     * but it's triggered only one time: at component mount.
     */
    loadOptionsOnce: PropTypes.func,
    modifierClassName: PropTypes.string,
    nativeOnMobile: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.func,
    onOpen: PropTypes.func,
    options: PropTypes.array,
    placeholderAlwaysVisible: PropTypes.bool,
    stateLink: PropTypes.array,
    wrapperOnClick: PropTypes.func,
  };

  static defaultProps = {
    convertBooleans: false,
    description: '',
    displayName: '',
    error: '',
    isClearable: true,
    isValidatedByProp: false,
    loadOptionsOnce: noop,
    nativeOnMobile: false,
    onBlur: noop,
    onChange: noop,
    onClose: noop,
    onFocus: noop,
    onOpen: noop,
    placeholderAlwaysVisible: true,
    wrapperOnClick: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      isValid: props.isValid,
      value: props.value,
    };
  }

  get value() {
    const [context, stateField] = this.props.stateLink || [];
    return this.props.value
      || (context ? context.state[stateField] : this.state.value);
  }

  set value(value) {
    this.setState({ value });
  }

  componentDidUpdate() {
    this.displayErrorIfNeeded();
  }

  componentDidMount() {
    if (this.props.loadOptionsOnce !== noop) {
      this.props.loadOptionsOnce(this.value, ({ options }) => {
        this.setState({ options });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ('value' in nextProps) {
      this.value = nextProps.value;
    }
  }

  displayErrorIfNeeded() {
    if (!this.props.isValidatedByProp) return;
    if (this.props.error) {
      this.setValid(false, this.props.error);
    } else {
      this.setValid(true);
    }
  }

  setValid = (status, invalidMessage = '') => { // public
    this.setState({
      isValid: status,
      invalidMessage: status ? false : invalidMessage,
    });
  };

  handleBlur = (e) => {
    if (is.iOS()) {
      this.setState({ isMenuOpen: false });
    }
    this.setState({ placeholderVisible: !!this.props.placeholderAlwaysVisible });
    this.props.onBlur(e);
  };

  handleChange = (newValue) => {
    const { stateLink } = this.props;
    const newValueProcessed = newValue === null
      ? { value: null }
      : newValue;

    const callback = () => {
      this.setValid(null);
      this.props.onChange(newValueProcessed);
    };

    if (stateLink) {
      stateLink[0].setState({
        [stateLink[1]]: newValueProcessed.value,
      }, callback);
    } else {
      this.setState({ value: newValueProcessed.value }, callback);
    }
  };

  handleFocus = (e) => {
    if (is.iOS()) {
      this.setState({ isMenuOpen: true }); // FIX for iOS sometimes not opening the dropdown
    }


    this.setState({ placeholderVisible: true });
    this.props.onFocus(e);
  };

  renderLabel() {
    if (!this.props.displayName) return null;

    return (
      <label
        id={ `${this.className}-${this.componentId}-label` }
        htmlFor={ `${this.className}-${this.componentId}-input` }
        className={ this.cn`__label` }
      >
        { this.props.displayName }
      </label>
    );
  }

  renderDescription() {
    if (!this.props.description) return null;

    return (
      <span role="definition" className={ this.cn`__description` }>
        { this.props.description }
      </span>
    );
  }

  renderHint() {
    let hintText = this.props.hint;
    let hasError = false;

    if (this.state.isValid === false && this.state.invalidMessage) {
      hasError = true;
      hintText = this.state.invalidMessage;
    }

    if (!hintText) {
      return null;
    }

    return (
      <div
        id={ `${this.className}-${this.componentId}-hint` }
        className={ this.cn({
          '__hint': true,
          '__hint--error': hasError,
        }) }
        ref="hint"
      >
        { hintText }
      </div>
    );
  }

  focus() { // public
    this.refs.input.focus();
  }

  render() {
    let Component = this.props.async
      ? ReactSelect.Async
      : ReactSelect;
    if (this.props.nativeOnMobile && (is.mobile() || is.tablet())) {
      Component = NativeSelect;
    }

    const selectClassNames = {
      '__field': true,
      '__field--success': this.state.isValid,
      '__field--danger': this.state.isValid === false,
    };
    const rootClassName = this.props.modifierClassName ? `--${this.props.modifierClassName}` : '';

    const options = this.state.options ? this.state.options : this.props.options;

    const value = options.find((option) => this.value === option.value);

    const placeholder = this.state.placeholderVisible ? this.props.placeholder : '';

    return (
      <div
        { ...this.pickProps(/* NOTE: ReactSelect does not accept data-* props */) }
        className={ this.rootcn(rootClassName) }
        data-toggle={ this.state.invalidMessage ? 'tooltip' : '' }
        title={ this.state.invalidMessage || this.props.title }
      >
        { this.renderLabel() }
        { this.renderDescription() }
        <Component
          aria-labelledby={ `${this.className}-${this.componentId}-label` }
          cacheOptions={ false }
          className={ this.cn(selectClassNames) }
          classNamePrefix="ts-Select"
          id={ `${this.className}-${this.componentId}-select-container` }
          inputId={ `${this.className}-${this.componentId}-input` }
          instanceId={ this.componentId }
          isClearable={ this.props.isClearable }
          isDisabled={ this.props.disabled }
          onBlur={ this.handleBlur }
          onChange={ this.handleChange }
          onFocus={ this.handleFocus }
          onMenuClose={ this.props.onClose }
          onMenuOpen={ this.props.onOpen }
          openAfterFocus={ true }
          options={ options }
          placeholder={ placeholder }
          ref="input"
          tabIndex={ this.props.disabled ? '-1' : this.props.tabIndex }
          theme={ REACT_SELECT_THEME }
          value={ value }
          openMenuOnFocus={ true }
          menuIsOpen={ this.state.isMenuOpen }
          aria-describedby={ `${this.className}-${this.componentId}-hint` }
        />
        { this.renderHint() }
      </div>
    );
  }
}
