import atom from 'atom-js';
import is from 'next-is';
import { get, errorHandler } from 'sf/helpers/request';
import user from 'models/user';

const model = atom.setup({
  methods: {
    getAccountBalance: (resolve, reject) => {
      get('backend/payment/balance/', 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { number_of_credits } = res.body.data;
            model.set('number_of_credits', number_of_credits);
            resolve();
          }
        });
    },
    getPaymentsHistoryList: (resolve, reject, paginatorPage = model.get('paymentsHistoryPage')) => {
      const page = parseInt(paginatorPage, 10);
      if (page < 1) {
        return;
      }
      get('backend/payment/history/', 'UNIVERSAL')
        .query({
          page,
          number_per_page: 10,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data, number_of_pages } = res.body.data;
            model.set({
              'paymentsHistoryPage': page,
              'paymentsHistoryPagesCount': number_of_pages,
              'paymentsHistoryList': data,
            });
            resolve();
          }
        });
    },
    getShareHistoryList: (resolve, reject, page = model.get('shareHistoryPage')) => {
      if (!is.isNumber(page) || page < 1) {
        return;
      }

      get('backend/profile/send_profile/history/', 'UNIVERSAL')
        .query({
          page,
          number_per_page: 8,
        })
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data } = res.body;
            model.set({
              shareHistoryList: data.data,
              shareHistoryPagesCount: data.number_of_pages,
            });
            resolve();
          }
        });
    },
  },
})({
  paymentsHistoryList: [],
  paymentsHistoryPage: null,
  paymentsHistoryPagesCount: null,
  shareHistoryList: [],
  shareHistoryPage: null,
  shareHistoryPagesCount: null,
});

model.on('shareHistoryPage', model.getShareHistoryList);
user.entangle(model, 'number_of_credits');

export default model;
