import React from 'react';
import is from 'next-is';
import BaseComponent from 'components/BaseComponent';
import { BRANDINGS } from 'constants';
import user from 'models/user';

export default function (ComposedComponent, { onAccessDenied } = {}) {
  return class Authorization extends BaseComponent {
    className = 'ts-Authorization';

    render() {
      const loginURL = BRAND_NAME === BRANDINGS.REALTOR
        ? '/business/LogInCredentials.html'
        : '/LogIn.html';

      const isSignedIn = user.get('isSignedIn');
      const next = this.props.route.path || '';
      if (isSignedIn === null) return null;
      if (isSignedIn) return <ComposedComponent { ...this.props } />;
      if (onAccessDenied) {
        onAccessDenied({
          returnUrl: next,
        });
      } else if (is.browser()) {
        this.navigate(`${loginURL}#next=${encodeURIComponent(next)}`);
      }
      return null;
    }
  };
}
