import React from 'react';
import { symbols } from 'sf/helpers';

export const STEPS = symbols('PIN', 'SELFIE', 'SOCIAL');

/* eslint-disable max-len */
export const DATA = {
  [STEPS.PIN]: {
    title: 'Reset your PIN number',
    subtitle: 'Enter 4 digits login PIN for TrustStamp. You will be using this number every time you log in',
  },
  [STEPS.SELFIE]: {
    title: 'Take a selfie',
    subtitle: {
      smUp: (
        <ul style={ { textAlign: 'left', margin: '0 auto', maxWidth: '400px' } }>
          <li>make sure that your face fits the light oval</li>
          <li>have optimal lighting -  consider turning on your lights or<br />
            moving closer to the window
          </li>
          <li>act natural!</li>
        </ul>
      ),
      default: (
        <p>Move the phone a few inches toward your face and act natural</p>
      ),
    },
  },
  [STEPS.SOCIAL]: {
    title: 'Choose a social media account connected to Trust Stamp',
    subtitle: 'You need to log in with Social Media to change your PIN',
  },
};
/* eslint-enable max-len */
