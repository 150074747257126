import React from 'react';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';

export function ActionBar({
  children,
  className,
  light,
  important,
  reversed,
  subtitle,
  title,
}) {
  const classNames = {
    [className]: className,
    '--important': important,
    '--light': light,
  };
  const actionsClassNames = {
    '__actions': true,
    '__actions--reversed': reversed,
  };

  return (
    <div className={ this.rootcn(classNames) }>
      { title &&
        <div className={ this.cn`__title ts-themed-color` }>
          { title }
        </div>
      }
      { children &&
        <div className={ this.cn(actionsClassNames) }>
          { children }
        </div>
      }
      { subtitle &&
        <div className={ this.cn`__subtitle` }>
          { subtitle }
        </div>
      }
    </div>
  );
}

ActionBar.propTypes = {
  children: PropTypes.node,
  important: PropTypes.bool,
  light: PropTypes.bool,
  reversed: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

ActionBar.defaultProps = {
  children: null,
  important: false,
  light: false,
  reversed: false,
  subtitle: null,
  title: null,
};

export default baseFunctionalComponent(ActionBar, 'ActionBar');
