/* eslint-disable max-len */
export const TERMS_OF_USE = `Equifax Lead Accelerator Services (“Services”) may only be accessed by authorized users. As an authorized user, you agree that you:

(i) will use these Services solely for the permitted purposes of lead segmentation and prioritization and/or verifying/updating the contact information of an individual, and not to determine the individual’s eligibility for credit, insurance, employment or any other product or service nor for any other purpose whatsever;
(ii) shall comply with any and all applicable laws, rules and regulations, including, but not limited to, adherence to fair and responsible housing and Equal Opportunity laws and regulations;
(iii) will only access and use the Services based on permissions and license as granted in the underlying agreement for Services; and
(iv) are and shall remain in full compliance with the terms and conditions of the agreement for Services.

By accessing the property of a private corporation, your activities may be monitored and/or recorded, and you should have no expectation of privacy in connection with the use of the Services. Further, your use of the Services implies consent to monitoring and recording in accordance with the terms and conditions of the agreement for Services.`;
/* eslint-enable */
