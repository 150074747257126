import atom from 'atom-js';
import config from 'config';
import { del, errorHandler, patch } from 'sf/helpers/request';
import userModel from 'models/user';
import { buildQuery, mediator } from 'sf/helpers';

const model = Object.assign(atom(), {
  getService(service_name) {
    const servicesList = model.get('services');
    return servicesList.find(({ id }) => id === service_name) || {};
  },

  updateService: (updated_service_name, updatedObject = {}) => {
    const updatedServices = model.get('services')
      .reduce((currentServices, service) => {
        if (service.service_name === updated_service_name) {
          currentServices.push(Object.assign({}, service, updatedObject));
        } else {
          currentServices.push(service);
        }
        return currentServices;
      }, []);
    model.set('services', updatedServices);
  },

  showService: (service_name) => {
    model.setServiceVisibility(service_name, true);
  },

  hideService: (service_name) => {
    model.setServiceVisibility(service_name, false);
  },

  setServiceVisibility: (service_name, published) => {
    patch('backend/profile/update_services/', 'UNIVERSAL')
      .send({
        provider: service_name,
        published,
      })
      .end((err, res) => {
        if (err) {
          errorHandler(err, res);
        } else {
          model.updateService(service_name, {
            published,
          });
        }
      });
  },

  connectService(service_name, oneall_user_token) {
    const callbackQueryParams = buildQuery({
      ts_app_url: `${location.protocol}//${location.host}`,
    });
    const queryParams = buildQuery({
      service: 'social_link',
      action: 'link_identity',
      callback_uri: `${BACKEND_URL}/profile/update_services?${callbackQueryParams}`,
      user_token: oneall_user_token,
    });
    /* eslint-disable */
    window.location.href = `//${config.oneAllApp}.api.oneall.com/socialize/connect/direct/${service_name}/?${queryParams}`;
    /* eslint-enable */
  },

  refreshService(service_name, oneall_user_token) {
    const callbackQueryParams = buildQuery({
      ts_app_url: `${location.protocol}//${location.host}`,
    });
    const queryParams = buildQuery({
      service: 'social_link',
      action: 'link_identity',
      callback_uri: `${BACKEND_URL}/profile/reload_service?${callbackQueryParams}`,
      user_token: oneall_user_token,
    });
    /* eslint-disable */
    window.location.href = `//${config.oneAllApp}.api.oneall.com/socialize/connect/direct/${service_name}/?${queryParams}`;
    /* eslint-enable */
  },

  disconnectService(service_name) {
    del('backend/profile/update_services/', 'UNIVERSAL')
      .send({
        provider: service_name,
      })
      .end((err, res) => {
        if (err) {
          errorHandler(err, res);
        } else {
          model.updateService(service_name, {
            connected: false,
          });
          mediator.publish('reloadOneAll');
        }
      });
  },

  setServicesList: () => {
    model.set('services', userModel.get('services'));
    userModel.entangle(model, 'services');
  },
});

export default model;
