import React from 'react';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { getQuery } from 'sf/helpers';
import model from 'models/settings';
import BasePage from 'pages/BasePage';

export default class EmailChangeConfirmation extends BasePage {
  className = 'ts-EmailChangeConfirmation';
  title = 'Email Change Confirmation';

  state = {
    isEmailConfirmed: null,
  }

  componentDidMount() {
    this.syncStateWithModel(model, ['isEmailConfirmed']);
  }

  componentDidMount() {
    model.getEmailCodeVerification(getQuery().code);
  }

  handleBackToProfileButtonClick = () => {
    this.navigate('/Profile.html');
  }

  getTitleText = () => {
    if (this.state.isEmailConfirmed) {
      return {
        title: 'Well done!',
        subtitle: 'Your e-mail has been successfully changed',
      };
    }
    return {
      title: 'Oops! Something went wrong!',
      subtitle: 'Go back to change the settings and try again',
    };
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <div className={ this.rootcn`__content` }>
          <div className={ this.cn`__title` }>
            <PageTitle
              { ...this.getTitleText() }
            />
          </div>
          <div className={ this.cn`__button` }>
            <Button
              onClick={ this.handleBackToProfileButtonClick }
              type="submit"
              size="big"
            >
              Take me to my profile
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
