import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import help from 'models/help';
import user from 'models/user';
import Input from 'sf/components/Input';
import withPasswordTypeToggler from 'sf/hoc/PasswordTypeToggler';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLinkAndToggle = compose(
  withPasswordTypeToggler,
  withStateLink,
  withValidation,
)(Input);

const INITIAL_STATE = {
  new_password: undefined,
  new_password_confirm: undefined,
  old_password: undefined,
};

export default class ChangePasswordForm extends BaseComponent {
  className = 'ts-ChangePasswordForm';

  state = {
    ...INITIAL_STATE,
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = await this.formValidation(
      user.set(this.state)
    )
      .catch(() => false);

    if (!isFormValid) return;

    await this.props.onSubmit(this.state)
      .then(() => help.addNotification({
        title: 'Success',
        type: 'success',
        value: 'Your password has been changed',
      }));
    this.setState(INITIAL_STATE);
  }

  shouldDisableSubmitButton = () => !Object.values(this.state)
    .every(Boolean);

  render() {
    return (
      <form
        className={ this.rootcn`` }
        onSubmit={ this.handleSubmit }
      >
        <div className={ this.cn`__field __field--old-password` }>
          <div className={ this.cn`__label` }>Old password</div>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLinkAndToggle
              autocomplete="off"
              ref="old_password"
              stateLink={ [this, 'old_password'] }
              placeholder="********"
              type="password"
            />
          </span>
        </div>
        <div className={ this.cn`__field __field--new-password` }>
          <div className={ this.cn`__label` }>New password</div>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLinkAndToggle
              autocomplete="off"
              ref="new_password"
              stateLink={ [this, 'new_password'] }
              placeholder="********"
              type="password"
            />
          </span>
        </div>
        <div className={ this.cn`__field __field--new-password-confirm` }>
          <div className={ this.cn`__label` }>Confirm new password</div>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLinkAndToggle
              autocomplete="off"
              ref="new_password_confirm"
              stateLink={ [this, 'new_password_confirm'] }
              placeholder="********"
              type="password"
            />
          </span>
        </div>
        <div className={ this.cn`__submit` }>
          <Button
            disabled={ this.shouldDisableSubmitButton() }
            size="big"
            type="submit"
          >
            Change password
          </Button>
        </div>
      </form>
    );
  }
}
