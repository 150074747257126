/* global FEATURE_LEAD_ACCELERATOR_COMING_SOON */
import React from 'react';
import _get from 'lodash/get';
import noop from 'no-op';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import EmailConfirmationBar from 'components/EmailConfirmationBar';
import InvitationDialog from 'components/InvitationDialog';
import InvitationList from 'components/InvitationList';
import Render from 'components/Render';
import Stamp from 'components/Stamp';
import UserPOLRetakeDialog from 'components/UserPOLRetakeDialog';
import config from 'config';
import { BRANDINGS, ROLES, ROUTES } from 'constants';
import device from 'models/device';
import help from 'models/help';
import invitation from 'models/invitation';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import * as ProfileActions from 'pages/Profile/Profile.actions';

/* eslint-disable max-len */
const ERROR = {
  UNVERIFIED_EMAIL: 'Please confirm your e-mail address first. We\'ve sent you a link with a verification code via e-mail.',
  PUBLIC_DATA_CONFLICT: 'We found a potential Public Data conflict. Please contact us.',
};
/* eslint-enable max-len */

export class AskSomeoneToVerify extends BasePage {
  className = 'ts-AskSomeoneToVerify';
  title = 'Ask someone to verify';

  state = {
    invitations: [],
  };

  componentDidMount() {
    this.setRestricted(true);
    this.syncStateWithModel(invitation, ['invitations']);
    this.syncStateWithModel(device, ['xsmUp']);
    this.syncStateWithModel(user, [
      'credit_price',
      'email_confirmed',
      'public_data_check_passed',
      'wallet_insight_terms_accepted',
    ]);
    this.updateData();
  }

  componentWillUpdate(nextProps) {
    // start POL video capture process of existing users
    if (_get(nextProps, 'profile.user') && !_get(nextProps, 'profile.user.is_pol_passed')) {
      this.userPOLRetakeDialogInstance.toggle(true);
    }
  }

  isPermittedToSendRequests() {
    if (!this.state.email_confirmed) {
      help.addNotification({ type: 'error', value: ERROR.UNVERIFIED_EMAIL });
      return false;
    } else if (this.state.public_data_check_passed === false) {
      help.addNotification({ type: 'error', value: ERROR.PUBLIC_DATA_CONFLICT });
      return false;
    }
    return true;
  }

  handleVerifySomeoneButtonClick = () => {
    if (this.isPermittedToSendRequests()) {
      this.InvitationDialog.show();
    }
  };

  handleEvaluateButtonClick = () => {
    this.navigate(ROUTES.WALLET_INSIGHT_FORM);
  };

  handleRegisterButtonClick = () => {
    this.navigate(ROUTES.WALLET_INSIGHT_REGISTRATION);
  };

  updateData = () => {
    this.props.actions.requestProfileData();
    invitation.getList();
  };

  renderAskToVerify = () => (
    <div className={ this.cn`__invite-section` }>
      <div className={ this.cn`__title` }>
        Ask someone to verify
      </div>
      <p className={ this.cn`__description` }>
        Best option when entering a business partnership<br />
        (e.g. renting an apartment).
      </p>
      { this.state.credit_price !== -1 &&
        <p className={ this.cn`__description` }>
          You will have to pay <Stamp>{ config.verificationCost }</Stamp> to have someone
          verified but you will be able to access their profile for 72 hours.
        </p> }
      <div className={ this.cn`__invite-button` }>
        <Button
          onClick={ this.handleVerifySomeoneButtonClick }
          size={ this.state.xsmUp ? 'big' : 'medium' }
          theme="secondary"
        >
          Have someone verified
        </Button>
      </div>
    </div>
  );

  renderWalletInsight = () => this.shouldRenderWalletInsight()
    ? (
      <div className={ this.cn`__invite-section` }>
        <div className={ this.cn`__title` }>
          Search Lead Accelerator™ Insights
        </div>
        <p className={ this.cn`__description` }>
          Evaluate unregistered clients<br />
          with Lead Accelerator™
        </p>
        <div className={ this.cn`__invite-button` }>
          <Button
            onClick={ this.handleEvaluateButtonClick }
            size={ this.state.xsmUp ? 'big' : 'medium' }
            theme="secondary"
          >
            Evaluate
          </Button>
        </div>
      </div>
    )
    : null;

  renderWalletInsightRegistration = () => this.shouldRenderWalletInsightRegistration()
    ? (
      <div className={ this.cn`__invite-section __registration` }>
        <div className={ this.cn`__title` }>
          Register for Lead Accelerator™ to gain insights on potential clients
        </div>
        <div className={ this.cn`__registration-descriptions` }>
          <span className={ this.cn`__description __description--narrow` }>
            Qualify leads faster using data to make prioritization decisions
          </span>
          <span className={ this.cn`__description __description--narrow` }>
            Better understand likelihood of transaction
          </span>
          <span className={ this.cn`__description __description--narrow` }>
            Identify and prioritize top lead clusters based on economic profiles
          </span>
        </div>
        <div className={ this.cn`__invite-button` }>
          <Button
            onClick={ FEATURE_LEAD_ACCELERATOR_COMING_SOON ? noop : this.handleRegisterButtonClick }
            size={ this.state.xsmUp ? 'big' : 'medium' }
            theme={ FEATURE_LEAD_ACCELERATOR_COMING_SOON ? 'disabled' : 'secondary' }
          >
            { FEATURE_LEAD_ACCELERATOR_COMING_SOON ? 'Coming soon!' : 'Register' }
          </Button>
        </div>
      </div>
    )
    : null;

  shouldRenderWalletInsight = () => this.state.wallet_insight_terms_accepted &&
    BRAND_NAME === BRANDINGS.REALTOR;

  shouldRenderWalletInsightRegistration = () => {
    return !this.state.wallet_insight_terms_accepted && BRAND_NAME === BRANDINGS.REALTOR;
  }

  render() {
    const displayEmailConfirmation = user.hasRole(ROLES.BUSINESS_USER) &&
      this.state.email_confirmed === false;
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__action-bar-wrapper` }>
          <Render when={ displayEmailConfirmation } >
            <div className={ this.cn`__email-confirmation-wrapper` }>
              <div className={ this.cn`__action-bar` } >
                <EmailConfirmationBar />
              </div>
            </div>
          </Render>
          <div className={ this.cn`__action-bar` }>
            <ActionBar title={ this.title } />
          </div>
        </div>

        <div className={ this.cn`ts-container` }>
          <div className={ this.cn`__invite-options ` }>
            <div
              className={ this.cn({
                '__invite-options-wrapper': true,
                '__invite-options-wrapper--double': this.shouldRenderWalletInsight(),
              }) }
            >
              { this.renderAskToVerify() }
              { this.renderWalletInsight() }
            </div>
            <InvitationDialog
              ref={ (dialog) => this.InvitationDialog = dialog }
              onInvitationSuccess={ this.updateData }
            />
          </div>

          <div className={ this.cn`__existing-invitations` }>
            <div className={ this.cn`__subtitle` }>
              People you asked to verify
            </div>
            <div className={ this.cn`__invitations-list` }>
              <InvitationList invitations={ this.state.invitations } />
            </div>
          </div>
          { this.renderWalletInsightRegistration() }
        </div>
        <UserPOLRetakeDialog
          ref={ (dialog) => { this.userPOLRetakeDialogInstance = dialog; } }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ profile }) => ({
  profile: profile,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(ProfileActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AskSomeoneToVerify);
