import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from 'components/Box';
import Button from 'components/Button';
import ChangePasswordForm from 'components/ChangePasswordForm';
import Dialog, { DialogText, DialogFooter } from 'components/Dialog';
import PageTitle from 'components/PageTitle';
import Render from 'components/Render';
import UserPOLRetakeDialog from 'components/UserPOLRetakeDialog';
import { ROLES } from 'constants';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import * as UserSettingsActions from 'pages/UserSettings/UserSettings.actions';
import { Col, Row } from 'sf/components/Grid';

export class UserSettings extends BasePage {
  className = 'ts-UserSettings';
  title = 'User Settings';

  state = {
    api_key: '',
  }

  componentDidMount() {
    this.syncStateWithModel(user, [
      'api_key',
      'absolute_small_photo_url',
    ]);
  }

  componentWillReceiveProps({ isRetakeSelfieDialogVisible }) {
    if (isRetakeSelfieDialogVisible !== this.props.isRetakeSelfieDialogVisible) {
      this.retakeSelfieDialogInstance.toggle(isRetakeSelfieDialogVisible);
    }
  }

  handleApiKeyDialogCancel = () => {
    this.generateApiKeyDialogInstance.dismiss();
  }

  handleApiKeyDialogConfirm = () => {
    this.props.actions.regenerateKey();
    this.generateApiKeyDialogInstance.dismiss();
  }

  handleGenerateAPIKey = () => {
    this.generateApiKeyDialogInstance.toggle(true);
  }

  renderApiKeyBox() {
    return (
      <Box title="Your API key" fluid={ true }>
        <div className={ this.cn`__generate-api-key-container` }>
          <Render when={ this.state.api_key }>
            <div className={ this.cn`__api-key` }>
              { this.state.api_key }
            </div>
          </Render>
          <Render when={ !this.state.api_key }>
            <p>
              You have to be confirmed by your organization
            </p>
          </Render>
        </div>
        <Button
          size="big"
          onClick={ this.handleGenerateAPIKey }
          disabled={ !this.state.api_key }
        >
          Generate new API key
        </Button>
        { this.renderGenerateApiKeyConfirmDialog() }
      </Box>
    );
  }

  handleChangePasswordSubmit = ({ old_password, new_password, new_password_confirm }) => {
    return user.updatePassword({ old_password, new_password_confirm, new_password });
  }

  renderChangePasswordBox() {
    return (
      <Box title="Change your password" fluid={ true }>
        <p>
          { /* eslint-disable max-len */ }
          Your password must be at least 8 characters long and contain: an uppercase letter, lowercase letter and a digit.
          { /* eslint-enable max-len */ }
        </p>
        <div className={ this.cn`__change-password-form` }>
          <ChangePasswordForm onSubmit={ this.handleChangePasswordSubmit } />
        </div>
      </Box>
    );
  }

  renderRetakeSelfieBox() {
    return (
      <Box
        title="Change your selfie"
        fluid={ true }
      >
        <p className={ this.cn`__paragraph` }>
          { /* eslint-disable max-len */ }
          Your selfie is sent with verification e-mail. Your client uses it to confirm that you are a Trusted Realtor<sup>&#174;</sup>.
          { /* eslint-enable max-len */ }
        </p>
        <img
          alt=""
          className={ this.cn`__selfie` }
          src={ this.state.absolute_small_photo_url }
        />
        <p className={ this.cn`__paragraph __paragraph--left` }>
          You can use these tips to take a great selfie:
        </p>
        <ul className={ this.cn`__paragraph __paragraph--left` }>
          <li>make sure that your face fits the blurry circle</li>
          { /* eslint-disable max-len */ }
          <li>make sure that you have good frontal lightning - consider turning on your lights or facing a window</li>
          { /* eslint-enable max-len */ }
          <li>act natural!</li>
        </ul>
        <div className={ this.cn`__change-selfie-button` }>
          <Button
            onClick={ () => this.retakeSelfieDialogInstance.toggle(true) }
            size="big"
          >
            Retake selfie video
          </Button>
        </div>
        <UserPOLRetakeDialog
          ref={ (instance) => { this.retakeSelfieDialogInstance = instance; } }
          closable={ true }
        />
      </Box>
    );
  }

  renderGenerateApiKeyConfirmDialog() {
    return (
      <Dialog
        className={ this.cn`__capture-dialog` }
        ref={ (instance) => { this.generateApiKeyDialogInstance = instance; } }
        size="small"
        title="Confirm generate new API key"
      >
        <DialogText>
          <p>You are about to regenerate your API key. Your old API key will be no longer valid</p>
          <p>Do you want to proceed?</p>
        </DialogText>
        <DialogFooter>
          <Button
            onClick={ this.handleApiKeyDialogConfirm }
          >
            Proceed
          </Button>
          <Button
            theme=""
            onClick={ this.handleApiKeyDialogCancel }
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`` }>
        <div className={ this.cn`ts-container ts-container--narrow` }>
          <div className={ this.cn`__title` }>
            <PageTitle title={ this.title } />
          </div>
          <Row center={ ['xxsm'] }>
            <Render when={ user.hasRole(ROLES.BUSINESS_USER) }>
              <Col sm={ 6 }>
                { this.renderChangePasswordBox() }
              </Col>
            </Render>
            <Col sm={ 6 }>
              { this.renderRetakeSelfieBox() }
            </Col>
            <Render when={ user.hasRole(ROLES.ORGANIZATION_AGENT) }>
              <Col sm={ 12 }>
                { this.renderApiKeyBox() }
              </Col>
            </Render>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userSettings, form }) => {
  return { ...userSettings, form };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(UserSettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
