import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import BusinessSignUpBasicInfo from 'components/BusinessSignUp/BusinessSignUpBasicInfo';
import BusinessSignUpPhoneVerification
  from 'components/BusinessSignUp/BusinessSignUpPhoneVerification';
import BusinessSignUpVideo from 'components/BusinessSignUp/BusinessSignUpVideo';
import { STEPS } from 'models/businessSignUp';

export default class BusinessSignUp extends BaseComponent {
  state = {};

  static propTypes = {
    step: PropTypes.oneOf(Object.keys(STEPS)).isRequired,
  };

  getContentComponent = () => {
    switch (this.props.step) {
      case STEPS.PHONE_VERIFICATION:
        return BusinessSignUpPhoneVerification;
      case STEPS.VIDEO:
        return BusinessSignUpVideo;
      case STEPS.BASIC_INFO:
      default:
        return BusinessSignUpBasicInfo;
    }
  };

  render() {
    const Component = this.getContentComponent();
    return <Component { ...this.props } />;
  }
}
