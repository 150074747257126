import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import PillButton from 'components/PillButton';
import Render from 'components/Render';
import SocialTile from 'components/SocialTile';
import deviceModel from 'models/device';
import Icon from 'sf/components/Icon';

export default class SocialTilesList extends BaseComponent {
  className = 'ts-SocialTilesList';

  static contextTypes = {
    socialNetworksModel: PropTypes.object,
    servicesModel: PropTypes.object,
    viewMode: PropTypes.string,
  };

  state = {
    services: null,
    expanded: false,
    edited: false,
  }

  componentDidMount() {
    this.syncStateWithModel(deviceModel, ['xsmUp']);
  }

  componentDidMount() {
    this.syncStateWithModel(this.context.servicesModel, ['services']);
  }

  getSocialListHeight() {
    if (this.state.edited) return 'auto';
    const socialListRowHeight = 290;
    const servicesList = this.state.services || [];
    const connectedServicesCount = servicesList.filter(({ connected }) => connected).length;
    const columnsCount = this.state.xsmUp ? 4 : 1;
    const initialRowsCount = this.state.xsmUp ? 1 : Math.min(connectedServicesCount, 2);
    if (this.state.expanded) {
      return Math.ceil(connectedServicesCount / columnsCount) * socialListRowHeight;
    }
    return socialListRowHeight * initialRowsCount;
  }

  handlePillButtonClick = () => {
    const { edited } = this.state;
    this.setState({
      edited: !edited,
    });
  };

  handleToggleLinkClick = () => {
    if (this.state.edited) return;
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  renderEditButton() {
    if (this.context.viewMode === 'PUBLIC') return;
    const cogLabel = this.state.edited ? 'Stop editing' : 'Manage connections';

    return (
      <div className={ this.cn`__edit` }>
        <PillButton
          onClick={ this.handlePillButtonClick }
          shell={ true }
        >
          { cogLabel }
        </PillButton>
      </div>
    );
  }

  connectService = (service) => {
    this.context.servicesModel.connectService(
      service.service_name,
      this.context.socialNetworksModel.get('oneall_user_token')
    );
  };

  disconnectService = (service) => {
    this.context.servicesModel.disconnectService(service.service_name);
  };

  hideService = (service) => {
    this.context.servicesModel.hideService(service.service_name);
  };

  showService = (service) => {
    this.context.servicesModel.showService(service.service_name);
  };

  render() {
    const servicesList = this.state.services || [];
    const connectedServicesList = servicesList.filter(({ connected }) => connected);
    const className = {
      '__items': true,
      '__items--expanded': this.state.expanded,
      '__items--edited': this.state.edited,
    };
    const visibleServicesCount = this.state.xsmUp ? 4 : Math.min(2, connectedServicesList.length);
    const hiddenServicesCount = Math.max(0, connectedServicesList.length - visibleServicesCount);
    const stylesAttributes = {
      height: this.getSocialListHeight(),
    };
    return (
      <div className={ this.rootcn() }>
        <h1 className={ this.cn`__title` }>
          { connectedServicesList.length } verified social media { }
          { connectedServicesList.length === 1 ? 'account' : 'accounts' }
        </h1>
        { this.renderEditButton() }
        <div className={ this.cn(className) } style={ stylesAttributes }>
          { servicesList.map((service) => (
            <div className={ this.cn`__item` } key={ `service-${service.service_name}` }>
              <SocialTile
                service={ service }
                edited={ this.state.edited }
                viewMode={ this.context.viewMode }
                onConnect={ this.connectService }
                onDisconnect={ this.disconnectService }
                onHide={ this.hideService }
                onShow={ this.showService }
              />
            </div>
          )) }
        </div>
        <Render when={ hiddenServicesCount }>
          <a
            className={ this.cn`__toggle` }
            role="button"
            onClick={ this.handleToggleLinkClick }
            tabIndex={ 0 }
          >
            <div className={ this.cn`__toggle-label` }>
              Show { hiddenServicesCount } { hiddenServicesCount > 1 ? 'accounts' : 'account' } { }
              { this.state.expanded ? 'less' : 'more' }
            </div>
            <div className={ this.cn`__toggle-icon` }>
              <Icon
                key={ `expand-icon-${this.state.expanded}` }
                type={ this.state.expanded ? 'angle-double-up' : 'angle-double-down' }
              />
            </div>
          </a>
        </Render>
      </div>
    );
  }
}
