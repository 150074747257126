import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import CardButton from 'components/CardButton';
import { MODES as AUTH_MODES } from 'components/SocialServicesCards/SocialServicesCards.constants';
import { features } from 'config';
import { BUSINESS_SERVICE } from 'constants';
import help from 'models/help';
import loginModel from 'models/login';
import registrationModel from 'models/registration';

export const MODES = AUTH_MODES;

export default class SocialServicesCards extends BaseComponent {
  className = 'ts-SocialServicesCards';

  static propTypes = {
    includeBusinessOnlyService: PropTypes.bool,
    mode: PropTypes.oneOf(Object.values(MODES)),
    services: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    includeBusinessOnlyService: false,
    mode: MODES.SIGN_UP,
    services: [],
  };

  displayUseWebappHCWarning = (serviceName) => () => {
    help.addNotification({
      type: 'warning',
      value: (
        <div>
          { /* eslint-disable max-len */ }
          <p>
            { `We apologize for the inconvenience but due to ${serviceName}'s new policy, you have to ${this.props.mode === MODES.SIGN_UP ? 'sign up' : 'login'} through your web browser. Afterwards you will be redirected back to the application.` }
          </p>
          <Button
            theme="ternary"
            onClick={ () => document.querySelector(`.${this.cn`__redirect-to-webapp-link-${serviceName}`}`).click() }
          >
            Proceed
          </Button>,
          { /* eslint-enable max-len */ }
        </div>
      ),
    });
    // TODO: Add support for isVisible in addNotification
    help.toggle();
  };

  getModeProps = ({ id, useWebapp, name }) => {
    const isBusinessService = id === BUSINESS_SERVICE.id;
    const getOnClick = () => {
      switch (this.props.mode) {
        case MODES.SIGN_UP:
          return isBusinessService ?
            () => { this.navigate('/business/SignUpBasicInfo.html'); } :
            () => { registrationModel.signUpWithService(id); };
        case MODES.LOG_IN:
          return isBusinessService ?
            () => { this.navigate('/business/LogInCredentials.html'); } :
            () => { loginModel.logInWithService(id); };
        case MODES.RESET_PIN:
          return () => {
            loginModel.logInWithService(id);
          };
        default:
          return noop;
      }
    };
    const getSuptitle = () => {
      switch (this.props.mode) {
        case MODES.SIGN_UP:
          return isBusinessService ? 'Create a' : 'Connect with';
        case MODES.LOG_IN:
          return 'Log in with';
        case MODES.RESET_PIN:
          return 'Reset PIN with';
        default:
          return noop;
      }
    };

    let onClick = getOnClick();
    if (useWebapp && is.cordova()) {
      onClick = this.displayUseWebappHCWarning(name);
    }

    return {
      onClick,
      suptitle: getSuptitle(),
    };
  }

  renderService = (service) => {
    return (
      <div
        className={ this.cn`__item` }
        key={ `item-${service.name}` }
      >
        <CardButton
          className="--small"
          icon={ service.icon }
          iconSet="ts"
          title={ service.name }
          { ...this.getModeProps(service) }
        />
        { /* eslint-disable max-len, jsx-a11y/anchor-has-content */ }
        { /*
          HACK: Triggering click programmatically on created element (even appended to the DOM) doesn't work
          or opens some built-in Cordova browser 😞
        */ }
        <a
          className={ this.cn`__redirect-to-webapp-link-${service.name}` }
          style={ { display: 'none' } }
          href={ this.props.mode === MODES.SIGN_UP ?
            `${FRONTEND_URL}/SignUp.html?mode=realtor&next=REALTOR_APP&socialNetwork=${service.id}` :
            `${FRONTEND_URL}/business/LogIn.html?next=REALTOR_APP&socialNetwork=${service.id}`
          }
          target="_blank"
          rel="noopener noreferrer"
        />
        { /* eslint-enable max-len, jsx-a11y/anchor-has-content */ }
      </div>
    );
  }

  render() {
    const { services, includeBusinessOnlyService } = this.props;
    if (!services.length) return null;
    const servicesToRender = services.filter(
      (service) => (
        !service.isBusinessOnly ||
        includeBusinessOnlyService && features.noScoreRegistration
      )
    );
    return (
      <div className={ this.rootcn() }>
        { servicesToRender.map(this.renderService) }
      </div>
    );
  }
}
