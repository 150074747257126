import atom from 'atom-js';
import is from 'next-is';
import {
  errorHandler,
  get,
  post,
} from 'sf/helpers/request';
import {
  getStateValidation,
  getStringValidation,
} from 'helpers/validation';

const model = atom.setup({
  validation: {
    first_name: getStringValidation('first name'),
    last_name: getStringValidation('last name'),
    state: getStateValidation(),
    address: getStringValidation('address'),
    city: getStringValidation('city'),
    zip_code: {
      ...getStringValidation('zip code'),
      'The zip code should be exactly 5 digits long': (input) =>
        is.string(input) && input.replace(/\D/gi, '').length === 5,
    },
  },
  methods: {
    triggerSearch(resolve, reject, data) {
      post('backend/invitation/equifax_data/', 'UNIVERSAL')
        .type('form')
        .send(data)
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data: { invitation_key, existing_invitation_key } } = res.body;
            resolve(invitation_key || existing_invitation_key || model.get('invitation_key'));
          }
        });
    },
    getProfileInfo(resolve, reject, invitation_key) {
      model.set({ profile: null });
      get(`backend/invitation/${invitation_key}/equifax_data/`, 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            const { data } = res.body;
            const profile = {
              ...data,
            };
            model
              .set({ profile })
              .then(resolve);
          }
        });
    },
  },
})();

export default model;
