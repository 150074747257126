import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';
import Render from 'components/Render';
import Slider from 'components/Slider';
import { DRIVING_LICENSE_STEPS as STEPS } from 'constants';
import { asset } from 'helpers';

// "now flip your DL" slide number;
// used for different display style for this slide (disabled navigation, blockded swipe etc.)
const MOBILE_FLIP_DL_SLIDE = 5;
const DESKTOP_FLIP_DL_SLIDE = 4;
// standard US DL dimensions aspect ratio
const DL_ASPECT_RATIO = 1.5;

export default class CaptureDrivingLicense extends BaseComponent {
  className = 'ts-CaptureDrivingLicense';

  state = {
    step: STEPS.TUTORIAL,
    tutorialSlideIndex: 0,
    frontPhotoCompleted: false,
    sliderBlocked: false,
    // used for tutorial content related to a hadrdware (not a screensize)
    mobileVersion: is.mobile() || is.tablet(),
  }

  static propTypes = {
    onPhotoTaken: PropTypes.func,
    onStepChange: PropTypes.func,
  };

  static defaultProps = {
    onPhotoTaken: noop,
    onStepChange: noop,
  };

  componentDidMount() {
    this.subscribe('drivingLicensePhotoUpdated', this.handleDrivingLicensePhotoUpdated);
  }

  handleDrivingLicensePhotoUpdated = (side) => {
    if (side === 'front') {
      this.setState({
        frontPhotoCompleted: true,
        step: STEPS.FLIP_DL,
        tutorialSlideIndex: this.state.mobileVersion ? MOBILE_FLIP_DL_SLIDE : DESKTOP_FLIP_DL_SLIDE,
        sliderBlocked: true,
      }, () => this.props.onStepChange(STEPS.FLIP_DL));
    }
    if (side === 'back') {
      this.setState({
        sliderBlocked: false,
      });
    }
  };

  handlePhotoTaken = (photoData) => {
    this.props.onPhotoTaken(photoData);
  }

  handleSkipTutorialButton = () => {
    this.setState((prevState) => ({
      step: prevState.frontPhotoCompleted ? STEPS.DL_BACK_CAPTURE : STEPS.FRONT_CAPTURE,
    }), () =>
      this.props.onStepChange(
        this.state.frontPhotoCompleted ? STEPS.DL_BACK_CAPTURE : STEPS.FRONT_CAPTURE
      ));
  }

  handleBackToTutorial = () => {
    this.setState({
      step: STEPS.TUTORIAL,
      tutorialSlideIndex: 0,
    }, () => this.props.onStepChange(STEPS.TUTORIAL));
  }

  handleShowTutorial = () => {
    this.setState({
      step: STEPS.TUTORIAL,
    }, () => this.props.onStepChange(STEPS.TUTORIAL));
  }

  handleShowTakeFrontPhoto = () => {
    this.setState({
      step: STEPS.FRONT_CAPTURE,
    }, () => this.props.onStepChange(STEPS.FRONT_CAPTURE));
  }

  handleShowTakeBackPhoto = () => {
    this.setState({
      step: STEPS.DL_BACK_CAPTURE,
    }, () => this.props.onStepChange(STEPS.DL_BACK_CAPTURE));
  }

  handleSlideIndexChange = (slideIndex) => {
    this.setState({
      tutorialSlideIndex: slideIndex,
    });
  }

  renderTutorial = () => (
    <div>
      <Render when={ this.state.mobileVersion }>
        <div className={ this.cn`__tutorial-wrapper` }>
          <div className={ this.cn`__tutorial-container __tutorial-container--mobile` }>
            <Slider
              settings={ {
                infinite: false,
                arrows: false,
                dots: !this.state.sliderBlocked,
                swipe: !this.state.sliderBlocked,
                initialSlide: this.state.tutorialSlideIndex,
                afterChange: this.handleSlideIndexChange,
              } }
            >
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  Grab your phone horizontaly
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/mobile_1.svg` }
                  className={ this.cn`__slide-image` }
                />
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  Place your Driver’s License on a dark, even surface
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/mobile_2.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  It can be a table, floor or a spare black t-shirt
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  Avoid casting shadows by facing a light source
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/mobile_3.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Don’t use flash, use sunlight or a desk lamp.
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  Make sure that your Driver’s License fits the screen
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/mobile_4.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  It’s important for all the data to be in the photo.
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  Take a photo!
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/mobile_5.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Take a photo
                </p>
              </div>
              { this.state.frontPhotoCompleted ? (
                <div>
                  <h2 className={ this.cn`__slide-header` }>
                    Now flip your Driver’s Licence and take a photo of its back
                  </h2>
                  <img
                    alt=""
                    src={ asset`img/dl-tutorial/mobile_6.svg` }
                    className={ this.cn`__slide-image` }
                  />
                  <p className={ this.cn`__slide-text` }>
                    Take a photo
                  </p>
                </div>
              ) : null }
            </Slider>
            <div className={ this.cn`__tutorial-footer` }>
              <div className={ this.cn`__tutorial-footer-button-container` }>
                <Render when={ this.state.tutorialSlideIndex === DESKTOP_FLIP_DL_SLIDE }>
                  <Button
                    onClick={ this.handleShowTakeFrontPhoto }
                    theme="secondary"
                  >
                    Take a photo
                  </Button>
                </Render>
                <Render when={ this.state.tutorialSlideIndex === MOBILE_FLIP_DL_SLIDE }>
                  <div className={ this.cn`__tutorial-bottom-container` }>
                    <Button
                      onClick={ this.handleShowTakeBackPhoto }
                      theme="secondary"
                    >
                      Take a photo
                    </Button>
                  </div>
                </Render>
              </div>
              <Render
                when={ this.state.tutorialSlideIndex !== MOBILE_FLIP_DL_SLIDE }
              >
                <div className={ this.cn`__skip-back-button` }>
                  <Button theme="link" onClick={ this.handleSkipTutorialButton }>
                    Skip the tutorial and take a photo
                  </Button>
                </div>
              </Render>
            </div>
          </div>
        </div>
      </Render>
      <Render when={ !this.state.mobileVersion }>
        <div className={ this.cn`__tutorial-wrapper` }>
          <div className={ this.cn`__tutorial-container` }>
            <Slider
              settings={ {
                infinite: false,
                arrows: !this.state.sliderBlocked,
                dots: !this.state.sliderBlocked,
                swipe: !this.state.sliderBlocked,
                initialSlide: this.state.tutorialSlideIndex,
                afterChange: this.handleSlideIndexChange,
                additionalSlideClassname: this.cn`__desktop-slide`,
              } }
            >
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  How to take a Driver’s License photo?
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/desktop_1.svg` }

                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Pinch your Driver’s License with fingers. Your hand will
                  be more steady and your fingers will not obscure any data.
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  How to take a Driver’s License photo?
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/desktop_2.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Find good lightning, darker background, light in front of you.
                  Our software will read data better and process it faster.
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  How to take a Driver’s License photo?
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/desktop_3.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Fit your Driver’s License in the green edges. You will take a
                  better photo and avoid missing any details.
                </p>
              </div>
              <div>
                <h2 className={ this.cn`__slide-header` }>
                  How to take a Driver’s License photo?
                </h2>
                <img
                  alt=""
                  src={ asset`img/dl-tutorial/desktop_4.svg` }
                  className={ this.cn`__slide-image` }
                />
                <p className={ this.cn`__slide-text` }>
                  Take a photo
                </p>
              </div>
              { this.state.frontPhotoCompleted ? (
                <div>
                  <h2 className={ this.cn`__slide-header` }>
                    How to take a Driver’s License photo?
                  </h2>
                  <img
                    alt=""
                    src={ asset`img/dl-tutorial/desktop_5.svg` }
                    className={ this.cn`__slide-image` }
                  />
                  <p className={ this.cn`__slide-text` }>
                    Now flip your Driver’s Licence and take a photo of its back
                  </p>
                </div>
              ) : null }
            </Slider>
            <div className={ this.cn`__tutorial-footer` }>
              <div className={ this.cn`__tutorial-footer-button-container` }>
                <Render when={ this.state.tutorialSlideIndex === 3 }>
                  <Button
                    onClick={ this.handleShowTakeFrontPhoto }
                    theme="secondary"
                  >
                    Take a photo
                  </Button>
                </Render>
                <Render when={ this.state.tutorialSlideIndex === DESKTOP_FLIP_DL_SLIDE }>
                  <div className={ this.cn`__tutorial-bottom-container` }>
                    <Button
                      onClick={ this.handleShowTakeBackPhoto }
                      theme="secondary"
                    >
                      Take a photo
                    </Button>
                  </div>
                </Render>
              </div>
            </div>
            <Render
              when={ this.state.tutorialSlideIndex !== DESKTOP_FLIP_DL_SLIDE }
            >
              <div className={ this.cn`__skip-back-button` }>
                <Button theme="link" onClick={ this.handleSkipTutorialButton }>
                  Skip the tutorial and take a photo
                </Button>
              </div>
            </Render>
          </div>
        </div>
      </Render>
    </div>
  )

  renderFrontCapture = () => (
    <div>
      <LocalAdvancedPhotoCapture
        allowRetake={ true }
        cropAspectRatio={ DL_ASPECT_RATIO }
        overlay="id-front"
        allowCrop={ true }
        onPhotoTaken={ (img) => {
          this.handlePhotoTaken({
            img: img,
            side: 'front',
          });
        } }
      />
      <div className={ this.cn`__skip-back-button` }>
        <Button theme="link" onClick={ this.handleBackToTutorial }>
          Not sure what to do? Check the tutorial again
        </Button>
      </div>
    </div>
  );

  renderBackCapture = () => (
    <div>
      <LocalAdvancedPhotoCapture
        allowRetake={ true }
        allowCrop={ true }
        cropAspectRatio={ DL_ASPECT_RATIO }
        overlay="id-back"
        onPhotoTaken={ (img) => {
          this.handlePhotoTaken({
            img: img,
            side: 'back',
          });
        } }
      />
      <div className={ this.cn`__skip-back-button` }>
        <Button theme="link" onClick={ this.handleBackToTutorial }>
          Not sure what to do? Check the tutorial again
        </Button>
      </div>
    </div>
  );
  render() {
    return (
      <div className={ this.rootcn() }>
        <Render when={ [STEPS.TUTORIAL, STEPS.FLIP_DL].includes(this.state.step) }>
          { this.renderTutorial() }
        </Render>
        <Render when={ this.state.step === STEPS.FRONT_CAPTURE }>
          { this.renderFrontCapture() }
        </Render>
        <Render when={ this.state.step === STEPS.DL_BACK_CAPTURE }>
          { this.renderBackCapture() }
        </Render>
      </div>
    );
  }
}
