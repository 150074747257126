import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import BaseComponent from 'components/BaseComponent';
import IconButton from 'components/IconButton';
import {
  generateGroupId,
  getHelpCenterNotifications,
} from 'helpers/notifications';
import help from 'models/help';

export default class HelpButton extends BaseComponent {
  className = 'ts-HelpButton';

  static propTypes = {
    notifications: PropTypes.array.isRequired,
  };

  state = {
    groupId: '',
  };

  componentDidMount() {
    this.pushNotificationsToHelpCenter();
  }

  componentWillUnmount() {
    this.removeNotifications();
    super.componentWillUnmount();
  }

  componentDidUpdate() {
    this.removeNotifications();
    this.pushNotificationsToHelpCenter();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.notifications, this.props.notifications);
  }

  handleClick = () => {
    help.toggle(this.state.groupId);
  }

  pushNotificationsToHelpCenter = () => {
    const groupId = generateGroupId();
    this.setState({ groupId });
    const notifications = getHelpCenterNotifications(this.props.notifications);

    notifications.forEach((notification) => {
      this.notify('info', {
        ...notification,
        groupId,
        clearOnRouteChange: false,
      });
    });
  }

  removeNotifications = () => {
    help.clearNotifications(this.state.groupId);
  }

  render() {
    return (
      <IconButton
        className={ this.rootcn() }
        buttonType="button"
        size={ 20 }
        set="io"
        type="ios-help-outline"
        onClick={ this.handleClick }
      />
    );
  }
}
