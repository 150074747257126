import React from 'react';
import BaseComponent from 'components/BaseComponent';
import CaptureDrivingLicense from 'components/CaptureDrivingLicense';
import PageTitle from 'components/PageTitle';
import { DRIVING_LICENSE_STEPS as STEPS } from 'constants';
import analytics from 'models/analytics';
import registration from 'models/registration';
import { mediator } from 'sf/helpers';

const STEP_TITLE = {
  [STEPS.TUTORIAL]: 'Take Driver’s License photo',
  [STEPS.FRONT_CAPTURE]: 'Take front of Driver’s License photo',
  [STEPS.DL_BACK_CAPTURE]: 'Take back of Driver’s License photo',
};

export default class SignUpDrivingLicenseUpload extends BaseComponent {
  className = 'ts-SignUpPhotoUpload';

  state = {
    title: STEP_TITLE[STEPS.TUTORIAL],
  };

  handlePhotoTaken = ({ img, side }) => {
    const isFront = side === 'front';
    registration.uploadDrivingLicensePhoto(img, isFront).then(
      () => {
        if (isFront) {
          analytics.trackEvent('WEBAPP_REGISTRATION_DRIVERS_LICENCE_STEP_DONE');
        }
        mediator.publish('drivingLicensePhotoUpdated', isFront ? 'front' : 'back');
        registration.set(
          isFront ? 'frontDriverLicensePhotoPassed' : 'backDriverLicensePhotoPassed',
          true
        );
      },
      () => {
        this.notify('error', 'Uploading driver license photo failed. Please try again.');
      }
    );
  }

  setTitle = (step) => {
    this.setState({
      title: STEP_TITLE[step === STEPS.FLIP_DL ? STEPS.DL_BACK_CAPTURE : step],
    });
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <div className={ this.cn`__page-title` }>
          <PageTitle title={ this.state.title } />
        </div>
        <CaptureDrivingLicense
          onStepChange={ this.setTitle }
          onPhotoTaken={ this.handlePhotoTaken }
        />
      </div>
    );
  }
}
