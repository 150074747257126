import React from 'react';
import PropTypes from 'prop-types';
import { STEPS } from 'components/ResetPIN/ResetPIN.constants';
import ResetPINPIN from 'components/ResetPIN/ResetPINPIN';
import ResetPINSelfie from 'components/ResetPIN/ResetPINSelfie';
import ResetPINSelectSocialNetwork from 'components/ResetPIN/ResetPINSelectSocialNetwork';

const getComponent = (resetStep) => {
  switch (resetStep) {
    case STEPS.PIN:
      return ResetPINPIN;
    case STEPS.SELFIE:
      return ResetPINSelfie;
    case STEPS.SOCIAL:
    default:
      return ResetPINSelectSocialNetwork;
  }
};

const ResetPIN = (props) => {
  const Component = getComponent(props.resetStep);
  return <Component { ...props } />;
};

ResetPIN.propTypes = {
  resetStep: PropTypes.oneOf(Object.keys(STEPS)).isRequired,
};

export default ResetPIN;
