import React from 'react';
import _get from 'lodash/get';
import is from 'next-is';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { BRANDINGS } from 'constants';
import { getAllChildRoutesFromProps } from 'helpers';
import {
  getQuery,
  mediator,
} from 'sf/helpers';
import store from 'helpers/store';
import analytics from 'models/analytics';
import device from 'models/device';
import invitation from 'models/invitation';
import registration from 'models/registration';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import Breadcrumbs from 'sf/components/Breadcrumbs';

let isPageReload = global.performance && performance.navigation.type === 1;

export default class SignUpPage extends BasePage {
  className = 'ts-SignUpPage';
  pageTitle = 'Subscriber Sign Up';

  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    browserSupported: true,
  };

  initUserReturningChecker = () => {
    const checkUserReturning = () => {
      const registrationStep = registration.get('registrationStep');
      const activityPeriod = 1000 * 60 * 5;
      const lastActivity = user.get('lastActivity') || 0;
      // eslint-disable-next-line
      const isFirstStep = !registrationStep || registrationStep === '/SignUpSelectSocialnetwork.html';
      const activeInFiveMinutes = new Date() < lastActivity + activityPeriod;

      // NOTE: isPageReload will be always true on page reload even with in-app routing.
      //       This is why it needs to be reset manually
      setTimeout(() => { isPageReload = false; });

      return !isFirstStep && (!activeInFiveMinutes || isPageReload);
    };

    const userReturningIntervalCallback = () => {
      if (checkUserReturning()) {
        this.setState({ showReturningDialog: true });
        clearInterval(this.returningInterval);
      }
    };
    clearInterval(this.returningInterval);
    this.returningInterval = setInterval(userReturningIntervalCallback, 5000);
    userReturningIntervalCallback();
  };

  componentDidMount() {
    const hashQuery = getQuery(location.hash);
    const query = getQuery();
    const next = hashQuery.next || query.next || store.get('next') || '';
    store.set('next', next);

    this.queryParams = query;

    if (this.queryParams.organization) {
      registration.set('organization', this.queryParams.organization);
    }

    // Promise fails if the user is already registered
    this.initialAction().then(() => {
      this.syncStateWithModel(user, ['legal_name']);
      this.initUserReturningChecker();
      this.checkSignUpStatus();
    });
    this.syncStateWithModel(device, ['xsmDown']);
    this.syncStateWithModel(registration, ['isAppChosen']);
    const isAppChosen = query.redirect === 'true';
    registration
      .set({ isAppChosen });
  }

  componentDidUpdate() {
    this.initialAction();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    clearInterval(this.returningInterval);
  }

  checkSignUpStatus() {
    const statusOK = (req) => {
      // TODO: not sure why it's needed
      if (_get(req, 'responseJSON.data.message')) {
        mediator.publish('success', req.responseJSON.data.message);
      }
    };
    const statusFail = (req) => {
      const data = req.responseJSON && req.responseJSON.data;
      if (data && data.appErrorCode === 'RegistrationTokenNotFound') {
        // handle case when user has started registration but admin
        // deleted his registration progress.
        user.logOut();
        this.navigate('/SignUp.html');
      }

      if (data && data.message && getQuery(location.hash).hasOwnProperty('check_status')) {
        /*
          When OneAll redirects user to backend with some error, backend stores error message and
          redirects to frontend with #check_status. This is the only way to inform user what was
          wrong on OneAll side.
         */
        this.notify('error', data.message);
      }
    };

    registration.getRegistrationStep().then((registrationStep) => {
      if (registrationStep !== '/SignUpSelectSocialnetwork.html') {
        registration.getSignUpStatus().then(statusOK, statusFail);
      }
    });
  }

  initialAction() {
    return registration.getRegistrationStep().then((registrationStep) => {
      let currentStep = registrationStep;
      const loc = location.pathname;

      if (registrationStep === registration.REGISTRATION_DONE_STEP) {
        this.navigate('/Profile.html');
        throw new Error('User already registered');
      } else if (
        // initial step or step handled by backend redirectons.
        loc === '/SignUpPhone.html' && currentStep === '/SignUpSelectSocialnetwork.html'
      ) {
        currentStep = loc;
      }

      if (
        loc !== currentStep &&
        currentStep !== '/SignUp.html' &&
        currentStep !== '/SignUpSelectSocialnetwork.html'
      ) {
        this.setState({ showReturningDialog: true });
      }
      // currentStep = loc; // <--- WIP
      mediator.publish('setRegistrationStep', currentStep);

      // getUserMedia (photo/video capture via HTML5) is supported in iOS11+
      // iOS9 and iOS10 are supported by photo_validation/ endpoint
      // iOS < 9 is not supported at all.
      if (!device.appSupported() && !device.iOSFileFallbackCameraMode()) {
        this.setState({
          browserSupported: false,
        });
      }

      const { invitation_key, mode, organization } = this.queryParams;
      // Handle invitation key, that is passed from url:
      // SignUp.html?invitation_key=94ec7a7e-0fa2-4348-8f0b-08e5206f5cf2
      if (invitation_key) {
        user.set('invitation_key', invitation_key);
        analytics.trackEvent('WEBAPP_REGISTRATION_STARTED_REALTOR_CLIENT');
        invitation.getInfo(invitation_key)
          .then((credit_price) => user.set('credit_price', credit_price));
      }

      if (currentStep === '/SignUpSelectSocialnetwork.html') {
        registration.set('isRealtor', !!window.REALTOR || mode === BRANDINGS.REALTOR);
      } else {
        registration.set('isRealtor', !!window.REALTOR || registration.get('isRealtor'));
      }
      if (organization) {
        registration.set('organization', organization);
      }
    });
  }

  handleStartFromScratchButtonClick = () => {
    const done = () => {
      user.logOut().then(() => {
        this.navigate('/SignUp.html');
        this.props.onReset();
      });
    };

    const fail = (message) => {
      const msg = message || 'Could not remove registration progress. Please try again.';
      this.publish('showFloatingText', {
        text: msg,
        isValid: false,
      });
    };

    registration.deleteProgress().then(done, fail);
  }

  renderUnsupportedMessage() {
    if (is.mobile()) {
      return (
        <p className={ this.cn`__browser-support-notice ts-container` }>
          Dear customer,<br />
          To finish registration you need to use browser that supports video API -
          by HTML5 or Flash technology. Please try other browser or desktop computer.
        </p>
      );
    }
    return (
      <p className={ this.cn`__browser-support-notice ts-container` }>
        Dear customer,<br />
        To finish registration you need to use browser that supports video API -
        by HTML5 or Flash technology.
        Please Install and enable&nbsp;
        <a href="https://get.adobe.com/pl/flashplayer/" target="_blank" rel="noopener noreferrer">
          Flash Player
        </a>.

        <span className="ts-or" />

        <a href="http://outdatedbrowser.com/en" target="_blank" rel="noopener noreferrer">
          Update your outdated browser
        </a>.
      </p>
    );
  }

  render() {
    const { pageTitle } = this;

    if (!this.state.browserSupported) {
      return (
        <div className={ this.rootcn() }>
          <h1 className={ this.cn`__header` }>{ pageTitle } - Notice</h1>
          { this.renderUnsupportedMessage() }
        </div>
      );
    }

    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__header-container ts-container` }>
          <h1 className={ this.cn`__header` }>
            { pageTitle }
          </h1>
          <Button
            className={ this.cn`__start-from-scratch-button` }
            theme="ternary"
            size="small"
            onClick={ this.handleStartFromScratchButtonClick }
          >
            Start from scratch
          </Button>
        </div>
        <div className={ this.cn`__breadcrumbs` }>
          <Breadcrumbs
            allRoutes={ getAllChildRoutesFromProps(this.props) }
            routeParent="/SignUp.html"
            clickable={ false }
            responsive={ true }
            small={ this.state.xsmDown }
          />
        </div>
        { this.props.children }
      </div>
    );
  }
}
