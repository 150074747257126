import omit from 'lodash/omit';
/**
 * Create special type of object.
 * If called property doesnt exist, error will be thrown.
 * This way we can spot missing translations fast.
 */
const langEnumPolyfill = (object, id) => {
  const PolyfilledProxy = global.Proxy
    ? global.Proxy
    : (obj) => obj;

  return new PolyfilledProxy(object, {
    get: (obj, prop) => {
      if (!(prop in obj)) throw new Error(`${prop} is not a member of "${id}" translation file!`);
      return obj[prop];
    }
  });
};

const allL10n = {};
/**
 * Use this function in every translation file.
 * @param  {String} id            ID that will be used in language file.
 * @param  {Object} originalLines Translation lines
 * @return {Object}               Language object/dict
 */
export const registerLanguageFile = (id, originalLines) => {
  if (allL10n[id]) {
    throw new Error(`TS_SDK: "${id}" key already registered by registerLanguageFile`);
  }
  const l10nLines = langEnumPolyfill({
    ...originalLines,
    __esModule: null, // make webpack happy
    toJSON: null, // make JSON.stringify happy
  }, id);
  allL10n[id] = l10nLines;

  return l10nLines;
};

export const getL10n = (line, file = 'l10n') => {
  if (!line) {
    const preparedAllL10n = {};
    Object.keys(allL10n).forEach((key) => {
      preparedAllL10n[key] = omit({ ...allL10n[key] }, ['__esModule', 'toJSON']);
    });

    return preparedAllL10n;
  }

  return allL10n[file][line];
};

/**
 * Download translation file that contains all registered translation files.
 * This function is meant to be used by people who implements SDK.
 */
export const exportTranslationFile = (fileName = 'truststamp-sdk-translations') => {
  require('sf/helpers').getFileFromBlob({
    text: JSON.stringify(getL10n(), null, 2),
    extension: 'json',
    name: fileName,
    type: 'application/json',
  });
};

/**
 * Option to update any translation line/file dynamically.
 *
 * @param  {String} id            Translation file ID
 * @param  {Object} linesToUpdate Language object/dict
 */
export const updateTranslations = (id, linesToUpdate) => {
  if (!allL10n[id]) {
    throw new Error(`TS_SDK: No language file with ID: "${id}"`);
  }

  if (ENV === 'local') {
    // just for local development.
    // When updated line doesn't exist in the original file, throw an error.

    Object.keys(linesToUpdate).forEach((lineKey) => {
      if (!Object.prototype.hasOwnProperty.call(allL10n[id], lineKey)) {
        throw new Error(`"${id}" source language file does not contain "${lineKey}"`);
      }
    });
  }

  Object.assign(allL10n[id], linesToUpdate);
};
