import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import PageTitle from 'components/PageTitle';

export default class MainTextBox extends BaseComponent {
  className = 'ts-MainTextBox';

  static propTypes = {
    align: PropTypes.oneOf(['left', 'right', 'center']),
    customSubtitles: PropTypes.node,
    size: PropTypes.string,
    subtitles: PropTypes.array,
    superscription: PropTypes.array,
    title: PropTypes.node.isRequired,
  };

  static defaultProps = {
    align: 'left',
    size: 'huge',
    subtitles: [],
    superscription: [],
  };

  renderSubtitles(subtitles, customSubtitles) {
    const content = subtitles.map((subtitle, index) => (
      <p key={ index }>{ subtitle }</p> // eslint-disable-line react/no-array-index-key
    ));

    return <div className={ this.cn`__subtitles` }>{ customSubtitles || content }</div>;
  }

  renderSuperscription(superscriptions) {
    /* eslint-disable react/no-array-index-key */
    const content = superscriptions.map((superscription, index) => (
      <span key={ index }>
        { superscription }
        { index < superscriptions.length - 1 &&
          <span className={ this.cn`__superscriptions-separator` } />
        }
      </span>
    ));
    /* eslint-enable react/no-array-index-key */

    return <div className={ this.cn`__superscriptions` }>{ content }</div>;
  }

  render() {
    const { align, customSubtitles, size, subtitles, superscription, title } = this.props;
    const classNames = {
      [`--${align}`]: align,
    };

    return (
      <div className={ this.rootcn(classNames, this.props.className) }>
        { this.renderSuperscription(superscription) }
        <PageTitle
          align={ align }
          className={ this.cn`__title` }
          size={ size }
          title={ title }
        />
        { this.renderSubtitles(subtitles, customSubtitles) }
      </div>
    );
  }
}
