import atom from 'atom-js';
import webcam from 'mighty-webcamjs';
import is from 'next-is';
import {
  ROUTES as ADMIN_APP_ROUTES,
} from 'components/AdminPanel/AdminPanel.constants';
import { AGE_OF_ELIGIBILITY, OLDEST_PERSON_BIRTHDATE, ROLES } from 'constants';
import {
  errorHandler,
  get,
  post,
  postMultipart,
  requestEnd,
} from 'sf/helpers/request';
import help from 'models/help';
import user from 'models/user';
import {
  getExactLengthValidation,
  getStringValidation,
  passwordValidation,
} from 'helpers/validation';
import {
  chain,
  generateToken,
  mediator,
  navigate,
  symbols,
} from 'sf/helpers';
import date from 'sf/models/date';

const STEPS_LIST = ['BASIC_INFO', 'PHONE_VERIFICATION', 'VIDEO'];

export const STEPS = symbols(...STEPS_LIST);

/* eslint-disable max-len */
export const DATA = {
  [STEPS.BASIC_INFO]: {
    breadcrumb: 'Create an account',
    postMethod: 'postBasicInfo',
    subtitle: 'We will cross-reference it with your data to calculate a more accurate Trust Score',
    title: 'Create a NRDS Account',
  },
  [STEPS.PHONE_VERIFICATION]: {
    breadcrumb: 'Phone verification',
    postMethod: 'postPhoneVerification',
    subtitle: 'We need it to verify you and keep things running smoothly',
    title: 'Verify cell phone number',
  },
  [STEPS.VIDEO]: {
    breadcrumb: 'Verify your video',
    postMethod: 'postVideo',
  },
};
/* eslint-enable max-len */

const isStepAtIndexFirst = (index) => {
  return index === 0;
};

const isStepAtIndexLast = (index) => {
  return index === STEPS_LIST.length - 1;
};

const getStateForStepIndex = (index) => ({
  activeStep: STEPS_LIST[index],
  nextStep: STEPS_LIST[index + 1] || null,
  prevStep: STEPS_LIST[index - 1] || null,
  isActiveStepFirst: isStepAtIndexFirst(index),
  isActiveStepLast: isStepAtIndexLast(index),
});

const getUrl = (isOrganization) => `backend/signup/${isOrganization
  ? 'organization'
  : 'business'
}`;

const formatUsDate = (usDate) => chain(usDate)
  .pipe(date.getDate)
  .pipe(date.toISODate)
  .value;

const model = atom.setup({
  validation: {
    code: {
      ...getStringValidation('phone verification code'),
      ...getExactLengthValidation(8, 'phone verification code'),
      'Your NRDS contains invalid characters': (input) => /^[0-9]+$/.test(input),

    },
    date_birth: {
      ...getStringValidation('date of birth'),
      'Provided date is not valid': (input) => {
        return is.date.isSameOrBefore(OLDEST_PERSON_BIRTHDATE, formatUsDate(input));
      },
      'Sorry, you’re too young': (input) => {
        return is.date.isMinimumAge(formatUsDate(input), AGE_OF_ELIGIBILITY);
      },
    },
    email: {
      ...getStringValidation('email address'),
      'Please provide a valid emaill address': (input) => is.string.isEmail(input),
    },
    legal_name: {
      ...getStringValidation('legal name'),
      'Your legal name must be at least two words': (input) => input.split(' ').length > 1,
    },
    realtor_document_number: {
      ...getStringValidation('NRDS'),
      ...getExactLengthValidation(9, 'NRDS'),
      'Your NRDS contains invalid characters': (input) => /^-?[0-9]+$/.test(input),
    },
    password: passwordValidation,
    phone_number: {
      ...getStringValidation('phone number'),
      ...getExactLengthValidation(10, 'phone number'),
    },
    repeat_password: {
      'Please retype the password': (input) => is.string(input)
        && !!input.replace(/[^a-zA-ZÀ-ƒ0-9]/gi, '').length,
      'Passwords don’t match': (input, { password }) => input === password,
    },
    terms: {
      'Please accept the Terms and Privacy Policy': Boolean,
    },
  },
  methods: {
  // HACK: The counter overrides atom-js's cache (it's cached by arguments)
    getSignUpComplete: (resolve, reject, isOrganization = false, counter = 0) => {
      get(`${getUrl(isOrganization)}/complete/`, 'SIGNUP')
        .end((err, res) => {
          const { data = {} } = res.body;
          if (err) {
            if (data.appErrorCode === 'UserAlreadyRegistered') {
              navigate('/LogIn.html');

              help.addNotification({ type: 'error', value: data.message });
              return;
            }
            errorHandler(err, res);
            reject();
          }

          if (res.status === 202) {
            model
              .checkVerificationStatus(isOrganization)
              .then(() => model.getSignUpComplete(isOrganization, counter + 1))
              .then(resolve);
          } else {
            model.resetRegistration();
            resolve(data);
          }
        });
    },
    // HACK: The counter overrides atom-js's cache (it's cached by arguments)
    checkVerificationStatus: (resolve, reject, isOrganization = false, counter = 0) => {
      get(`${getUrl(isOrganization)}/verification_status/`, 'SIGNUP')
        .end((err, res) => {
          const { data = {} } = res.body;
          if (err) {
            errorHandler(err, res);
            reject();
            return;
          }

          if (res.status === 202) {
            // video is still processed; re-check for every 3 seconds and show loader
            mediator.publish('GlobalLoader--toggle', true);
            setTimeout(() => {
              model
                .checkVerificationStatus(isOrganization, counter + 1)
                .then(resolve);
            }, 3000);
          } else {
            mediator.publish('GlobalLoader--toggle', false);
            resolve(data);
          }
        });
    },
    postBasicInfo: (resolve, reject, data, successUrl, isOrganization = false) => {
      post(`${getUrl(isOrganization)}/basic/`, 'SIGNUP')
        .type('form')
        .send({
          ...data,
          url: `${location.origin}/EmailConfirmation.html`,
        })
        .end(requestEnd(
          () => {
            model.markActiveStepDone();
            model.goToNextStep();
            navigate(successUrl);
          },
          reject,
        ));
    },
    postPhoneVerification: (resolve, reject, data, successUrl, isOrganization = false) => {
      post(`${getUrl(isOrganization)}/verify/`, 'SIGNUP')
        .type('form')
        .send(data)
        .end(requestEnd(
          () => {
            model.markActiveStepDone();
            model.goToNextStep();
            navigate(successUrl);
          },
          reject,
        ));
    },
    postVideo: (resolve, reject, videoData, successUrl, isOrganization = false) => {
      const endpointURL = `${getUrl(isOrganization)}/pol/`;

      const resolveVideoRequest = () => {
        model.markActiveStepDone();
        model
          .getSignUpComplete(isOrganization)
          .then(({ token }) => {
            user.set({
              isSignedIn: true,
              roles: [ROLES.BUSINESS_USER],
              token,
            });
            if (isOrganization) {
              navigate(ADMIN_APP_ROUTES.INVITE);
            } else {
              navigate('/AskSomeoneToVerify.html');
            }
          })
          .then(resolve);
      };

      if (videoData instanceof Blob) {
        const fileName = `video.${webcam.helpers.videoRecorder.videoFormatToExt(
          videoData.type,
        )}`;

        postMultipart(endpointURL, 'SIGNUP')
          .attach('video_file', videoData, fileName)
          .end(requestEnd(resolveVideoRequest, reject));

        if (videoData.size < 1024) { // Video smaller than 1KB - super weird.
          throw new Error('blob passed to postVideo is suspiciously small.');
        }
      } else if (typeof videoData === 'string') {
        post(endpointURL, 'SIGNUP')
          .send({ video_url: videoData })
          .type('json')
          .end(requestEnd(resolveVideoRequest, reject));
      } else {
        throw new Error('Video passed to postVideo is not a blob');
      }
    },
  },
})({
  ...getStateForStepIndex(0),
  areAllStepsDone: false,
  doneSteps: [],
});

Object.assign(model, {
  getActiveStepIndex: () => STEPS_LIST.findIndex((step) => step === model.get('activeStep')),
  goToNextStep: () => {
    const { isActiveStepDone, isActiveStepLast } = model.get();
    if (isActiveStepLast || !isActiveStepDone) return;

    model.set({
      ...getStateForStepIndex(
        model.getActiveStepIndex() + 1
      ),
    });
  },
  goToPrevStep: () => {
    const { isActiveStepDone, isActiveStepFirst } = model.get();
    if (isActiveStepFirst || !isActiveStepDone) return;

    model.set({
      ...getStateForStepIndex(
        model.getActiveStepIndex() - 1
      ),
    });
  },
  markActiveStepDone: () => {
    const { activeStep, doneSteps } = model.get();
    if (doneSteps.includes(activeStep)) return;

    model.set({
      ...getStateForStepIndex(
        model.getActiveStepIndex(),
      ),
      areAllStepsDone: doneSteps.length + 1 === STEPS_LIST.length,
      doneSteps: [...doneSteps, activeStep],
    });
  },
  resetRegistration: () => {
    user.set({ token: generateToken() });
    model.set({
      ...getStateForStepIndex(
        model.getActiveStepIndex(),
      ),
    });
  },
  setOrganization: (organization) => {
    model.set({ organization });
  },
  setSignUpStep: (stepName) => {
    const activeStepIndexByName = STEPS_LIST.findIndex((step) => step === stepName);
    model.set({
      ...getStateForStepIndex(
        activeStepIndexByName < 0
          ? 0
          : activeStepIndexByName
      ),
    });
  },
});

export default model;
