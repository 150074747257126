import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import { webcam } from 'sf/components/Webcam';
import { asset, FPS, mostCommonElement } from 'sf/helpers';
import { getScript } from 'sf/helpers/domHelper';
import {
  INSTRUCTION_FACE_FIT,
  INSTRUCTION_MOVE_CLOSER,
  MULTIPLE_FACES_DETECTED,
  PROCESSING,
} from 'sf/l10n';

// 0.2 = 20%. Maximum value: 1.
// 20% of the camera area needs to be covered with face.
//
// NOTE: jsfeat returns just a part of the face!
//       https://giladaya.github.io/face-detector-polyfill/webcam/index.html
const MINIMUM_FACE_SIZE_PROPORTIONAL = 0.25;

/**
 * When calculating message to display, take most common of
 * last X feedbacks from the face detector.
 * This prevents message blink when one frame detection failed, but all other frames
 * found the face.
 */
const EASING_NUMBER = 5;
const EASING_NUMBER_LOW_FPS = 3; // when FPS drops below 10, we use easing of 3.

/**
 if face was too far before, it needs to be 5% larger
 in order to activate "FACE_OK" message.
 This way, message is not blinking when face is in
 threadshold of `too_far` and `ok`
 */
const FACE_OK_THRESHOLD = 1.05; // 5%

/**
 * interval of 20ms is to limit scan rate to max 50fps.
 * We don't need more.
 */
const SCAN_INTERVAL = 20;

/**
 * As for Jan 2023 FaceDetector API is experimental, and flag is required to run it.
 */
class FaceDetectorPolyfill {
  static loadPerformed = false;

  detector = false;

  constructor(...initParams) {
    this.initParams = initParams;
    this.fps = new FPS(`Face detector (throttled to ${1000 / SCAN_INTERVAL}) FPS:`);
  }

  static prefetch() {
    FaceDetectorPolyfill.loadPerformed = true;

    return getScript(asset`lib/TSFaceDetector.js`, true);
  }

  remove() {
    this.fps.remove();
    this.destroyed = true;
    if (this.detector) {
      this.detector.remove();
      delete this.detector;
    }
  }

  detect(input) {
    return new Promise((resolve, reject) => {
      if (this.destroyed) return;

      if (!global.TSFaceDetector) {
        const handleLoad = () => this.detect(input).then(resolve, reject);

        if (!FaceDetectorPolyfill.loadPerformed) {
          return FaceDetectorPolyfill.prefetch()
            .then(handleLoad)
            .catch(reject);
        }
        // TSFaceDetector not loaded yet. Waiting.
        return setTimeout(handleLoad, 500);
      }


      if (!this.detector) {
        // eslint-disable-next-line no-undef
        this.detector = new TSFaceDetector(...this.initParams);
      }

      this.detector.detect(input).then((...args) => {
        if (this.destroyed) return;
        this.fps.count();
        resolve(...args)
      }, reject);
    });
  }
}

export default class CameraCountDown extends BaseComponent {
  className = 'ts-CameraCountDown';

  countDownID = 0;
  countDownStarted = false;
  faceDetector = null;
  isDone = false;
  latestStatus = '';
  latestStatuses = [];

  state = {
    countDownText: '',

    live: null,
    cameraVideoElement: null,

    ...this.syncStateWithModelInitial(webcam.params, [
      'captureInProgress',
    ]),
  };

  static propTypes = {
    onDone: PropTypes.func.isRequired,
    onViewFinderText: PropTypes.func.isRequired,
    onCountDown: PropTypes.func,
    mode: PropTypes.oneOf([
      'simple',
      'face',
    ])
  };

  static defaultProps = {
    mode: 'simple',
    onCountDown: () => {}
  };

  static prefetch() {
    FaceDetectorPolyfill.prefetch();
  }

  overlayTextChooser = () => {
    if (this.isDone) return;
    const easingNumber = this.faceDetector?.fps.get() > 10
      ? EASING_NUMBER
      : EASING_NUMBER_LOW_FPS;

    // limit latestStatuses to the last {easingNumber} elements
    this.latestStatuses.splice(0, this.latestStatuses.length - easingNumber);

    this.latestStatus = mostCommonElement(this.latestStatuses, EASING_NUMBER_LOW_FPS);

    switch (this.latestStatus) {
      case 'NO_CAMERA':
        this.stopCountDown();
        this.props.onViewFinderText(PROCESSING);
        break;
      case 'MULTIPLE_FACES':
        this.stopCountDown();
        this.props.onViewFinderText(MULTIPLE_FACES_DETECTED);
        break;
      case 'FACE_TOO_SMALL':
        this.stopCountDown();
        this.props.onViewFinderText(INSTRUCTION_MOVE_CLOSER);
        break;
      case 'FACE_OK':
        if (!this.countDownStarted) {
          this.startNewCountDown();
        }
        break;
      case 'FACE_NOT_FOUND':
        this.stopCountDown();
        this.props.onViewFinderText(INSTRUCTION_FACE_FIT);
        break;
      default: /* noop */
    }
  };

  isFaceBigEnough = (input) => {
    const faceSize = typeof input === 'number'
      ? input
      : input.width * input.height;
    const videoElement = this.state.cameraVideoElement || {};
    // Multipler is introduced to limit text blinking when face is in the threshold
    // between "FACE_OK" and "FACE_TOO_SMALL".
    const multipler = this.latestStatus === 'FACE_OK' ? 1 : (FACE_OK_THRESHOLD ** 2);

    const minimumFaceSize = (MINIMUM_FACE_SIZE_PROPORTIONAL ** 2)
      * videoElement.videoWidth
      * videoElement.videoHeight;

    return faceSize >= (minimumFaceSize * multipler);
  };

  startLookingForFace = async () => {
    this.overlayTextChooser();

    const { cameraVideoElement, live, captureInProgress } = this.state;

    if (captureInProgress || this.isDestroyed || this.isDone) {
      this.isDone = true;
      return; // Fin.
    }

    if (!cameraVideoElement || !live) {
      this.latestStatuses.push('NO_CAMERA');
      return this.setTimeout(this.startLookingForFace, SCAN_INTERVAL);
    }

    if (!this.faceDetector) {
      this.faceDetector = new FaceDetectorPolyfill({ maxDetectedFaces: 2, maxWorkSize: 160 });
    }

    const faces = await this.faceDetector.detect(cameraVideoElement) || [];

    // We are using a face with the largest confidence. Not the largest one.
    const faceBoundingBox = faces[0] && faces[0].boundingBox;

    // NOTE: it's a good place to perform brightness check.

    if (faces.length > 1 && faces.every(this.isFaceBigEnough)) {
      this.latestStatuses.push('MULTIPLE_FACES');
    } else if (faceBoundingBox) {
      this.latestStatuses.push(
        this.isFaceBigEnough(faceBoundingBox)
          ? 'FACE_OK'
          : 'FACE_TOO_SMALL'
      );
    } else {
      this.latestStatuses.push('FACE_NOT_FOUND');
    }

    this.setTimeout(this.startLookingForFace, SCAN_INTERVAL);
  };

  componentWillUnmount() {
    this.stopCountDown();
    this.props.onViewFinderText('');
    if (this.faceDetector) {
      this.faceDetector.remove();
      this.faceDetector = null;
    }
    this.latestStatuses.length = 0;
    super.componentWillUnmount();
  }

  componentDidMount() {
    if (this.props.mode === 'face') {
      this.startLookingForFace();
    } else {
      // simple:
      this.startNewCountDown();
    }

    this.addEventListener(
      window,
      'orientationchange',
      () => {
        this.stopCountDown();

        if (this.props.mode === 'simple') {
          this.startNewCountDown();
        }
      }
    );

    this.syncStateWithModel(
      webcam.params,
      ['live', 'cameraVideoElement'],
      (field, value) => {
        this.stopCountDown();

        if (field === 'live') {
          if (!value) {
            return;
          }
        } else if (!this.state.live) {
          return;
        }

        if (this.props.mode === 'simple') {
          this.startNewCountDown();
        }
      }
    );
  }

  countDown({
    id,
    stateName,
    steps,
    delay,
  }) {
    return Promise.all(
      steps.map((step, index) => this.setTimeout(() => {
        if (
          id === this.countDownID // when new countDown started, forget about old one
          && !this.isDone
        ) {
          this.setState({ [stateName]: step });
          this.props.onCountDown(step);
        }
      }, index * delay))
    );
  }

  stopCountDown() {
    ++this.countDownID;
    this.countDownStarted = false;
    this.setState({ countDownText: '' });
  }

  startNewCountDown() {
    if (this.isDone) {
      return;
    }

    this.countDownStarted = true;
    const id = ++this.countDownID;

    const steps = Array
      .from({ length: this.props.mode === 'simple' ? 5 : 4 })
      .map((_, i) => i)
      .reverse(); // 4,3,2,1,0...

    this.countDown({
      delay: 1000,
      id: id,
      stateName: 'countDownText',
      steps
    }).then(() => {
      if (id === this.countDownID && !this.isDone && this.state.live) {
        this.isDone = true;

        this.props.onDone();
      }
    });
  }

  render(props, state) {
    if (state.captureInProgress || !state.countDownText || this.isDone) {
      return;
    }

    return (
      <div className={ this.rootcn`__text-on-top` }>
        { state.countDownText }
      </div>
    );
  }
}
