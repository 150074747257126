import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from 'components/ActionBar';
import BaseComponent from 'components/BaseComponent';
import Button from 'sf/components/Button';

export default class ProfileFooter extends BaseComponent {
  className = 'ts-ProfileFooter';

  static propTypes = {
    className: PropTypes.any,
    onDisableClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    return (
      <ActionBar
        className={ this.props.className }
        light={ true }
        title="Danger zone"
      >
        <Button
          className="ts-themed-color-on-hover"
          theme="ternary"
          onClick={ this.props.onDisableClick }
        >
          Disable your profile
        </Button>
      </ActionBar>
    );
  }
}
