import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import VideoCaptureTips from 'components/VideoCaptureTips';
import Dialog from 'components/Dialog';
import VideoCaptureButton from 'sf/components/VideoCaptureButton';

import user from 'models/user';

export default class UserPOLRetakeDialog extends BaseComponent {
  static propTypes = {
    onVerificationStart: PropTypes.func,
    onVerifySuccess: PropTypes.func,
    onVerifyError: PropTypes.func,
    closable: PropTypes.bool,
  };

  static defaultProps = {
    onVerificationStart: noop,
    onVerifySuccess: noop,
    onVerifyError: noop,
    closable: false,
  };

  // exposed as public
  toggle(...args) {
    this.newPolCaptureDialogInstance.toggle(args);
  }

  handleVerificationStart = () => {
    this.props.onVerificationStart();
  }

  handleVerifySuccess = () => {
    this.props.onVerifySuccess();
  }

  handleVerifyError = () => {
    this.props.onVerifyError();
  }

  checkPolReady = (passedResolver, passedRejector) => new Promise((resolve, reject) => {
    const resolver = passedResolver || resolve;
    const rejector = passedRejector || reject;
    user.getPolStatus()
      .then((data) => {
        // finished processing
        if (data.status === 200) {
          this.publish('GlobalLoader--toggle', false);
          resolver();
        } else {
          this.publish('GlobalLoader--toggle', true);
          setTimeout(() => this.checkPolReady(resolver, rejector), 2000);
        }
      })
      .catch(() => {
        this.publish('GlobalLoader--toggle', false);
        rejector();
      });
  });

  handlePolVideoTaken = (dataURI) => {
    this.handleVerificationStart();
    return user
      .updateUserPolVideo(dataURI)
      .then(this.checkPolReady)
      .then(user.getProfileData)
      .then(() => {
        // success
        this.handleVerifySuccess();
        this.newPolCaptureDialogInstance.toggle(false);
      })
      .catch((...args) => {
        this.handleVerifyError();
        console.error(...args); // eslint-disable-line
      });
  }

  render() {
    return (
      <Dialog
        ref={ (dialog) => { this.newPolCaptureDialogInstance = dialog; } }
        title="Take a proof of liveness video"
        closable={ this.props.closable }
      >
        <div className={ this.rootcn`ts-container` } >
          <VideoCaptureTips
            actionButton={
              <VideoCaptureButton
                ref={ (el) => { this.videoCaptureComponent = el; } }
                maxLength={ 5 * 1000 } // 5 seconds
                mediaRecorderFallback={ MEDIA_STREAM_DEFAULT_FALLBACK_TYPE }
                outlined={ false }
                onVideoTaken={ this.handlePolVideoTaken }
                overlay="face"
              />
            }
          />
        </div>
      </Dialog>
    );
  }
}
