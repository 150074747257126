import React from 'react';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import { BRANDINGS } from 'constants';
import BasePage from 'pages/BasePage';

export default class SessionExpired extends BasePage {
  className = 'ts-SessionExpired';

  getLogInURL = () => BRAND_NAME === BRANDINGS.REALTOR
    ? '/business/LogInCredentials.html'
    : '/LogIn.html';

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className="ts-container">
          <PageTitle title="Session expired" />
          <p className={ this.cn`__description` }>
            You were gone too long and your session has expired. Please log in again.
          </p>
          <Button onClick={ () => this.navigate(this.getLogInURL()) }>
            Log in
          </Button>
        </div>
      </div>
    );
  }
}
