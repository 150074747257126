import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import { getMailboxLink } from 'sf/helpers';
import user from 'models/user';

export default class PredefinedMessage extends BaseComponent {
  className = 'ts-PredefinedMessage';

  state = {};

  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.syncStateWithModel(user, ['email']);
  }

  getMessage(messageId) {
    switch (messageId) {
      case 'EMAIL_NOT_CONFIRMED':
        return (
          <span>
            Please&nbsp;
            { getMailboxLink(user.get('email'), 'go to your inbox') }
            &nbsp;and&nbsp;verify your e-mail address.
          </span>
        );
      default:
    }
  }

  render() {
    return (
      <span className={ this.rootcn() }>
        { this.getMessage(this.props.id) }
      </span>
    );
  }
}
