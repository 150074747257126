import React from 'react';
import PropTypes from 'prop-types';
import noop from 'no-op';

import BaseComponent from 'components/BaseComponent';

class Input extends BaseComponent {
  className = 'ts-Input';

  state = {}

  static propTypes = {
    inputProps: PropTypes.object,
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onChange: PropTypes.func,
    onEnterKeypress: PropTypes.func,
  };

  static defaultProps = {
    initialValue: '',
    onChange: noop,
    onEnterKeypress: noop,
  }

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue,
    };
    this.value = this.props.initialValue;
  }

  clear = () => {
    this.setState({
      value: '',
    });
    this.value = '';
  }

  handleChange = (e) => {
    const { value: inputValue } = e.target;
    this.setState({
      value: inputValue,
    }, () => {
      this.value = inputValue;
      this.props.onChange(inputValue);
    });
  }

  handleKeyPress = (e) => {
    const { value: inputValue } = e.target;
    if (e.key === 'Enter') {
      this.value = inputValue;
      this.props.onEnterKeypress(inputValue);
    }
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <input
          type="text"
          className={ this.cn`__input-field` }
          value={ this.state.value }
          { ...this.props.inputProps }
          onChange={ this.handleChange }
          onKeyPress={ this.handleKeyPress }
        />
        <div className={ this.cn`__fancy-line` } />
      </div>
    );
  }
}

export default Input;
