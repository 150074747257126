/* global ENABLE_CORDOVA_SUPPORT */
/* eslint-disable import/no-mutable-exports */
import device from 'sf/models/device';
import PhotoCaptureButtonBrowser from './PhotoCaptureButtonBrowser';

let result;
if (ENABLE_CORDOVA_SUPPORT && device.isCordova()) {
  const PhotoCaptureButtonCordova = require('./PhotoCaptureButtonCordova');

  result = PhotoCaptureButtonCordova;
} else {
  result = PhotoCaptureButtonBrowser;
}

export default result;
