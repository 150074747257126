import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import CompareImages from 'components/CompareImages';
import Dialog from 'components/Dialog';
import FeatureCard from 'components/FeatureCard';
import Link from 'components/Link';
import NewsletterBox from 'components/NewsletterBox';
import PageTitle from 'components/PageTitle';
import { ROUTES } from 'constants';
import store from 'helpers/store';
import { asset } from 'helpers';
import device from 'models/device';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import Icon from 'sf/components/Icon';

const videoSrc = 'https://drive.google.com/file/d/0ByppTZasfseWcEhRalE3UlRRUHM/preview?autoplay=1';
const COMPARE_IMAGES = [
  [
    asset`img/compare-laura.jpg`,
    asset`img/compare-laura-locked.jpg`,
  ], [
    asset`img/compare-jerry.jpg`,
    asset`img/compare-jerry-locked.jpg`,
  ],
];

export default class Index extends BasePage {
  className = 'ts-Index';

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: '',
  };

  state = {
    isHeroError: false,
    isHeroLoaded: false,
    compareImagesSet: 0,
  }

  componentDidMount() {
    this.setState({
      compareImagesSet: store.get('imagesSet'),
    }, () => {
      store.set('imagesSet', this.state.compareImagesSet === 0 ? 1 : 0);
    });

    this.syncStateWithModel(device, ['xxsmUp', 'xsmUp']);
  }

  componentDidMount() {
    user.set({ is_realtor: false });
  }

  handleSignUpButtonClick = () => {
    this.navigate('/SignUp.html?mode=default');
  }

  handleCompareImagesError = () => {
    this.setState({
      isHeroError: true,
    });
  }

  handleCompareImagesLoaded = () => {
    this.setState({
      isHeroLoaded: true,
    });
  }

  handleVideoButtonClick = () => {
    if (this.state.xsmUp) {
      this.refs.dialog.toggle(true);
    } else {
      open(videoSrc);
    }
  }

  renderHeroSection() {
    const heroClass = {
      'ts-hero': true,
      'ts-hero--error': this.state.isHeroError,
      'ts-hero--loaded': this.state.isHeroLoaded,
    };
    return (
      <section className={ this.cn`__hero` }>
        <div className={ this.cn(heroClass) }>
          <div className="ts-hero__feature">
            <div className="ts-browser">
              <div className="ts-browser__content">
                <CompareImages
                  images={ COMPARE_IMAGES[this.state.compareImagesSet || 0] }
                  onLoad={ this.handleCompareImagesLoaded }
                  onError={ this.handleCompareImagesError }
                  initialSeparatorPosition={ 100 }
                  separatorPosition={ this.state.xxsmUp ? 320 : 100 }
                />
              </div>
            </div>
          </div>
          <div className="ts-container">
            <div className="ts-hero__content">
              <h1 className="ts-hero__title">
                <span className="ts-hero__title-part">Be Known - </span>
                <span className="ts-hero__title-part">Be Safe - </span>
                <span className="ts-hero__title-part">Be Trusted</span>

                <span className="ts-hero__subtitle">
                  In Under 2 minutes
                </span>
              </h1>

              <p>
                Trust Stamp uses social media and other publicly available data
                to verify your identity and provide a unique FICO-like trust score.
              </p>
              <p>
                Once your score is created, you can improve it by adding additional
                information. While the details of your score are private and under
                your control, you can easily share your trust score on any platform.
              </p>
              <div className="ts-hero__button">
                <Button theme="primary" onClick={ this.handleSignUpButtonClick }>
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderHowTrustStampWorksSection() {
    /* eslint-disable max-len */
    return (
      <section className={ this.cn`__section` }>
        <div className="ts-container">
          <PageTitle
            size="big"
            title="How Trust Stamp Works"
            subtitle="Getting verified online in just three simple steps"
          />
          <div className={ this.cn`__steps` }>
            <div className="ts-steps">
              <div className="ts-steps__item">
                <FeatureCard
                  title="Create your Account"
                  text="Account creation is fast, easy and secure. If only everything worked this way!"
                  imageUrl={ asset`img/account.svg` }
                  number={ 1 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title="Connect your Social Networks"
                  text="The more accounts you connect, the higher your potential Trust Score!
                    Your account information is not stored and your privacy is protected."
                  imageUrl={ asset`img/social-networks.svg` }
                  number={ 2 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title="Share your Trust Stamp & Score"
                  text="Your Trust Stamp and Trust Score allow others to know that
                    you are who you say you are, creating a basis for a trusting relationship."
                  imageUrl={ asset`img/trustscore.svg` }
                  number={ 3 }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
    /* eslint-enable */
  }

  renderWhyRegisterServices() {
    /* eslint-disable */
    const services = [{
      icon: 'lock-combination',
      title: 'Bank Level Security',
      text: 'We maintain minimal information on our secure servers and\u00A0use SSLsecurity to protect your data. We are also implementing blockchain verification using the Ethereum distributed ledger and we will keep you updated on the transition to the Homestead implementation.'
    }, {
      icon: 'person-stalker',
      title: 'User Support',
      text: (<span>If you have a question, send it via <Link to={ ROUTES.CONTACT_US }>contact us</Link> form and we promise to reply within 24 hours.</span>)
    }, {
      icon: 'eye',
      title: 'Control your Online Trust Standing',
      text: [
        'Traditional background check companies provide expensive automated reports, but they don’t show you how others see you online. You may never know that someone has bought a\u00A0report that shows you in a bad light.',
        'By creating a Trust Stamp, you can easily see yourself the\u00A0way that others will see you. Most importantly, you ensure that others see the positive information about you!'
      ]
    }];
    /* eslint-enable */

    return (
      <div className="ts-services">
        {
          services.map(({ icon, title, text }) => {
            const textLines = [].concat(text);
            return (
              <div key={ title } className="ts-services__item">
                <div className="ts-services__title">
                  <span className="ts-services__title-icon">
                    <Icon set="io" type={ icon } size={ 24 }>
                      { title }
                    </Icon>
                  </span>
                  <h1 className="ts-services__title-label">
                    { title }
                  </h1>
                </div>
                {
                  textLines.map((line) => {
                    return (
                      <p key={ line } className="ts-services__text">
                        { line }
                      </p>
                    );
                  })
                }
              </div>
            );
          })
        }
      </div>
    );
  }

  renderWhyRegisterSection() {
    return (
      <section className={ this.cn`__section` }>
        <div className="ts-container">
          <PageTitle
            size="big"
            title="Why Register for a Trust Stamp?"
            subtitle="The only secure identity verification and trustworthiness token"
          />
          <div className={ this.cn`__services` }>
            { this.renderWhyRegisterServices() }
          </div>
        </div>
      </section>
    );
  }

  renderIdentityVerificationSection() {
    return (
      <section className={ this.cn`__section __section--why-register` }>
        <div className="ts-container">
          <div className={ this.cn`__section-content` }>
            <img className={ this.cn`__icon-user` } src={ asset`img/icon-user.svg` } alt="" />
            <PageTitle
              align="left"
              size="big"
              title="Safe & Easy Identity Verification for&nbsp;Everyone"
            />
            <p>
              You choose which social media and e-commerce accounts you link to provide
              identity verification. The greater the number and the older the accounts are,
              the higher your potential score. We also give you additional credit
              for the number and age of your documented relationships.
            </p>
            <p>
              You will have future opportunities to add additional social media
              and e-commerce accounts so you can start with your oldest and most used
              accounts or add all your accounts now. Your Trust Score, Your Choice!
            </p>
            <Button
              size="huge"
              theme="ternary"
              onClick={ this.handleVideoButtonClick }
            >
              <Icon type="play" size={ 16 }>
                Play video
              </Icon>
              <span className={ this.cn`__video-label __video-label--short` }>
                How Trust Stamp works
              </span>
              <span className={ this.cn`__video-label __video-label--long` }>
                Want to see how a Trust Stamp is made?
              </span>
            </Button>
          </div>
        </div>
      </section>
    );
  }

  renderSignUpSection() {
    return (
      <section className={ this.cn`__section __section--sign-up` }>
        <div className="ts-container">
          <p className={ this.cn`__sign-up-text` }>
            Sign up today for free. As a founder member you will never pay to maintain your account.
          </p>
          <div className={ this.cn`__sign-up-button` }>
            <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
              Get Started
            </Button>
          </div>
        </div>
      </section>
    );
  }

  renderNewsletterSection() {
    return (
      <section className={ this.cn`__section __section--newsletter` }>
        <div className="ts-container">
          <PageTitle
            title="Newsletter"
            subtitle="Subscribe to our newsletter and stay updated"
          />
          <div className={ this.cn`__newsletter` }>
            <NewsletterBox showTitle={ false } />
          </div>
        </div>
      </section>
    );
  }

  renderVideoDialog() {
    return (
      <Dialog
        className={ this.cn`__video-dialog` }
        ref="dialog"
        title="How a Trust Stamp is made"
      >
        <iframe
          allowFullScreen={ true }
          className={ this.cn`__video-iframe` }
          frameBorder="0"
          height="360"
          src={ videoSrc }
          width="640"
        />
      </Dialog>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderHeroSection() }
        { this.renderHowTrustStampWorksSection() }
        { this.renderIdentityVerificationSection() }
        { this.renderWhyRegisterSection() }
        { this.renderSignUpSection() }
        { this.renderNewsletterSection() }
        { this.renderVideoDialog() }
      </div>
    );
  }
}
