import React from 'react';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';


const StyledLabel = ({ children }) => (
  <div className="ts-StyledLabel">
    { children }
  </div>
);

StyledLabel.propTypes = {
  children: PropTypes.node,
};

StyledLabel.defaultProps = {
  children: null,
};

const StyledInput = ({ children }) => (
  <div className="ts-StyledInput">
    { children }
  </div>
);

StyledInput.propTypes = {
  children: PropTypes.node,
};

StyledInput.defaultProps = {
  children: null,
};

const StyledFooter = ({ children }) => (
  <div className="ts-StyledFooter">
    { children }
  </div>
);

StyledFooter.propTypes = {
  children: PropTypes.node,
};

StyledFooter.defaultProps = {
  children: null,
};

function StyledFormComponent({ width, centered, children }) {
  const classes = {
    [`--width-${width}`]: true,
    '--centered': centered,
  };
  return (
    <div className={ this.rootcn() }>
      <div className={ this.cn(classes) }>
        { children }
      </div>
    </div>
  );
}

const StyledForm = baseFunctionalComponent(StyledFormComponent, 'StyledForm');

StyledFormComponent.propTypes = {
  width: PropTypes.oneOf(['default', 'narrow']),
  centered: PropTypes.bool,
  children: PropTypes.node,
};

StyledFormComponent.defaultProps = {
  width: 'default',
  centered: false,
  children: null,
};

export {
  StyledForm,
  StyledLabel,
  StyledFooter,
  StyledInput,
};

export default StyledForm;
