import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import Card from 'components/Card';
import VideoCaptureButton from 'sf/components/VideoCaptureButton';
import VideoCaptureTips from 'components/VideoCaptureTips';

class BusinessSignUpVideo extends BaseComponent {
  render() {
    return (
      <div
        className={ this.rootcn`ts-container ts-container--narrow` }
      >
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          <VideoCaptureTips
            actionButton={
              <VideoCaptureButton
                ref={ (el) => { this.videoCaptureNode = el; } }
                maxLength={ 5 * 1000 } // 5 seconds
                mediaRecorderFallback={ MEDIA_STREAM_DEFAULT_FALLBACK_TYPE }
                outlined={ false }
                onVideoTaken={ this.props.onSubmit }
                overlay="face"
              />
            }
          />
        </Card>
      </div>
    );
  }
}

BusinessSignUpVideo.propTypes = {
  onSubmit: PropTypes.func,
};

BusinessSignUpVideo.defaultProps = {
  onSubmit: noop,
};

export default BusinessSignUpVideo;
