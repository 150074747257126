import React from 'react';
import webcam from 'mighty-webcamjs';
import { Link } from 'react-router';

import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';
import Button from 'components/Button';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import Render from 'components/Render';
import SocialServicesCards, { MODES } from 'components/SocialServicesCards';
import {
  BRANDINGS,
  BUSINESS_SERVICE,
  FAILED_LOGIN_ATTEMPTS_BEFORE_NOTIFICATION,
  PIN_CODE_LENGTH,
  ROLES,
  ROUTES,
  SERVICES,
} from 'constants';
import { goToRealtorApp } from 'helpers';
import { getQuery } from 'sf/helpers';
import {
  getHelpCenterNotification,
  adaptResponseMessage,
} from 'helpers/notifications';
import store from 'helpers/store';
import analytics from 'models/analytics';
import device from 'models/device';
import help from 'models/help';
import login from 'models/login';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import Icon from 'sf/components/Icon';
import Card from 'sf/components/Card';
import ValidationInput from 'sf/components/ValidationInput';

const CONTEXT_HELP_NOTIFICATIONS = {
  SOCIAL_CARDS: [
    'FORGOT_WHICH_NETWORK',
  ],
  SELFIE: [
    'NO_WEBCAM',
    'WHY_IS_SELFIE_NEEDED',
  ],
  PIN: [
    'FORGOT_PIN',
    'WHAT_IS_A_PIN',
  ],
};

const STEP = {
  SOCIAL_CARDS: 'SOCIAL_CARDS',
  SELFIE: 'SELFIE',
  PIN: 'PIN',
  RESET_PIN: 'RESET_PIN',
};

let selfieFails = 0;
let pinFails = 0;

export default class LogIn extends BasePage {
  className = 'ts-LogIn';
  title = 'Log in';
  subtitle = 'You will take a selfie and enter your PIN code next.';

  state = {
    isResetEmailSent: false,
    pinCode: '',
  };

  logIn(token) {
    let next = store.get('next') || '/Profile.html';
    store.remove('next');

    if (next === 'REALTOR_APP') {
      next = '/';
      this.goBackToRealtorApp(token);
    } else {
      user.set('token', token);
      user.set('isSignedIn', true);
      user.set('roles', [ROLES.SCORE_USER]);
    }

    this.navigate(next);
  }

  goBackToRealtorApp(token) {
    const query = {
      token: token,
      userRoles: JSON.stringify(user.get('roles') || []),
    };
    goToRealtorApp(query);
  }

  componentDidMount() {
    // backend redirects to LogIn.html#token=... or #error=...
    // `setRestricted` uses #next=...
    const hashQuery = getQuery(location.hash);
    const query = getQuery();
    const next = hashQuery.next || query.next || store.get('next') || '';
    store.set('next', next);

    if (user.get('isSignedIn')) {
      if (next === 'REALTOR_APP') {
        this.logIn(user.get('token'));
      } else {
        this.navigate('/Profile.html');
      }
      return;
    }
    if (user.get('pinResetCode')) {
      user.set('pinResetCode', null);
    }

    if (this.getLogInStep() === STEP.SOCIAL_CARDS && next === 'REALTOR_APP') {
      const socialSigin = document.querySelector(`.ts-CardButton--${query.socialNetwork}`);
      if (socialSigin) socialSigin.click();
    }

    if (hashQuery.error) {
      this.notify('error', adaptResponseMessage(hashQuery.error));
      location.hash = '';
    } else if (hashQuery.token) {
      this.logIn(hashQuery.token);
    }
    this.syncStateWithModel(device, ['smUp', 'xxsmUp']);
  }

  handlePINCodeKeyUp = (event) => {
    const pinCode = this.state.pinCode.replace(/_/g, '');
    if (event.keyCode === 13 && pinCode.length === PIN_CODE_LENGTH) {
      this.handlePINCodeSubmit();
    }
  };

  handlePINResetCodeKeyUp = (event) => {
    if (event.keyCode === 13) {
      this.handlePINResetSubmit();
    }
  };

  handlePINCodeSubmit = () => {
    const done = (token) => {
      this.logIn(token);
      analytics.trackEvent('WEBAPP_PIN_LOGIN_SUCCESS');
    };
    const fail = (err) => {
      if (err.errorCode === 404) {
        this.displayInvalidTokenHCWarning();
      } else if (++pinFails >= FAILED_LOGIN_ATTEMPTS_BEFORE_NOTIFICATION) {
        this.notify(
          'warning',
          getHelpCenterNotification('INCORRECT_PIN')
        );
      }
      analytics.trackEvent('WEBAPP_PIN_LOGIN_FAILURE');
    };
    login.sendPINCode(this.state.pinCode).then(done, fail);
  };

  // NOTE: Also used in RealtorLogin.js. Remember to update it accordingly.
  displayInvalidTokenHCWarning = () => {
    help.addNotification({
      type: 'warning',
      value: (
        <div>
          { /* eslint-disable max-len */ }
          <p>
            Unfortunately, your login token is not valid (probably got expired). Please start the login process from scratch.
          </p>
          <Button
            theme="ternary"
            onClick={ () => this.navigate('/LogIn.html') }
          >
            Start from scratch
          </Button>,
          { /* eslint-enable max-len */ }
        </div>
      ),
    });
    help.toggle();
  };

  handlePINResetSubmit = () => {
    const done = () => {
      this.setState({
        isResetEmailSent: true,
      });
    };
    const fail = () => {};
    login.sendResetPINEmail({
      email: this.state.email,
    }).then(done, fail);
  };

  photoCaptured = (dataURI) => {
    webcam.freeze();
    const done = () => {
      this.navigate('/LogInPIN.html');
      analytics.trackEvent('WEBAPP_SELFIE_LOGIN_SUCCESS');
    };
    const fail = () => {
      if (++selfieFails >= FAILED_LOGIN_ATTEMPTS_BEFORE_NOTIFICATION) {
        this.notify(
          'warning',
          getHelpCenterNotification('SELFIE_FAILED_TWO_TIMES')
        );
      }
      webcam.unfreeze();
      analytics.trackEvent('WEBAPP_SELFIE_LOGIN_FAILURE');
    };

    login.sendSelfie(dataURI).then(done, fail);
  };

  getLogInStep() {
    const addr = this.props.location && this.props.location.pathname;
    switch (addr) {
      case '/LogInSelfie.html': // TODO: rely on some other data source than url
        return STEP.SELFIE;
      case '/LogInPIN.html': case 'business/LogInPIN.html':
        return STEP.PIN;
      case '/LogInResetPIN.html':
        return STEP.RESET_PIN;
      default:
        return STEP.SOCIAL_CARDS;
    }
  }

  isPinCodeValid = () => {
    return /\d{4}/.test(this.state.pinCode);
  };

  getSubpageData() {
    switch (this.getLogInStep()) {
      case STEP.SELFIE:
        return {
          content: (
            <div className={ this.cn`__selfie-step` }>
              <div className={ this.cn`ts-container ts-container--narrow` }>
                <LocalAdvancedPhotoCapture
                  allowCrop={ false }
                  allowRetake={ false }
                  fullScreen={ false }
                  onPhotoTaken={ this.photoCaptured }
                  overlay="face"
                />
              </div>
            </div>
          ),
          title: 'Take a great selfie',
          subtitle: (
            <div>
              { this.state.smUp ?
                <ul style={ { textAlign: 'left', margin: '0 auto', maxWidth: '400px' } }>
                  <li>make sure that your face fits the light oval</li>
                  <li>have optimal lighting -  consider turning on your lights or<br />
                    moving closer to the window
                  </li>
                  <li>act natural!</li>
                </ul> :
                <p>Move the phone a few inches toward your face and act natural</p>
              }
            </div>
          ),
        };
      case STEP.PIN:
        return {
          content: (
            <div className={ this.cn`__pin-code-step` }>
              <ValidationInput
                className={ this.cn`__pin-input` }
                displayName="Your PIN number"
                stateLink={ [this, 'pinCode'] }
                maxLength={ 4 }
                inputMode="numeric"
                onKeyUp={ this.handlePINCodeKeyUp }
              />
              <Button
                disabled={ !this.isPinCodeValid() }
                onClick={ this.handlePINCodeSubmit }
              >
                That’s my PIN
              </Button>
              <Link
                to="/LogInResetPIN.html"
                className={ this.cn`__reset-pin-link` }
              >
                Forgot your pin?
              </Link>
            </div>
          ),
          title: 'Enter your PIN number',
          subtitle: 'You used it during registration',
        };
      case STEP.SOCIAL_CARDS:
        return {
          content: (
            <div className={ this.cn`__cards` }>
              <SocialServicesCards
                includeBusinessOnlyService={ BRAND_NAME === BRANDINGS.REALTOR }
                mode={ MODES.LOG_IN }
                services={ [
                  ...BRAND_NAME === BRANDINGS.REALTOR ? [BUSINESS_SERVICE] : [],
                  ...SERVICES,
                ].filter(({ isUsedDuringAuth }) => isUsedDuringAuth) }
              />
            </div>
          ),
          title: this.title,
          subtitle: this.subtitle,
        };
      case STEP.RESET_PIN:
        return {
          content: (
            <div className={ this.cn`ts-container` }>
              <div
                className={ this.cn({
                  '__pin-reset-step': true,
                  '__pin-reset-step--hidden': this.state.isResetEmailSent,
                }) }
              >
                <ValidationInput
                  className={ this.cn`__email-input` }
                  displayName="Your e-mail"
                  type="email"
                  stateLink={ [this, 'email'] }
                  placeholder="john.doe@example.com"
                  onKeyUp={ this.handlePINResetCodeKeyUp }
                />
                <Button onClick={ this.handlePINResetSubmit }>Reset your PIN</Button>
                <Link
                  to="/LogInPIN.html"
                  className={ this.cn`__reset-pin-link` }
                >
                  Remembered your PIN?
                </Link>
              </div>
              <div
                className={ this.cn({
                  '__pin-reset-email-sent': true,
                  '__pin-reset-email-sent--visible': this.state.isResetEmailSent,
                }) }
              >
                <Icon
                  className={ this.cn`__pin-reset-email-sent-icon` }
                  set="io"
                  type="checkmark"
                />
                <span className={ this.cn`__pin-reset-email-sent-text` }>
                  Email sent!
                </span>
                <span className={ this.cn` __no-reset-email` }>
                  Didn&lsquo;t receive an email?&nbsp;
                  <Link
                    className={ this.cn`__no-reset-mail-link` }
                    to={ ROUTES.CONTACT_US }
                  >
                    Contact us
                  </Link>
                </span>
              </div>
            </div>
          ),
          title: 'Reset your PIN number',
          subtitle: this.state.isResetEmailSent
            ? 'We’ve sent you an email with a link to reset your PIN number'
            : 'Enter e-mail address you used during registration',
        };
      default:
        return null;
    }
  }

  getNotifications() {
    return CONTEXT_HELP_NOTIFICATIONS[this.getLogInStep()] || [];
  }

  render() {
    const { content, title, subtitle } = this.getSubpageData();
    const logInStep = this.getLogInStep();
    const notifications = this.getNotifications();
    const classNames = this.state.xxsmUp ? 'ts-container ts-container--narrow' : null;
    const helpButtonClassNames = {
      '__title-help-button': true,
      '__title-help-button--login': logInStep === STEP.SOCIAL_CARDS,
      '__title-help-button--selfie': logInStep === STEP.SELFIE,
      '__title-help-button--pin': logInStep === STEP.PIN,
    };
    const pageTitleClassName = {
      '__title': true,
      '__title-with-help': notifications.length,
    };

    return (
      <div className={ this.rootcn(classNames) }>
        <Card
          className={ this.cn`__card` }
          size="small"
          fullWidth={ true }
        >
          <div className={ this.cn`__page-title` }>
            <PageTitle
              className={ this.cn(pageTitleClassName) }
              title={ title }
              subtitle={ subtitle }
            />
            <Render when={ notifications.length }>
              <HelpButton
                className={ this.cn(helpButtonClassNames) }
                notifications={ this.getNotifications() }
              />
            </Render>
          </div>
          <div className={ this.cn`__content` }>
            { content }
          </div>
        </Card>
      </div>
    );
  }
}
