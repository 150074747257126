import React from 'react';
import is from 'next-is';
import BaseComponent from 'components/BaseComponent';
import PhotoCaptureButton from 'sf/components/PhotoCaptureButton';
import AdvancedPhotoCapture from 'sf/components/AdvancedPhotoCapture';

/**
 * In truststamp.net we are using fullscreen photo capture only on mobile.
 * On desktop, camera controls are placed directly into page contents.
 */

export default class LocalAdvancedPhotoCapture extends BaseComponent {
  state = { mounted: false };

  componentDidMount() {
    this.setState({ mounted: true }); // eslint-disable-line
  }

  /* public */ getInstance() {
    return this.advancedPhotoCaptureInstance ||
      this.buttonInstance && this.buttonInstance.refs.AdvancedPhotoCapture;
  }

  render() {
    if (this.state.mounted && is.mobile()) {
      return (
        <PhotoCaptureButton
          ref={ (el) => { this.buttonInstance = el; } }
          theme="success"
          mainAction={ true }
          { ...this.props }
        />
      );
    }
    return (
      <AdvancedPhotoCapture
        ref={ (el) => { this.advancedPhotoCaptureInstance = el; } }
        { ...this.props }
      />
    );
  }
}
