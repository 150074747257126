import React from 'react';
import throttle from 'lodash/throttle';
import Button from 'components/Button';
import Link from 'components/Link';
import Render from 'components/Render';
import { asset } from 'helpers';
import { addEventListener, removeEventListener, scrollTo } from 'sf/helpers/domHelper';
import device from 'models/device';
import BasePage from 'pages/BasePage';
import { Col, Row } from 'sf/components/Grid';
import Icon from 'sf/components/Icon';
import InfographicTableOfContents from './InfographicTableOfContents';
import LandingInfographic from './LandingInfographic';

/* eslint-disable max-len */
const cards = [{
  id: 'your-safety',
  content: (
    <p>Meeting with strangers can be dangerous. With just a&nbsp;few clicks, you can keep yourself and those you love safe. Trust Stamp uses over two hundred data sources to help make sure of that.</p>
  ),
  emphasisInTitle: 'your safety',
  image: asset`img/marketplace-heroes/watch-out.svg`,
  title: 'Stay safe',
  fullTitle: 'Technology that helps ensure your safety',
}, {
  id: 'verifying-people',
  content: (
    <p>It only takes about two minutes to verify the other party. You can confirm they are who they say they are all for less than a cup of coffee.</p>
  ),
  emphasisInTitle: 'Verifying people',
  image: asset`img/marketplace-heroes/trust-score.png`,
  title: 'Verify people',
  fullTitle: 'Verifying people has never been so simple and affordable',
}, {
  id: 'helping-spread',
  content: (
    <p>Every time you share your profile or ask to verify someone, we will donate 25 cents to the local charity of your Heroes' choice. You can learn more about the campaign&nbsp;
      <Link to="/mph">here</Link>.
    </p>
  ),
  emphasisInTitle: 'Helping',
  image: asset`img/marketplace-heroes/heart-coin-hand.svg`,
  title: 'Help others',
  fullTitle: 'Helping support other&nbsp;causes',
}];
/* eslint-enable max-len */

const TABLE_OF_CONTENTS = [
  ...cards,
  {
    id: 'join-us',
    title: 'Join Us',
  },
];

export default class MarketplaceHeroesJoinIndex extends BasePage {
  className = 'ts-MarketplaceHeroesJoinIndex';
  title = 'Page:MarketplaceHeroesJoinIndex';
  isUnmounted = true;

  state = {
    activeCardIndex: 0,
    activeCard: cards[0],
  }

  componentDidMount() {
    this.isUnmounted = false;
    this.syncStateWithModel(device, ['mdUp']);

    this.setVisibleCardAsActive = throttle(this.setVisibleCardAsActive, 100);
    addEventListener(window, 'scroll', this.setVisibleCardAsActive);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.isUnmounted = true;
    removeEventListener(window, 'scroll', this.setVisibleCardAsActive);
  }

  setVisibleCardAsActive = () => {
    if (this.isUnmounted) return;

    const currentCard = TABLE_OF_CONTENTS.find((element, index) => {
      const ref = this[this.getCardRefName(index)];
      const rect = ref.getBoundingClientRect();
      return rect.top + rect.height > document.documentElement.clientHeight / 2;
    });
    if (this.state.activeCard.id !== currentCard.id) {
      this.setState({
        activeCard: currentCard,
      });
    }
  }

  getCardRefName = (n) => {
    return `card-${n}`;
  }

  scrollToCardByIndex(n) {
    const card = this[this.getCardRefName(n)];
    if (!card) return;
    scrollTo(card, 300, 0);
  }

  handleCardButtonClick = (index) => {
    this.setState({
      activeCardIndex: index + 1,
    }, () => {
      this.scrollToCardByIndex(this.state.activeCardIndex);
    });
  }

  scrollToCard = (card) => {
    const index = TABLE_OF_CONTENTS.findIndex((c) => c.id === card.id);
    this.scrollToCardByIndex(index);
  }

  renderCard = ({ id, content, emphasisInTitle, image, fullTitle }, index) => {
    const classNames = {
      '__card': true,
      [`__card--${id}`]: id,
    };
    return (
      <div
        className={ this.cn(classNames) }
        key={ id }
        ref={ (instance) => { this[this.getCardRefName(index)] = instance; } }
      >
        <div className={ this.cn`ts-container` }>
          <Row center={ ['md'] } middle={ ['xxsm'] }>
            <Col md={ 8 } lg={ 12 } >
              <div className={ this.cn`__infographic` }>
                <LandingInfographic
                  id={ id }
                  emphasisInTitle={ emphasisInTitle }
                  image={ image }
                  title={ fullTitle }
                >
                  { content }
                </LandingInfographic>
              </div>
              <div className={ this.cn`__card-scroll-button-wrapper` }>
                <div
                  className={ this.cn`__card-scroll-button` }
                  onClick={ () => this.handleCardButtonClick(index) }
                  role="button"
                  tabIndex={ 0 }
                >
                  <Icon type="ios-arrow-down" set="io" size={ 20 } />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderLastCard = () => {
    const className = this.cn({
      '__card': true,
      '__card--join-us': true,
    });
    return (
      <div
        className={ className }
        ref={ (instance) => { this[this.getCardRefName(cards.length)] = instance; } }
      >
        <div className={ this.cn`ts-container` }>
          <Row center={ ['xxsm'] } middle={ ['lg'] }>
            <Col sm={ 5 }>
              <div className={ this.cn`__call-to-action` }>
                <h1 className={ this.cn`__title` }>
                  <span className={ this.cn`__title--emphasize` }>
                    Join Us&nbsp;
                  </span>
                  to<br />start helping others
                </h1>
                <div className={ this.cn`__sign-up-button` }>
                  <Button
                    onClick={ () => this.navigate('/SignUp.html') }
                    size="big"
                  >
                    Sign up
                  </Button>
                </div>
                <div className={ this.cn`__separator` }>or</div>
                <Button
                  onClick={ () => this.navigate('/LogIn.html') }
                  theme="primary"
                >
                  Log in
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`` }>
        <Render when={ this.state.mdUp }>
          <div className={ this.cn`__infographic-table-of-contents` }>
            <InfographicTableOfContents
              activeEntry={ this.state.activeCard }
              entries={ TABLE_OF_CONTENTS }
              onClick={ this.scrollToCard }
            />
          </div>
        </Render>
        { cards.map(this.renderCard) }
        { this.renderLastCard() }
      </div>
    );
  }
}
