import { Element, scroller } from 'react-scroll';
import noop from 'no-op';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog, { DialogText } from 'components/Dialog';
import IconButton from 'components/IconButton';
import Payment from 'components/Payment';
import Render from 'components/Render';
import Stamp from 'components/Stamp';
import { creditPrice, refreshCost } from 'config';
import device from 'models/device';
import user from 'models/user';

const SCROLL_DURATION = 1000;
const TOOLTIP_VISIBILITY_DURATION = 4000;

export default class RefreshProfileButton extends BaseComponent {
  className = 'ts-RefreshProfileButton';

  state = {
    isRefreshed: false,
    paymentInProgress: false,
  }

  componentDidMount() {
    this.syncStateWithModel(device, ['xxsmDown']);
    this.syncStateWithModel(user, ['credit_price', 'number_of_credits', 'has_first_refresh_free']);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  activateTooltip() {
    this.setState({
      isRefreshed: false,
    });
    scroller.scrollTo('tooltip', {
      duration: SCROLL_DURATION,
      delay: 100,
      smooth: true,
    });

    const showTimeout = setTimeout(() => {
      ReactTooltip.show(this.tooltipNode);
      clearTimeout(showTimeout);
    }, SCROLL_DURATION);
    const hideTimeout = setTimeout(() => {
      ReactTooltip.hide(this.tooltipNode);
      clearTimeout(hideTimeout);
    }, SCROLL_DURATION + TOOLTIP_VISIBILITY_DURATION);
  }

  handleRefreshProfileButtonBlur = () => {
    this.deactivateTooltip();
  }

  deactivateTooltip = () => {
    ReactTooltip.hide(this.tooltipNode);
  }

  handleRefreshButtonClick = () => {
    ReactTooltip.hide(this.tooltipNode);
    this.dialogInstance.toggle(true);
  }

  handleSuccessfulPaymentForRefresh = () => {
    this.dialogInstance.toggle(false);
    user.refreshProfile().then(() => {
      this.setState({
        isRefreshed: true,
      });
      user.getProfileData();
    });
  }

  handlePaymentSubmit = () => {
    this.setState({ paymentInProgress: true });
  }

  handlePaymentError = (msg) => {
    this.setState({ paymentInProgress: false });
    this.dialogInstance.showFloatingText({
      text: msg || 'Payment failed',
      isValid: false,
    });
  }

  renderDialogContent = () => {
    const isFirstRefreshFree = user.get('has_first_refresh_free');
    const refreshCostCents = Math.ceil(creditPrice * refreshCost * 100);

    if (this.state.credit_price === -1) { // free (and no payments) when -1
      return (
        <div>
          <p className={ this.cn`__paragraph` }>
            Are you done editing your profile and want to refresh it?
          </p>
          <div className={ this.cn`__refresh-dialog-button` }>
            <Button onClick={ this.handleSuccessfulPaymentForRefresh }>
              Refresh now
            </Button>
          </div>
        </div>
      );
    }
    return isFirstRefreshFree ? (
      <div>
        <p>
          Are you done editing your profile and want to refresh it?
        </p>
        <p>
          { /* eslint-disable max-len */ }
          First refresh is <span className={ this.cn`__important-text` }>free</span>, every next one costs<br />{ refreshCostCents } cents or <Stamp>{ refreshCost }</Stamp>
          { /* eslint-enable max-len */ }
        </p>
        <div className={ this.cn`__refresh-dialog-button` }>
          <Button onClick={ this.handleSuccessfulPaymentForRefresh }>
            Refresh now
          </Button>
        </div>
      </div>
    ) : (
      <div>
        <p className={ this.cn`__paragraph` }>
          Are you done editing your profile and want to refresh it?
        </p>
        <p className={ this.cn`__paragraph` }>
          { /* eslint-disable max-len */ }
          Every refresh costs <Stamp>{ refreshCost }</Stamp> or { refreshCostCents } cents.
          { /* eslint-enable max-len */ }
        </p>
        <div className={ this.cn`__payments` }>
          <div className={ this.cn`__payments-item` }>
            <div className={ this.cn`__payments-title` }>
              You currently have <Stamp>{ user.getCreditsBalance() }</Stamp>
            </div>
            <Button
              disabled={ user.get('number_of_credits') < refreshCost }
              onClick={ this.handleSuccessfulPaymentForRefresh }
            >
              Use your credits
            </Button>
          </div>
          <div className={ this.cn`__payments-item` }>
            <div className={ this.cn`__payments-title` }>
              Pay with PayPal or Credit Card
            </div>
            <Payment
              amount={ refreshCost }
              disabled={ this.state.paymentInProgress }
              onSubmit={ this.handlePaymentSubmit }
              onError={ this.handlePaymentError }
              onSuccess={ this.handleSuccessfulPaymentForRefresh }
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Element name="tooltip">
        <div
          className={ this.rootcn() }
          data-delay-show="300"
          data-effect="solid"
          data-html="true"
          data-offset="{'bottom': '-20px'}"
          data-place="bottom"
          data-tip="
            <p>Here you can refresh the profile to see how your TrustScore has changed.</p>
            <p>The profile is also refreshed when someone views it.</p>
          "
          ref={ this.createRef('tooltipNode') }
        >
          <Render when={ !this.state.isRefreshed }>
            <IconButton
              iconSize={ 18 }
              onClick={ this.handleRefreshButtonClick }
              theme="ternary"
              type="loop"
              onBlur={ this.handleRefreshProfileButtonBlur }
            >
              Refresh profile
            </IconButton>
          </Render>
          <Render when={ this.state.isRefreshed }>
            <IconButton
              iconSize={ 18 }
              onClick={ noop }
              theme="secondary"
              type="checkmark"
              disabled={ true }
            >
              Profile refreshed
            </IconButton>
          </Render>
        </div>
        <ReactTooltip
          class={ this.cn`__tooltip` }
          disable={ this.state.isRefreshed }
        />
        <Dialog
          ref={ (c) => { this.dialogInstance = c; } }
          title="Refresh your profile"
        >
          <DialogText>
            { this.renderDialogContent() }
          </DialogText>
        </Dialog>
      </Element>
    );
  }
}
