import atom from 'atom-js';
import { errorHandler, get, requestEnd } from 'sf/helpers/request';

const model = atom.setup({
  validation: {},
  methods: {
    getSocialNetworks(resolve, reject) {
      get('backend/signup/select_socialnetwork/')
        .end(requestEnd(resolve, reject));
    },
    getRemainingProfileSocialNetworks(resolve, reject) {
      get('backend/profile/unconnected_services/', 'UNIVERSAL')
        .end(requestEnd(resolve, reject));
    },
    getSocialNetworksForLogIn(resolve, reject) {
      get('backend/login/select_socialnetwork/')
        .end(requestEnd(resolve, reject));
    },
    getOneAllUserToken() {
      get('backend/profile/unconnected_services/', 'UNIVERSAL')
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
          } else {
            model.set('oneall_user_token', res.body.data.oneall_user_token);
          }
        });
    },
  },
})({
  oneall_user_token: null,
});

export default model;
