import React from 'react';
import Card from 'components/Card';
import DocumentUploader from 'components/DocumentUploader';
import { ROUTES } from 'constants';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import { mediator } from 'sf/helpers';

export default class VerifyID extends BasePage {
  className = 'ts-VerifyID';
  title = 'Verify Driving Licence';

  state = {};

  handleClick = () => {
    this.setState({ allowSkip: true });
  };

  handlePhotoTaken = ({ imageDataURI }) => {
    registration.uploadDocument(
      imageDataURI,
      registration.get('documentType'),
      registration.get('countryState'),
      false
    ).then(() => {
      mediator.publish('setRegistrationStep', ROUTES.APPLY_FORM.VERIFY_INFORMATION);
      this.navigate(ROUTES.APPLY_FORM.VERIFY_INFORMATION);
    });
  };

  render() {
    return (
      <div className={ this.rootcn`` }>
        <section className={ this.cn`ts-container--narrow __section` }>
          <Card
            className={ this.cn`__card` }
            fullWidth={ true }
          >
            <DocumentUploader
              onPhotoTaken={ this.handlePhotoTaken }
              captureSide="back"
            />
          </Card>
        </section>
      </div>
    );
  }
}
