import React from 'react';
import is from 'next-is';
import PageTitle from 'components/PageTitle';
import ResetPIN from 'components/ResetPIN';
import { DATA, STEPS } from 'components/ResetPIN/ResetPIN.constants';
import { getQuery } from 'sf/helpers';
import device from 'models/device';
import user from 'models/user';
import BasePage from 'pages/BasePage';

export default class ResetPINPage extends BasePage {
  className = 'ts-ResetPIN';

  state = {
    resetPINStep: STEPS.SOCIAL,
  };

  constructorBrowser() {
    const { code } = getQuery();
    if (code) {
      user.set({
        pinResetCode: code,
      });
    }
  }

  componentDidMount() {
    this.syncStateWithModel(device, ['smUp']);
    this.syncStateWithModel(user, ['resetPINStep']);
  }

  componentDidMount() {
    if (this.props.route.isResetPINSelfieStep) {
      user.setPINResetStep(STEPS.SELFIE);
    }
  }

  render() {
    const { title, subtitle } = DATA[this.state.resetPINStep];
    let _subtitle = subtitle;
    if (is.isObject(subtitle)) {
      _subtitle = subtitle[this.state.smUp ? 'smUp' : 'default'];
    }

    return (
      <div className={ this.rootcn`ts-container` }>
        <div className={ this.cn`__page-title` }>
          <PageTitle
            title={ title }
            subtitle={ _subtitle }
          />
        </div>
        <ResetPIN
          resetStep={ this.state.resetPINStep }
        />
      </div>
    );
  }
}
