import React from 'react';
import capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HELP_CENTER_NOTIFICATIONS } from 'components/HelpCenter/HelpCenter.constants';
import ActionBar from 'components/ActionBar';
import BasicProfileInfo from 'components/BasicProfileInfo';
import Button from 'components/Button';
import DocumentUploader from 'components/DocumentUploader';
import MetaBox from 'components/MetaBox';
import ProfileFooter from 'components/ProfileFooter';
import ProfileHeader from 'components/ProfileHeader';
import ProfileViewPaymentDialog from 'components/ProfileViewPaymentDialog';
import RefreshProfileButton from 'components/RefreshProfileButton';
import RetakeSelfieDialog from 'components/RetakeSelfieDialog';
import Render from 'components/Render';
import ScoreToken from 'components/ScoreToken';
import ShareProfileDialog from 'components/ShareProfileDialog';
import SocialTilesList from 'components/SocialTilesList';
import UserPOLRetakeDialog from 'components/UserPOLRetakeDialog';
import config from 'config';
import {
  getQuery,
} from 'sf/helpers';
import { adaptResponseMessage } from 'helpers/notifications';
import { addEventListener, removeEventListener } from 'sf/helpers/domHelper';
import withTheme, { THEMES } from 'hoc/Theme';
import { PUBLIC_ACCESS_MODES } from 'models/publicUser';
import analytics from 'models/analytics';
import socialNetworksModel from 'models/socialNetworks';
import services from 'models/services';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import Dialog, { DialogFooter, DialogText } from 'components/Dialog';
import PreviewModeBox from './PreviewModeBox';
import DocumentSection from './DocumentSection';
import PolSection from './PolSection';
import * as ProfileActions from './Profile.actions';
import SocialNetworkLogInButton from './SocialNetworkLogInButton';

const BASIC_INFO_SHARING_PROPERTIES = [
  'is_date_birth_hidden_during_sharing',
  'is_city_hidden_during_sharing',
  'is_address_hidden_during_sharing',
  'is_email_hidden_during_sharing',
  'is_phone_hidden_during_sharing',
];

const DialogWithTheme = withTheme(Dialog);
const ProfileFooterWithTheme = withTheme(ProfileFooter);

export class Profile extends BasePage {
  className = 'ts-Profile';
  title = 'Profile';

  static childContextTypes = {
    servicesModel: PropTypes.object,
    socialNetworksModel: PropTypes.object,
    userModel: PropTypes.object,
    viewMode: PropTypes.oneOf(['PRIVATE', 'PUBLIC']),
  };

  state = {
    isDisableDialogVisible: false,
    isProfileViewPaymentDialogVisible: false,
    isPolProcessingActive: false,
    isTooltipIntroduced: false,
    services: [],
  }

  isUserVisitingOwnPublicProfile() {
    return this.props.params.shortProfileID === user.get('relative_short_url');
  }

  constructor(props) {
    super(props);

    if (this.isUserVisitingOwnPublicProfile()) {
      this.userModel = user;
    } else {
      this.userModel = props.route.user || user;
    }
    if (this.isPrivateViewMode) {
      this.setRestricted(true);
    }
  }

  // TODO: This is sick, get rid of contxt in the future
  getChildContext() {
    let viewMode = this.userModel === user && !global.REALTOR ? 'PRIVATE' : 'PUBLIC';

    // HACK: allow realtors to show their public profile, even when it's disabled.
    let { userModel } = this;
    let servicesModel = viewMode === 'PRIVATE' ? services : this.userModel;
    if (
      global.REALTOR &&
      viewMode === 'PUBLIC' &&
      this.isUserVisitingOwnPublicProfile()
    ) {
      userModel = user;
      userModel.set('accessMode', PUBLIC_ACCESS_MODES.FULL);
      servicesModel = services;
    }
    // TODO: Find a cleaner way for "Profile Display Mode"
    // Some ACL could be of use.
    if (this.props.route.mode === 'PREVIEW') {
      viewMode = 'PUBLIC';
      userModel.set('accessMode', PUBLIC_ACCESS_MODES.FULL);
    }

    return {
      // userModel works as services model as long, it contains "services" property
      servicesModel,
      socialNetworksModel,
      userModel,
      viewMode,
    };
  }

  get isPrivateViewMode() {
    return this.getChildContext().viewMode === 'PRIVATE';
  }

  // Basically emulate CSS `position: sticky`
  // If you think your CSS-fu is strong enough
  // feel free to refactor.
  stickPreviewModeBox = () => {
    if (!this.previewModeBoxNode) return;

    this.previewModeBoxNode.style.transform =
      window.scrollY > 70 ? 'translateY(-100px)' : 'translateY(0)';
  };

  componentDidMount() {
    const { userModel } = this.getChildContext();

    this.syncStateWithModel(user, [
      'number_of_credits',
      'relative_short_url',
      'isSignedIn',
      'is_realtor',
    ]);
    // this.userModel might be models/user or models/publicUser
    this.syncStateWithModel(this.userModel, [
      'is_pol_passed',
      'pol_proved',
      'absolute_pol_video_url',
      'absolute_pol_mp4_video_url',
      'accessMode',
      'email_confirmed',
      'is_address_real',
      'is_document_identical_to_selfie',
      'mismatched_surname',
      'published',
      'score',
      'services',
      'sharing_settings',
      'document',
    ]);

    if (this.isPrivateViewMode) {
      if (user.get('isSignedIn')) {
        this.getProfileData().then((/* userData */) => {
          if (!this.state.is_pol_passed) {
            // start POL video capture process of existing users
            this.userPOLRetakeDialogInstance.toggle(true);
          }
        });
        this.userModel.getSharingSettings();
        socialNetworksModel.getOneAllUserToken();
      }
    } else {
      this.getProfileData();
    }

    if (![
      PUBLIC_ACCESS_MODES.FULL,
      PUBLIC_ACCESS_MODES.FORBIDDEN,
    ].includes(userModel.get('accessMode'))) {
      this.togglePaymentsDialog(true);
    }

    this.stickPreviewModeBox = throttle(this.stickPreviewModeBox, 150);
    addEventListener(window, 'scroll', this.stickPreviewModeBox);
  }

  componentWillReceiveProps(newProps) {
    this.handleInitialHash(newProps.location);
  }

  componentDidUpdate() {
    // prevent score-related notifications to be displayed on a shared profile
    if (this.isPrivateViewMode) {
      this.logHelpCenterNotifications();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    removeEventListener(window, 'scroll', this.stickPreviewModeBox);
  }

  handlePaymentsDialogDismiss = () => {
    this.togglePaymentsDialog(false);
  };

  handleRefreshService = (service_name) => {
    const {
      servicesModel: servicesModelContext,
      socialNetworksModel: servicesNetworksContext,
    } = this.getChildContext();
    servicesModelContext.refreshService(
      service_name,
      servicesNetworksContext.get('oneall_user_token')
    );
  };

  togglePaymentsDialog = (isVisible) => {
    this.setState({
      isProfileViewPaymentDialogVisible: isVisible,
    });
  };

  logHelpCenterNotifications() {
    const {
      is_address_real,
      is_document_identical_to_selfie,
      is_realtor,
      mismatched_surname,
      score,
      // driver_license,
      document,
      services: servicesList,
    } = this.state;
    const expiredServices = servicesList &&
      servicesList.filter(
        ({ connected, days_to_expire, is_expired }) => connected && (is_expired || days_to_expire)
      ) || [];
    const getDaysNoun = (days) => days === 1 ? 'day' : 'days';

    expiredServices.forEach(({ days_to_expire, service_name }) => {
      this.notify('error', {
        title: days_to_expire ?
        /* eslint-disable max-len */
          `Your ${capitalize(service_name)} connection will expire in ${days_to_expire} ${getDaysNoun(days_to_expire)}` :
        /* eslint-enable max-len */
          `Your ${capitalize(service_name)} connection has expired`,
        value: <SocialNetworkLogInButton
          serviceName={ service_name }
          onClick={ () => this.handleRefreshService(service_name) }
        />,
      });
    });

    if (_get(document, 'front_photo.url') && !_get(document, 'back_photo.url')) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.ONLY_FRONT_OF_DRIVERS_LICENSE);
    }

    if (
      servicesList &&
      servicesList.filter(({ connected }) => connected).length === 1 &&
      !is_realtor
    ) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.ONLY_ONE_NETWORK);
    }

    if (mismatched_surname && !is_realtor) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.MISMATCHED_SURNAME);
    }

    if (score < 400 && !is_realtor) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.YOUNG_NETWORKS);
    }

    if (
      typeof is_document_identical_to_selfie !== 'undefined' &&
      !is_document_identical_to_selfie &&
      !is_realtor
    ) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.PHOTO_MISMATCH);
    }

    if (typeof is_address_real !== 'undefined' && !is_address_real) {
      this.notify('warning', HELP_CENTER_NOTIFICATIONS.NONEXISTENT_ADDRESS);
    }
  }

  getFullProfileData = () => {
    this.setState({ paymentStarted: false });
    return this.getProfileData(true);
  };

  getProfileData(isFull = false) {
    const { accessToken } = getQuery(); // access token for prepaid views

    return new Promise((resolve, reject) => {
      this.userModel
        .getProfileData(isFull, accessToken)
        .then((userData) => {
          services.setServicesList();
          this.handleInitialHash(this.props.location); // needs to be called after getProfileData
          resolve(userData);
        }).catch(reject);
    });
  }

  get basicInfoSharingSettings() {
    return pick(this.state.sharing_settings, BASIC_INFO_SHARING_PROPERTIES);
  }

  shouldDisplayDocument() {
    return this.state.document &&
      (this.isPrivateViewMode ||
      !_get(this.state, ['sharing_settings', 'is_document_hidden_during_sharing']));
  }

  handleInitialHash(location) {
    // backend redirects to Profile.html#error=... or #edit
    const query = getQuery(location.hash);
    if (query.error) {
      this.notify('error', adaptResponseMessage(query.error));
      location.hash = '';
    } else if (query.connected) {
      this.refs.refreshProfileButton.activateTooltip();
    } else if (location.query.action) {
      switch (location.query.action) {
        case 'share-profile':
          if (this.state.published) {
            this.shareProfileDialogInstance.show();
          }
          break;
        default:
          break;
      }
    } else if (this.shareProfileDialogInstance) {
      this.shareProfileDialogInstance.hide();
    }
  }

  openProfilePage = () => {
    // After closing popup, clear the URL
    this.navigate('/Profile.html');
  };

  displayRefreshProfileTooltip = () => {
    if (!this.state.isTooltipIntroduced) {
      this.refs.refreshProfileButton.activateTooltip();
      this.setState({
        isTooltipIntroduced: true,
      });
    }
  };

  disablePolProcessing = () => {
    this.setState({
      isPolProcessingActive: false,
    });
  }

  enablePolProcessing = () => {
    this.setState({
      isPolProcessingActive: true,
    });
  }

  handleRetakeSelfieButtonClick = () => {
    this.retakeSelfieDialogInstance.toggle(true);
  };

  handleToggleDocumentVisibilityButtonClick = (event) => {
    event.stopPropagation();
    const key = 'is_document_hidden_during_sharing';
    const settings = this.state.sharing_settings;
    this.setState({
      sharing_settings: Object.assign({}, settings, {
        [key]: !settings[key],
      }),
    }, () => {
      this.userModel.saveSharingSettings(this.state.sharing_settings);
    });
  };

  handleRetakeDocumentButtonClick = (event) => {
    event.stopPropagation();
    this.documentUploaderDialogInstance.toggle(true);
  };

  handleRetakeDocumentBackButtonClick = (event) => {
    event.stopPropagation();
    this.documentBackUploaderDialogInstance.toggle(true);
  };

  handleDocumentUploaderDialogDismiss = () => {
    this.documentUploaderDialogInstance.toggle(false);
  }

  handlePOLVerificationStart = () => {
    this.enablePolProcessing();
  }

  handlePOLVerifySuccess = () => {
    this.disablePolProcessing();
  }

  handlePOLVerifyError = () => {
    this.disablePolProcessing();
  }

  handleDisableButtonClick = () => this.userModel
    .disableProfile()
    .then(() => this.userModel.logOut({ redirect: true }));

  handleNewSelfie = (croppedImg) => {
    const { userModel } = this;
    userModel.set('photo', croppedImg);
    userModel
      .updateUserPhoto()
      .then(() => {
        userModel.getProfileData();
        this.navigate('/Profile.html');
        this.retakeSelfieDialogInstance.toggle(false);
      }, () => {
        this.retakeSelfieDialogInstance.toggle(false);
      });
  };

  handlePaymentError = (error) => {
    this.notify('error', error.message);
  };

  handleSuccessfulPayment = () => {
    this.getProfileData(true).then(this.handlePaymentsDialogDismiss);
  };

  renderPaymentDialog() {
    return (
      <div className={ this.cn`__payment-wrapper ts-container` }>
        <ProfileViewPaymentDialog
          isViewerLoggedIn={ user.get('isSignedIn') }
          isVisible={ this.state.isProfileViewPaymentDialogVisible }
          onDismiss={ this.handlePaymentsDialogDismiss }
          onSuccessfulPayment={ this.handleSuccessfulPayment }
          onStampsPayment={ this.userModel.getFullProfileData }
          viewerStampsCount={ user.get('number_of_credits') }
          price={ config.creditPrice }
        />
      </div>
    );
  }

  handleUnlockProfileButtonClick = () => {
    this.togglePaymentsDialog(true);
  };

  renderPublicActionBar() {
    const { userModel } = this.getChildContext();
    // Disable when viewing own profile
    const isDisabled = user.get('relative_short_url') === userModel.get('shortProfileID');

    return [
      PUBLIC_ACCESS_MODES.FULL,
      PUBLIC_ACCESS_MODES.FORBIDDEN,
    ]
      .includes(userModel.get('accessMode')) ?
      /* eslint-disable indent */
        <ActionBar title="View Profile" /> :
        <ActionBar title="View Profile">
          <Button
            disabled={ isDisabled }
            onClick={ this.handleUnlockProfileButtonClick }
          >§
            Unlock the profile
          </Button>
          { this.renderPaymentDialog() }
        </ActionBar>;
      /* eslint-enable */
  }

  publishProfile = () => {
    const nextPublishedState = !this.state.published;
    this.userModel.publishProfile(nextPublishedState)
      .then(() => {
        // TODO: Move to analytics redux middleware
        if (nextPublishedState) {
          analytics.trackEvent('WEBAPP_PROFILE_PUBLISHED');
        } else {
          analytics.trackEvent('WEBAPP_PROFILE_UNPUBLISHED');
        }
      });
  };

  renderActionBar() {
    const { viewMode, userModel } = this.getChildContext();

    return viewMode === 'PUBLIC' ?
      this.renderPublicActionBar() : (
        <ProfileHeader
          published={ userModel.get('published') }
          emailConfirmed={ userModel.get('email_confirmed') }
          onPublish={ this.publishProfile }
        />
      );
  }

  renderRetakeSelfieDialog() {
    if (this.context.viewMode === 'PUBLIC') return;
    return (
      <RetakeSelfieDialog
        onPhotoTaken={ this.handleNewSelfie }
        ref={ (dialog) => { this.retakeSelfieDialogInstance = dialog; } }
      />
    );
  }

  getDocumentPhotoTakenHandler = (isFront) => ({ imageDataURI, document_type, state }) => {
    const dialogInstance = isFront ?
      this.documentUploaderDialogInstance :
      this.documentBackUploaderDialogInstance;
    user
      .uploadDocument(
        imageDataURI,
        document_type,
        state,
        isFront,
      )
      .then(() => {
        if (isFront) {
          user.set({
            'countryState': state,
            'documentType': document_type,
          });
        }
        dialogInstance.toggle(false);
      })
      .then(this.userModel.getProfileData)
      .catch(() => {
        dialogInstance.toggle(false);
        this.notify(
          'error',
          'Hey, we have some trouble uploading your photo. Can you retake it?'
        );
      });
  }

  render() {
    const { viewMode, userModel } = this.getChildContext();
    if (!userModel.get('isSignedIn') && !userModel.get('accessMode')) {
      return null; // prevent from weird profile look until profile/data/ is received
    }
    const isSelfieMismatched = !userModel.get('is_document_identical_to_selfie');
    const isHiddenDuringSharing =
      _get(this.state.sharing_settings, 'is_document_hidden_during_sharing');
    const isDocumentVisible = this.shouldDisplayDocument();
    const actionBarClassNames = {
      '__action-bar-wrapper': true,
      '__action-bar-wrapper--important':
        this.state.email_confirmed === false && this.isPrivateViewMode,
    };

    return (
      <div className={ this.rootcn() }>
        <Dialog
          className={ this.cn`__capture-dialog` }
          onDismiss={ this.props.handleDocumentUploaderDialogDismiss }
          ref={ (dialog) => { this.documentUploaderDialogInstance = dialog; } }
          title="Retake document"
        >
          <section className={ this.cn`ts-container` }>
            <DocumentUploader
              captureSide="front"
              showDocumentTypeSelect={ false }
              showStateSelect={ false }
              onPhotoTaken={ this.getDocumentPhotoTakenHandler(true) }
            />
          </section>
        </Dialog>
        <Dialog
          className={ this.cn`__capture-dialog` }
          onDismiss={ this.props.handleDocumentUploaderDialogDismiss }
          ref={ (dialog) => { this.documentBackUploaderDialogInstance = dialog; } }
          title="Retake document back"
        >
          <section className={ this.cn`ts-container` }>
            <DocumentUploader
              captureSide="back"
              onPhotoTaken={ this.getDocumentPhotoTakenHandler(false) }
            />
          </section>
        </Dialog>
        <UserPOLRetakeDialog
          ref={ (dialog) => { this.userPOLRetakeDialogInstance = dialog; } }
          onVerificationStart={ this.handlePOLVerificationStart }
          onVerifySuccess={ this.handlePOLVerifySuccess }
          onVerifyError={ this.handlePOLVerifyError }
          closable={ this.state.is_pol_passed }
        />
        <div className={ this.cn`__user-details-wrap` }>
          { this.props.route.mode === 'PREVIEW' &&
            <div
              className={ this.cn`__preview-mode-box` }
              ref={ this.createRef('previewModeBoxNode') }
            >
              <PreviewModeBox onGoBack={ this.openProfilePage } />
            </div>
          }
          <div className={ this.cn(actionBarClassNames) }>
            <div className={ this.cn`__action-bar` }>
              { this.renderActionBar() }
            </div>
          </div>
          <div className={ this.cn`__token-container` }>
            <div className={ this.cn`__user-details` }>
              <div className={ this.cn`__token` }>
                <ScoreToken
                  isSelfieMismatched={
                    isSelfieMismatched &&
                    userModel.get('accessMode') !== PUBLIC_ACCESS_MODES.LIMITED
                  }
                  onRetakeSelfieButtonClick={ this.handleRetakeSelfieButtonClick }
                />
              </div>
              <div className={ this.cn`__info` }>
                <div className={ this.cn`__basic-info` }>
                  <BasicProfileInfo
                    onChange={ this.displayRefreshProfileTooltip }
                    sharingSettings={ this.basicInfoSharingSettings }
                  />
                </div>
                <div className={ this.cn`__dl-pol` }>
                  <DocumentSection
                    document={ this.state.document }
                    isDocumentVisible={ isDocumentVisible }
                    isHiddenDuringSharing={ isHiddenDuringSharing }
                    isPrivateViewMode={ this.isPrivateViewMode }
                    isSelfieMismatched={
                      isSelfieMismatched &&
                      userModel.get('accessMode') !== PUBLIC_ACCESS_MODES.LIMITED
                    }
                    portrait={ _get(this.state, 'document.front_photo.url') }
                    onRetakeButtonClick={ this.handleRetakeDocumentButtonClick }
                    onBackRetakeButtonClick={ this.handleRetakeDocumentBackButtonClick }
                    onToggleVisibilityClick={ this.handleToggleDocumentVisibilityButtonClick }
                  />
                  <PolSection
                    isPrivateViewMode={ this.isPrivateViewMode }
                    isPreviewMode={ this.props.route.mode === 'PREVIEW' }
                    videoUrl={
                      this.state.absolute_pol_mp4_video_url ||
                      this.state.absolute_pol_video_url
                    }
                    onRetakeButtonClick={ () => this.userPOLRetakeDialogInstance.toggle(true) }
                    isVideoProcessing={ this.state.isPolProcessingActive }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ts-container">
          <Render when={ this.isPrivateViewMode }>
            <div className={ this.cn`__refresh-profile-button` }>
              <RefreshProfileButton ref="refreshProfileButton" />
            </div>
          </Render>
          { viewMode !== 'PUBLIC' &&
            <div className={ this.cn`__meta-box` }>
              <MetaBox
                isSelfieMismatched={ !this.state.is_document_identical_to_selfie }
                isPolFailed={ !this.state.pol_proved }
                onRetakeSelfieButtonClick={ () => this.userPOLRetakeDialogInstance.toggle(true) }
                onRetakeDriversLicenseButtonClick={ this.handleRetakeDocumentButtonClick }
              />
            </div>
          }
          <div className={ this.cn`__social-media-accounts` }>
            <SocialTilesList />
          </div>
          <ShareProfileDialog
            ref={ (instance) => this.shareProfileDialogInstance = instance }
            onDismiss={ this.openProfilePage }
          />
        </div>
        { viewMode === 'PRIVATE' &&
          <div className={ this.cn`__action-bar-wrapper` }>
            <div className={ this.cn`__action-bar` }>
              <ProfileFooterWithTheme
                theme={ THEMES.danger }
                onDisableClick={ () => this.setState({
                  isDisableDialogVisible: true,
                }) }
              />
            </div>
          </div> }
        { this.renderRetakeSelfieDialog() }
        { viewMode === 'PRIVATE' &&
          <DialogWithTheme
            size="small"
            theme={ THEMES.danger }
            title="Disable profile"
            visible={ this.state.isDisableDialogVisible }
            onDismiss={ () => this.setState({
              isDisableDialogVisible: false,
            }) }
          >
            <DialogText>
              <p>
                Are you sure want to disable your Trust Stamp profile? This cannot be undone.
              </p>
            </DialogText>
            <DialogFooter>
              <Button
                className="ts-themed-color-on-hover"
                theme="ternary"
                onClick={ this.handleDisableButtonClick }
              >
                Continue
              </Button>
            </DialogFooter>
          </DialogWithTheme> }
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ProfileActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
