import React from 'react';
import is from 'next-is';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import config from 'config';

export default class NewsletterBox extends BaseComponent {
  className = 'ts-NewsletterBox';

  static propTypes = {
    showTitle: PropTypes.bool,
  };

  static defaultProps = {
    showTitle: true,
  };

  state = {
    formSubmitted: false,
    isInvalid: false,
  };

  handleSubmit = (event) => {
    const { value } = this.emailInputNode;
    if (event.currentTarget.checkValidity() && is.string.isEmail(value)) {
      this.setState({ formSubmitted: true });
    } else {
      event.preventDefault();
      this.setState({ isInvalid: true });
      this.emailInputNode.focus();
    }
  };

  renderTitle() {
    return (
      <div>
        <h1 className={ this.cn`__title` }>
          Newsletter
        </h1>
        <p className={ this.cn`__legend` }>
          Subscribe to our newsletter and stay updated
        </p>
      </div>
    );
  }

  renderForm() {
    const formWrapperClassNames = this.cn({
      'form-inline': true,
      '__hidden': this.state.formSubmitted,
    });
    const inputClassName = this.cn({
      'form-control': true,
      '__email': true,
      '__email--danger': this.state.isInvalid,
    });

    return (
      <div>
        <div className={ formWrapperClassNames }>
          <form
            action={ config.mailChimpURL }
            method="post"
            name="mc-embedded-subscribe-form"
            noValidate="novalidate"
            target="_blank"
            onSubmit={ this.handleSubmit }
          >
            <label
              className="sr-only"
              htmlFor="newsletter-email"
            >
              E-mail address
            </label>
            <input
              name="EMAIL"
              type="email"
              ref={ this.createRef('emailInputNode') }
              className={ inputClassName }
              id="newsletter-email"
              placeholder="E-mail address"
              required={ true }
            />
            <div
              className={ this.cn`__mailChimpToken form-group` }
              aria-hidden="true"
            >
              <input
                name="b_1d8d22517c8c8d2ab003ef4ab_8dd4146db1"
                tabIndex="-1"
              />
            </div>
            <Button
              theme="success"
              name="subscribe"
              type="submit"
            >
              Sign me up!
            </Button>
          </form>

          <p className={ this.cn`__legend` }>
            Trust us, We don’t spam. Promise!
          </p>
        </div>
        { this.state.formSubmitted && this.renderThankYouMessage() }
      </div>
    );
  }

  renderThankYouMessage() {
    return (
      <div className={ this.rootcn() }>
        <p className={ this.cn`__legend` }>
          Thank you!
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.props.showTitle && this.renderTitle() }
        { this.renderForm() }
      </div>
    );
  }
}
