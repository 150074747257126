import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class Avatar extends BaseComponent {
  className = 'ts-Avatar';

  static propTypes = {
    imageUrl: PropTypes.string,
    txt: PropTypes.string,
  };

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__alt-container` }>
          { this.props.imageUrl ? (
            <div
              className={ this.cn`__image-container` }
              style={ { backgroundImage: `url(${this.props.imageUrl})` } }
            />
          ) : (
            <div className={ this.cn`__alt-txt` }>
              { this.props.txt }
            </div>
          ) }
        </div>
      </div>
    );
  }
}

export default Avatar;
