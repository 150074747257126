import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import BaseComponent from 'components/BaseComponent';

class AdminAlert extends BaseComponent {
  className = 'ts-AdminButton';

  static propTypes = {
    size: PropTypes.oneOf([
      'default',
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.string,
    theme: PropTypes.shape({
      size: PropTypes.oneOf([
        'normal',
        'small',
      ]),
      color: PropTypes.oneOf([
        'transparent',
        'white',
        'blue',
        'gray',
      ]),
    }),
  };

  static defaultProps = {
    size: 'default',
    disabled: false,
    theme: {
      size: 'normal',
      color: 'gray',
    },
  };

  render() {
    return (
      <div className={ this.rootcn() }>
        <button
          className={ this.cn`
            ${this.props.disabled ? '--disabled' : ''}
            --theme-size-${this.props.theme.size || 'normal'}
            --theme-color-${this.props.theme.color || 'gray'}
            --size-${this.props.size}
            ${this.props.className}
          ` }
          { ...omit(this.props, [
            'theme',
            'className',
          ]) }
        >
          { this.props.children }
        </button>
      </div>
    );
  }
}

export default AdminAlert;
