import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';

class ResetPasswordSelfieComponent extends BaseComponent {
  className = 'ts-ResetPassword';

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  }

  handlePhotoTaken = (data) => {
    this.props.onSubmit(data);
  }

  render() {
    return (
      <div className={ this.cn`__password` }>
        <LocalAdvancedPhotoCapture
          allowCrop={ false }
          allowRetake={ false }
          onPhotoTaken={ this.handlePhotoTaken }
          overlay="face"
        />
      </div>
    );
  }
}

export default ResetPasswordSelfieComponent;
