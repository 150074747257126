import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class Heading extends BaseComponent {
  className = 'ts-Heading';

  static propTypes = {
    children: PropTypes.any,
    subtitle: PropTypes.any,
  };

  render() {
    return (
      <div className={ this.rootcn`--info` }>
        { this.props.children }
        <div className={ this.cn`__subtitle` }>
          { this.props.subtitle }
        </div>
      </div>
    );
  }
}

export default Heading;
