import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';

class AdminPanelIndexView extends BaseComponent {
  className = 'ts-AdminPanelIndexView';

  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <div className={ this.rootcn() } >
        Index
      </div>
    );
  }
}

export default AdminPanelIndexView;
