import React from 'react';
import pick from 'lodash/pick';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import payment from 'models/payment';
import Button from 'sf/components/Button';
import { asset } from 'sf/helpers';
import { CURRENCIES } from 'sf/constants';

class PaymentPayPal extends BaseComponent {
  className = 'ts-PaymentPayPal';

  state = {};

  static propTypes = {
    amount: PropTypes.number,
    creditPrice: PropTypes.number.isRequired,
    currency: PropTypes.oneOf(Object.values(CURRENCIES)),
    disabled: PropTypes.bool,
    paymentMethod: PropTypes.func.isRequired,
    renderActionButton: PropTypes.func,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    amount: 1,
    currency: CURRENCIES.GBP,
    disabled: false,
    onError: noop,
    onSuccess: noop,
  };

  handlePayPalButtonClick = () => {
    const { amount, paymentMethod } = this.props;
    if (!this.payPalInstance) return;
    this.payPalInstance.tokenize({
      flow: 'checkout',
      amount: (this.props.amount * this.props.creditPrice).toFixed(2),
      currency: this.props.currency,
      locale: 'en_us',
    }, (tokenizeErr, data) => {
      if (tokenizeErr) {
        console.error('Paypal tokenize error', tokenizeErr, data); // eslint-disable-line
        this.props.onError(tokenizeErr);
        return;
      }

      paymentMethod({
        ...pick(data, ['nonce', 'type']),
        amount,
      })
        .then(this.props.onSuccess)
        .catch(this.props.onError);
    });
  }

  setup = () => {
    payment.getClientToken().then((clientToken) => {
      global.braintree.client.create({
        authorization: clientToken,
      }, (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Paypal client error', clientErr, clientInstance); // eslint-disable-line
        }

        global.braintree.paypal.create({
          client: clientInstance,
        }, (payPalErr, payPalInstance) => {
          if (payPalErr) {
            console.error('Paypal instance error', payPalErr, payPalInstance); // eslint-disable-line
          }

          Button.setProcessing(this.refs.paypalButton, false);
          this.payPalInstance = payPalInstance;
        });
      });
    });
  }

  componentDidMount() {
    Button.setProcessing(this.refs.paypalButton);
    payment.getBraintreeScripts(this.setup);
  }

  renderActionButton = () => {
    return this.props.renderActionButton ? this.props.renderActionButton({
      disabled: this.props.disabled || !this.props.amount,
      onClick: this.handlePayPalButtonClick,
    }) : (
      <Button
        className={ this.cn`__paypal-button` }
        onClick={ this.handlePayPalButtonClick }
        ref="paypalButton"
        theme="paypal"
        disabled={
          this.props.disabled ||
          !this.props.amount
        }
        mainAction={ true }
      >
        <img src={ asset`img/paypal.svg` } alt="PayPal" />
      </Button>
    );
  }

  render() {
    return this.renderActionButton();
  }
}

export default PaymentPayPal;
