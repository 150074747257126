/* global ASSETS_URL */

require('sf').configure({
  SpinnerComponent: require('components/Spinner'),
  assetsURL: ASSETS_URL,
  errorHandler: customErrorHandler,
  backendURL: BACKEND_URL,
});

// require instead of imports to load polyfills first.
const Html = require('./layout/Html');
const is = require('next-is');

const React = require('react');
const { createRoot } = require('react-dom/client');
const Routes = require('./routes');
const { Router, browserHistory, match } = require('react-router');
const { getQuery, mediator } = require('sf/helpers');
const user = require('models/user');
const help = require('models/help');
const { BRANDINGS } = require('constants');
const setupAuthorization = require('./setupAuthorization');

require('bootstrap/scss/bootstrap.scss');
require('./app.scss');

if (!is.browser()) {
  // list all modules required for back-end rendering.
  module.exports = {
    './routes': Routes,
    './layout/Html': Html,
  };
} else {
  setupAuthorization();

  // global.require = require;
  // front-end: init app.
  match({
    history: browserHistory,
    routes: Routes,
  }, (error, redirectLocation, renderProps) => {
    const runApp = () => {
      user.startup();
      const _renderProps = renderProps || {
        history: browserHistory,
        routes: Routes,
      };
      createRoot(document.querySelector('#app')).render(
        <Router { ..._renderProps } />
      );
    };

    let isCurrentTabActive = true;

    if (BRAND_NAME !== BRANDINGS.REALTOR) {
      window.addEventListener('focus', () => {
        if (!user.isBrowserSessionActive()) {
          location.reload();
        }
        user.saveBrowserSessionAsActive();
        isCurrentTabActive = true;
      });

      window.addEventListener('blur', () => {
        isCurrentTabActive = false;
      });
    }


    window.addEventListener('storage', (event) => {
      if (isCurrentTabActive) {
        // focused tab is a source of truth
        return;
      }
      // logout in a current browser tab after
      // user was logged out in another instance or tab
      // see more in a frontend/src/models/user.js
      if (event.key === 'shouldLogOut' && JSON.parse(event.newValue)) {
        user.logOut({ redirect: true, reason: 'auto' });
      }
    });

    // NOTE:
    // token need to be handled before any request.
    const { token, userRoles, redirectionSource, loginToken } = getQuery();
    if (token) {
      // It's required for Wallet redirection from Realty app.
      user.logInWithToken(token).then(() => {
        // HACK: Business and score users has different endpoints. After signing in
        // we don't know if user is business or not until profile/data is fetched.
        // Waiting until profile/data is fetched to starting any other request
        // will slow down the site a lot.
        // After backend refactor to use the same addresses for score & non-score users
        // it can be removed.
        if (redirectionSource === 'realtorapp' && userRoles) {
          user.set('roles', JSON.parse(userRoles));
        }
        runApp();
      }, runApp);
    } else if (loginToken) {
      user.logOut()
        .then(() => user.set({
          'token': loginToken,
        }))
        .then(runApp);
    } else {
      runApp();
    }

    is.appendBrowsers();
    // if (ENV !== 'prod') global._allModels = require('models/all');
  });
}

function customErrorHandler(err, res, level = 'error') {
  try {
    const resData = res.body.data;
    if (resData && resData.message) {
      if (level === 'error') {
        let errorMessage = resData.message;
        // override main response message with validator ones (if they exist)
        if (resData.validation) {
          errorMessage = Object.values(resData.validation);
        }
        if (errorMessage && !Array.isArray(errorMessage)) {
          errorMessage = [errorMessage];
        }
        help.clearNotifications();
        errorMessage.forEach((value) => {
          help.addNotification({
            type: 'error',
            title: 'Error',
            value,
          });
        });
        help.open();
      } else {
        mediator.publish(level, resData.message);
      }
    } else {
      console.error(resData); // eslint-disable-line no-console
    }
  } catch (e) {
    console.error(err.toString()); // eslint-disable-line no-console
  }
}
