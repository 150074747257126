import React from 'react';
import PropTypes from 'prop-types';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import PillButton from 'components/PillButton';
import Icon from 'sf/components/Icon';

export function PreviewModeBox({ onGoBack }) {
  return (
    <div className={ this.rootcn() }>
      <Icon
        set="io"
        type="eye"
      />
      <span className={ this.cn`__label` }>
        You're in preview mode
      </span>
      <PillButton
        shell={ true }
        onClick={ onGoBack }
      >
        Back
      </PillButton>
    </div>
  );
}

PreviewModeBox.propTypes = {
  onGoBack: PropTypes.func.isRequired,
};

export default baseFunctionalComponent(PreviewModeBox, 'PreviewModeBox');
