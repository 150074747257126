import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledFooter,
} from 'components/StyledForm';
import user from 'models/user';
import Input from 'sf/components/Input';
import withPasswordTypeToggler from 'sf/hoc/PasswordTypeToggler';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLinkAndToggle = compose(
  withPasswordTypeToggler,
  withStateLink,
  withValidation,
)(Input);

export default class ResetPasswordNewPassword extends BaseComponent {
  state = {
    new_password: undefined,
    new_password_confirm: undefined,
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = await this.formValidation(
      user.set(this.state)
    )
      .catch(() => false);

    if (!isFormValid) return;

    this.props.onSubmit();
  }

  shouldDisableSubmitButton = () => !Object.values(this.state)
    .every(Boolean);

  render() {
    return (
      <div>
        <StyledForm
          centered={ true }
          width="narrow"
        >
          <form
            onSubmit={ this.handleSubmit }
          >
            <StyledLabel>Enter your new password</StyledLabel>
            <StyledInput>
              <ValidationInputWithStateLinkAndToggle
                autocomplete="off"
                ref="new_password"
                stateLink={ [this, 'new_password'] }
                placeholder="New password"
                type="password"
              />
            </StyledInput>
            <StyledLabel>Repeat your new password</StyledLabel>
            <StyledInput>
              <ValidationInputWithStateLinkAndToggle
                autocomplete="off"
                ref="new_password_confirm"
                stateLink={ [this, 'new_password_confirm'] }
                placeholder="Repeat new password"
                type="password"
              />
            </StyledInput>
            <StyledFooter>
              <Button
                disabled={ this.shouldDisableSubmitButton() }
                type="submit"
              >
                Set your password
              </Button>
            </StyledFooter>
          </form>
        </StyledForm>
      </div>
    );
  }
}
