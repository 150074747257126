import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import BusinessLogInForm from 'components/BusinessLogInForm';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import { ROUTES as ADMIN_APP_ROUTES } from 'components/AdminPanel/AdminPanel.constants';
import {
  BRANDINGS,
  BUSINESS_LOGIN_STEPS,
  ROLES,
} from 'constants';
import { getHelpCenterNotification } from 'helpers/notifications';
import businessLogIn from 'models/businessLogIn';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import LocalAdvancedPhotoCapture from 'components/LocalAdvancedPhotoCapture';
import Alert from 'sf/components/Alert';

const CONTEXT_HELP_NOTIFICATIONS = {
  [BUSINESS_LOGIN_STEPS.SELFIE]: [
    'NO_WEBCAM',
    'WHY_IS_SELFIE_NEEDED',
  ],
  [BUSINESS_LOGIN_STEPS.CREDENTIALS]: [
    'CANT_REMEMBER_BOA_USERNAME',
    'FORGOT_PASSWORD',
  ],
};

export class BusinessLogInComponent extends BasePage {
  className = 'ts-BusinessLogIn';
  title = BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR
    ? 'Log in using Organization Account'
    : 'Log in using NRDS Account';

  state = {
    activeStep: BUSINESS_LOGIN_STEPS.CREDENTIALS,
  };

  componentDidMount() {
    businessLogIn.on('token', this.login);
    businessLogIn.on('isLogInFailed', this.handleLoginFailed);

    if (user.get('isSignedIn')) {
      if (BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR) {
        this.navigate(ADMIN_APP_ROUTES.INVITE);
      } else {
        this.navigate('/AskSomeoneToVerify.html');
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    businessLogIn.off('token', this.login);
    businessLogIn.off('isLogInFailed', this.handleLoginFailed);
  }

  componentDidMount() {
    if (this.props.route.isBusinessLogInSelfieStep) {
      this.setState({ activeStep: BUSINESS_LOGIN_STEPS.SELFIE });
    }
  }

  handleLoginFailed = (isLogInFailed) => {
    if (isLogInFailed) {
      this.notify(
        'warning',
        getHelpCenterNotification('INCORRECT_BOA_CREDENTIALS')
      );
    }
  };

  logIn = (token) => {
    user.set({
      token,
      isSignedIn: true,
      roles: [ROLES.BUSINESS_USER],
    });
    if (BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR) {
      this.navigate(ADMIN_APP_ROUTES.INVITE);
    } else {
      this.navigate('/AskSomeoneToVerify.html');
    }
  }

  handleSubmit = (formData) => {
    businessLogIn
      .sendCredentials({
        credentials: formData,
        discount_code: this.props.mphDiscountCode,
        ...BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR ?
          { brandingPrefix: 'organization' } :
          {},
      })
      .then(() => {
        this.setState({ activeStep: BUSINESS_LOGIN_STEPS.SELFIE });
        this.navigate('/business/LogInSelfie.html');
      })
      .catch(() => {
        this.notify(
          'warning',
          getHelpCenterNotification('INCORRECT_BOA_CREDENTIALS')
        );
      });
  };

  handlePhotoTaken = (photoDataURI) => {
    businessLogIn
      .sendPhoto({
        photoDataURI,
        ...BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR ?
          { brandingPrefix: 'organization' } :
          {},
      })
      .then(({ token }) => this.logIn(token));
  };

  getContent() {
    switch (this.state.activeStep) {
      case BUSINESS_LOGIN_STEPS.CREDENTIALS:
        return {
          main: (
            <BusinessLogInForm
              onSubmit={ this.handleSubmit }
            />
          ),
          subtitle: 'Enter your username and password',
        };
      case BUSINESS_LOGIN_STEPS.SELFIE:
        return {
          main: (
            <div className="ts-container ts-container--narrow">
              <LocalAdvancedPhotoCapture
                allowCrop={ false }
                allowRetake={ false }
                fullScreen={ false }
                overlay="face"
                onPhotoTaken={ this.handlePhotoTaken }
              />
            </div>
          ),
          subtitle: 'Take a selfie to start using Trust Stamp',
        };
      default:
        return {
          main: null,
          subtitle: null,
        };
    }
  }

  render() {
    const { main, subtitle } = this.getContent();
    return (
      <Fragment>
        { this.state.activeStep === BUSINESS_LOGIN_STEPS.CREDENTIALS &&
          <Alert
            className={ this.cn`__alert` }
            closeable={ true }
            theme="info"
          >
            <div className="ts-container">
              To log in as a Realtor® using your Social Media account click&nbsp;
              <Link
                to="/LogIn.html"
              >
                here
              </Link>
            </div>
          </Alert> }
        <div className={ this.rootcn`ts-container` }>
          <div className={ this.cn`__page-title` }>
            <PageTitle
              title={ this.title }
              subtitle={ subtitle }
              className={ this.cn`__title` }
            />
            <HelpButton
              className={ this.cn`__title-help-button` }
              notifications={ CONTEXT_HELP_NOTIFICATIONS[this.state.activeStep] }
            />
          </div>
          { main }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ profile }) => ({
  mphDiscountCode: profile.mph_discount_code,
});

const BusinessLogIn = connect(
  mapStateToProps,
  null,
)(BusinessLogInComponent);

export default BusinessLogIn;
