import React from 'react';
import unzipWith from 'lodash/unzipWith';
import noop from 'no-op';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

const MASK_FORMAT_CHARS = {
  '9': '[-0-9]',
  'a': '[A-Za-z]',
  '*': '[A-Za-z0-9]',
};

export default class MaskedInput extends BaseComponent {
  className = 'ts-MaskedInput';

  static propTypes = {
    mask: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    unmask: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    unmask: true,
    placeholder: null,
    onChange: noop,
  };

  handleChange = (e) => {
    const { value } = e.target;
    const unmaskedValue = this.props.unmask ?
      unzipWith([
        this.splitMask(this.props.mask),
        value.split(''),
      ], (maskChar, valueChar) => maskChar && valueChar)
        .filter((char) => !!char)
        .join('') :
      value;
    this.props.onChange(unmaskedValue);
  };

  splitMask = (mask) => mask
    .replace(/(\\.)/gi, 'x') // any non-mask char
    .split('')
    .map((char) => Object.keys(MASK_FORMAT_CHARS).includes(char));

  render() {
    return (
      <InputMask
        { ...this.pickProps(
          'inputMode',
          'placeholder',
          'type',
        ) }
        alwaysShowMask={ true }
        className={ this.rootcn() }
        formatChars={ MASK_FORMAT_CHARS }
        mask={ this.props.mask }
        maskChar={ null }
        onChange={ this.handleChange }
      />
    );
  }
}
