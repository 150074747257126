import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { getDate } from 'sf/helpers';

import invitationModel from 'models/invitation';
import messengerModel from 'models/messenger';
import userModel from 'models/user';

import BaseComponent from 'components/BaseComponent';
import Dialog from 'components/Dialog';
import Heading from '../../elements/Heading';
import Section from '../../elements/Section';
import Avatar from '../../elements/Avatar';
import Button from '../../elements/Button';
import InputAdder from '../../elements/InputAdder';
import Messenger from '../../elements/Messenger';
import Alert from '../../elements/Alert';
import {
  PersonTable,
  PersonTableHeader,
  PersonTableRow,
  PersonTableLabel,
  PersonTableCell,
} from '../../elements/PersonTable/PersonTable';

class AdminPanelInviteView extends BaseComponent {
  className = 'ts-AdminPanelInviteView';

  state = {
    invitations: [],
  }

  static propTypes = {
    children: PropTypes.node,
  };

  componentDidMount() {
    messengerModel.clearMessages();
    this.syncStateWithModel(userModel, [
      'organization',
      'isLoading',
    ]);
    this.syncStateWithModel(invitationModel, [
      'invitations',
      'current_page',
      'number_of_pages',
      'invitation_details',
    ]);
    userModel.getProfileData();
    invitationModel.getList();
  }

  renderInvitationsTable = () => {
    return (
      <PersonTable>
        <PersonTableHeader>
          People you asked to verify
        </PersonTableHeader>
        <PersonTableRow>
          <PersonTableLabel>
            User
          </PersonTableLabel>
          <PersonTableLabel>
            Status
          </PersonTableLabel>
          { /* Probably outdated */ }
          { /* <PersonTableLabel>
            Current result
          </PersonTableLabel> */ }
          <PersonTableLabel>
            Date
          </PersonTableLabel>
          <PersonTableLabel />
        </PersonTableRow>
        { this.state.invitations.map((invitation) => (
          <PersonTableRow key={ invitation.id }>
            <PersonTableCell>
              <Avatar
                txt={ invitation.invited_info.contact.substr(0, 2) }
              />
              { invitation.invited_info.contact }
            </PersonTableCell>
            <PersonTableCell>
              { invitation.status }
            </PersonTableCell>
            { /* Probably outdated */ }
            { /* <PersonTableCell>
              { invitation.result }
            </PersonTableCell> */ }
            <PersonTableCell>
              { getDate(invitation.modified) }
            </PersonTableCell>
            <PersonTableCell className={ this.cn`__invite-buttons` }>
              { invitation.status === 'not_registered' && invitation.resend_url &&
                <Button
                  onClick={ () => {
                    this.handleAskAgainButtonClick(invitation.resend_url);
                  } }
                  theme={ {
                    size: 'small',
                    color: 'white',
                  } }
                >
                  Ask again
                </Button>
              }
              { invitation.status === 'registered' &&
                <Button
                  onClick={ () => {
                    this.handleDetailsButtonClick(invitation.view_report_url);
                  } }
                  theme={ {
                    size: 'small',
                    color: 'white',
                  } }
                >
                  Details
                </Button>
              }
            </PersonTableCell>
          </PersonTableRow>
        )) }
      </PersonTable>
    );
  };

  renderAdder = () => {
    return !this.state.isLoading ? (
      <div className={ this.cn`__adder-container` }>
        { this.state.organization ? (
          <InputAdder
            ref={ (ref) => { this.inputAdderComponent = ref; } }
            onAdd={ this.handleInputAdderOnAdd }
            inputProps={ {
              placeholder: 'example@mail.com',
            } }
          />
        ) : (
          <div>
            <Alert type="error">
              Cannot invite new users. Wait for an organization assigment
            </Alert>
          </div>
        ) }
      </div>
    ) : null;
  }

  renderDetailsDialog = () => {
    return (
      <Dialog
        align={ { points: ['tc', 'tc'], offset: [0, 140] } }
        className={ this.cn`__details-dialog` }
        ref={ (el) => { this.detailsDialog = el; } }
        title="Invitation details"
      >
        <table className={ this.cn`__details-table` }>
          { Object.keys(_get(this.state, 'invitation_details', {})).map((key) => (
            <tr>
              <td>
                { key }
              </td>
              <td>
                { this.state.invitation_details[key] }
              </td>
            </tr>
          )) }
        </table>
      </Dialog>
    );
  }

  handleDetailsButtonClick = (detailsUrl) => {
    const id = detailsUrl.split('/').pop();
    invitationModel.getReport(id).then(() => {
      this.detailsDialog.toggle(true);
    });
  }

  handleAskAgainButtonClick = (resendUrl) => {
    invitationModel.reSendInvitation(resendUrl)
      .then(() => {
        messengerModel
          .clearMessages().then(() => {
            messengerModel.addMessage({
              msg: 'Invitaton sent once again.',
              type: 'success',
            });
          });
        this.refreshInvitations();
      });
  }

  refreshInvitations = () => {
    invitationModel.getList();
  }

  handleInputAdderOnAdd = (newEmail) => {
    // TODO: validate here
    const senderPays = true;
    invitationModel.sendInvitation(newEmail, senderPays)
      .then(() => {
        this.inputAdderComponent.clear();
        messengerModel.clearMessages();
        messengerModel.addMessage({
          msg: 'Invitaton sent',
          type: 'success',
        });
        this.refreshInvitations();
      })
      .catch((error) => {
        messengerModel.clearMessages();
        messengerModel.addMessage({
          msg: error,
          type: 'error',
        }).then(this.refreshInvitations);
      });
  }

  render() {
    return (
      <div className={ this.rootcn() } >
        <Heading
          subtitle="Pick wisely"
        >
          Invite Customers
        </Heading>
        <Messenger />
        { this.renderDetailsDialog() }
        { this.renderAdder() }
        <div className={ this.cn`__people-list-container` }>
          <Section>
            { this.state.invitations ? this.renderInvitationsTable() : (
              <div>No invitations to display</div>
            ) }
          </Section>
        </div>
      </div>
    );
  }
}

export default AdminPanelInviteView;
