import React from 'react';
import PropTypes from 'prop-types';

import BaseComponent from 'components/BaseComponent';
import messengerModel from 'models/messenger';
import Alert from '../../elements/Alert';

class Messenger extends BaseComponent {
  className = 'ts-Messenger';

  state = {
    messages: [],
  }

  static propTypes = {
    channel: PropTypes.string,
  };

  static defaultProps = {
    type: 'default',
  }

  componentDidMount() {
    this.syncStateWithModel(messengerModel, [
      'messages',
    ]);
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.state.messages.map((message) => (
          <div className={ this.cn`__alert-container` } key={ message.msg }>
            <Alert
              type={ message.type }
            >
              { message.msg }
            </Alert>
          </div>
        )) }
      </div>
    );
  }
}

export default Messenger;
