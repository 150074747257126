import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import PaymentCreditCardDialog from 'components/PaymentCreditCardDialog';

export default class PaymentCreditCardButton extends BaseComponent {
  className = 'ts-PaymentCreditCardButton';

  static propTypes = {
    amount: PropTypes.number,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    amount: 0,
    onError: noop,
    onSuccess: noop,
  };

  toggleDialog = (isVisible) => {
    this.dialogInstance.toggle(isVisible);
  };

  handleInvokeDialogButtonClick = () => {
    this.toggleDialog(true);
  }

  handleSuccessfulPayment = () => {
    this.props.onSuccess();
    this.dialogInstance.toggle(false);
  }

  handleUnsuccessfulPayment = () => {
    this.props.onError();
    this.dialogInstance.toggle(false);
  }

  render() {
    return (
      <div className={ this.rootcn`` }>
        <Button onClick={ this.handleInvokeDialogButtonClick }>
          Use Credit Card
        </Button>
        <Dialog
          align={ { points: ['tc', 'tc'], offset: [0, 140] } }
          className={ this.cn`__dialog` }
          ref={ (instance) => { this.dialogInstance = instance; } }
          size="small"
          title="Credit Card information"
        >
          <PaymentCreditCardDialog
            amount={ this.props.amount }
            onError={ this.handleUnuccessfulPayment }
            onSuccess={ this.handleSuccessfulPayment }
          />
        </Dialog>
      </div>
    );
  }
}
