import React from 'react';
import Card from 'components/Card';
import VideoCaptureTips from 'components/VideoCaptureTips';
import { getHelpCenterNotification } from 'helpers/notifications';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import VideoCaptureButton from 'sf/components/VideoCaptureButton';
import {
  mediator,
} from 'sf/helpers';

export default class SignUpVideoUpload extends BasePage {
  className = 'ts-SignUpVideoUpload';

  state = {}

  componentDidMount() {
    // show registration errors after CLEAR_NOTIFICATIONS on HelpCenter mount
    setTimeout(() => {
      this.showErrors();
    }, 1000);
  }

  showErrors = () => {
    switch (registration.get('verification_error_message')) {
      case 'No face found':
        this.notify(
          'error',
          getHelpCenterNotification('SELFIE_VIDEO_FACE_NOT_FOUND')
        );
        break;
      case 'Liveness test not found':
      case 'No face found in all frames':
        this.notify(
          'error',
          getHelpCenterNotification('SELFIE_VIDEO_SCORE_FAILURE')
        );
        break;
      default:
        this.notify(
          'error',
          getHelpCenterNotification('SELFIE_VIDEO_UNKNOWN_ERROR')
        );
        break;
    }
  }

  handleVideoTaken = (dataURI) => {
    registration.sendUserPolVideo(dataURI).then(
      () => {
        registration.set('polVideoPassed', true);
        mediator.publish('setRegistrationStep', '/SignUpComplete.html');
      }
    ).catch(() => {
      registration.set('polVideoPassed', false);
      this.notify('error', 'Uploading user POL video failed. Please try again.');
    });
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` } >
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          <VideoCaptureTips
            actionButton={
              <VideoCaptureButton
                maxLength={ 5 * 1000 } // 5 seconds
                mediaRecorderFallback={ MEDIA_STREAM_DEFAULT_FALLBACK_TYPE }
                outlined={ false }
                overlay="face"
                ref={ (el) => { this.videoCaptureNode = el; } }
                onVideoTaken={ this.handleVideoTaken }
              />
            }
          />
        </Card>
      </div>
    );
  }
}
