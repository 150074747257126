/* eslint-disable max-len */
export const DESCRIPTIONS = {
  'Estimated Household Income': 'This estimated value includes income from both wages and invested assets.',
  'Discretionary Spending': 'This is a modeled dollar amount of what a houseold likely spends on discretionary items after accounting for the fixed expenses of life (shelter, utilities, insurance, fixed transportation costs, healthcare).',
  'Economic Segment': 'Using Equifax’s proprietary segmentation system, it provides a complete picture of the consumer wallet in combination of the key demographic, behavioral and lifestyle attributes.  The building blocks of this measure are economic capacity and potential, demographics and home ownership, credit & geography.',
  'Financial Risk': 'Aggregated FICO® scores modeled to the micro-neighborhood level, or zip+4.',
  'Mortgage Financial Risk': 'A mortgage tradeline specific modeled score within a micro-neighborhood that predicts whether an account, or potential credit customer will become a serious credit risk within 24 months of scoring.',
  'Credit Capacity': 'An aggregated FICO® score which identifies a consumer’s ability to safely take on additional debt.',

  'Estimated Home Value': 'Estimated Home Market Value',
  'Estimated Combined Loan Balance': 'Estimated Combined Loan Balance is populated with the lead\'s outstanding combined mortgage loan amounts amortized using rates, if known, or estimated using rate tables.',
  'Time in Home': 'The elapsed time the homeowner has been in the home.',
  'Current CLTV': 'This is derived from the combined loan amount and the current estimated home market value.',
  'Estimated Property Equity': 'The field is estimated using the market value less the estimated loan balance of all outstanding loans.',
};
/* eslint-enable */
