import React from 'react';
import Card from 'components/Card';
import DocumentUploader from 'components/DocumentUploader';
import {
  ROUTES,
} from 'constants';
import registration from 'models/registration';
import BasePage from 'pages/BasePage';
import {
  mediator,
} from 'sf/helpers';

export default class VerifyID extends BasePage {
  className = 'ts-VerifyID';

  state = {};

  componentDidMount() {
    this.syncStateWithModel(registration, ['id_photo_retake']);
  }

  componentDidUpdate() {
    if (this.state.id_photo_retake) {
      const content = (
        <ul>
          <li>Find better lighting. Do not use flash.</li>
          <li>Fit the ID within the camera screen</li>
          <li>Make sure your photo in the ID is clearly visible</li>
        </ul>
      );

      this.publish('showHelp', {
        content,
        headerInfo: 'We couldn’t match the face from your video with your ID',
        theme: 'error',
        title: 'Please try doing these:',
      });
    }
  }

  handlePhotoTaken = ({ imageDataURI, document_type, state }) => {
    registration.uploadDocument(
      imageDataURI,
      document_type,
      state
    ).then((data) => {
      registration.set({
        'countryState': state,
        'documentType': document_type,
      });
      switch (true) {
        case this.state.id_photo_retake:
          registration.set({
            id_photo_retake: false,
          });
          this.navigate(ROUTES.APPLY_FORM.VERIFICATION_COMPLETE);
          break;
        case data && data.twosided:
          mediator.publish('setRegistrationStep', ROUTES.APPLY_FORM.VERIFY_ID_BACK);
          this.navigate(ROUTES.APPLY_FORM.VERIFY_ID_BACK);
          break;
        default:
          this.navigate(ROUTES.APPLY_FORM.VERIFY_ID_BACK);
          break;
      }
    });
  };

  render() {
    return (
      <div className={ this.rootcn`` }>
        <section className={ this.cn`ts-container ts-container--narrow __section` }>
          <Card
            className={ this.cn`__card` }
            fullWidth={ true }
            size="small"
          >
            <DocumentUploader
              onPhotoTaken={ this.handlePhotoTaken }
              captureSide="front"
            />
          </Card>
        </section>
      </div>
    );
  }
}
