import React from 'react';
import baseFunctionalComponent from 'components/BaseFunctionalComponent';
import Button from 'sf/components/Button';
import Dialog from 'sf/components/Dialog';

function PrivacyPolicy() {
  return (
    <Button
      className={ this.rootcn() }
      theme="link-unstyled"
      type="button"
      onClick={ () => this.paymentDialogInstance.toggle(true) }
    >
      Privacy Policy
      <Dialog
        className={ this.cn`__dialog` }
        ref={ (dialog) => { this.paymentDialogInstance = dialog; } }
        onDismiss={ this.handlePaymentDialogDismiss }
        size="xlarge"
      >
        { /* eslint-disable max-len */ }
        <h2>Privacy Policy</h2>
        <p>T Stamp Inc. (Trust Stamp) https://truststamp.ai is an international provider of secure identity services to banks, card networks, law enforcement agencies, financial services and other enterprises (our clients). You are utilizing Trust Stamp and its family of websites and applications because you have been referred to us by one of our clients or directly accessing Trust Stamp whether by typing a URL, snapping a QR Code or clicking on a link.</p>
        <p>Contact us by emailing <a href="mailto:privacy@truststamp.ai">privacy@truststamp.ai</a>.</p>
        <p>Trust Stamp is the ‘Controller’ of personal and sensitive information you provide when you are completing biometric identity verification through web sites and mobile applications operated by our clients or directly by Trust Stamp.</p>
        <p>We will process your personal and sensitive information in accordance with all applicable laws, including the General Data Protection Regulation (GDPR) 2018 and the California Consumer Privacy (CCPA).</p>
        <p><b>Collection of Information</b></p>
        { /* eslint-disable-next-line react/no-unescaped-entities */ }
        <p>We may collect non-personally identifiable information from you regarding your usage of our products and services.  Non-personally identifiable information refers to data that does not, by itself identify persons and may include, search criteria used and results, date, time, connection speed, internet protocol ("IP") address, which may also be contained on server logs, and/or other information which does not specifically identify you. The source of the usage data is our analytics tracking system. This usage data may be processed for the purposes of analyzing the use of the website and services. </p>
        <p>Trust Stamp also collects Personally Identifiable Information from you when you interact with our products and services and communicate with a member of our team, including but not limited to when you sign up for an account, you are voluntarily providing us with a self-portrait style photographic image (we call it a secure selfie) to allow us to prove your identity and help our client to know and trust your identity. You have consented to that process in accordance with the Terms and Conditions of Use and Privacy Policy applicable to our client’s services.</p>
        <p>You are also providing us with an image of an identity document. We use that image to compare the photograph on the document to your secure selfie and estimate whether the identity document photograph matches the secure selfie. Sometimes there is not a match due to the age of the image, damage to the document or the quality of the photograph. We also compare the text on the identity document to the information that you have given in account opening. You have consented to that process in accordance with the Terms and Conditions of Use and Privacy Policy applicable to our client’s services.</p>
        <p>In some instances, we also access 3rd party data sources to augment or validate the data you have provided.</p>
        <p>We will only use the information you provide for the original purpose, as specified in this Policy, subject to any legal exemptions and requirements.</p>
        <p>To train our software to carry out functions including the identity authentication process described above, we use a process called “machine learning” so that the software learns to recognizes patterns in the data that it receives and make predictions based upon that data. Your data will be part of the continuous process of training and contribute to the learning of the software.</p>
        <p>If you are in the United States, any data that we store will be stored on a secure server in the United States If your registered address is in Turkey, any data that we store will be stored on a secure server in Turkey. If you are in the EU or EEA, any data that we store will be stored on a secure server in the EU. If you are resident in the UK, and the UK is no longer part of the EU, we will continue to ensure that appropriate technical and organizational measures are implemented to safeguard personal information.</p>
        <p>Unless compelled by law we do not and will not, share your identity or other data with anyone other than the client through which you accessed our services. We do not use your data to market to you nor do we allow anyone else to do so.</p>
        <p>Our clients have varying legal obligations as to the length of time that data must be stored. In respect of any data that we store, we comply with those requirements as directed by them.</p>
        <p className={ this.cn`__uppercase` }><b>Additional Rights if you are Resident in the EU, EEA, or UK</b></p>
        <p>If you are resident in the EU or EEA, you have the right to request a copy of any personal information we hold about you. This is known as a Subject Access Request for which there is no charge, providing the requests are not manifestly unfounded or excessive. Your request needs to be made in writing and sent to the Data Protection Officer at the address given below under ‘How to contact us’. We may ask you to provide ID before processing the request. Once in receipt of this, we will process the request without undue delay and within one month. If the matter is complex an extension of up to two months can be given.</p>
        <p>You also have rights in relation to rectification, erasure, restriction, data portability, objection and automated decision making. If you would like more information on these rights, within the context of your relationship with Trust Stamp or wish to exercise the above rights, please contact the Data Protection Officer using our contact details below.</p>
        <p>We can only delete the data that we have stored and then only if we are not legally obligated to keep it for a set or indeterminate length of time. If you have a broader request than the data we store, we recommend that you contact the referring client who can provide us with a request to delete the data.</p>
        <p>If you require further assistance, would like to make a complaint, or wish to access your personal information, please contact us using the information below:</p>
        <p>Contact us by emailing <a href="mailto:privacy@truststamp.ai">privacy@truststamp.ai</a>.</p>
        <p><b>EU or EEA</b></p>
        <p className={ this.cn`__align--left` }>
          Data Protection Officer (DPO)<br />
          Trust Stamp<br />
          Level 1,<br />
          Tagliaferro Business Centre,<br />
          High Street, Sliema,<br />
          SLM 1551, Malta
        </p>
        <p><b>UK</b></p>
        <p className={ this.cn`__align--left` }>
          Data Protection Officer (DPO)<br />
          Trust Stamp<br />
          Hub 8, Unit 2 The Brewery Quarter, High St,<br />
          Cheltenham GL50 3FF, United Kingdom
        </p>
        <p className={ this.cn`__align--left` }>If you remain dissatisfied after pursuing the above remedy, you may also complain to the Information Commissioner’s Office. They can be contacted at:</p>
        <p className={ this.cn`__align--left` }>
          Information Commissioner’s Office<br />
          Wycliffe House<br />
          Water Lane<br />
          Wilmslow<br />
          Cheshire<br />
          SK9 5AF<br />
          www.ico.org.uk
        </p>
        <p><b>ADDITIONAL RIGHTS IF YOU ARE RESIDENT OF CALIFORNIA UNDER THE CCPA</b></p>
        <p>You have the right to know: what categories and specific pieces of CCPA personal information have been collected about you; the categories of sources from which CCPA personal information are collected; our business or commercial purpose for collecting, using, or disclosing CCPA personal information; the categories of third parties with whom we share CCPA personal information; the categories of CCPA personal information we have disclosed about you for a business purpose. We do not sell personal information.</p>
        <p>You have a right to receive a copy of the specific CCPA personal information we have collected about you.</p>
        <p>You have a right to deletion of your CCPA personal information, subject to exceptions under the CCPA.</p>
        <p>We will not discriminate against you based on your exercise any of your CCPA rights. </p>
        <p>You can assert these rights only where we receive a verified request from you. To make requests, or if you have a question regarding our privacy practices, please contact our Data Protection Officer at https://truststamp.ai/ContactUs.html.</p>
        { /* eslint-enable */ }
      </Dialog>
    </Button>
  );
}

export default baseFunctionalComponent(PrivacyPolicy, 'PrivacyPolicy');
