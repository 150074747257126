import PropTypes from 'prop-types';

export default {
  overlay: PropTypes.oneOf([
    'qrcode',
    'barcode',
    'face',
    'id',
    'id-back',
    'id-front',
    'id-front-auto',
    ''
  ]),
};
