import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import Link from 'components/Link';
import businessLogIn from 'models/businessLogIn';
import Input from 'sf/components/Input';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const ValidationInputWithStateLinkAndLabel = compose(
  withValidation,
  withStateLink,
)(Input);

export default class BusinessLogInFormComponent extends BaseComponent {
  className = 'ts-BusinessLogInForm';

  static propTypes = {
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onSubmit: noop,
  }

  state = {
    login: undefined,
    password: undefined,
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    await this.formValidation(
      businessLogIn.set(this.state)
    )
      .catch(() => {
        isFormValid = false;
      });

    if (!isFormValid) return;

    this.props.onSubmit(this.state);
  };

  shouldDisableSubmitButton = () => !Object.values(this.state)
    .every(Boolean);

  render() {
    return (
      <form
        className={ this.rootcn`__form` }
        onSubmit={ this.handleSubmit }
        noValidate="true"
      >
        <label
          className={ this.cn`__label` }
        >
          Your e-mail
        </label>
        <ValidationInputWithStateLinkAndLabel
          className={ this.cn`__input` }
          ref="login"
          placeholder="Enter your business login"
          stateLink={ [this, 'login'] }
          type="email"
        />
        <label
          className={ this.cn`__label` }
        >
          Your password
        </label>
        <ValidationInputWithStateLinkAndLabel
          className={ this.cn`__input` }
          ref="password"
          placeholder="Enter your business password"
          stateLink={ [this, 'password'] }
          type="password"
        />
        <div className={ this.cn`__forgot-password` }>
          <Link
            to="/business/ResetPassword/Selfie.html"
            className={ this.cn`__contact-link` }
          >
            Forgot your password?
          </Link>
        </div>
        <Button
          className={ this.cn`__submit` }
          type="submit"
          disabled={ this.shouldDisableSubmitButton() }
        >
          Next step
        </Button>
      </form>
    );
  }
}
