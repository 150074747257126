import React from 'react';
import _get from 'lodash/get';
import BaseComponent from 'components/BaseComponent';
import SocialServicesCards, { MODES } from 'components/SocialServicesCards';
import { SERVICES } from 'constants';
import { buildQuery, getQuery } from 'sf/helpers';
import { adaptResponseMessage } from 'helpers/notifications';

export default class ResetPINSelectSocialNetwork extends BaseComponent {
  className = 'ts-ResetPINSelectSocialNetwork'

  componentDidMount() {
    // backend redirects to LogIn.html#token=... or #error=...
    const query = getQuery(location.hash);
    if (query.error) {
      this.notify('error', adaptResponseMessage(query.error));
      delete query.error;
      location.hash = buildQuery(query);
    }
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <SocialServicesCards
          includeBusinessOnlyService={ false }
          mode={ _get(MODES, 'RESET_PIN') }
          services={ SERVICES.filter(({ isUsedDuringAuth }) => isUsedDuringAuth) }
        />
      </div>
    );
  }
}
