import React from 'react';
import PropTypes from 'prop-types';
import ReactSlick from 'react-slick';
import BaseComponent from 'components/BaseComponent';
import { asset } from 'helpers';

export default class Slider extends BaseComponent {
  className = 'ts-Slider';

  static propTypes = {
    children: PropTypes.node,
    extraComponents: PropTypes.node,
    settings: PropTypes.object,
  };

  static defaultProps = {
    extraComponents: null,
    settings: {},
  };

  goTo = (index) => this.slick.slickGoTo(index);

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: (
        <button className="arrow-right">
          <img alt="" src={ asset`img/arrow-right.svg` } />
        </button>
      ),
      nextArrow: (
        <button>
          <img alt="" src={ asset`img/arrow-right.svg` } />
        </button>
      ),
      additionalSlideClassname: '',
      ...this.props.settings,
    };
    return (
      <div className={ this.rootcn() }>
        { this.props.extraComponents }
        <div
          key="wrapper"
          className={ this.cn`__container` }
        >
          <ReactSlick
            ref={ (slick) => { this.slick = slick; } }
            { ...settings }
          >
            { React.Children.map(this.props.children, (child, index) => {
              // prevent to wrap empty children
              if (!child) return;
              const baseCn = this.cn`__slide __slide-${index}`;
              const additionalCn = settings.additionalSlideClassname ?
                `${settings.additionalSlideClassname}
                 ${settings.additionalSlideClassname}-${index}` : '';
              return (
                <div>
                  <div
                    className={ `${baseCn} ${additionalCn}` }
                    key={ `slide-${index}` }
                  >
                    { child }
                  </div>
                </div>);
            }) }
          </ReactSlick>
        </div>
      </div>
    );
  }
}
