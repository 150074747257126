import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import userModel from 'models/user';

import BaseComponent from 'components/BaseComponent';
import layoutModel from 'models/layout';
import Icon from 'sf/components/Icon';

import { ROUTES } from './AdminPanel.constants';
import IndexView from './views/Index/Index';
import InviteView from './views/Invite/Invite';
import VerificationView from './views/Verification/Verification';
import BalanceView from './views/Balance/Balance';

import Alert from './elements/Alert';
import Button from './elements/Button';

class AdminPanel extends BaseComponent {
  className = 'ts-AdminPanel';

  static propTypes = {
    view: PropTypes.oneOf(Object.keys(ROUTES)),
  };

  static defaultProps = {
  };

  state = {}

  componentDidMount() {
    this.syncStateWithModel(layoutModel, ['branding_logo_url']);
  }

  handleLogoutButtonClick = () => {
    userModel.logOut();
  }

  getView = () => {
    switch (this.props.view) {
      case 'INVITE':
        return <InviteView />;
      case 'VERIFICATION':
        return <VerificationView />;
      case 'BALANCE':
        return <BalanceView />;
      case 'INDEX':
      default:
        return <IndexView />;
    }
  }

  render() {
    return (
      <div className={ this.rootcn() } >
        <div className={ this.cn`__top-container` }>
          <div className={ this.cn`__top-container-logo-container` }>
            { this.state.branding_logo_url &&
              <img
                className={ this.cn`__top-container-logo-image` }
                src={ this.state.branding_logo_url }
                alt="logo"
              />
            }
          </div>
          <div className={ this.cn`__top-right-section` }>
            { /* <div className={ this.cn`__top-right-section-item` }>
              <NotificationsBar notificationsCounter={ 3 } />
            </div> */ }
            { /* <div className={ this.cn`__top-right-section-item` }>
              <Icon
                set="io"
                size={ 30 }
                type="ios-chatboxes"
              />
            </div> */ }
            { /* <div className={ this.cn`__top-right-section-item` }>
              <Icon
                set="io"
                size={ 30 }
                type="android-settings"
              />
            </div> */ }
            { userModel.get('isSignedIn') &&
              <div className={ this.cn`__top-right-section-item` }>
                <Button onClick={ this.handleLogoutButtonClick }>
                  Logout
                </Button>
              </div>
            }
          </div>
        </div>
        <div className={ this.cn`__bottom-containers` }>
          <div className={ this.cn`__left-container` }>
            <div className={ this.cn`__navigation-container` }>
              <ul className={ this.cn`__navigation-list` }>
                <li className={ this.cn`__navigation-list-item` }>
                  <Link
                    to={ ROUTES.INVITE }
                    className={ this.cn`__navigation-list-link` }
                  >
                    <Icon
                      className={ this.cn`__navigation-list-icon` }
                      set="io"
                      size={ 20 }
                      type="ios-email"
                    />
                    Invite
                  </Link>
                </li>
                { /* <li className={ this.cn`__navigation-list-item` }>
                  <Link
                    to={ ROUTES.VERIFICATION }
                    className={ this.cn`__navigation-list-link` }
                  >
                    <Icon
                      className={ this.cn`__navigation-list-icon` }
                      set="io"
                      size={ 20 }
                      type="android-cancel"
                    />
                    Verification
                  </Link>
                </li> */ }
                <li className={ this.cn`__navigation-list-item` }>
                  <Link
                    to={ ROUTES.BALANCE }
                    className={ this.cn`__navigation-list-link` }
                  >
                    <Icon
                      className={ this.cn`__navigation-list-icon` }
                      set="io"
                      size={ 20 }
                      type="card"
                    />
                    Credit balance
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={ this.cn`__main-container` }>
            <Alert />
            { this.getView() }
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPanel;
