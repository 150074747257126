import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

const DEFAULT_COLORS = {
  min: {
    r: 229,
    g: 85,
    b: 39,
  },
  mid: {
    r: 186,
    g: 192,
    b: 62,
  },
  max: {
    r: 80,
    g: 163,
    b: 109,
  },
};

const colorShape = PropTypes.shape({
  r: PropTypes.number,
  g: PropTypes.number,
  b: PropTypes.number,
});

export default class LinearGradientBar extends BaseComponent {
  className = 'ts-LinearGradientBar';


  static propTypes = {
    colors: PropTypes.shape({
      max: colorShape,
      mid: colorShape,
      min: colorShape,
    }),
    isBig: PropTypes.bool,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  static defaultProps = {
    isBig: false,
    colors: DEFAULT_COLORS,
  };

  getValueColor = (relativeValue) => {
    const { colors } = this.props;
    if (relativeValue === 0.5) return colors.mid;
    if (relativeValue === 0) return colors.min;
    if (relativeValue === 1) return colors.max;

    const leftColor = relativeValue < 0.5 ? colors.min : colors.mid;
    const rightColor = relativeValue < 0.5 ? colors.mid : colors.max;

    const normalizedRelativeValue = (
      relativeValue < 0.5
        ? relativeValue
        : relativeValue - 0.5
    ) / 0.5;

    const getMedialNumber = (first, second, weight) => Math.round(
      first * (1 - weight) + second * weight
    );

    return {
      r: getMedialNumber(leftColor.r, rightColor.r, normalizedRelativeValue),
      g: getMedialNumber(leftColor.g, rightColor.g, normalizedRelativeValue),
      b: getMedialNumber(leftColor.b, rightColor.b, normalizedRelativeValue),
    };
  }

  render() {
    const { isBig, colors, max, min, value } = this.props;
    const relativeValue = value / (max - min);
    const { r, g, b } = this.getValueColor(relativeValue);
    const backgroundImage = `linear-gradient(to right, rgb(${
      colors.min.r
    }, ${
      colors.min.g
    }, ${
      colors.min.b
    }) 0, rgb(${
      colors.mid.r
    }, ${
      colors.mid.g
    }, ${
      colors.mid.b
    }) 50%, rgb(${
      colors.max.r
    }, ${
      colors.max.g
    }, ${
      colors.max.b
    }) 100%)`;
    return (
      <div
        className={ this.rootcn({ '--big': isBig }) }
      >
        <div
          className={ this.cn`__bar` }
          style={ { backgroundImage } }
        >
          <span className={ this.cn`__min` }>{ this.props.min }</span>
          <div
            className={ this.cn`__value` }
            style={ {
              backgroundColor: `rgb(${r}, ${g}, ${b})`,
              left: `${100 * relativeValue}%`,
            } }
          >
            { this.props.value }
          </div>
          <span className={ this.cn`__max` }>{ this.props.max }</span>
        </div>
      </div>
    );
  }
}
