import React from 'react';
import atom from 'atom-js';
import noop from 'no-op';

import config from 'config';
import { errorHandler, get, post, requestEnd } from 'sf/helpers/request';
import analytics from 'models/analytics';
import publicUser from 'models/publicUser';
import user from 'models/user';
import { getScript } from 'sf/helpers/domHelper';

const model = atom.setup({
  validation: {
    clientToken: null,
    nonce: null,
    type: null,
  },
  methods: {
    getClientToken(resolve, reject) {
      get('backend/payment/get_client_token/')
        .end((err, res) => {
          if (err) {
            reject(err, res);
          } else {
            resolve(res.body.data.client_token);
          }
        });
    },

    payForWalletInsight(resolve, reject, { nonce }, invitation_key) {
      post('backend/payment/pay_for_wallet_insight/', 'UNIVERSAL')
        .type('form')
        .send({
          payment_method_nonce: nonce,
          invitation_key,
        })
        .end(requestEnd(resolve, reject));
    },

    pay(
      resolve,
      reject,
      {
        nonce,
        amount,
        type,
      }
    ) {
      let paymentRequest;
      if (user.get('isSignedIn')) {
        paymentRequest = post('backend/payment/buy_credits/', 'UNIVERSAL')
          .type('form')
          .send({
            payment_method_nonce: nonce,
            number_of_credits: amount,
          });
      } else if (user.get('credit_price')) {
        paymentRequest = post('backend/payment/pay_for_registration/')
          .type('form')
          .send({
            payment_method_nonce: nonce,
            registration_token: user.get('token'),
          });
      } else {
        paymentRequest = post('backend/payment/pay/')
          .type('form')
          .send({
            payment_method_nonce: nonce,
            short_url: publicUser.get('shortProfileID'),
          });
      }

      paymentRequest
        .end((err, res) => {
          if (err) {
            errorHandler(err, res);
            reject();
          } else {
            analytics.trackEvent('WEBAPP_CREDITS_ADDED', {
              amount,
              type,
            });
            resolve();
          }
        });
    },

    useDiscountCode: (resolve, reject, discountCode) => {
      post('backend/payment/use_discount_code/', 'UNIVERSAL')
        .type('form')
        .send({
          discount_code: discountCode,
        })
        .end((err, res) => {
          if (err) {
            reject({
              statusCode: res.statusCode,
              message: res.body.data.message,
            });
          } else {
            resolve(res.body.data);
          }
        });
    },
  },
})({ credit_price: user.get('credit_price') || config.creditPrice });

Object.assign(model, {
  getBraintreeScripts(callback = noop) {
    if (global.braintree) return callback();

    const BRAINTREE_API_VERSION = '3.48.0';
    const CLIENT_SCRIPT_URL = `https://js.braintreegateway.com/web/${BRAINTREE_API_VERSION}/js/client.min.js`;
    const PAYPAL_SCRIPT_URL = `https://js.braintreegateway.com/web/${BRAINTREE_API_VERSION}/js/paypal.min.js`;
    const HOSTED_FIELDS_SCRIPT_URL = `https://js.braintreegateway.com/web/${BRAINTREE_API_VERSION}/js/hosted-fields.min.js`;
    if (!global.braintree) {
      getScript(CLIENT_SCRIPT_URL)
        .then(() => {
          getScript(PAYPAL_SCRIPT_URL);
          getScript(HOSTED_FIELDS_SCRIPT_URL);
        })
        .then(callback);
    } else {
      callback();
    }
  },
});

user.entangle(model, 'credit_price');

model.getPrice = (amount = 1) => {
  return (amount * model.get('credit_price')).toFixed(2);
};

model.getPrettyPrice = (amount = 1) => {
  let result = `$${model.getPrice(amount)}`;
  if (parseFloat(model.get('credit_price')) < parseFloat(config.creditPrice)) {
    result = (
      <span>
        <strike>${ (amount * config.creditPrice).toFixed(2) }</strike> { result }
      </span>
    );
  }

  return result;
};

export default model;
