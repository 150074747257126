import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import PageTitle from 'components/PageTitle';
import {
  asset,
} from 'helpers';

export default class VideoCaptureTips extends BaseComponent {
  className = 'ts-VideoCaptureTips';

  static propTypes = {
    actionButton: PropTypes.node,
    onActionButtonClick: PropTypes.func,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  };

  /* eslint-disable max-len */
  static defaultProps = {
    actionButton: null,
    onActionButtonClick: noop,
    subtitle: 'Move your head side to side as if you were trying to say no slowly and keep a neutral face expression.',
    title: 'Follow the movement in the animation',
  };
  /* eslint-enable max-len */

  handleActionButtonClick = () => {
    this.props.onActionButtonClick();
  }

  render() {
    return (
      <div
        className={ this.rootcn`` }
      >
        <div className={ this.cn`__page-title` }>
          <PageTitle
            title={ this.props.title }
            subtitle={ this.props.subtitle }
          />
        </div>
        <img
          alt="video recording desktop tips"
          src={ asset`img/video-record-tip.gif` }
          className={ this.cn`__content-image` }
        />
        <div className={ this.cn`__main-action` }>
          { this.props.actionButton }
          { !this.props.actionButton && (
            <Button
              onClick={ this.handleActionButtonClick }
            >
              Click to take a video
            </Button>
          ) }
        </div>
      </div>
    );
  }
}
