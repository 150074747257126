import React, { Fragment } from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import { BRANDINGS } from 'constants';
import businessSignUp from 'models/businessSignUp';
import Button from 'sf/components/Button';
import Checkbox from 'sf/components/Checkbox';
import Input from 'sf/components/Input';
import Link from 'sf/components/Link';
import MaskedInput from 'sf/components/MaskedInput';
import { chain } from 'sf/helpers';
import withLabel, { POSITIONS } from 'sf/hoc/Label';
import withPasswordTypeToggler from 'sf/hoc/PasswordTypeToggler';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';
import date from 'sf/models/date';

const ValidationInputWithStateLink = compose(
  withStateLink,
  withValidation,
)(Input);

const ValidationCheckboxWithStateLinkAndLabel = compose(
  withStateLink,
  withLabel,
  withValidation,
)(Checkbox);

const MaskedValidationInputWithStateLink = compose(
  withStateLink,
  withValidation,
)(MaskedInput);

const ValidationInputWithStateLinkAndToggle = compose(
  withPasswordTypeToggler,
  withStateLink,
  withValidation,
)(Input);

export default class BusinessSignUpBasicInfo extends BaseComponent {
  className = 'ts-BusinessSignUp';

  state = {
    legal_name: undefined,
    date_birth: undefined,
    email: undefined,
    phone_number: undefined,
    realtor_document_number: undefined,
    password: undefined,
    repeat_password: undefined,
    terms: false,
  };

  static propTypes = {
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: noop,
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let isFormValid = true;

    await this.formValidation(
      businessSignUp.set(this.state)
    )
      .catch(() => {
        isFormValid = false;
      });

    if (!isFormValid) return;

    const { phone_number, date_birth, ...data } = this.state;
    this.props.onSubmit({
      phone_number: phone_number.split('-').join(''),
      date_birth: chain(date_birth)
        .pipe(date.getDate)
        .pipe(date.toISODate)
        .value,
      ...omit(data, ['repeat_password']),
    });
  }

  shouldDisableSubmitButton = () => !Object.values(this.state)
    .every(Boolean);

  render() {
    const { isDone } = this.props;
    const isOrganization = BRANDINGS.ORGANIZATION_SUPERVISOR === BRAND_NAME;
    const label = (
      <Fragment>
        By creating account you accept&nbsp;
        <Link to="/TermsAndConditions.html">
          Terms & Privacy Policy
        </Link>
      </Fragment>
    );

    return (
      <form
        className={ this.cn`__form` }
        onSubmit={ this.handleSubmit }
        noValidate="true"
      >
        <div className={ this.cn`__name` }>
          <span className={ this.cn`__label` }>Hi, my legal name is</span>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLink
              disabled={ isDone }
              ref="legal_name"
              stateLink={ [this, 'legal_name'] }
              placeholder="John Smith"
            />
          </span>
        </div>
        <div className={ this.cn`__date-of-birth` }>
          <span className={ this.cn`__label` }>and I was born on</span>
          <span className={ this.cn`__input` }>
            <MaskedValidationInputWithStateLink
              disabled={ isDone }
              inputMode="numeric"
              mask="99/99/9999"
              ref="date_birth"
              placeholder="MM/DD/YYYY"
              stateLink={ [this, 'date_birth'] }
              unmask={ false }
            />
          </span>
        </div>
        <div className={ this.cn`__email` }>
          <span className={ this.cn`__label` }>My e-mail is</span>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLink
              disabled={ isDone }
              ref="email"
              stateLink={ [this, 'email'] }
              placeholder="john.smith@example.com"
              type="email"
            />
          </span>
        </div>
        <div className={ this.cn`__phone` }>
          <span className={ this.cn`__label` }>and my phone number is</span>
          <span className={ this.cn`__input` }>
            <MaskedValidationInputWithStateLink
              disabled={ isDone }
              inputMode="numeric"
              stateLink={ [this, 'phone_number'] }
              ref="phone_number"
              mask="+1-999-999-9999"
              placeholder="+1-123-456-7890"
              type="text"
            />
          </span>
        </div>
        { !isOrganization &&
          <div className={ this.cn`__nrds` }>
            <div className={ this.cn`__nrds-wrapper` }>
              <span className={ this.cn`__label` }>My NRDS ID is</span>
              <span className={ this.cn`__input` }>
                <MaskedValidationInputWithStateLink
                  disabled={ isDone }
                  inputMode="numeric"
                  stateLink={ [this, 'realtor_document_number'] }
                  ref="realtor_document_number"
                  mask="999999999"
                  placeholder="123456789"
                />
              </span>
            </div>
          </div>
        }
        <div className={ this.cn`__password` }>
          <div className={ this.cn`__label` }>I want my password to be:</div>
          <div className={ this.cn`__info` }>
            { /* eslint-disable max-len */ }
            Your password must be at least 8 characters long and contain: an uppercase letter, lowercase letter and a digit.
            { /* eslint-enable max-len */ }
          </div>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLinkAndToggle
              autocomplete="off"
              disabled={ isDone }
              ref="password"
              stateLink={ [this, 'password'] }
              placeholder="********"
              type="password"
            />
          </span>
          <div className={ this.cn`__info __password-info` }>
            Please retype your password
          </div>
          <span className={ this.cn`__input` }>
            <ValidationInputWithStateLinkAndToggle
              autocomplete="off"
              disabled={ isDone }
              ref="repeat_password"
              stateLink={ [this, 'repeat_password'] }
              placeholder="********"
              type="password"
            />
          </span>
        </div>
        <div className={ this.cn`__terms` }>
          <ValidationCheckboxWithStateLinkAndLabel
            disabled={ isDone }
            labelClassName={ this.cn`__label-terms` }
            labelPosition={ POSITIONS.RIGHT }
            labelText={ label }
            ref="terms"
            stateLink={ [this, 'terms'] }
          />
        </div>
        <div className={ this.cn`__submit` }>
          <Button
            disabled={ this.shouldDisableSubmitButton() }
            type="submit"
          >
            Next step…
          </Button>
        </div>
      </form>
    );
  }
}
