import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';

export default class Stamp extends BaseComponent {
  className = 'ts-Stamp';

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    children: 0,
  };

  render() {
    const { children } = this.props;
    return (
      <span
        { ...this.props }
        className={ this.rootcn() }
      >
        { children || 0 }
        <span className={ this.cn`__coin` }>
          { children === 1 ? 'stamp' : 'stamps' }
        </span>
      </span>
    );
  }
}
