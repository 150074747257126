import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Dialog from 'components/Dialog';

export default class BasicProfileInfoEmailDialog extends BaseComponent {
  className = 'ts-BasicProfileInfo';

  static propTypes = {
    confirmationEmail: PropTypes.string,
  };

  static defaultProps = {
    confirmationEmail: '',
  };

  open() {
    this.refs.dialog.toggle(true);
  }

  render() {
    return (
      <Dialog
        className={ this.cn`__change-dialog` }
        ref="dialog"
        size="small"
        title="Confirm your email"
      >
        <div className={ this.cn`__change-dialog-body` }>
          Click a link in the e-mail we’ve sent to
          <div className={ this.cn`__change-dialog-important` }>
            { this.props.confirmationEmail }
          </div>
          If it has not arrived, check your spam folder.
        </div>
      </Dialog>
    );
  }
}
