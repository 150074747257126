import React from 'react';
import Button from 'components/Button';
import BaseComponent from 'components/BaseComponent';
import appState from 'models/appState';
import user from 'models/user';
import ValidationInput from 'sf/components/ValidationInput';

export default function (ComposedComponent) {
  return class AcceptTerms extends BaseComponent {
    className = 'ts-AcceptTerms';

    state = {
      isVisible: null,
      isChecked: false,
    }

    componentDidMount() {
      this.checkComponentVisibility();
    }

    checkComponentVisibility = () => {
      this.setState({
        isVisible: user.get('isSignedIn') || appState.get('termsAcceptanceTimestamp'),
      });
    }

    handleButtonClick = () => {
      this.setState({
        isVisible: true,
      });
      appState.set('termsAcceptanceTimestamp', new Date().getTime());
    }

    render() {
      if (this.state.isVisible === null) return null;
      if (this.state.isVisible) return <ComposedComponent { ...this.props } />;
      return (
        <div className={ this.rootcn() }>
          <div className="ts-container">
            <div className={ this.cn`__title` }>
              Before we go any further&hellip;
            </div>
            <div className={ this.cn`__checkbox` }>
              <ValidationInput
                type="checkbox"
                stateLink={ [this, 'isChecked'] }
              />
            </div>
            I Accept { }
            <a
              className={ this.cn`__terms-link` }
              href="/TermsAndConditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              The Terms and Privacy Policy
            </a>
            <div className={ this.cn`__continue-button` }>
              <Button
                disabled={ !this.state.isChecked }
                onClick={ this.handleButtonClick }
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      );
    }
  };
}
