import React from 'react';

import AdminPanel from 'components/AdminPanel';
import { ROUTES as ADMIN_APP_ROUTES } from 'components/AdminPanel/AdminPanel.constants';
import BasePage from 'pages/BasePage';

export class AdminPanelPage extends BasePage {
  className = 'ts-AdminPanelPage';

  state = {};

  getAdminViewByRoute = () => {
    return Object.keys(ADMIN_APP_ROUTES)
      .find((key) => ADMIN_APP_ROUTES[key] === this.props.location.pathname);
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <AdminPanel
          view={ this.getAdminViewByRoute() }
        />
      </div>
    );
  }
}

export default AdminPanelPage;
