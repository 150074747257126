import React from 'react';
import { compose } from 'recompose';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import { ROUTES } from 'constants';
import withStateLink from 'hoc/StateLink';
import withValidation from 'hoc/Validation';
import analytics from 'models/analytics';
import help from 'models/help';
import registration from 'models/registration';
import user from 'models/user';
import BasePage from 'pages/BasePage';
import Button from 'sf/components/Button';
import Card from 'sf/components/Card';
import MaskedInput from 'sf/components/MaskedInput';
import ValidationInput from 'sf/components/ValidationInput';
import { mediator } from 'sf/helpers';
import { setCaretPosition } from 'sf/helpers/domHelper';

const CONTEXT_HELP_NOTIFICATIONS = {
  CODE: [
    'NO_VERIFICATION_CODE',
    'WHAT_IS_THE_VERIFICATION_CODE',
  ],
  PHONE: [
    'US_PHONE_NUMBER_REQUIRED',
    'PHONE_NUMBER_REQUIRED',
  ],
};

const MaskedValidationInputWithStateLink = compose(
  withValidation,
  withStateLink,
)(MaskedInput);

export default class SignUpPhoneComponent extends BasePage {
  className = 'ts-SignUpPhone';
  state = {
    phone: null,
    phoneVerificationCode: null,
    codeSent: false,
    resendMode: false,
    allDoneMode: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { phone } = this.state;
    this.formValidation(
      user.set({
        phone,
      })
    ).then(() => {
      const done = () => {
        this.setState({
          codeSent: true,
        });
        // Hide a possible outdated error alert (e.g. duplicate phone number)
        help.clearNotifications();

        analytics.trackEvent('WEBAPP_REGISTRATION_PHONE_STEP_DONE');
        analytics.updateUserInfo();
      };

      const fail = (message) => {
        this.refs.phone.setValid(false, message || 'Provided phone number could not be saved');
      };

      registration.signUpPhoneNumber(`+1${phone}`).then(done, fail);
    });
  }

  handleCodeSubmit = (e) => {
    e.preventDefault();
    this.formValidation(
      registration.set({
        'phoneVerificationCode': this.state.phoneVerificationCode,
      })
    ).then(() => {
      mediator.publish('setRegistrationStep', '/SignUpVideo.html');
      analytics.trackEvent('WEBAPP_REGISTRATION_PHONE_VERIFICATION_STEP_DONE');
    });
  }

  fixCarret = (e) => {
    setCaretPosition(e.target, 0);
  };

  setResendMode = (e) => {
    e.preventDefault();
    this.setState({
      codeSent: false,
      resendMode: true,
    });
  }

  componentDidMount() {
    const events = [
      'WEBAPP_REGISTRATION_SOCIAL_NETWORK_STEP_DONE',
    ];
    if (global.REALTOR) {
      events.unshift('WEBAPP_REGISTRATION_STARTED_REALTOR');
    } else {
      events.unshift('WEBAPP_REGISTRATION_STARTED');
    }
    analytics.trackEvent(events);

    this
      .syncStateWithModel(user, ['phone', 'phoneVerificationCode'])
      .then(() => {
        if (this.state.phone) {
          this.setState({ codeSent: true }); // model says, number is already there.
        }
        if (this.state.phoneVerificationCode) { // model says, phone is already confirmed
          this.setState({});
        }
      });
  }

  handleContactClick = () => {
    this.navigate(ROUTES.CONTACT_US);
  }

  getText() {
    if (this.state.allDoneMode) {
      return {
        title: 'Add your cell phone number',
        subtitle: 'We need to verify you and keep things running smoothly',
        notifications: CONTEXT_HELP_NOTIFICATIONS.PHONE,
      };
    }

    if (this.state.codeSent) {
      return {
        title: 'Enter the code you received',
        subtitle: 'So we know it is your number',
        notifications: CONTEXT_HELP_NOTIFICATIONS.CODE,
      };
    }

    return {
      title: 'Add your cell phone number',
      subtitle: 'We need to verify you and keep things running smoothly',
      notifications: CONTEXT_HELP_NOTIFICATIONS.PHONE,
    };
  }

  renderBeforeSend() {
    return (
      <form
        className={ this.cn`__form` }
        key="form1"
        noValidate={ true }
        onSubmit={ this.handleSubmit }
      >
        <MaskedValidationInputWithStateLink
          inputMode="numeric"
          mask="+1 (999) 999-9999"
          ref="phone"
          stateLink={ [this, 'phone'] }
          type="tel"
        />
        <div className={ this.cn`__main-action` }>
          <Button type="submit">
            { this.state.resendMode ? 'Resend code' : 'That’s my number' }
          </Button>
        </div>
      </form>
    );
  }

  renderMessage() {
    let helpMessage;

    if (this.state.resendMode) {
      helpMessage = (
        <Button type="button" theme="link" onClick={ this.handleContactClick }>
          Please contact our customer service
        </Button>
      );
    } else {
      helpMessage = (
        <Button type="button" theme="link" onClick={ this.setResendMode }>
          I did not receive a message from Trust Stamp
        </Button>
      );
    }
    return (
      <p className={ this.cn`__help-message` }>
        { helpMessage }
      </p>
    );
  }

  renderAfterSend() {
    return (
      <form
        className={ this.cn`__form` }
        key="form2"
        onSubmit={ this.handleCodeSubmit }
      >
        <ValidationInput
          ref="phoneVerificationCode"
          type="text"
          placeholder="Verification code..."
          displayName="Verification Code"
          stateLink={ [this, 'phoneVerificationCode'] }
          inputMode="numeric"
        />
        <div className={ this.cn`__main-action` }>
          <Button type="submit">
            That’s the code
          </Button>
        </div>
        { this.renderMessage() }
      </form>
    );
  }

  renderContent() {
    const titleHelpButotnClassNames = {
      '__title-help-button': true,
      '__title-help-button--code-sent': this.state.codeSent,
    };
    if (this.state.allDoneMode) {
      return (
        <div key="alldone">
          <div className={ this.cn`__page-title` }>
            <PageTitle
              className={ this.cn`__title` }
              title={ this.getText().title }
              context={ this.state.phone }
              subtitle={ this.getText().subtitle }
            />
            <HelpButton
              className={ this.cn(titleHelpButotnClassNames) }
              notifications={ this.getText().notifications }
            />
          </div>
          <p className={ this.cn`__text __text--centered` }>
            Your phone number is already confirmed
          </p>
        </div>
      );
    }

    return (
      <div key="phonenumber">
        <div className={ this.cn`__page-title` }>
          <PageTitle
            className={ this.cn`__title` }
            title={ this.getText().title }
            subtitle={ this.getText().subtitle }
          />
          <HelpButton
            className={ this.cn(titleHelpButotnClassNames) }
            notifications={ this.getText().notifications }
          />
        </div>
        { this.state.codeSent ? this.renderAfterSend() : this.renderBeforeSend() }
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` }>
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          { this.renderContent() }
        </Card>
      </div>
    );
  }
}
