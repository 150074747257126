import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import businessSignUp from 'models/businessSignUp';
import MaskedInput from 'sf/components/MaskedInput';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const MaskedValidationInputWithStateLink = compose(
  withStateLink,
  withValidation,
)(MaskedInput);

export default class BusinessSignUpBasicInfo extends BaseComponent {
  className = 'ts-BusinessSignUp';

  state = {
    code: undefined,
  }

  static propTypes = {
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    onSubmit: noop,
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let isFormValid = true;

    await this.formValidation(
      businessSignUp.set(this.state)
    )
      .catch(() => {
        isFormValid = false;
      });

    if (!isFormValid) return;
    this.props.onSubmit(this.state);
  }

  render() {
    const { isDone } = this.props;
    return (
      <form className={ this.cn`__form` } onSubmit={ this.handleSubmit }>
        <div className={ this.cn`__code` }>
          <div className={ this.cn`__code-wrapper` }>
            <span className={ this.cn`__label` }>My code is:</span>
            <span className={ this.cn`__input` }>
              <MaskedValidationInputWithStateLink
                autocomplete="off"
                disabled={ isDone }
                inputMode="numeric"
                mask={ '9'.repeat(8) }
                ref="code"
                placeholder="12345678"
                stateLink={ [this, 'code'] }
              />
            </span>
          </div>
          <div className={ this.cn`__submit` }>
            <Button
              disabled={ !this.state.code }
              type="submit"
            >
              That՚s the code!
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
