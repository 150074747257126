import React from 'react';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip'; // ReactTooltip handles data-tip="..." arg
import { findDOMNode } from 'react-dom';

import BaseComponent from 'components/BaseComponent';
import Button from 'sf/components/Button';
import CropImage from 'sf/components/CropImage';
import IconButton from 'sf/components/IconButton';
import Webcam, { webcam } from 'sf/components/Webcam';
import withTooltip from 'sf/hoc/Tooltip';
import { CAPTURE_MODE_MANUAL } from 'sf/components/Webcam/constants';
import { waitFor } from 'sf/helpers';
import { logImage } from 'sf/helpers/canvas';

import {
  blockPageInTransition,
  takeControlOverWindow,
} from 'sf/helpers/domHelper';
import {
  TAKE_PHOTO,
  TAKE_SELFIE,
  WEBCAM_BACK,
  HOLD_STILL,
} from 'sf/l10n';
import device from 'sf/models/device';
// import SpaceFiller from 'sf/components/Webcam/SpaceFiller';

const WEBCAM_CONTAINER_MAX_WIDTH = 1152;
const WEBCAM_CONTAINER_MAX_HEIGHT = 864;

const IconWithTooltip = withTooltip(IconButton);

export const AdvancedPhotoCaptureStep = {
  TAKING_PHOTO: 'TAKING_PHOTO',
  CROPPING_PHOTO: 'CROPPING_PHOTO',
  SAVE_PHOTO: 'SAVE_PHOTO',
};

export default class AdvancedPhotoCapture extends BaseComponent {
  className = 'ts-AdvancedPhotoCapture';

  static propTypes = {
    allowCrop: PropTypes.bool,
    allowRetake: PropTypes.bool,
    cropAspectRatio: PropTypes.number,
    cropSlideable: PropTypes.bool,
    delayedStart: PropTypes.bool,
    fullScreen: PropTypes.bool,
    header: PropTypes.node,
    onError: PropTypes.func,
    onPhotoTaken: PropTypes.func.isRequired,
    onToggle: PropTypes.func,
    overlay: PropTypes.string,
    webcamParams: PropTypes.object,
    viewFinderText: PropTypes.string,
  };

  static defaultProps = {
    allowCrop: false,
    allowRetake: false,
    cropAspectRatio: 1,
    delayedStart: false,
    fullScreen: false,
    header: null,
    onError: noop,
    onToggle: noop,
  };

  photo = null;
  croppedPhoto = null;

  state = {
    hidden: this.props.fullScreen,
    step: AdvancedPhotoCaptureStep.TAKING_PHOTO,
    webcamContainerHeight: '100%',
    webcamContainerLeftOffset: 0,
    webcamContainerTopOffset: 0,
    webcamContainerWidth: '100%',
    ...this.syncStateWithModelInitial(webcam.params, [
      'load',
      'live',
      'isFullyLoaded',
    ]),
  };

  photoInstance = React.createRef();

  componentDidMount() {
    this.subscribe('camera-snap', () => {
      this.handleCaptureButtonClick();
    });

    this.subscribe(
      device,
      ['windowHeight', 'windowWidth'],
      this.updateCameraContainerDimensions,
    );
    this.subscribe(webcam.params, 'loadedVideoDimensions', this.updateCameraContainerDimensions);
    this.addEventListener(window, 'keypress', (event) => {
      if (
        event.key === 'Enter' &&
        this.props.fullScreen &&
        !this.state.hidden
      ) {
        this.handleCaptureButtonClick();
      }
    });

    this.updateCameraContainerDimensions();

    this.timeStart = Date.now();
  }

  componentWillUnmount() {
    if (this.props.fullScreen) document.body.style.overflow = '';
    this.photo = null;
    this.croppedPhoto = null;
    this.removeWindowControl();

    this.toggleFullScreenFix(false);
    super.componentWillUnmount();
  }

  removeWindowControl = () => {
    // eslint-disable-next-line no-unused-expressions
    this.windowControl && this.windowControl.remove();
    delete this.windowControl;
  };

  toggleFullScreenFix(isDisplayed) {
    // transform breaks position fixed:
    // https://stackoverflow.com/questions/28157125/why-does-transform-break-position-fixed
    //
    // This function can be also found in VideoCapture
    //
    // Fix for OL-674
    if (this.props.fullScreen) {
      const fixClassName = this.cn`__position-fixed-glitch-fix`;
      if (isDisplayed) {
        let parent = findDOMNode(this);
        if (!parent) return;

        // eslint-disable-next-line no-cond-assign
        while ((parent = parent.parentElement)) {
          parent.classList.add(fixClassName);
        }
      } else {
        [...document.querySelectorAll(`.${fixClassName}`)].forEach(
          (domNode) => {
            domNode.classList.remove(fixClassName);
          }
        );
      }
    }
  }

  async toggle(isDisplayed) {
    blockPageInTransition();
    this.toggleFullScreenFix(isDisplayed);

    const promise = this.props.onToggle(isDisplayed);
    if (isDisplayed && promise && promise.then) {
      // wait for toggleFullScreen
      await promise.catch(noop);
    }

    this.setState({ hidden: !isDisplayed }, () => {
      if (this.props.fullScreen) {
        document.body.style.overflow = this.state.hidden ? '' : 'hidden';
        this.removeWindowControl();

        if (!this.state.hidden) {
          this.windowControl = takeControlOverWindow(
            this.rootNode,
            findDOMNode(this.refs.captureButton)
          );
        }
      }
    });
    if (isDisplayed) this.timeStart = Date.now();
  }

  handleCaptureButtonClick = async () => {
    const picPreparingTimeStart = Date.now();

    webcam.params.set('captureInProgress', true);

    if (!this.photoInstance.current) {
      // eslint-disable-next-line no-console
      return console.warn(
        'photoInstance in AdvancedPhotoCapture is not present',
        this
      );
    }
    const extraInfo = {
      pic_time: Date.now() - this.timeStart,
      pic_mode: CAPTURE_MODE_MANUAL,
    };
    // SNAP might take a moment on slower devices and for large photos. Use spinner

    Button.setProcessing(this.refs.captureButton, true);

    const result = await waitFor(this.photoInstance.current.snap(), {
      spinnerMessageText: `${HOLD_STILL}...`,
    });

    Button.setProcessing(this.refs.captureButton, false);

    extraInfo.pic_preparing_time = Date.now() - picPreparingTimeStart;

    this.handlePhotoTaken(result, extraInfo);
  };

  handlePhotoTaken = (dataURI, extraInfo) => {
    webcam.params.set('captureInProgress', false);

    logImage(dataURI);

    this.photo = dataURI;
    let step = false;

    if (this.props.allowCrop) {
      step = AdvancedPhotoCaptureStep.CROPPING_PHOTO;
    } else if (this.props.allowRetake) {
      step = AdvancedPhotoCaptureStep.SAVE_PHOTO;
    }

    if (step === AdvancedPhotoCaptureStep.TAKING_PHOTO) return;

    if (step) {
      // go to the next step
      this.setState({
        step,
      });
      // fullScreen must be toggled to allow crop in
      // `allowUploadAlternative` mode.
      return this.toggle(true);
    }

    // return a captured image when all additional steps are disabled
    this.props.onPhotoTaken(this.photo, extraInfo);
    delete this.photo;
  };

  handleCropAndSave = async () => {
    this.croppedPhoto = await this.cropImageInstance.getCroppedImage();
    if (this.props.allowRetake) {
      this.setState({
        step: AdvancedPhotoCaptureStep.SAVE_PHOTO,
      });
    } else {
      this.reset(() => {
        this.toggle(false);
        this.handleSavePhoto();
      });
    }
  };

  reset(callback) {
    this.setState(
      {
        step: AdvancedPhotoCaptureStep.TAKING_PHOTO,
      },
      callback || noop
    );
  }

  handleRetakeButtonClick = () => {
    this.reset();
  };

  handleSavePhoto = () => {
    this.props.onPhotoTaken(
      this.props.allowCrop ? this.croppedPhoto : this.photo,
      this.props.allowCrop ? this.photo : null
    );

    delete this.photo;
    delete this.croppedPhoto;
  };

  handleCropperClose = () => {
    this.reset(() => {
      if (!this.props.webcamParams.force_fresh_photo) {
        this.toggle(false);
      }
    });
  };

  handlePhotoCaptureBackButtonClick = () => {
    this.publish('cameraBackButtonClick');
    this.reset();
    this.toggle(false);
  };

  getRootNodeParam(param) {
    return this.rootNode && this.rootNode[param];
  }

  updateCameraContainerDimensions = () => {
    const containerWidth = this.props.fullScreen
      ? device.windowWidth()
      : this.getRootNodeParam('clientWidth');
    const containerHeight = this.props.fullScreen
      ? device.windowHeight()
      : this.getRootNodeParam('clientHeight');

    const webcamContainerWidth = Math.min(
      containerWidth,
      WEBCAM_CONTAINER_MAX_WIDTH
    );
    const webcamContainerHeight = Math.min(
      containerHeight,
      WEBCAM_CONTAINER_MAX_HEIGHT
    );

    const dimensions = Object.entries({
      webcamContainerWidth,
      // leave some space for mobile browsers navigation bar at the bottom which can overlay
      // document and can block bottom buttons and ui elements
      webcamContainerHeight,
      webcamContainerLeftOffset: (containerWidth - webcamContainerWidth) / 2,
      webcamContainerTopOffset: (containerHeight - webcamContainerHeight) / 2,
    }).reduce((acc, [key, value]) => {
      acc[key] = Number.isFinite(value) ? value : this.state[key];

      return acc;
    }, {});

    this.setState(dimensions);
  };

  handleFallbackUpload = (scaledPicture, extraInfo) => {
    this.handlePhotoTaken(scaledPicture, extraInfo);
  };

  renderStep = () => {
    const webCamContainerStyle = {
      width: this.state.webcamContainerWidth,
      height: this.state.webcamContainerHeight,
      top: this.state.webcamContainerTopOffset,
      left: this.state.webcamContainerLeftOffset,
    };

    switch (this.state.step) {
      case AdvancedPhotoCaptureStep.SAVE_PHOTO:
        return (
          <div
            className={ this.cn`__webcam-container` }
            style={ webCamContainerStyle }
          >
            <img
              alt=""
              className={ this.cn`__photo-taken` }
              ref={ this.createRef('capturedImageNode') }
              src={ this.photo }
              crossOrigin="anonymous"
            />
            <div className={ this.cn`__secondary-action` }>
              <Button theme="link" onClick={ this.handleRetakeButtonClick }>
                Take another photo
              </Button>
            </div>
            <div className={ this.cn`__main-action` }>
              <Button theme="action" onClick={ this.handleSavePhoto }>
                Save photo
              </Button>
            </div>
          </div>
        );
      case AdvancedPhotoCaptureStep.CROPPING_PHOTO:
        return (
          <div
            className={ this.cn`__webcam-container` }
            style={ webCamContainerStyle }
          >
            <div className={ this.rootcn`__webcam` }>
              <div className={ this.cn`__crop-image` }>
                <CropImage
                  ref={ (el) => {
                    this.cropImageInstance = el;
                  } }
                  aspectRatio={ this.props.cropAspectRatio }
                  image={ this.photo }
                  autoCropArea={ 0.6 }
                  cropperCaption={ this.props.cropperCaption }
                  zoomable={ true }
                  width={ this.state.webcamContainerWidth }
                  height={ this.state.webcamContainerHeight }
                  onClose={ this.handleCropperClose }
                  minCropBoxWidth={ 30 }
                  minCropBoxHeight={ 30 }
                  slideable={ this.props.cropSlideable }
                />
              </div>
            </div>
            <div
              className={ this.cn({
                '__actions': true,
                '__actions--crop-slideable': this.props.cropSlideable,
              }) }
            >
              <div className={ this.cn`__main-action` }>
                <Button
                  theme="action"
                  pill={ true }
                  onClick={ this.handleCropAndSave }
                >
                  Crop and save
                </Button>
              </div>
            </div>
          </div>
        );
      default:
        // eslint-disable-next-line no-case-declarations
        const WebcamComponent =
          this.props.delayedStart &&
          this.state.hidden &&
          is.userMediaSupported()
            ? Webcam.FallbackUploadHandler
            : Webcam;
        // eslint-disable-next-line no-case-declarations
        const captureButtonText =
          this.props.overlay === 'face' ? TAKE_SELFIE : TAKE_PHOTO;

        // <SpaceFiller key="fillter" />,
        return (
          <div
            key="div"
            className={ this.cn`__webcam-container` }
            style={ webCamContainerStyle }
          >
            <IconWithTooltip
              className={ this.cn`__back-button` }
              iconSize={ 24 }
              onClick={ this.handlePhotoCaptureBackButtonClick }
              set="fa"
              aria-label={ WEBCAM_BACK }
              type="chevron-left"
              tooltipText={ WEBCAM_BACK }
            />
            <WebcamComponent
              key={ WebcamComponent.name }
              ref={ this.photoInstance }
              { ...this.props.webcamParams }
              fullScreen={ this.props.fullScreen }
              onContainerSizeSet={ this.handleContainerSizeSet }
              onError={ this.handleError }
              onFallbackUpload={ this.handleFallbackUpload }
              overlay={ this.props.overlay }
              enableFullScreenFallbackMode={
                this.state.enableFullScreenFallbackMode
              }
              customViewFinderText={ this.props.viewFinderText }
            />

            { this.state.hidden || this.props.children }

            <Button
              className={ this.cn`__capture-button` }
              disabled={
                !this.state.load ||
                !this.state.live ||
                !this.state.isFullyLoaded
              }
              iconPosition="left"
              onClick={ this.handleCaptureButtonClick }
              ref="captureButton"
              // theme='action'
              aria-label={ captureButtonText }
              data-tip={ captureButtonText }
            >
            </Button>
          </div>
        );
    }
  };

  handleError = (...args) => {
    Button.setProcessing(this.refs.captureButton, false);

    if (is.iOS() || is.android()) {
      this.setState({
        /*
         * When WebRTC fails because of one of the following problems,
         * we are showing upload photo button
         *
         * - OverconstrainedError
         * - NotAllowedError
         * - etc...
         */
        enableFullScreenFallbackMode: true,
      });
    } else {
      return this.props.onError(...args);
    }
  };

  handleContainerSizeSet = (videoContainerSize) => {
    // `videoContainerHeight` is picked from Webcam component.
    // Its required for non-fullscreen camera, that should be
    // separate component in the future (TODO)
    this.setState(videoContainerSize);
  };

  render({ fullScreen, header }, { hidden }) {
    const className = {
      '--full-screen': fullScreen,
      '--hidden': hidden,
    };
    const extraStyles = fullScreen
      ? undefined
      : { height: this.state.videoContainerHeight };

    return (
      <div
        { ...this.pickProps() }
        ref={ this.createRef('rootNode') }
        className={ this.rootcn(className) }
        style={ extraStyles }
        role="dialog"
        aria-modal="true"
      >
        <ReactTooltip />
        { header && (
          <div className={ this.cn`__header` }>{ header }</div>
        ) }
        { this.renderStep() }
      </div>
    );
  }
}
