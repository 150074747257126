import React from 'react';
import ActionBar from 'components/ActionBar';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import DiscountCodesForm from 'components/DiscountCodesForm';
import Icon from 'sf/components/Icon';
import Payment from 'components/Payment';
import PaymentList from 'components/PaymentList';
import Render from 'components/Render';
import SendProfileList from 'components/Lists/SendProfileList';
import Stamp from 'components/Stamp';
import { ROLES } from 'constants';
import payment from 'models/payment';
import wallet from 'models/wallet';
import user from 'models/user';
import BasePage from 'pages/BasePage';

export default class Wallet extends BasePage {
  className = 'ts-Wallet';
  title = 'Your Wallet';

  state = {
    rechargeCreditsAmount: 1,
  };

  constructor(props) {
    super(props);
    this.setRestricted(true);
  }

  constructorBrowser() {
    wallet.getPaymentsHistoryList(this.props.params.paymentsHistoryPage || 1);
    wallet.getAccountBalance();
    user.getProfileData();
  }

  componentDidMount() {
    this.syncStateWithModel(payment, ['credit_price']);
    this.syncStateWithModel(user, ['active_discount_code', 'number_of_credits']);
  }

  componentWillReceiveProps({ params }) {
    if (params.paymentsHistoryPage !== this.state.paymentsHistoryPage) {
      wallet.getPaymentsHistoryList(params.paymentsHistoryPage);
    }
  }

  handleCreditsChange = (value) => {
    const { rechargeCreditsAmount } = this.state;
    if (value === rechargeCreditsAmount || value > 999) return;
    if (value < 2 || !value) {
      this.setState({
        rechargeCreditsAmount: 1,
      });
      return;
    }
    this.setState({
      rechargeCreditsAmount: value,
    });
  }

  handleSuccessfulCreditsRecharge = () => {
    wallet.getAccountBalance();
    wallet.getPaymentsHistoryList(this.props.params.paymentsHistoryPage);
    this.navigate('/Wallet.html');
  }

  renderCreditsBalance() {
    return (
      <div className={ this.cn`__account-balance-wrap` }>
        <div className={ this.cn`__account-balance` }>
          <h1 className={ this.cn`__account-balance-title` }>
            Your current wallet balance
          </h1>
          <Render when={ user.getCreditsBalance() }>
            <p className={ this.cn`__account-balance-description` }>
              You can send your Trust Stamp profile to&#160;this&#160;many&#160;people
            </p>
          </Render>
          <div className={ this.cn`__account-balance-credit` }>
            <Stamp>{ user.getCreditsBalance() }</Stamp>
          </div>
        </div>
      </div>
    );
  }

  openDiscountCodeDialog = () => {
    this.refs.discountDialog.toggle(true);
  }

  renderDiscountCodes() {
    const renderExistingDiscountCode = () => {
      return (
        <span>
          You are using a discount code { }
          <strong>
            { this.state.active_discount_code.code }
          </strong>

          <Button
            onClick={ this.openDiscountCodeDialog }
            className={ this.cn`__discount-code-button` }
            theme="link"
          >
            Enter a new discount code
          </Button>
        </span>
      );
    };

    const renderNonExistingDiscountCode = () => {
      return (
        <Button
          onClick={ this.openDiscountCodeDialog }
          className={ this.cn`__discount-code-button` }
          theme="link"
        >
          I have a discount code
        </Button>
      );
    };

    return (
      <div className={ this.cn`__discount-codes-wrap` }>
        <div className={ this.cn`__discount-codes` }>
          <h1 className={ this.cn`__discount-codes-title` }>
            Discount code
          </h1>
          <p className={ this.cn`__discount-codes-description` }>
            { this.state.active_discount_code ?
              renderExistingDiscountCode() :
              renderNonExistingDiscountCode()
            }
          </p>
        </div>
        <Dialog
          className={ this.cn`__discount-dialog` }
          ref="discountDialog"
          size="small"
          title="Discount Code"
        >
          <DiscountCodesForm />
        </Dialog>
      </div>
    );
  }

  renderCreditsRecharge() {
    const { rechargeCreditsAmount } = this.state;
    return (
      <div className={ this.cn`__credits-recharge-wrap` }>
        <div className={ this.cn`__credits-recharge` }>
          <h1 className={ this.cn`__credits-recharge-title` }>
            Add more credits
          </h1>
          <p className={ this.cn`__credits-recharge-description` }>
            <Stamp>1</Stamp> = { payment.getPrettyPrice() }
          </p>
          <div className={ this.cn`__credits-change-control` }>
            <div
              className={ this.cn`__credits-change-button` }
              role="button"
              tabIndex={ 0 }
              onClick={ () => this.handleCreditsChange(rechargeCreditsAmount - 1) }
            >
              <Icon set="io" size={ 24 } type="ios-minus" />
            </div>
            <input
              className={ this.cn`__credits-change-input` }
              onChange={ (event) => this.handleCreditsChange(parseInt(event.target.value, 10)) }
              type="text"
              value={ this.state.rechargeCreditsAmount }
            />
            <div
              className={ this.cn`__credits-change-button` }
              role="button"
              tabIndex={ 0 }
              onClick={ () => this.handleCreditsChange(rechargeCreditsAmount + 1) }
            >
              <Icon set="io" size={ 24 } type="ios-plus" />
            </div>
          </div>
          <p className={ this.cn`__credits-recharge-description` }>
            Total price: { }
            <span className={ this.cn`__credits-recharge-description--important` }>
              ${ payment.getPrice(rechargeCreditsAmount) }
            </span>
          </p>
          <Payment
            className={ this.cn`__payment-methods` }
            amount={ rechargeCreditsAmount }
            onSuccess={ this.handleSuccessfulCreditsRecharge }
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <div className={ this.cn`__action-bar-wrapper` }>
          <div className={ this.cn`__action-bar` }>
            <ActionBar title={ this.title } />
          </div>
        </div>

        <div className={ this.cn`__credits` }>
          <div className="ts-container ts-container--narrow">
            { this.renderCreditsBalance() }
            { this.renderCreditsRecharge() }
            { this.renderDiscountCodes() }
          </div>
        </div>

        <div className="ts-container">
          <div className={ this.cn`__send-profile-list` }>
            <Render when={ user.hasRole(ROLES.SCORE_USER) }>
              <SendProfileList
                title="People you shared your&#160;Trust&#160;Stamp&#160;with"
              />
            </Render>
          </div>
          <div className={ this.cn`__payments-list` }>
            <PaymentList
              title="Your payments"
            />
          </div>
        </div>
      </div>
    );
  }
}
