import { Component } from 'react';

/**
 * Use this component in webpack config, in case Cropper is never used.
 * By removing react-cropper we save over 100KB of JS code, that user won't
 * ever execute.
 * This should be removed when we resolve code splitting in the future.
 *
 * webpack config:
    resolve: {
      alias: {
        'sf/components/CropImage': 'sf/components/CropImage/CropImageDummy',
      }
    }
 */

export default class CropImageDummy extends Component {
  /* public */ getCroppedImage() { return Promise.resolve(null); }
  render() { return null; }
}
