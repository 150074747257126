/* global ENABLE_CORDOVA_SUPPORT */
import { addEventListener, getWindowHeight, getWindowWidth } from 'sf/helpers/domHelper';
import atom from 'atom-js';
import is from 'next-is';

const config = {
  xxxsm_breakpoint: 320,
  xxsm_breakpoint: 460,
  xsm_breakpoint: 768,
  sm_breakpoint: 880,
  md_breakpoint: 1000,
  lg_breakpoint: 1200,
  xlg_breakpoint: 1480,
  xxlg_breakpoint: 1680,
};

const deviceHelper = {
  /**
   * Check if window width resolves selector from _media-queries.scss
   */
  xxxsmDown: function () { return getWindowWidth() <= config.xxxsm_breakpoint - 1; },
  xxsmDown: function () { return getWindowWidth() <= config.xxsm_breakpoint - 1; },
  xsmDown: function () { return getWindowWidth() <= config.xsm_breakpoint - 1; },
  smDown: function () { return getWindowWidth() <= config.sm_breakpoint - 1; },
  mdDown: function () { return getWindowWidth() <= config.md_breakpoint - 1; },
  lgDown: function () { return getWindowWidth() <= config.lg_breakpoint - 1; },
  xlgDown: function () { return getWindowWidth() <= config.xlg_breakpoint - 1; },

  xxxsmUp: function () { return getWindowWidth() >= config.xxxsm_breakpoint; },
  xxsmUp: function () { return getWindowWidth() >= config.xxsm_breakpoint; },
  xsmUp: function () { return getWindowWidth() >= config.xsm_breakpoint; },
  smUp: function () { return getWindowWidth() >= config.sm_breakpoint; },
  mdUp: function () { return getWindowWidth() >= config.md_breakpoint; },
  lgUp: function () { return getWindowWidth() >= config.lg_breakpoint; },
  xlgUp: function () { return getWindowWidth() >= config.xlg_breakpoint; },

  windowWidth: function () { return getWindowWidth(); }, // CSS pixels
  windowHeight: function () { return getWindowHeight(); }, // CSS pixels

  vertical: function () { return getWindowHeight() >= getWindowWidth(); },
  horizontal: function () { return !deviceHelper.vertical() },
  orientation: function () { return deviceHelper.vertical() ? 'vertical' : 'horizontal'; },
};

function getDeviceState() {
  const result = {};
  Object.keys(deviceHelper).forEach((helperName) => {
    result[helperName] = deviceHelper[helperName]();
  });

  return result;
}

const model = atom(getDeviceState());

/**
 * get real resolution based on a devicePixelRatio.
 * Some devices like Samsung J730GM report pixel ratio, that don't sum up to real screen
 * resolution.
 * devicePixelRatio = 2.625
 * width = 732
 * 732*2.625 = 1921.5 (!?)
 * The real screen resolution should be 1920.
 * This function returns the most probable resolution, based on a given params.
 *
 * @param  {number} cssPixels
 * @param  {number} pixelRatio
 * @return {number}
 */
model.getRealPixelsFromResolution = (cssPixels, pixelRatio = window.devicePixelRatio) => {
  const res = Math.floor(cssPixels * pixelRatio);

  return res % 2 ? res - 1 : res;
};

model.getRealScreenWidth = () => model.getRealPixelsFromResolution(window.screen.availWidth);
model.getRealScreenHeight = () => model.getRealPixelsFromResolution(window.screen.availHeight);

model.isCordova = () => (
  ENABLE_CORDOVA_SUPPORT
    ? /Cordova\/NaeaApp/.test(global.navigator?.userAgent || '')
    : false
);

if (is.browser()) {
  /**
   * in browser environment every resize event will trigger
   * model update.
   */
  addEventListener(window, 'resize', () => {
    model.set(getDeviceState());
  });

  const setOnline = (status) => {
    model.set({
      'is.online': status,
      'is.offline': !status,
    });
  };

  addEventListener(window, 'online', setOnline.bind(null, true));

  addEventListener(window, 'offline', setOnline.bind(null, false));

  setOnline(navigator.onLine);
} else {
  /**
   * SSR HANDLING
   */
  const sayNO = function () { return false; };
  Object.keys(deviceHelper).forEach((helperName) => {
    deviceHelper[helperName] = sayNO;
  });
}

Object.assign(model, deviceHelper);

/**
 * Update default `device` helper config with project specific.
 *
 * @param  {Object} configObj object to extend `config`
 */
model.configure = (configObj) => {
  Object.keys(configObj).forEach((key) => {
    if (Object.hasOwn(config, key)) {
      config[key] = configObj[key];
    } else {
      throw new Error(`device.configure error - "${key}" key invalid`);
    }
  });
};

export default model;
