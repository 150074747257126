/* global TSResizeObserver */

// All css and sizing should be done in a parent component.
// This component should fill whole prepared space and fit video stream with an aspect
// ratio preserved.

import React from 'react';
import omit from 'lodash/omit';
import throttle from 'lodash/throttle';
import webcam from 'mighty-webcamjs';
import is from 'next-is';
import noop from 'no-op';
import PropTypes from 'prop-types';
import template from 'lodash/template';

import BaseComponent from 'components/BaseComponent';
import Icon from 'sf/components/Icon';
import Spinner from 'sf/components/Spinner';
import ViewFinder from 'sf/components/Webcam/ViewFinder';
import WebcamPropTypes from 'sf/components/Webcam/WebcamPropTypes';
import WebcamFeedback from 'sf/components/WebcamFeedback';
import WebcamRects from 'sf/components/WebcamRects';
import { mediator, waitFor } from 'sf/helpers';
import {
  HELP_MESSAGE_CAMERA_IN_USE,
  HELP_MESSAGE_CAMERA_NOT_WORKING,
  HELP_MESSAGE_LOW_CAMERA_RESOLUTION,
} from 'sf/messages';
import {
  ACTION_CLICK,
  ACTION_TAP,
  HELP_MESSAGE_CAMERA_NOT_WORKING_TITLE,
  HELP_MESSAGE_SELECT_IMAGE_IE10,
  TAKE_PHOTO_BUTTON,
  TAKE_VIDEO_BUTTON,
} from 'sf/l10n';
import { getConfig } from 'sf';
import webcamModel from 'sf/models/webcam';

// sometimes Webcam can be used on two consecutive steps
// In such cases, we force at least 5 seconds between componentWillUnmount and componentDidMount,
// so we can request for camera again.
const COOLDOWN_TIMEOUT = TEST_RUNNER === 'cypress' ? 0 : 6000;
let lastUnmountTime = 0;

// Cache mode state so after switching, mode stays the same.
let cachedMode = 'auto';
const noInterfaceFoundText = 'noInterfaceFoundText';

function showInsufficientCameraResolutionMessage() {
  mediator.publish('showHelp', HELP_MESSAGE_LOW_CAMERA_RESOLUTION());
}

export { webcam };
export { COOLDOWN_TIMEOUT };

let errorHandler;

export default class WebcamComponent extends BaseComponent {
  className = 'ts-Webcam';

  state = {
    mode: cachedMode,
    mounted: false, // rerender on side effects
    videoContainerTopOffset: 0,
    videoContainerLeftOffset: 0,
    videoContainerWidth: 0,
    videoContainerHeight: 0,
  };

  // props corresponds with WebcamJS config:
  // https://github.com/truststamp/webcamjs#configuration
  static propTypes = {
    captureTriggers: PropTypes.array,
    communicationChannel: PropTypes.string,
    dest_height: PropTypes.number,
    dest_width: PropTypes.number,
    errorHandler: PropTypes.func, // more high-level error handling
    flip_horiz: PropTypes.bool,
    fullScreen: PropTypes.bool,
    image_format: PropTypes.oneOf(['jpeg', 'png']),
    jpeg_quality: PropTypes.number,
    minPixels: PropTypes.number, // minimal photo quality
    onContainerSizeSet: PropTypes.func,
    // eslint-disable-next-line max-len
    onError: PropTypes.func, // low-level error handling. When `false` is returned errorHandler won't be called
    onFallbackUpload: PropTypes.func,
    onWebcamInit: PropTypes.func,
    overlay: WebcamPropTypes.overlay,
    withFeedbacks: PropTypes.array,
    withRects: PropTypes.bool,
    viewFinderText: PropTypes.node,
    viewFinderTheme: PropTypes.string,
    enableFullScreenFallbackMode: PropTypes.bool,
    customViewFinderText: PropTypes.string,
    viewFinderTimeout: PropTypes.number,
  };

  static defaultProps = {
    capture_mode: webcam.constants.CAPTURE_MODE_PHOTO,
    captureTriggers: [],
    communicationChannel: 'default',
    image_format: 'jpeg',
    jpeg_quality: 94,
    minPixels: 480 * 480,
    onContainerSizeSet: noop,
    onFallbackUpload: noop,
    onWebcamInit: noop,
    overlay: '',
    withFeedbacks: [],
    withRects: false,
    viewFinderText: '',
    viewFinderTheme: '',
    enableFullScreenFallbackMode: false,
  };

  captureTriggers = [];

  resetWebcam = async () => {
    try {
      await this.resetCaptureTriggers({ force: true });
      return webcam.reset();
    } catch (e) {
      // flash content may trigger some errors...
    }
  };

  componentDidMount() {
    this.syncStateWithModel(webcam.params, ['isFullyLoaded']);
    this.subscribe(webcam.params, ['cameraVideoElement'], () => {
      this.setVideoContainerSize();
    });

    const resizeObserver = new TSResizeObserver(this.setVideoContainerSize);

    resizeObserver.observe(this.rootNode);
    this.__listeners.push(() => {
      resizeObserver.disconnect();
    });

    const lastUnmountTimeAgo = Date.now() - lastUnmountTime;
    let timeout = lastUnmountTimeAgo > COOLDOWN_TIMEOUT
      ? 0
      : COOLDOWN_TIMEOUT - lastUnmountTimeAgo;
    if (this.props.delayedStart) {
      timeout = 0;
    }
    this.setTimeout(() => {
      this.initWebcam();

      const cam = this.props.overlay === 'face'
        ? webcam.constants.CAM_FRONT
        : webcam.constants.CAM_BACK;

      webcam.switchCamera(cam).then(() => {
        this.attachWebcam();
      });

      this.setState({ mounted: true }); // eslint-disable-line

      this.addEventListener(window, 'beforeunload', this.handleBeforeUnload);
    }, Math.max(0, timeout));
  }

  handleBeforeUnload = () => {
    this.setTimeout(() => {
      // window.onbeforeunload, unloads the webcam js:
      // https://github.com/jhuckaby/webcamjs/issues/207
      // This behaviour can be observed in Chrome and Firefox.
      this.reinitialize();
    }, 2000);
  }

  componentWillUnmount() {
    this.resetWebcam();
    lastUnmountTime = Date.now();
    webcamModel.set({ cameraId: undefined });
    super.componentWillUnmount();
  }

  reinitialize = async () => {
    await this.resetWebcam();
    this.initWebcam();
    this.attachWebcam();
  };

  static setErrorHandler = (newErrorHandler) => {
    // eslint-disable-next-line no-console
    console.error('Webcam.setErrorHandler is obsolete! Please use `errorHandler` prop instead.');
    errorHandler = newErrorHandler;
  };

  errorHandler = (err, msg, extendedMsg) => {
    const handler = this.props.errorHandler || errorHandler;

    if (webcam.params.get('verbose')) {
      // eslint-disable-next-line no-console
      console.log(err, msg, extendedMsg);
    }

    return handler ? handler(err, msg, extendedMsg) : mediator.publish('showFloatingText', {
      text: msg,
      isValid: false,
    });
  };

  webcamErrorHandler = (err) => {
    let msg = `${err}`;
    let extendedMsg;
    if (msg.substr(0, 7) === '[object') {
      // WORKAROUND:
      // Not all errors have valid .toString implemented. Use err.message instead
      // of ugly [object SomeWebcamError]
      msg = err.message;
    }
    if (msg.includes('Please try again and select "Take Photo" option')) {
      // eslint-disable-next-line max-len
      msg = HELP_MESSAGE_SELECT_IMAGE_IE10;
    } else if (msg.includes('SourceUnavailableError')) {
      // eslint-disable-next-line max-len
      extendedMsg = HELP_MESSAGE_CAMERA_IN_USE();
    } else if (msg.includes(noInterfaceFoundText)) {
      if (is.safari() && is.desktop()) {
        // FIX for TS-1045
        return;
      }
      msg = msg.replace(
        noInterfaceFoundText,
        'No supported webcam interface found. Please try on another browser.',
      );
    }

    if (err && err.name === 'NotReadableError') {
      // eslint-disable-next-line max-len
      extendedMsg = HELP_MESSAGE_CAMERA_IN_USE();
    } else if (err && err.name === 'NotAllowedError') {
      extendedMsg = HELP_MESSAGE_CAMERA_NOT_WORKING();
    }

    if (extendedMsg) {
      msg = extendedMsg.content;
    }

    this.errorHandler(err, msg, extendedMsg);
  };

  async initCaptureTriggers(captureTriggers = this.props.captureTriggers) {
    // capture triggers are made to analyse video stream contents,
    // and capture picture when needed.
    // This might be used in following situations:
    // - reading barcode
    // - capture when smile
    // It might, but doesn't have to, be a Promise

    if (webcam.params.get('captureInProgress')) {
      return;
    }

    await this.resetCaptureTriggers({ force: true });

    if (!captureTriggers.length) return Promise.resolve();

    return Promise.all(
      captureTriggers.map((trigger) => {
        return trigger({
          video: webcam.getVideo(),
          trigger: (canvas, details) => {
            if (details && this.props.verbose) {
              console.info('captureTrigger details', details); // eslint-disable-line no-console
            }
            waitFor(webcam.getSnapResult(canvas))
              .then((result) => {
                this.props.onFallbackUpload(result, details || {});
              });
          },
        }); // init trigger with video
      }),
    )
      .then(async (triggerObjects) => {
        await this.resetCaptureTriggers({ force: true });

        if (process.env.NODE_ENV !== 'production') {
          triggerObjects.forEach((triggerObject) => {
            if (!triggerObject || !(triggerObject.remove instanceof Function)) {
              throw new Error('triggerObject need to contain `remove`.');
            }
          });
        }
        triggerObjects.forEach((triggerObject) => {
          triggerObject.run();
        });

        this.captureTriggers = triggerObjects;
      });
  }

  async resetCaptureTriggers(options = {}) {
    const results = await Promise.all(
      this.captureTriggers.map((trigger) => trigger.remove(options)),
    );
    this.captureTriggers.length = 0;

    return options.force ? [] : results.filter(Boolean);
  }

  loadedVideoDimensionsEvent = throttle((dimensions) => {
    // Some browsers (Firefox) can result 0x0 before granting access.
    // Some browsers (Android) can result 2x2 before granting access.
    // Some devices (Pixel) can never return more than 2x2. Another getUserMedia is required there
    // Use `verbose: true` for more debug info.
    const pixels = dimensions.width * dimensions.height;

    if (!Number.isFinite(pixels) || pixels <= 4) {
      return;
    }

    this.setState({
      videoWidth: dimensions.width,
      videoHeight: dimensions.height
    }, this.setVideoContainerSize);

    if (!this.props.fullScreen && pixels < this.props.minPixels) {
      showInsufficientCameraResolutionMessage();
    }

    this.initCaptureTriggers()
      .then(() => {
        webcam.params.set({ isFullyLoaded: true });
      });
  }, 100);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.captureTriggers !== this.props.captureTriggers) {
      this.initCaptureTriggers(nextProps.captureTriggers);
    }
  }

  errorHandlerEvent = (...args) => {
    if (this.props.onError) {
      if (this.props.onError(...args) === false) return;
    } else {
      console.error('Webcam errors', args); // eslint-disable-line
    }
    this.webcamErrorHandler(...args);
  };

  /**
   * This function does all required work before attaching.
   * @return {[type]} [description]
   */
  initWebcam() {
    const { mode } = this.state;
    const flipHoriz = typeof this.props.flip_horiz === 'boolean'
      ? this.props.flip_horiz
      : this.props.overlay === 'face';
    const ASSET_PATH = `${getConfig('assetsURL') || ASSETS_URL}lib/webcamjs/`;
    cachedMode = mode;

    const componentProps = omit(this.props, [
      'captureTriggers',
      'communicationChannel',
      'delayedStart',
      'errorHandler',
      'fullScreen',
      'minPixels',
      'onContainerSizeSet',
      'onError',
      'onFallbackUpload',
      'onWebcamInit',
      'overlay',
      'withFeedbacks',
      'withRects',
      'viewFinderText',
      'viewFinderTheme',
      'enableFullScreenFallbackMode',
      'customViewFinderText',
      'viewFinderTimeout'
    ]);

    const webcamProps = {
      webcam_path: ASSET_PATH,
      no_interface_found_text: noInterfaceFoundText,
      width: 'auto',
      height: 'auto',
      flip_horiz: flipHoriz,
      flip_horiz_back: false,
      switch_camera_node: <Icon set="fa" type="refresh" size={ 24 } />,
      enable_file_fallback: !!(is.iOS() || is.android()),
      force_fresh_photo: true,
      verbose: false,
      use_ImageCapture: false,
      flash_light_node: <Icon set="io" type="flash" size={ 48 } />,
      userMedia: true,
      controls: true,
      ...componentProps,
    };

    this.subscribe(webcam.params, 'error', this.errorHandlerEvent);
    this.subscribe(webcam.params, 'loadedVideoDimensions', this.loadedVideoDimensionsEvent);

    this.subscribe(
      webcam.params,
      `imageSelected:${this.props.communicationChannel}`,
      this.props.onFallbackUpload,
    );

    const cam = this.props.overlay === 'face'
      ? webcam.constants.CAM_FRONT
      : webcam.constants.CAM_BACK;

    webcamProps.camera = cam;

    webcam.set(webcamProps);
  }

  attachWebcam() {
    webcam.params.set({ isFullyLoaded: false });

    if (this.isDestroyed) return;
    webcam.attach(this.camNode);

    this.props.onWebcamInit();
  }

  /* eslint-disable class-methods-use-this */
  async snap(...args) {
    const triggerResults = await this.resetCaptureTriggers();

    if (triggerResults.length > 1) {
      // eslint-disable-next-line no-console
      console.error('More than one trigger returned an result.');
    }

    // if any trigger.remove returns value, we use it as a snap result
    if (triggerResults.length) {
      return Promise.resolve(triggerResults[0]);
    }

    return webcam.snap(...args);
  }
  /* eslint-enable */

  setVideoContainerSize = () => {
    const { rootNode, state } = this;

    if (rootNode && rootNode.clientWidth) {
      const parentWidth = rootNode.clientWidth;
      const parentHeight = rootNode.clientHeight;
      const videoAspectRatio = state.videoWidth / state.videoHeight;

      // try to fit starting with width
      let newWidth = parentWidth;
      let newHeight = parentWidth / videoAspectRatio;

      // check if new height is not greater than parent and recalculate if needed
      if (newHeight > parentHeight) {
        newHeight = parentHeight;
        newWidth = parentHeight * videoAspectRatio;
      }

      const videoContainerSize = {
        videoContainerTopOffset: Math.round((parentHeight - newHeight) / 2) || 0,
        videoContainerLeftOffset: Math.round((parentWidth - newWidth) / 2) || 0,
        videoContainerWidth: newWidth || 0,
        videoContainerHeight: newHeight || 0,
      };

      this.setState(videoContainerSize, () => {
        this.props.onContainerSizeSet(videoContainerSize);
        this.handleVideoResize();
      });
    }
  };

  handleVideoResize() {
    if (this.camNode) {
      const styles = {
        top: this.state.videoContainerTopOffset,
        left: this.state.videoContainerLeftOffset,
        width: this.state.videoContainerWidth,
        height: this.state.videoContainerHeight,
      };
      const video = webcam.getVideo();
      this.setState({
        styles,
        videoClientHeight: video?.clientHeight || 0,
        videoClientWidth: video?.clientWidth || 0,
      });
    }
  }

  handleSizeChange = (e) => {
    webcamModel.set({
      overlaySize: e,
    });
  };

  renderFallbackUploadNode(extraProps) {
    webcam.set({ capture_mode: this.props.capture_mode });
    const fallbackNode = webcam.getUploadFallbackNode(`${this.props.communicationChannel}`, {
      ...extraProps,
      onChange: (e) => {
        if (!e.target.files || !e.target.files.length) return;
        mediator.publish('photoTaken');
      }
    });

    return fallbackNode;
  }

  render({ communicationChannel, enableFullScreenFallbackMode }, { styles, isFullyLoaded }) {
    if (enableFullScreenFallbackMode) {
      /* eslint-disable max-len */

      const text = this.props.capture_mode === webcam.constants.CAPTURE_MODE_PHOTO
        ? TAKE_PHOTO_BUTTON
        : TAKE_VIDEO_BUTTON;
      const fallbackText = `${HELP_MESSAGE_CAMERA_NOT_WORKING_TITLE}
${template(text)({ actionName: (is.mobile() || is.tablet()) ? ACTION_TAP : ACTION_CLICK })}`;
      /* eslint-enable max-len */

      return (
        <div
          className={ this.rootcn() }
          ref={ this.createRef('rootNode') }
        >
          { this.renderFallbackUploadNode({
            'className': this.cn`__fallback-file-upload-wrapper __full-screen-fallback`,
            'data-title': fallbackText,
          }) }
        </div>
      );
    }

    return (
      <div
        className={ this.rootcn() }
        ref={ this.createRef('rootNode') }
      >
        { !isFullyLoaded && <Spinner className={ this.cn`__loader` } /> }
        <div
          className={ this.cn`__cam` }
          data-channel={ communicationChannel }
          ref={ this.createRef('camNode') }
          style={ styles }
        />
        {
          [
            styles && isFullyLoaded && <ViewFinder
              videoWidth={ this.state.videoWidth }
              videoHeight={ this.state.videoHeight }
              videoClientHeight={ this.state.videoClientHeight }
              videoClientWidth={ this.state.videoClientWidth }
              key="viewfinder"
              overlay={ this.props.overlay }
              style={ styles }
              onSizeChange={ this.handleSizeChange }
              text={ this.props.viewFinderText || this.props.customViewFinderText }
              theme={ this.props.viewFinderTheme }
              messageTimeout={ this.props.viewFinderTimeout }
            />,
            this.props.withFeedbacks.length && <WebcamFeedback
              key="feedbacks"
              feedbacks={ this.props.withFeedbacks }
              style={ styles }
            />,
            this.props.withRects && <WebcamRects
              key="rects"
              style={ styles }
            />,
          ].filter(Boolean)
        }
      </div>
    );
  }
}

// This hacky class is done just to make work following props combination:
// delayedStart (AdvancedPhotoCapture) + allowUploadAlternative (PhotoCaptureButtonBrowser).
//
// To achieve it with with WebcamComponent only, we would need to create lots of redundant ifs
//
// What it does:
// FallbackUploadHandler calls initWebcam, so webcam events can be used by upper components
// and we can use fallback image upload event.
//
// How to use it:
// The only place, where it needs to be used is AdvancedPhotoCapture. Probably no need
// to use it anywhere else.
WebcamComponent.FallbackUploadHandler = class FallbackUploadHandler extends BaseComponent {
  state = {};

  UNSAFE_componentWillMount() {
    WebcamComponent.prototype.initWebcam.apply(this);
  }

  componentWillUnmount() {
    WebcamComponent.prototype.componentWillUnmount.apply(this);
    super.componentWillUnmount();
  }

  initCaptureTriggers() {}
  reinitialize() {}
  resetCaptureTriggers() {}
  resetWebcam() {}
  webcamErrorHandler() {}

  loadedVideoDimensionsEvent() {}
  errorHandlerEvent() {}

  snap() { return Promise.reject(); }

  render() { return <div />; }
};

Object.keys(WebcamComponent.prototype).forEach((keyName) => {
  if (
    WebcamComponent.prototype[keyName] instanceof Function
    && !WebcamComponent.FallbackUploadHandler[keyName]
  ) {
    // make FallbackUploadHandler API the same as WebcamComponent
    // with no-op functions.
    WebcamComponent.FallbackUploadHandler[keyName] = noop;
  }
});

