import React from 'react';
import is from 'next-is';

import PageTitle from 'components/PageTitle';
import { pascalCase } from 'sf/helpers';
import { scrollTo } from 'sf/helpers/domHelper';
import BasePage from 'pages/BasePage';
import FAQ_DATA from './FAQData';

export default class MarketplaceHeroesFAQ extends BasePage {
  className = 'ts-MarketplaceHeroesFAQ';
  title = 'Frequently Asked Questions';

  componentDidMount() {
    const { hash } = this.props.location;
    scrollTo(this[`${pascalCase(hash.substr(1))}Node`], 0);
  }

  handleLinkOnClick = (event, title) => {
    event.preventDefault();
    location.hash = `#${pascalCase(title)}`;
    scrollTo(this[`${pascalCase(title)}Node`], 0);
  }

  renderTableOfContentsItem = ({ title, content = [] }, index) => {
    return (
      <li
        className={ this.cn`__table-of-contents-item` }
        key={ index }
      >
        <a
          href={ `#${pascalCase(title)}` }
          onClick={ (event) => this.handleLinkOnClick(event, title) }
        >
          { title }
        </a>
        { is.isArray(content) && content.length ?
          (<ol> { content.map(this.renderTableOfContentsItem) } </ol>) : null
        }
      </li>
    );
  }

  renderTableOfContents = () => {
    return (
      <ol className={ this.cn`__table-of-contents` }>
        { FAQ_DATA.map(this.renderTableOfContentsItem) }
      </ol>
    );
  }

  renderSectionItem = (question) => {
    return [
      <dt
        className={ this.cn`__question-title` }
        ref={ this.createRef(`${pascalCase(question.title)}Node`) }
      >
        { question.title }
      </dt>,
      <dd className={ this.cn`__question-content` }>
        { question.content }
      </dd>,
    ];
  }

  renderSection = (section, index) => {
    return (
      <div
        className={ this.cn`__section` }
        key={ index }
        ref={ this.createRef(`${pascalCase(section.title)}Node`) }
      >
        <h1 className={ this.cn`__section-title` }>
          { section.title }
        </h1>
        <dl className={ this.cn`__questions` }>
          { section.content.map(this.renderSectionItem) }
        </dl>
      </div>
    );
  }

  renderContents = () => {
    return FAQ_DATA.map(this.renderSection);
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <div className={ this.cn`__wrapper` }>
          <div className={ this.cn`__title` }>
            <PageTitle title={ this.title } />
          </div>
          { this.renderTableOfContents() }
          { this.renderContents() }
        </div>
      </div>
    );
  }
}
