import React from 'react';
import _get from 'lodash/get';
import _first from 'lodash/first';
import _flatmap from 'lodash/flatMap';
import _compact from 'lodash/compact';
import { compose } from 'recompose';
import Button from 'components/Button';
import Card from 'components/Card';
import HelpButton from 'components/HelpButton';
import PageTitle from 'components/PageTitle';
import Select from 'components/Select';
import {
  BRANDINGS,
  PIN_CODE_LENGTH,
  ROUTES,
} from 'constants';
import {
  snakeCase,
} from 'sf/helpers';
import { createCustomEvent } from 'sf/helpers/domHelper';
import analytics from 'models/analytics';
import registration from 'models/registration';
import states from 'models/state';
import userModel from 'models/user';
import BasePage from 'pages/BasePage';
import MaskedInput from 'sf/components/MaskedInput';
import ValidationDateInput from 'sf/components/ValidationDateInput';
import ValidationInput from 'sf/components/ValidationInput';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const CONTEXT_HELP_NOTIFICATIONS = {
  OVERALL: [
    'DATA_PURPOSE',
    'DATA_USAGE',
    'DATA_SAFETY',
  ],
  NAME: [
    'FULL_NAME_FORMAT',
  ],
  EMAIL: [
    'WHICH_EMAIL',
    'NO_EMAIL',
  ],
  ZIPCODE: [
    'ZIP_CODE_FORMAT',
  ],
  ADDRESS: [
    'NO_ADDRESS',
    'ADDRESS_LINES',
  ],
  PIN: [
    'PIN_FORMAT',
    'PIN_USAGE',
  ],
};

const INPUTS = [
  {
    label: 'Full name',
    name: 'legal_name',
    Component: ValidationInput,
    helpKeys: CONTEXT_HELP_NOTIFICATIONS.NAME,
  },
  {
    label: 'Date of birth',
    name: 'date_birth',
    Component: ValidationDateInput,
    reversedFormat: true,
  },
  {
    label: 'Address',
    name: 'address',
    Component: ValidationInput,
  },
  {
    label: 'ZIP code',
    name: 'zip_code',
    Component: ValidationInput,
    helpKeys: CONTEXT_HELP_NOTIFICATIONS.ZIPCODE,
  },
  {
    label: 'City',
    name: 'city',
    Component: ValidationInput,
  },
  {
    label: 'State',
    name: 'state',
    Component: Select,
    options: states.get('states'),
  },
];

const MaskedValidationInputWithStateLink = compose(
  withValidation,
  withStateLink,
)(MaskedInput);

export default class SignUpBasicInfo extends BasePage {
  className = 'ts-SignUpBasicInfo';
  title = 'Fill out the form with your basic data';
  subtitle = 'We will cross-reference it with your data to calculate a more accurate Trust Score';

  state = {
    legal_name: 'John',
    zip_code: '',
    stateEnabled: true,
    cityEnabled: true,
    state: states.get('states') && _first(states.get('states')),
    submitted: false,
  };

  componentDidMount() {
    const stateToSync = [
      'legal_name',
      'address',
      'email',
      'zip_code',
      'state',
      'city',
      'date_birth',
      'pinCode',
    ];

    if (registration.get('isRealtor')) {
      stateToSync.push('realtor_number');
    }

    this.syncStateWithModel(userModel, stateToSync);
  }

  componentDidMount() {
    this.syncStateWithModel(registration, [
      ..._flatmap(
        INPUTS.map(({ name }) => snakeCase(name)),
        (name) => [name, `${name}_alts`]
      ),
    ]);
    registration.getOCRData();
    this.subscribe('basicInfoContinue', () => {
      this.handleSubmit(createCustomEvent(''));
    });
  }

  getAltValues = (prop) => !this.state.submitted
    ? _compact(_get(this.state, `${prop}_alts`, []))
    : [];

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState(() => ({ submitted: true }));

    this.formValidation(
      userModel.set(this.state)
    ).then(() => {
      const basicInfoDone = () => {
        analytics.updateUserInfo();
        analytics.trackEvent('WEBAPP_REGISTRATION_BASIC_INFO_STEP_DONE');
        this.navigate(ROUTES.APPLY_FORM.COMPLETE);
      };
      const basicInfoFail = (err) => {
        // validation example:
        // { legal_name: "Use only latin letters" }
        const validation = _get(err, 'response.body.data.validation');
        if (Object.keys(validation || {}).length) {
          this.formValidation(Promise.reject(validation));
        }
      };
      const numberPromise = registration.get('isRealtor') ?
        registration.sendRealtorNumber :
        () => Promise.resolve();
      registration.signUpBasicInfoStepDone()
        .then(numberPromise)
        .then(basicInfoDone, basicInfoFail);
    });
  }

  renderInputs = () => INPUTS
    .map(({
      Component,
      helpKeys,
      label,
      name,
      options,
      reversedFormat,
    }) => {
      const altValues = this.getAltValues(name);
      const mismatchInfo = !!altValues && !!altValues.length;
      return (
        <Component
          altValues={ altValues }
          altValueTemplate={ {
            prefix: 'We also detected ',
            suffix: '.',
            connector: ', ',
            lastConnector: ' and ',
          } }
          className={ this.cn`__input` }
          clearable={ true }
          displayName={ label || name }
          helpKeys={ helpKeys }
          key={ name }
          mismatchInfo={ mismatchInfo }
          options={ options }
          placeholder=""
          ref={ name }
          reversedFormat={ reversedFormat }
          stateLink={ [this, name] }
        />
      );
    });

  render() {
    const buttonClassName = {
      'ts-SignUpPage__button': true,
      'ts-SignUpPage__main-action': global.REALTOR,
    };
    return (
      <div className={ this.rootcn`ts-container ts-container--narrow` } >
        <Card
          className={ this.cn`__card` }
          fullWidth={ true }
          size="small"
        >
          <div className={ this.cn`__page-title` } >
            <PageTitle
              className={ this.cn`__title` }
              title={ this.title }
              subtitle={ this.subtitle }
            />
            <HelpButton
              className={ this.cn`__title-help-button` }
              notifications={ CONTEXT_HELP_NOTIFICATIONS.OVERALL }
            />
          </div>

          <form onSubmit={ this.handleSubmit } className="ts-SignUpPage__form" >
            { this.renderInputs() }
            <div className="row">
              <div className="col-md-12">
                <ValidationInput
                  className={ this.cn`__input-with-help` }
                  ref="email"
                  type="email"
                  displayName="My e-mail address is"
                  placeholder="Enter e-mail address"
                  stateLink={ [this, 'email'] }
                />
                <HelpButton
                  className={ this.cn`__input-help-button` }
                  notifications={ CONTEXT_HELP_NOTIFICATIONS.EMAIL }
                />
              </div>
            </div>
            { BRAND_NAME === BRANDINGS.REALTOR &&
              <div className={ this.cn`__realtor` } >
                <ValidationInput
                  ref="realtor_number"
                  type="text"
                  displayName="My Real Estate License Number (NRDS):"
                  placeholder="Real Estate License Number"
                  stateLink={ [this, 'realtor_number'] }
                  mask="[-{0,1}]999999999"
                  inputMode="numeric"
                  greedy={ false }
                />
              </div> }
            <div className={ this.cn`__pin row` } >
              <p>
                I want to use this 4-digit login PIN for TrustStamp and
                it’s a number I will remember:
              </p>
              <span className={ this.cn`__legend` } >
                You will be using this number every time you log in.
              </span>
              <div className={ this.cn`__pin-input` } >
                <MaskedValidationInputWithStateLink
                  className={ this.cn`__pin-input-with-help` }
                  ref="pinCode"
                  placeholder="PIN code"
                  stateLink={ [this, 'pinCode'] }
                  mask={ '9'.repeat(PIN_CODE_LENGTH) }
                  inputMode="numeric"
                />
                <HelpButton
                  className={ this.cn`__no-label-input-help-button` }
                  notifications={ CONTEXT_HELP_NOTIFICATIONS.PIN }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ValidationInput
                  type="checkbox"
                  stateLink={ [this, 'privacyAccepted'] }
                  className={ this.cn`__accept-privacy` }
                />
                I Accept { }
                <a
                  className={ this.cn`__terms-link` }
                  href="/TermsAndConditions.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Terms and Privacy Policy
                </a>
              </div>
              <div className="col-md-12">
                <Button
                  type="submit"
                  className={ this.cn(buttonClassName) }
                  disabled={ !this.state.privacyAccepted }
                  visuallyDisabled={ !this.state.privacyAccepted }
                >
                  Next Step...
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}
