import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/Button';
import { asset } from 'helpers';
import payment from 'models/payment';

class PaymentPayPalButton extends BaseComponent {
  className = 'ts-PaymentPayPalButton';

  state = {};

  static PropTypes = {
    amount: PropTypes.number,
    onError: PropTypes.func,
    onReady: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    amount: 1,
    onError: noop,
    onSuccess: noop,
    disabled: false,
  };

  componentDidMount() {
    this.syncStateWithModel(payment, ['credit_price']);
    payment.set('amount', this.props.amount);
  }

  componentWillReceiveProps(nextProps) {
    payment.set('amount', nextProps.amount);
  }

  handlePayPalButtonClick = () => {
    if (!this.payPalInstance) return;
    this.payPalInstance.tokenize({
      flow: 'checkout',
      amount: payment.getPrice(this.props.amount),
      currency: 'USD',
      locale: 'en_us',
    }, (tokenizeErr, data) => {
      if (tokenizeErr) {
        console.error('Paypal tokenize error', tokenizeErr, data); // eslint-disable-line
        this.props.onError(tokenizeErr);
        return;
      }

      payment.set({
        nonce: data.nonce,
        type: data.type,
        details: data.details,
      }).then(() => {
        payment.pay().then(this.props.onSuccess, this.props.onError);
      });
    });
  }

  setup = () => {
    payment.getClientToken().then((clientToken) => {
      global.braintree.client.create({
        authorization: clientToken,
      }, (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Paypal client error', payPalErr, clientInstance); // eslint-disable-line
        }

        global.braintree.paypal.create({
          client: clientInstance,
        }, (payPalErr, payPalInstance) => {
          if (payPalErr) {
            console.error('Paypal instance error', payPalErr, payPalInstance); // eslint-disable-line
          }

          Button.setProcessing(this.refs.paypalButton, false);
          this.payPalInstance = payPalInstance;
        });
      });
    });
  }

  componentDidMount() {
    Button.setProcessing(this.refs.paypalButton);
    payment.getBraintreeScripts(this.setup);
  }

  render() {
    return (
      <Button
        onClick={ this.handlePayPalButtonClick }
        ref="paypalButton"
        theme="blue"
        disabled={ this.props.disabled }
      >
        <img src={ asset`img/paypal.svg` } alt="PayPal" />
      </Button>
    );
  }
}

export default PaymentPayPalButton;
