import React from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import BasicProfileInfoEmail from 'components/BasicProfileInfo/BasicProfileInfoEmail';
import BasicProfileInfoPhone from 'components/BasicProfileInfo/BasicProfileInfoPhone';
import BasicProfileInfoRow from 'components/BasicProfileInfo/BasicProfileInfoRow';
import PillButton from 'components/PillButton';
import Render from 'components/Render';
import { KEYCODES } from 'constants';
import { getDate } from 'sf/helpers';
import { addEventListener, removeEventListener } from 'sf/helpers/domHelper';
import { PUBLIC_ACCESS_MODES } from 'models/publicUser';

export default class BasicProfileInfo extends BaseComponent {
  className = 'ts-BasicProfileInfo';

  static contextTypes = {
    userModel: PropTypes.object,
    viewMode: PropTypes.string,
  };

  static propTypes = {
    onChange: PropTypes.func,
    sharingSettings: PropTypes.object,
  };

  static defaultProps = {
    onChange: noop,
  };

  state = {
    inEditMode: false,
    sharingSettings: {},
    email: null,
    phone: null,
    email_change: null,
    phone_change: null,
  };

  componentWillUpdate(nextProps) {
    /* eslint-disable react/no-will-update-set-state */
    if (Object.keys(this.state.sharingSettings).length === 0) {
      this.setState({
        sharingSettings: nextProps.sharingSettings,
      });
    }
    /* eslint-enable react/no-will-update-set-state */
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    removeEventListener(...this.keypressHandlerArgs);
  }

  componentDidMount() {
    this.syncStateWithModel(this.context.userModel, [
      'accessMode',
      'email_change',
      'phone_change',
      'email_confirmed',
    ]);
    this.keypressHandlerArgs = [window, 'keyup', (event) => {
      if (this.state.inEditMode) {
        switch (event.keyCode) {
          case KEYCODES.ENTER:
            this.handleSaveForm();
            break;
          case KEYCODES.ESCAPE:
            this.handleExitForm();
            break;
          default:
            break;
        }
      }
    }];
    addEventListener(...this.keypressHandlerArgs);
  }

  getInputValue = (name = null) => {
    const model = this.context.userModel;
    const { state } = this;
    if (state[name] || state[name] === '') {
      return state[name];
    }
    return model.get(`${name}_change`) && model.get(`${name}_change`)[name] || model.get(name);
  }

  handleToggleRowVisibility = (key, visualDependencies = {}) => {
    const targetVisibility = !this.state.sharingSettings[key];
    const targetDependencies = visualDependencies[targetVisibility];

    this.setState((state) => ({
      sharingSettings: {
        ...state.sharingSettings,
        [key]: targetVisibility,
        ...targetDependencies,
      },
    }));
  }

  handleManageButtonClick = () => {
    this.setState({
      inEditMode: true,
    });
  }

  handleSaveButtonClick = () => this.handleSaveForm();

  handleSaveForm = () => {
    let dataChanged = false;
    const { userModel } = this.context;
    const { email, phone } = this.context.userModel.get();

    if (!isEqual(this.props.sharingSettings, this.state.sharingSettings)) {
      userModel.saveSharingSettings(this.state.sharingSettings);
      dataChanged = true;
    }

    this.formValidation(
      this.context.userModel.set({
        email: this.state.email || email,
        phone: this.state.phone || phone,
      })
    ).then(() => {
      if (this.state.phone) {
        userModel.postPhoneNumberChange().then(userModel.getProfileData);
        dataChanged = true;
      }

      if (this.state.email) {
        userModel.postEmailChange().then(userModel.getProfileData);
        dataChanged = true;
      }

      this.setState({
        inEditMode: false,
      }, () => {
        if (dataChanged) this.props.onChange();
      });
    });
  }

  handleExitForm = () => {
    this.setState({
      email: null,
      phone: null,
      inEditMode: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          sharingSettings: this.props.sharingSettings,
        });
      }, 150);
    });
  }

  handleInputValueChange = (event, stateName) => {
    this.setState({
      [`${stateName}`]: event.target.value,
    });
  }

  handleDialogOpen = (name) => {
    this.refs[`${name}Dialog`].open();
  }

  getRowsData(userModel, settings) {
    const address = [
      userModel.get('address'),
      userModel.get('city'),
      [
        userModel.get('state'),
        userModel.get('zip_code'),
      ].filter((part) => part).join(' '),
    ].filter((part) => part).join(', ');
    const city = [
      userModel.get('city'),
      userModel.get('state'),
    ].filter((part) => part).join(', ');
    const { inEditMode } = this.state;

    return [{
      isHidden: settings.is_date_birth_hidden_during_sharing,
      hiddenKey: 'is_date_birth_hidden_during_sharing',
      label: 'Age',
      name: 'age',
      value: userModel.getAge(),
    }, {
      // public user don't have `is_city_hidden_during_sharing` field, TODO: backend refactor
      isHidden: settings.is_city_hidden_during_sharing || !userModel.get('city'),
      hiddenKey: 'is_city_hidden_during_sharing',
      label: 'City',
      name: 'city',
      value: city,
      visualDependencies: {
        true: {
          is_address_hidden_during_sharing: true,
        },
      },
    }, {
      // public user don't have `is_address_hidden_during_sharing` field, TODO: backend refactor
      isHidden: settings.is_address_hidden_during_sharing ||
        (!userModel.get('address')),
      hiddenKey: 'is_address_hidden_during_sharing',
      label: 'Address',
      name: 'address',
      value: address,
      visualDependencies: {
        false: {
          is_city_hidden_during_sharing: false,
        },
      },
    }, {
      isConfirmed: this.state.email_confirmed && !this.state.email_change,
      isHidden: settings.is_email_hidden_during_sharing,
      hiddenKey: 'is_email_hidden_during_sharing',
      label: 'E-mail',
      name: 'email',
      ref: 'email',
      value: {
        disabled: !inEditMode,
        icon: 'edit',
        iconSet: 'io',
        isHidden: !inEditMode,
        onChange: (event) => this.handleInputValueChange(event, 'email'),
        type: 'text',
        value: this.getInputValue('email'),
      },
    }, {
      isHidden: settings.is_date_birth_hidden_during_sharing,
      hiddenKey: 'is_date_birth_hidden_during_sharing',
      label: 'Birthdate',
      name: 'birthdate',
      value: getDate(userModel.get('date_birth')),
    }, {
      isConfirmed: !this.state.phone_change,
      isHidden: settings.is_phone_hidden_during_sharing,
      hiddenKey: 'is_phone_hidden_during_sharing',
      label: 'Phone',
      name: 'phone',
      ref: 'phone',
      value: {
        disabled: !inEditMode,
        icon: 'edit',
        iconSet: 'io',
        isHidden: !inEditMode,
        onChange: (event) => this.handleInputValueChange(event, 'phone'),
        type: 'text',
        value: this.getInputValue('phone'),
      },
    }, {
      isHidden: null,
      label: 'Trust Stamp since',
      name: 'since',
      value: getDate(userModel.get('created')),
    }];
  }

  renderEditButton() {
    if (this.context.viewMode === 'PUBLIC') return;
    const { inEditMode } = this.state;
    return (
      <div className={ this.cn`__edit` }>
        <Render when={ inEditMode }>
          <div className={ this.cn`__edit-item` }>
            <PillButton
              key="button-cancel"
              onClick={ this.handleExitForm }
              shell={ true }
            >
              Cancel
            </PillButton>
          </div>
        </Render>
        <div className={ this.cn`__edit-item` }>
          <PillButton
            onClick={
              inEditMode ? this.handleSaveButtonClick : this.handleManageButtonClick
            }
            shell={ true }
            type={ inEditMode ? 'success' : 'default' }
          >
            { inEditMode ? 'Save changes' : 'Manage information' }
          </PillButton>
        </div>
      </div>
    );
  }

  renderRow = (item) => {
    const { viewMode } = this.context;
    return (
      <BasicProfileInfoRow
        key={ item.name }
        onVisibilityChange={ this.handleToggleRowVisibility }
        onConfirmationButtonClick={ this.handleDialogOpen }
        isPublic={ viewMode === 'PUBLIC' }
        isLocked={ viewMode === 'PUBLIC' && this.state.accessMode !== PUBLIC_ACCESS_MODES.FULL }
        { ...item }
      />
    );
  }

  render() {
    const { userModel } = this.context;
    const settings = this.state.sharingSettings || {};
    const rowsData = this.getRowsData(userModel, settings);
    const className = {
      '--is-edited': this.state.inEditMode,
    };
    return (
      <div className={ this.rootcn(className) }>
        <div className={ this.cn`__header` }>
          Basic information
          { this.renderEditButton() }
        </div>
        { rowsData.map(this.renderRow) }
        <BasicProfileInfoEmail
          confirmationEmail={ this.getInputValue('email') }
          ref="emailDialog"
        />
        <BasicProfileInfoPhone
          confirmationPhone={ this.getInputValue('phone') }
          onPhoneVerified={ () => {
            userModel.getProfileData();
            userModel.getSharingSettings();
          } }
          ref="phoneDialog"
        />
      </div>
    );
  }
}
