import atom from 'atom-js';
// import _get from 'lodash/get';

const model = atom.setup({
  methods: {
    clearMessages(resolve) {
      model.set('messages', []).then(resolve);
    },
    addMessage(resolve, reject, {
      msg,
      channel = 'default',
      type,
      data = {},
    } = {}) {
      model.set('messages', [
        ...model.get('messages'),
        {
          channel,
          msg,
          type,
          data,
        },
      ]).then(() => {
        resolve();
      });
      return model;
    },
    getMessages(channel = 'default') {
      return model.messages.filter((message) => message.channel === channel);
    },
  },
})({
  messages: [],
});

export default model;
