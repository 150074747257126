
import { get as _get } from 'lodash';
import { getQuery } from 'sf/helpers';
import { post } from 'sf/helpers/request';
import analytics from 'models/analytics';
import user from 'models/user';
import BasePage from 'pages/BasePage';

export default class EmailConfirmation extends BasePage {
  className = 'ts-EmailConfirmation';
  title = '';

  constructorBrowser() {
    const { code, is_realtor, next } = getQuery();
    if (!code) {
      // No confirmation code = user might have entered the page by mistake.
      return this.navigate(next ? next : '/Profile.html');
    }
    post('backend/signup/email/verify/')
      .type('form')
      .send({ code })
      .end((err, res) => {
        const redirectionUrl = _get(res, 'body.data.user_type') === 'organization' ?
          '/admin-invite.html' :
          '/Profile.html';
        if (err) {
          this.notify('error', res.body.detail || res.body.data.message);
        } else {
          user.set({
            email_confirmed: true,
            is_realtor,
          });
          analytics.trackEvent('WEBAPP_REGISTRATION_EMAIL_CONFIRMED');
        }
        this.navigate(next ? next : redirectionUrl);
      });
  }

  render() {
    return null;
  }
}
