import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Dialog from 'components/Dialog';
import HiddenLabel from 'components/HiddenLabel';
import PillButton from 'components/PillButton';
import Render from 'components/Render';

export default class CustomerDocument extends BaseComponent {
  className = 'ts-CustomerDocument';

  static contextTypes = {
    viewMode: PropTypes.oneOf(['PRIVATE', 'PUBLIC']),
  };

  static propTypes = {
    back: PropTypes.string,
    front: PropTypes.string,
    twoSided: PropTypes.bool,
    children: PropTypes.node,
    isSelfieMismatched: PropTypes.bool,
    mode: PropTypes.oneOf(['simple', 'full']),
    presentation: PropTypes.bool,
    onFlip: PropTypes.func,
  };

  static defaultProps = {
    isSelfieMismatched: false,
    onRetakeCustomerDocumentButtonClick: noop,
    presentation: false,
    twoSided: false,
    mode: 'simple',
  };

  state = {
    visibleSide: 'front',
  }

  handleFlipButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    switch (this.state.visibleSide) {
      case 'front':
        this.setState({
          visibleSide: 'back',
        });
        this.props.onFlip('back');
        break;
      case 'back':
        this.setState({
          visibleSide: 'front',
        });
        this.props.onFlip('front');
        break;
      default:
        break;
    }
  }

  handleZoomButtonClick = (event) => {
    event.preventDefault();
    this.refs.zoom.toggle(true);
  }

  componentDidMount() {
    if (this.props.presentation) {
      setTimeout(this.handleFlipButtonClick, 1250);
    }
  }

  renderFullView() {
    if (!this.props.front && !this.props.back) { return null; }
    const classNames = {
      '--is-selfie-mismatched': this.props.isSelfieMismatched,
    };
    const frontClassNames = {
      '__front': true,
      '__front--visible': this.state.visibleSide === 'front',
    };
    const backClassNames = {
      '__back': true,
      '__back--visible': this.state.visibleSide === 'back',
    };
    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.cn`__wrapper` }>
          <Render when={ this.props.front }>
            <div
              className={ this.cn(frontClassNames) }
              onClick={ this.handleZoomButtonClick }
              role="button"
              tabIndex={ 0 }
            >
              <Render when={ this.props.back && !this.props.presentation }>
                <div className={ this.cn`__flip-button` }>
                  <PillButton
                    icon="ios-undo"
                    onClick={ this.handleFlipButtonClick }
                    type="success"
                  >
                    Flip
                  </PillButton>
                </div>
              </Render>
              <img src={ this.props.front } alt="" />
              { this.props.children }
            </div>
          </Render>
          <Render when={ this.props.back }>
            <div
              className={ this.cn(backClassNames) }
              onClick={ this.handleZoomButtonClick }
              role="button"
              tabIndex={ 0 }
            >
              <Render when={ this.props.front && !this.props.presentation }>
                <div className={ this.cn`__flip-button` }>
                  <PillButton
                    icon="ios-redo"
                    onClick={ this.handleFlipButtonClick }
                    type="success"
                  >
                    Flip
                  </PillButton>
                </div>
              </Render>
              <img src={ this.props.back } alt="" />
              { this.props.children }
            </div>
          </Render>
        </div>
        <Dialog
          ref="zoom"
          size="medium"
          title={ `Document - ${this.state.visibleSide}` }
        >
          <div className={ this.cn`__dialog-image` }>
            <img src={ this.props[this.state.visibleSide] } alt="" />
          </div>
        </Dialog>
      </div>
    );
  }

  renderSimpleView() {
    const { viewMode } = this.context;

    const classNames = {
      '--is-selfie-mismatched': this.props.isSelfieMismatched,
    };
    const frontClassNames = {
      '__front': true,
      '__front--simple': true,
      '__front--visible': true,
    };
    return (
      <div className={ this.rootcn(classNames) }>
        <div className={ this.cn`__wrapper` }>
          <div className={ this.cn(frontClassNames) }>
            <div className={ this.cn`__placeholder` }>
              <div className={ this.cn`__portrait` } />
            </div>
            { this.props.children }
            <Render when={ viewMode === 'PUBLIC' }>
              <HiddenLabel />
            </Render>
          </div>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.mode) {
      case 'full':
        return this.renderFullView();
      case 'simple':
        return this.renderSimpleView();
      default:
        return null;
    }
  }
}
