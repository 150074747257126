import React from 'react';
import { compose } from 'recompose';
import BaseComponent from 'components/BaseComponent';
import help from 'models/help';
import user from 'models/user';
import Button from 'sf/components/Button';
import MaskedInput from 'sf/components/MaskedInput';
import withPasswordTypeToggler from 'sf/hoc/PasswordTypeToggler';
import withStateLink from 'sf/hoc/StateLink';
import withValidation from 'sf/hoc/Validation';

const MaskedValidationInputWithStateLinkAndToggle = compose(
  withPasswordTypeToggler,
  withStateLink,
  withValidation,
)(MaskedInput);

export default class ResetPINPIN extends BaseComponent {
  className = 'ts-ResetPIN';

  state = {
    new_pin: undefined,
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const isFormValid = await this.formValidation(
      user.set(this.state)
    )
      .catch(() => false);

    if (!isFormValid) return;

    user.resetPINPostPIN()
      .then(({ token }) => {
        user.set({
          isSignedIn: true,
          token: token,
        });
        help.addNotification({
          title: 'Success',
          type: 'success',
          value: 'Your PIN has been reset',
        });
        this.navigate('/Profile.html');
      });
  }

  render() {
    return (
      <form
        className={ this.cn`__form` }
        onSubmit={ this.handleSubmit }
      >
        <div className={ this.cn`__pin` }>
          <span className={ this.cn`__label` }>Your PIN number</span>
          <span className={ this.cn`__input` }>
            <MaskedValidationInputWithStateLinkAndToggle
              autocomplete="off"
              mask="9999"
              placeholder="Your new PIN number"
              ref="new_pin"
              stateLink={ [this, 'new_pin'] }
              type="password"
            />
          </span>
          <div className={ this.cn`__submit` }>
            <Button
              disabled={ !this.state.new_pin }
              type="submit"
            >
              Reset your PIN
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
