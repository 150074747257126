/* eslint-disable no-console */

import React from 'react';
import { findDOMNode } from 'react-dom';
import PageTitle from 'components/PageTitle';
import Button from 'components/Button';
import AdvancedPhotoCapture from 'sf/components/AdvancedPhotoCapture';
import SignUpDrivingLicenseUpload from 'components/SignUpDrivingLicenseUpload';
import { getQuery } from 'sf/helpers';
import BasePage from 'pages/BasePage';

export default class SelfTest extends BasePage {
  className = 'ts-SelfTest';
  title = 'Self Test Page';

  results = {};
  lastError = '';

  constructor(props) {
    super(props);
    let stepId = 0;
    if (global.location) {
      stepId = parseInt(getQuery(location.hash).stepId, 10) || 0;
    }
    this.state = {
      step: stepId,
    };
  }

  getSteps() {
    return [
      {
        label: 'AdvancedPhotoCapture',
        init: () => {

        },
        question: 'Do you see an image from your front camera?',
        markup: () => {
          return (
            <AdvancedPhotoCapture
              ref="photoCapture"
              key="photoFront"
              allowCrop={ false }
              allowRetake={ false }
              onError={ console.error }
              onPhotoTaken={ (photoURI) => {
                document
                  .querySelector('.ts-Webcam')
                  .innerHTML = `<img style="width:100%" src="${photoURI}" />`;
              } }
              onStepChange={ console.info }
              overlay="face"
            />
          );
        },
      },
      {
        label: 'AdvancedPhotoCapture',
        init: () => {

        },
        question: 'Do you see image from back camera (front of driver’s license)?',
        markup: () => {
          return (
            <AdvancedPhotoCapture
              ref="photoCapture"
              key="photoBack"
              allowCrop={ false }
              allowRetake={ false }
              onError={ console.error }
              onPhotoTaken={ (photoURI) => {
                findDOMNode(this.refs.photoCapture).innerHTML = `
<img src="${photoURI}" />
                `;
              } }
              onStepChange={ console.info }
              overlay="id-front"
            />
          );
        },
      },
      {
        label: 'AdvancedPhotoCapture',
        init: () => {

        },
        question: 'Do you see image from back camera (back of driver’s license)?',
        markup: () => {
          return (
            <AdvancedPhotoCapture
              ref="photoCapture"
              key="photoBackBack"
              allowCrop={ false }
              allowRetake={ false }
              onError={ console.error }
              onPhotoTaken={ (photoURI) => {
                findDOMNode(this.refs.photoCapture).innerHTML = `
<img src="${photoURI}" />
                `;
              } }
              onStepChange={ console.info }
              overlay="id-back"
            />
          );
        },
      },
      {
        label: 'SignUpDriversLicense',
        init: () => {

        },
        question: 'Now test SignUpDriversLicense as whole component',
        markup: () => {
          return (
            <SignUpDrivingLicenseUpload />
          );
        },
      },
    ];
  }

  logError = (...args) => {
    console.error('logError: ', ...args);
    this.lastError = args[0];
  }

  goToNextStep(status, description) {
    this.results[this.state.step] = status ? 'SUCCESS' : 'FAIL';
    if (description) this.results[this.state.step] += ` ${description}`;
    this.lastError = '';
    const step = this.state.step + 1;
    this.setState({
      step: step,
    });
    history.pushState(null, null, `#stepId=${step}`);
  }

  renderFinish() {
    return (
      <div className={ this.cn`__step` }>
        All tests are finished. Detailed score:
        <ul>
          { Object.keys(this.results).map((label) => {
            return `${label}: ${this.results[label]}`;
          }) }
        </ul>
      </div>
    );
  }

  renderStep() {
    const steps = this.getSteps();
    if (steps.length === this.state.step) return this.renderFinish();
    const step = this.getSteps()[this.state.step];

    return (
      <div className={ this.cn`__step` }>
        { step.markup() }
        <div className={ this.cn`__controls` }>
          <h2>{ step.question }</h2>
          <Button
            theme="info"
            onClick={ () => {
              this.goToNextStep(null, 'SKIPPED');
            } }
          >
            SKIP
          </Button>
          <Button
            theme="secondary"
            onClick={ () => {
              this.goToNextStep(false, this.lastError || 'Unknown error');
            } }
          >
            NO
          </Button>
          <Button
            theme="success"
            onClick={ () => {
              this.goToNextStep(true);
            } }
          >
            YES
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={ this.rootcn`ts-container` }>
        <PageTitle title={ this.title } />
        { this.renderStep() }
      </div>
    );
  }
}
