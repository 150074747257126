import React from 'react';
import noop from 'no-op';
import PropTypes from 'prop-types';
import Ink from 'react-ink';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class CardButton extends BaseComponent {
  className = 'ts-CardButton';

  static propTypes = {
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.string,
    iconSet: PropTypes.string,
    iconSize: PropTypes.number,
    onClick: PropTypes.func,
    subtitle: PropTypes.string,
    suptitle: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    href: null,
    icon: null,
    iconSet: 'io',
    iconSize: 24,
    onClick: noop,
    subtitle: null,
    suptitle: null,
    target: '_self',
    title: null,
  };

  handleOnClick = (event) => {
    event.preventDefault();
    this.props.onClick();
  }

  render() {
    const classNames = {
      '--has-link': this.props.onClick !== noop || this.props.href,
      '--is-disabled': this.props.disabled,
      [`--${this.props.icon}`]: this.props.icon,
    };
    const TagName = this.props.href ? 'a' : 'div';
    return (
      <TagName
        className={ this.rootcn(classNames) }
        href={ this.props.href }
        target={ this.props.target }
        onClick={ this.props.href ? null : this.handleOnClick }
        role={ this.props.onClick !== noop ? 'button' : null }
      >
        <Render when={ this.props.icon }>
          <span className={ this.cn`__icon` }>
            <Icon
              set={ this.props.iconSet }
              size={ this.props.iconSize }
              type={ this.props.icon }
            />
          </span>
        </Render>
        <h1 className={ this.cn`__title` }>
          <Render when={ this.props.suptitle }>
            <span className={ this.cn`__suptitle` }>
              { this.props.suptitle }
            </span>
          </Render>
          <Render when={ this.props.title }>
            <span className={ this.cn`__title--important` }>
              { this.props.title }
            </span>
          </Render>
          <Render when={ this.props.subtitle }>
            <span className={ this.cn`__subtitle` }>
              { this.props.subtitle }
            </span>
          </Render>
        </h1>
        <Ink duration={ 400 } />
      </TagName>
    );
  }
}
