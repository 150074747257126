import React from 'react';
import omit from 'lodash/omit';
import noop from 'no-op';
import PropTypes from 'prop-types';
import BaseComponent from 'components/BaseComponent';
import Render from 'components/Render';
import Icon from 'sf/components/Icon';

export default class PaperInput extends BaseComponent {
  className = 'ts-PaperInput';

  static propTypes = {
    icon: PropTypes.string,
    iconSet: PropTypes.string,
    isHidden: PropTypes.bool,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.any,
  };

  static defaultProps = {
    icon: null,
    iconSet: 'fa',
    isHidden: false,
    onChange: noop,
    value: '',
  };

  state = {
    inEdit: false,
  }

  handleEvent = (event) => {
    switch (event.type) {
      case 'blur':
        return this.setState({
          inEdit: false,
        });
      case 'change':
        this.props.onChange(event);
        return event;
      case 'focus':
        return this.setState({
          inEdit: true,
        });
      default:
        return console.warn(`No case for event type '${event.type}'`); // eslint-disable-line
    }
  }

  render() {
    const className = {
      '--in-edit': this.state.inEdit,
      '--is-invalid': this.props.isValid === false,
      '--is-hidden': this.props.isHidden && !this.state.inEdit,
    };
    return (
      <div className={ this.rootcn(className) }>
        <Render when={ this.props.icon }>
          <Icon
            className={ this.cn`__icon` }
            type={ this.props.icon }
            set={ this.props.iconSet }
            size={ 14 }
          />
        </Render>
        <div className={ this.cn`__input-wrap` }>
          <input
            { ...omit(this.props, [
              'className', 'icon', 'iconSet', 'isHidden', 'isValid',
              'onChange', 'onBlur', 'onFocus', 'validationMessage', 'value',
            ]) }
            className={ this.cn`__input` }
            onBlur={ this.handleEvent }
            onChange={ this.handleEvent }
            onFocus={ this.handleEvent }
            value={ this.props.value }
          />
        </div>
      </div>
    );
  }
}
