import config from 'config';
import { post } from 'sf/helpers/request';
import * as types from 'pages/MarketplaceHeroesIndex/MarketplaceHeroesIndex.types';

export const getStarted = () => ({
  type: types.GET_STARTED,
});

export const downloadMaterials = () => {
  window.open(config.marketplaceHeroesDocs, '_blank');
  return {
    type: types.DOWNLOAD_MATERIALS,
  };
};

export function registerMarketplaceHero(data) {
  return function (dispatch) {
    post('backend/campaign/signup/')
      .type('form')
      .send(data)
      .end((err, response) => {
        if (err) {
          dispatch({
            type: types.REGISTER_FAILURE,
          });
        } else {
          dispatch({
            data: response.body.data,
            type: types.REGISTER_SUCCESS,
          });
        }
      });
  };
}
