import React from 'react';
import Button from 'components/Button';
import FeatureCard from 'components/FeatureCard';
import NewsletterBox from 'components/NewsletterBox';
import PageTitle from 'components/PageTitle';
import { asset } from 'helpers';
import BasePage from 'pages/BasePage';

let cn;

export default class ZipLogixIndex extends BasePage {
  className = 'ts-ZipLogixIndex';

  constructor(props) {
    super(props);
    cn = this.cn();
  }

  renderIntroSection() {
    return (
      <section className={ cn`__section __section--intro` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              color="white"
              title={
                <div>
                  The Trust&nbsp;Stamp App<br />for
                  <span className={ cn`__logotype` } title="zipLogix" />
                </div>
              }
            />
            <div className={ cn`__description` }>
              { /* eslint-disable max-len */ }
              <p>
                <span className={ cn`__highlighted` }>zipLogix </span>
                <span className={ cn`__dimmed` }>
                  empowers Realtors® with its comprehensive transaction platform and the tools needed to prosper in today’s dynamic real estate market. The Trust Stamp App for
                </span>
                <span className={ cn`__highlighted` }> zipLogix </span>
                <span className={ cn`__dimmed` }>
                  is a fast, convenient and affordable tool to establish the identity and trustworthiness of a stranger before scheduling an appointment.
                </span>
              </p>
              <p className={ cn`__dimmed` }>
                Using sophisticated algorithms and artificial intelligence, Trust Stamp combines driver’s license and photographic identification with data from social media sites and public records to verify the identity of a stranger and create a VIP Profile with a FICO-like trustworthiness score. All in seconds and for less than the cost of a cup of coffee.
              </p>
              { /* eslint-enable max-len */ }
            </div>
            <div className={ cn`__sign-up-button` }>
              <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
                Get Started
              </Button>
            </div>
          </div>
        </div>
        <img
          alt=""
          src="img/ziplogix/intro.png"
          className={ cn`__intro-img` }
        />
      </section>
    );
  }

  renderFeaturesSection() {
    return (
      <section className={ cn`__section __section--features` }>
        <div className={ cn`ts-container` }>
          <PageTitle
            size="big"
            title="​The 2-Minute Trust&nbsp;Stamp Process"
          />
          <div className={ cn`__steps` }>
            <div className="ts-steps">
              <div className="ts-steps__item">
                <FeatureCard
                  title="Invite users to create a VIP Profile"
                  /* eslint-disable max-len */
                  text="In your zipLogix account, select a contact and click the “send Trust Stamp” button to invite the user to create a VIP profile via email or text."
                  /* eslint-enable max-len */
                  imageUrl={ asset`img/ziplogix/process-01.svg` }
                  number={ 1 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title="Check their profile"
                  /* eslint-disable max-len */
                  text="Once the prospect clicks the link, they complete their profile and receives their Trust Stamp which they can send directly to you. Once sent, they have effectively provided you with a proof of their identity."
                  /* eslint-enable max-len */
                  imageUrl={ asset`img/ziplogix/process-02.svg` }
                  number={ 2 }
                />
              </div>
              <div className="ts-steps__item">
                <FeatureCard
                  title="Verify their identity once you meet"
                  /* eslint-disable max-len */
                  text="Within seconds of the user completing their profile, you will receive notification that their Trust Stamp is available to view. You now have a verified photograph that can be used to recognize them! Each time you view someone’s profile you will be auto-debited $1.99 to your Trust Stamp account."
                  /* eslint-enable max-len */
                  imageUrl={ asset`img/ziplogix/process-03.svg` }
                  number={ 3 }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderExplanationSection() {
    return (
      <section className={ cn`__section __section--explanation` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              title={
                <span>
                  Using Trust Stamp for
                  <span className={ cn`__section--explanation--important` }> zipLogix</span>
                </span>
              }
            />
            { /* eslint-disable max-len */ }
            <div className={ cn`__description` }>
              <p className={ cn`__dimmed` }>
                Trust Stamp is an alternative to expensive background checks and can save the hassle of trying to spot a fake ID. Trust Stamp can be easily explained to strangers as a time saving tool that confers VIP Status.
              </p>
              <p className={ cn`__dimmed` }>
                In addition to the user’s verified name, photograph and Trust Score, the Trust Stamp is color coded and you can quickly make an informed judgment as to the level of trust and safeguards that are appropriate.
              </p>
              <p className={ cn`__dimmed` }>
                For less than the cost of a cup of coffee, you can protect yourself and your clients while saving time and money that could be wasted by a traditional background check.
              </p>
            </div>
            { /* eslint-enable max-len */ }
          </div>
        </div>
      </section>
    );
  }

  renderAppPresentationSection() {
    return (
      <section className={ cn`__section __section--app-presentation` }>
        <div className="ts-container">
          <div className={ cn`__section-content` }>
            <PageTitle
              align="left"
              size="big"
              title="How Trust Stamp Works"
            />
            <div className={ cn`__description` }>
              { /* eslint-disable max-len */ }
              <p className={ cn`__dimmed` }>
                In a sub 2-minute process, a user signs-up using a “selfie” (a micro-video or photo burst), takes a picture of their driver’s license, and logs in with one or more social media or other online accounts. Proprietary AI and proof-of-life software analyze the ID document, checks the selfie for valid liveness characteristics and compares it to the various photo images provided both online and on the driver’s license. Accounts are mined to "age" the proven existence of the subject based on postings and the extent, longevity, and scope of their interactions with 3rd parties. Once a positive ID is made, the user can review their profile and share it.
              </p>
              <p className={ cn`__dimmed` }>
                Trust Stamp follows bank-level data security protocols and all data is transmitted via encrypted channels. In order to protect customer privacy, only necessary information such as Trust Stamp login credentials are ever stored.
              </p>
              { /* eslint-enable max-len */ }
            </div>
          </div>
        </div>
        <img
          alt=""
          src="img/ziplogix/appscreen-profile.png"
          className={ cn`__app-presentation-phone-screen` }
        />
      </section>
    );
  }

  renderSignUpSection() {
    return (
      <section className={ cn`__section __section--sign-up` }>
        <div className="ts-container">
          <p className={ cn`__sign-up-text` }>
            Sign up today for free. As a founder member you will never pay to maintain your account.
          </p>
          <div className={ cn`__sign-up-button--bottom` }>
            <Button theme="ternary" onClick={ this.handleSignUpButtonClick }>
              Get Started
            </Button>
          </div>
        </div>
      </section>
    );
  }

  handleSignUpButtonClick = () => {
    this.navigate('/SignUp.html');
  }

  renderNewsletterSection() {
    return (
      <section className={ cn`__section __section--newsletter` }>
        <div className="ts-container">
          <PageTitle
            title="Newsletter"
            color="white"
          />
          <div className={ cn`__newsletter-title` }>
            Subscribe to our newsletter and stay updated
          </div>
          <div className={ cn`__newsletter` }>
            <NewsletterBox showTitle={ false } />
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        { this.renderIntroSection() }
        { this.renderFeaturesSection() }
        { this.renderExplanationSection() }
        { this.renderAppPresentationSection() }
        { this.renderSignUpSection() }
        { this.renderNewsletterSection() }
      </div>
    );
  }
}
