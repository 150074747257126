import React from 'react';
import BaseComponent from 'components/BaseComponent';
import { FEEDBACKS } from 'sf/components/Webcam/constants';
import webcam from 'sf/models/webcam';

export default class WebcamFeedback extends BaseComponent {
  className = 'ts-WebcamFeedback';

  state = {};

  componentDidMount() {
    this.syncStateWithModel(webcam, Object.keys(FEEDBACKS));
  }

  render() {
    return (
      <div
        { ...this.pickProps() }
        className={ this.rootcn() }
      >
        {
          Object.entries(FEEDBACKS)
            .filter(([feedback]) => this.props.feedbacks.includes(feedback))
            .map(([key, values]) => (
              <span
                key={ key }
                className={ this.cn({
                  '__feedback': true,
                  '__feedback--bad': !this.state[key],
                }) }
              >
                { values[!!this.state[key]] }
              </span>
            ))
        }
      </div>
    );
  }
}
