import React from 'react';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import PageTitle from 'components/PageTitle';
import ResetPassword from 'components/ResetPassword';
import { DATA, STEPS } from 'components/ResetPassword/ResetPassword.constants';
import { getAllChildRoutesFromProps } from 'helpers';
import BasePage from 'pages/BasePage';
import Breadcrumbs from 'sf/components/Breadcrumbs';


export class BusinessResetPasswordPage extends BasePage {
  className = 'ts-ResetPasswordPage';
  routeStep = '';

  static propTypes = {
    route: PropTypes.shape({
      routeStep: PropTypes.oneOf(Object.keys(STEPS)).isRequired,
    }),
  };

  static defaultProps = {
    route: {
      routeStep: STEPS.SELFIE,
    },
  };

  render() {
    this.routeStep = _get(this.props, 'route.routeStep');
    if (!this.props.route) return null;
    if (!this.routeStep) return null;
    const { title, subtitle } = DATA[this.routeStep];
    return (
      <div className={ this.rootcn() }>
        <h1 className={ this.cn`__header` }>Reset your password</h1>
        <div className={ this.cn('__breadcrumbs') }>
          <Breadcrumbs
            allRoutes={ getAllChildRoutesFromProps(this.props) }
            clickable={ false }
            routeParent="/business/ResetPassword.html"
          />
        </div>
        <div className={ this.cn`ts-container` }>
          <div className={ this.cn`__page-title` }>
            <PageTitle
              subtitle={ subtitle }
              title={ title }
            />
          </div>
          <ResetPassword
            code={ _get(this.props, 'location.query.code') }
            step={ this.routeStep }
          />
        </div>
      </div>
    );
  }
}

export default BusinessResetPasswordPage;
