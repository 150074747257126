import React from 'react';
import concat from 'lodash/concat';
import _get from 'lodash/get';
import is from 'next-is';
import { Redirect, Route, IndexRoute } from 'react-router';
import { compose, withProps } from 'recompose';
import reduxStore from 'reduxStore';

import { brandName } from 'brandingVariables';
import LPPageHeader from 'components/LPPageHeader';
import PageHeader from 'components/PageHeader';
import {
  STEPS as BUSINESS_PASSWORD_RESET_STEPS,
  DATA as BUSINESS_PASSWORD_RESET_DATA,
} from 'components/ResetPassword/ResetPassword.constants';
import { BRANDINGS, ROLES, ROUTES } from 'constants';
import App from 'layout/App';
import {
  STEPS as BUSINESS_SIGN_UP_STEPS,
  DATA as BUSINESS_SIGN_UP_DATA,
} from 'models/businessSignUp';
import login from 'models/login';
import publicUser from 'models/publicUser';
import user from 'models/user';
import AdminPanel from 'pages/AdminPanel';
import { ROUTES as ADMIN_APP_ROUTES } from 'components/AdminPanel/AdminPanel.constants';
import AgentConfirmation from 'pages/AgentConfirmation';
import BusinessLogIn from 'pages/BusinessLogIn';
import BusinessResetPasswordPage from 'pages/BusinessResetPasswordPage';
import BusinessSignUpPage from 'pages/BusinessSignUpPage';
import ContactUs from 'pages/ContactUs';
import EmailChangeConfirmation from 'pages/EmailChangeConfirmation';
import EmailConfirmation from 'pages/EmailConfirmation';
import ErrorPage from 'pages/ErrorPage';
import FacebookMarketplaceIndex from 'pages/FacebookMarketplaceIndex';
import Index from 'pages/Index';
import AskSomeoneToVerify from 'pages/AskSomeoneToVerify';
import LogIn from 'pages/LogIn';
import MarketplaceHeroesIndex from 'pages/MarketplaceHeroesIndex';
import MarketplaceHeroesJoinIndex from 'pages/MarketplaceHeroesJoinIndex';
import MarketplaceHeroesFAQ from 'pages/MarketplaceHeroesFAQ';
import NotaryCamIndex from 'pages/NotaryCamIndex';
import P2PIndex from 'pages/P2PIndex';
import ZipLogixIndex from 'pages/ZipLogixIndex';
import OrganizationSupervisorIndex from 'pages/OrganizationSupervisorIndex';
import Profile from 'pages/Profile';
import * as ProfileActions from 'pages/Profile/Profile.actions';
import RealtorIndex from 'pages/RealtorIndex';
import ResetPINPage from 'pages/ResetPINPage';
import SelfTest from 'pages/SelfTest';
import SessionExpired from 'pages/SessionExpired';
import SignUpBasicInfo from 'pages/SignUpBasicInfo';
import SignUpPage from 'pages/SignUpPage';
import SignUpPayment from 'pages/SignUpPayment';
import SignUpPhone from 'pages/SignUpPhone';
import VerifyID from 'pages/VerifyID';
import VerifyIDBack from 'pages/VerifyID/VerifyIDBack';
import SignUpVideoUpload from 'pages/SignUpVideoUpload';
import SignUpVideoReUpload from 'pages/SignUpVideoReUpload';
import SignUpSelectSocialnetwork from 'pages/SignUpSelectSocialnetwork';
import SignUpComplete from 'pages/VerificationComplete';
import TermsAndConditions from 'pages/TermsAndConditions';
import UserSettings from 'pages/UserSettings';
import Wallet from 'pages/Wallet';
import WalletInsightForm from 'pages/WalletInsightForm';
import WalletInsightProfile from 'pages/WalletInsightProfile';
import WalletInsightRegistration from 'pages/WalletInsightRegistration';
import Cookies from 'pages/Cookies';
import requireAuthorization from 'hoc/RequireAuthorization';
import requireTermsAcceptance from 'hoc/RequireTermsAcceptance';
import requireUserRole from 'hoc/RequireUserRole';
import ExternalRedirect from 'components/ExternalRedirect';
import { navigate, cloneClass, pascalCase } from 'sf/helpers';

const IndexExternalRedirect = compose(
  withProps({
    to: INDEX_REDIRECT_URL,
  })
)(ExternalRedirect);

const renderBusinessSignUpRoutes = () => Object.keys(BUSINESS_SIGN_UP_STEPS).map((step) => (
  <Route
    breadcrumbTitle={ BUSINESS_SIGN_UP_DATA[step].breadcrumb }
    component={ BusinessSignUpPage }
    key={ `${step}` }
    path={ `/business/SignUp${pascalCase(step)}.html` }
    stepName={ step }
  />));

const renderBusinessResetPasswordRoutes = () => Object.keys(BUSINESS_PASSWORD_RESET_STEPS)
  .map((step) => (
    <Route
      breadcrumbTitle={ BUSINESS_PASSWORD_RESET_DATA[step].breadcrumb }
      component={ BusinessResetPasswordPage }
      key={ step }
      path={ `/business/ResetPassword/${pascalCase(step)}.html` }
      routeStep={ step }
    />
  ));

/* eslint-disable max-len */
const businessLoginRoutes = [
  /* eslint-enable */
  // skip social networks selection for ORGANIZATION_SUPERVISOR branding
  BRAND_NAME === BRANDINGS.ORGANIZATION_SUPERVISOR ?
    <Redirect
      key="redirect"
      from="/business/LogIn.html"
      to="/business/LogInCredentials.html"
    />
    :
    <Route
      key="login"
      path="/business/LogIn.html"
      component={ LogIn }
    />,
  <Route
    key="credentials"
    path="/business/LogInCredentials.html"
    component={ BusinessLogIn }
  />,
  <Route
    key="reset-password"
    component={ (props) => props.children }
    path="/business/ResetPassword.html"
  >
    { renderBusinessResetPasswordRoutes() }
  </Route>,
  <Route
    key="login-social-network"
    path="/business/LogInSelectSocialNetwork.html"
    component={ LogIn }
  />,
  <Route
    key="login-pin"
    path="/business/LogInPIN.html"
    component={ LogIn }
  />,
  <Route
    key="login-reset-pin"
    path="/business/LogInResetPIN.html"
    component={ LogIn }
  />,
  <Route
    key="selfie"
    path="/business/LogInSelfie.html"
    component={ BusinessLogIn }
    isBusinessLogInSelfieStep={ true }
  />,
  <Route
    key="session-expired"
    path="/business/SessionExpired.html"
    component={ SessionExpired }
  />,
];

const isBusinessUser = (nextState, replace, callback) => {
  if (login.get('businessLogIn') || user.hasRole(ROLES.BUSINESS_USER)) {
    replace(`/business/${nextState.location.pathname.replace(/^\//, '')}`);
  }
  callback();
};

function isScoreUser(nextState, replace, callback) {
  if (is.browser() && user.get('isSignedIn') && !user.hasRole(ROLES.SCORE_USER)) {
    replace('/AskSomeoneToVerify.html');
  }
  callback();
}

// this is a good place to prepare your state/store before App is rendered
function beforeApp(nextState) {
  const queryParams = _get(nextState, 'location.query', {});
  if (queryParams.mph_discount_code) {
    // (,╯︵╰,)
    // I'm so sorry for this nasty HACK: with a timeout, but it's the only way I know right now
    // to dispatch this initial action AFTER and NOT BEFORE persistor/REHYDRATE
    //  ¯\_(ツ)_/¯
    // TODO: resolve this issue; make saveMPHDiscountCode and helpcenter actions great again
    setTimeout(() => {
      reduxStore.dispatch(ProfileActions.saveMPHDiscountCode(queryParams.mph_discount_code));
    });
  }
}

const getIndexForBranding = (branding) => {
  switch (branding) {
    case BRANDINGS.REALTOR:
      return {
        IndexComponent: RealtorIndex,
        IndexHeaderComponent: LPPageHeader,
        indexHeaderTheme: null,
        indexClassName: 'ts-realtor-landing',
      };
    case BRANDINGS.ORGANIZATION_SUPERVISOR:
      return {
        IndexComponent: Index,
        IndexHeaderComponent: PageHeader,
        indexHeaderTheme: 'light',
        indexClassName: null,
      };
    default:
      return {
        IndexComponent: FEATURES.INDEX_REDIRECT ?
          IndexExternalRedirect :
          Index,
        IndexHeaderComponent: PageHeader,
        indexHeaderTheme: 'light',
        indexClassName: null,
      };
  }
};

const {
  IndexComponent,
  indexClassName,
  indexHeaderTheme,
  IndexHeaderComponent,
} = getIndexForBranding(brandName);

const indexRoute = [
  <IndexRoute
    component={ IndexComponent }
    className={ indexClassName }
    headerTheme={ indexHeaderTheme }
    key="index"
    layoutProps={ {
      HeaderComponent: IndexHeaderComponent,
      headerProps: {
        background: true,
        showRegisterButton: true,
      },
    } }
    showFooterLogo={ false }
  />,
];

const businessSignUpRoutes = [
  <Route
    component={ (props) => props.children }
    key="business-signup"
    path="/business/SignUp.html"
  >
    { renderBusinessSignUpRoutes() }
  </Route>,
];

const confirmationRoutes = [
  <Route
    key="email-change-confirmation"
    path="/EmailChangeConfirmation.html"
    component={ EmailChangeConfirmation }
  />,
  <Route
    key="email-confirmation"
    path="/EmailConfirmation.html"
    component={ EmailConfirmation }
  />,
  <Route
    key="agent-confirmation"
    path="/AgentConfirmation.html"
    component={ AgentConfirmation }
  />,
];

const signUpRoutes = [
  <Route
    key="signup"
    path="/SignUp.html"
    component={ SignUpPage }
  >
    <Route
      path="/SignUpSelectSocialnetwork.html"
      component={ SignUpSelectSocialnetwork }
      breadcrumbTitle="Log in information"
    />
    <Route
      path="/SignUpPhone.html"
      component={ SignUpPhone }
      breadcrumbTitle="Phone verification"
    />
    <Route
      path="/SignUpVideo.html"
      component={ SignUpVideoUpload }
      breadcrumbTitle="Verify Your POL"
    />
    <Route
      path="/SignUpPhoto.html"
      component={ VerifyID }
      breadcrumbTitle="Verify Your Identity"
    />
    <Route
      path="/SignUpPhotoBack.html"
      component={ VerifyIDBack }
      breadcrumbTitle="Verify"
    />
    <Route
      path="/SignUpInformation.html"
      component={ SignUpBasicInfo }
      breadcrumbTitle="Verify"
    />
    <Route
      path="/SignUpPayment.html"
      component={ SignUpPayment }
      breadcrumbTitle="Verify"
    />
    <Route
      path="/SignUpComplete.html"
      component={ SignUpComplete }
      breadcrumbTitle="Verify"
    />
    <Route
      path="/SignUpRetakeVideo.html"
      component={ SignUpVideoReUpload }
    />
  </Route>,
];

const profileRoutes = [
  <Route
    key="profile-id"
    path="/:shortProfileID-profile.html"
    component={ requireTermsAcceptance(cloneClass(Profile)) }
    user={ publicUser }
  />,
  <Route
    key="profile-preview"
    path="/ProfilePreview.html"
    component={ cloneClass(Profile) }
    mode="PREVIEW"
  />,
  <Route
    key="profile"
    path="/Profile.html"
    component={ Profile }
    onEnter={ isScoreUser }
  />,
];

const settingsRoutes = [
  <Route
    key="settings"
    path="/Settings.html"
    component={ requireAuthorization(
      requireUserRole(UserSettings, [ROLES.BUSINESS_USER, ROLES.ORGANIZATION_AGENT])
    ) }
  />,
  <Route
    key="wallet"
    path="/Wallet.html"
    component={ requireAuthorization(Wallet) }
  >
    <Route
      path="/Wallet-:paymentsHistoryPage.html"
      component={ requireAuthorization(Wallet) }
    />
  </Route>,
];

const inviteRoutes = [
  <Route
    key="ask-to-verify"
    path="/AskSomeoneToVerify.html"
    component={ AskSomeoneToVerify }
  />,
];

const loginRoutes = [
  <Route
    key="login"
    path="/LogIn.html"
    component={ LogIn }
    onEnter={ isBusinessUser }
  >
    <Route
      path="/LogInSelectSocialNetwork.html"
      component={ LogIn }
    />
    <Route
      path="/LogInSelfie.html"
      component={ LogIn }
    />
    <Route
      path="/LogInPIN.html"
      component={ LogIn }
    />
    <Route
      path="/LogInResetPIN.html"
      component={ LogIn }
    />
  </Route>,
];

const resetPINRoutes = [
  <Route
    key="select-social"
    path="/ResetPIN/SelectSocialNetwork.html"
    component={ ResetPINPage }
  />,
  <Route
    key="reset-pin-selfie"
    path="/ResetPIN/Selfie.html"
    component={ ResetPINPage }
    isResetPINSelfieStep={ true }
  />,
  <Route
    key="reset-pin-pin"
    path="/ResetPIN/PIN.html"
    component={ ResetPINPage }
  />,
];

const auxilliaryRoutes = [
  <Route
    key="terms-and-conditions"
    path="/TermsAndConditions.html"
    component={ TermsAndConditions }
  />,
  <Route
    key="contact-us"
    path={ ROUTES.CONTACT_US }
    component={ ContactUs }
  />,
  <Route
    key="session-expired"
    path="/SessionExpired.html"
    component={ SessionExpired }
  />,
  <Route
    key="cookies-content"
    path={ ROUTES.COOKIES }
    component={ Cookies }
  />,
];

const lpRoutes = [
  /* P2P */
  <Route
    path="/P2P"
    headerTheme="light"
    component={ P2PIndex }
    brandingName="P2P"
  />,
  <Route
    brandingName="P2P"
    component={ ContactUs }
    path="/P2PContactUs.html"
  />,
  /* FACEBOOK MARKETPLACE */
  <Route
    path="/facebook-marketplace"
    headerTheme="light"
    component={ FacebookMarketplaceIndex }
    brandingName="fb-marketplace"
  />,
  <Redirect
    from="/facebook-marketplace"
    to="/p2p"
  />,
  /* NOTARYCAM */
  <Route
    path="/notarycam"
    headerTheme="light"
    component={ NotaryCamIndex }
    brandingName="notarycam"
  />,
  /* ZIPLOGIX */
  <Route
    path="/ziplogix"
    headerTheme="light"
    component={ ZipLogixIndex }
    brandingName="ziplogix"
  />,
  /* MARKETPLACE HEROES */
  <Route
    brandingName="marketplaceHeroes"
    component={ MarketplaceHeroesIndex }
    path="/mph"
  />,
  <Route
    brandingName="marketplaceHeroes"
    component={ ContactUs }
    path="/MarketplaceHeroesContactUs.html"
    recipient="social"
  />,
  <Route
    brandingName="marketplaceHeroes"
    component={ MarketplaceHeroesFAQ }
    path="/MarketplaceHeroesFAQ.html"
  />,
  <Route
    brandingName="marketplaceHeroes"
    component={ MarketplaceHeroesJoinIndex }
    isHeaderFloating={ true }
    headerTheme="light"
    path="/MarketplaceHeroesJoin.html"
  />,
  <Redirect
    from="/marketplace-heroes"
    to="/mph"
  />,
  <Redirect
    from="/marketplaceheroes"
    to="/mph"
  />,
  <Redirect
    from="/mph-:discountCode"
    to="/MarketplaceHeroesJoin.html?mph_discount_code=:discountCode"
  />,
];

const adminPanelAccessDeniedCallback = ({ returnUrl }) => {
  if (is.browser()) {
    navigate(`/business/LogInCredentials.html#next=${encodeURIComponent(returnUrl)}`);
  }
};

const adminAppRoutes = [
  <Route
    key="adminpanel"
    path={ ADMIN_APP_ROUTES.INDEX }
    layout="AdminPanel"
    component={ AdminPanel }
  />,
  <Route
    key="adminpanellogin"
    path={ ADMIN_APP_ROUTES.LOGIN }
    component={ OrganizationSupervisorIndex }
    indexClassName="ts-realtor-landing"
    headerTheme={ null }
    layoutProps={ {
      HeaderComponent: LPPageHeader,
      headerProps: {
        background: true,
        showRegisterButton: true,
      },
      manualHeaderFloat: true,
    } }
    showFooterLogo={ true }
  />,
  <Route
    key="adminpanelinvite"
    path={ ADMIN_APP_ROUTES.INVITE }
    layout="AdminPanel"
    component={ requireAuthorization(AdminPanel, {
      onAccessDenied: adminPanelAccessDeniedCallback,
    }) }
  />,
  <Route
    key="adminpanelverification"
    path={ ADMIN_APP_ROUTES.VERIFICATION }
    layout="AdminPanel"
    component={ requireAuthorization(AdminPanel, {
      onAccessDenied: adminPanelAccessDeniedCallback,
    }) }
  />,
  <Route
    key="adminpanelbalance"
    path={ ADMIN_APP_ROUTES.BALANCE }
    layout="AdminPanel"
    component={ requireAuthorization(AdminPanel, {
      onAccessDenied: adminPanelAccessDeniedCallback,
    }) }
  />,
  <Route
    key="adminpanelcustomize"
    path={ ADMIN_APP_ROUTES.CUSTOMIZE }
    layout="AdminPanel"
    component={ requireAuthorization(AdminPanel, {
      onAccessDenied: adminPanelAccessDeniedCallback,
    }) }
  />,
];

const walletInsightRoutes = [
  <Route
    key="wallet-insight-form"
    path={ ROUTES.WALLET_INSIGHT_FORM }
    component={ requireAuthorization(WalletInsightForm) }
  />,
  <Route
    key="wallet-insight-profile"
    path={ ROUTES.WALLET_INSIGHT_PROFILE }
    component={ requireAuthorization(WalletInsightProfile) }
  />,
  <Route
    key="wallet-insight-registration"
    path={ ROUTES.WALLET_INSIGHT_REGISTRATION }
    component={ requireAuthorization(WalletInsightRegistration) }
  />,
];

const testRoutes = [
  <Route
    key="selfie-test"
    path="/SelfTest.html"
    component={ SelfTest }
  />,
];

const errorRoutes = [
  <Route
    key="error"
    path="/Error.html"
    component={ ErrorPage }
  />,
  <Route
    key="*"
    path="*"
    component={ ErrorPage }
  />,
];

export default (
  <Route path="/(index.html)" component={ App } onEnter={ beforeApp }>
    { concat(
      indexRoute,
      businessSignUpRoutes,
      [
        BRANDINGS.REALTOR,
        BRANDINGS.ORGANIZATION_SUPERVISOR,
      ].includes(brandName) && businessLoginRoutes,
      confirmationRoutes,
      signUpRoutes,
      profileRoutes,
      settingsRoutes,
      inviteRoutes,
      loginRoutes,
      resetPINRoutes,
      auxilliaryRoutes,
      false && lpRoutes,
      adminAppRoutes,
      brandName === BRANDINGS.REALTOR && walletInsightRoutes,
      testRoutes,
      errorRoutes,
    ) }
  </Route>
);
